import { gql } from "@apollo/client";

export const LOAD_MOOVS_NETWORK_OPERATOR_QUERY = gql`
  query MoovsNetworkOperator($id: ID!) {
    node(id: $id) {
      id
      ... on MoovsNetworkOperator {
        createdAt
        updatedAt
        operatorName
        operatorEmail
        operatorPhone
        operatorAddress
        serviceArea
        contactName
        logoUrl
        internalNote
        websiteUrl
        isMoovsLiteOperator
        vehicleTypes {
          typeName
          typeSlug
          iconCategory
        }
        paymentMethods {
          id
          createdAt
          updatedAt
          cardNote
          isPrimary
          payerId
          stripeStatus
          billingDetails {
            name
          }
          card {
            expMonth
            expYear
            last4
            brand
          }
        }
        vehicles {
          id
          name
          capacity
          available
          description
          licensePlate
          weekendHourlyCost
          weekdayHourlyCost
          weekendMinMinutes
          weekdayMinMinutes
          minimumTotalBaseRate
          deadheadRatePerMile
          tripRatePerMile
          totalDeadheadDurationMinutes
          useTieredTransfer
          useTieredHourly
          useTieredHourlyWeekend
          tieredPricingTransfer {
            type
            tiers {
              lowerLimit
              upperLimit
              incrementalAmt
              rate
              type
            }
          }
          tieredPricingHourly {
            type
            tiers {
              lowerLimit
              upperLimit
              incrementalAmt
              rate
              type
            }
          }
          tieredPricingHourlyWeekend {
            type
            tiers {
              lowerLimit
              upperLimit
              incrementalAmt
              rate
              type
            }
          }
          farmAffiliateName
          farmAffiliateVariant
          isMoovsLiteOperator
          settings {
            id
            weekends
          }
          cancellationPolicy {
            id
            variant
            refund100 {
              periodName
              periodValue
              active
            }
            refund50 {
              periodName
              periodValue
              active
            }
            refund25 {
              periodName
              periodValue
              active
            }
          }
          insurancePolicy {
            id
            insuranceCompany
            policyNumber
          }
          features {
            id
            name
            category
          }
          photos {
            id
            url
            photoIndex
          }
          vehicleType {
            typeName
            typeSlug
            iconCategory
          }
        }
      }
    }
  }
`;

export const UPDATE_OPERATOR_MOOVS_NETWORK_OPERATOR = gql`
  mutation UpdateOperatorMoovsNetworkOperator(
    $input: UpdateOperatorMoovsNetworkOperatorInput!
  ) {
    updateOperatorMoovsNetworkOperator(input: $input) {
      paymentMethod {
        id
        card {
          id
          expMonth
          expYear
          last4
          brand
        }
        stripeStatus
        cardNote
      }
    }
  }
`;
