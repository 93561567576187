import React from "react";

import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import { TrashIcon } from "design-system/icons";
import { grayDark, black, moovsBlue } from "design-system/colors";
import { useOperator, useScreenSize } from "globals/hooks";
import MoovsToggleSwitch from "components/MoovsToggleSwitch";
import MoovsTooltip from "components/MoovsTooltip";
import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";
import { NumberFormatDollar } from "design-system/components/inputs/NumberFormat";
import {
  DistanceUnitEnum,
  PricingVariant,
  Tier,
  TieredPricingVariant,
} from "types";
import usePricingTiersFieldArray from "../hooks/usePricingTiersFieldArray";
import { useVehiclePricing } from "../context";
import {
  PricingVariantToggleButtonOption,
  getTieredTransferPricingVariantToggleButtons,
  tieredHourlyPricingVariantToggleButtons,
} from "../constant";
import { ControllerWrapper } from "./ControllerWrapper";

type PricingTypeVariant = "transfer" | "hourly" | "weekendHourly";

type PricingTiersBlockProps = {
  variant: PricingTypeVariant;
} & (
  | {
      mode: "create";
      // TODO: these will be updated for create form for pricing
      tieredPricingTypeFieldName?: never;
      tieredPricingTiersFieldName?: never;
    }
  | {
      mode: "update";
      tieredPricingTypeFieldName:
        | "vehicle.tieredPricingTransfer.type"
        | "vehicle.tieredPricingHourly.type"
        | "vehicle.tieredPricingHourlyWeekend.type";
      tieredPricingTiersFieldName:
        | "vehicle.tieredPricingTransfer.tiers"
        | "vehicle.tieredPricingHourly.tiers"
        | "vehicle.tieredPricingHourlyWeekend.tiers";
    }
);

type TieredPricingMapType = {
  [key in PricingTypeVariant]: {
    title: string;
    tieredPricingType: string;
    tieredPricingTiers: Tier[];
    tieredPricingVariantButtons: PricingVariantToggleButtonOption[];
    error: boolean;
  };
};

const MAX_TIERS = 5;
const TIERED_PRICING_ERROR_MSG =
  "Please fill in the above fields or disable tiered pricing to continue";

const TIERED_PRICING_VARIANT_BUTTONS = [
  {
    value: TieredPricingVariant.Incremental,
    content: (
      <Box display="flex" alignItems="center">
        <Typography variant="body2" mr={1}>
          Incremental
        </Typography>
        <MoovsTooltip tooltipText="Each tier is calculated individually and combined for the total." />
      </Box>
    ),
  },
  {
    value: TieredPricingVariant.Fixed,
    content: (
      <Box display="flex" alignItems="center">
        <Typography variant="body2" mr={1}>
          Fixed tier
        </Typography>
        <MoovsTooltip tooltipText="Applies the rate of the tier matching the full trip distance." />
      </Box>
    ),
  },
];

const TIER_RANGE_INPUT_PROPS = {
  min: 0,
  max: 9999,
  step: 1,
  pattern: "[0-9]*",
  // prevent decimal input
  onKeyDown: (e) => {
    if (
      e.key === "." ||
      e.key === "e" ||
      e.key === "E" ||
      e.key === "-" ||
      e.key === "+"
    ) {
      e.preventDefault();
    }
  },
};

export function PricingTiersBlock(props: PricingTiersBlockProps) {
  const { variant, tieredPricingTypeFieldName, tieredPricingTiersFieldName } =
    props;

  // hooks
  const { isMobileView } = useScreenSize();
  const operator = useOperator();
  const distanceUnit = operator?.settings?.distanceUnit;
  const isMiles = distanceUnit === DistanceUnitEnum.Miles;
  const {
    isReadOnly,
    isDisabled,
    control,
    vehiclePricing,
    formContext,
    tieredPricingErrors,
  } = useVehiclePricing();
  const {
    fields: tieredPricingTiersFromForm,
    insert,
    remove,
    replace,
  } = usePricingTiersFieldArray(control, tieredPricingTiersFieldName);
  const { watch, getValues, trigger } = formContext || {};

  // derived state
  const tieredPricingMap: TieredPricingMapType = {
    transfer: {
      title: "Transfer Rate",
      tieredPricingType:
        vehiclePricing && vehiclePricing.tieredPricingTransfer?.type,
      tieredPricingTiers:
        vehiclePricing && vehiclePricing.tieredPricingTransfer?.tiers,
      tieredPricingVariantButtons:
        getTieredTransferPricingVariantToggleButtons(isMiles),
      error: tieredPricingErrors.tieredPricingTransfer,
    },
    hourly: {
      title: "Hourly Rate",
      tieredPricingType:
        vehiclePricing && vehiclePricing.tieredPricingHourly?.type,
      tieredPricingTiers:
        vehiclePricing && vehiclePricing.tieredPricingHourly?.tiers,
      tieredPricingVariantButtons: tieredHourlyPricingVariantToggleButtons,
      error: tieredPricingErrors.tieredPricingHourly,
    },
    weekendHourly: {
      title: "Weekend Hourly Rate",
      tieredPricingType:
        vehiclePricing && vehiclePricing.tieredPricingHourlyWeekend?.type,
      tieredPricingTiers:
        vehiclePricing && vehiclePricing.tieredPricingHourlyWeekend?.tiers,
      tieredPricingVariantButtons: tieredHourlyPricingVariantToggleButtons,
      error: tieredPricingErrors.tieredPricingHourlyWeekend,
    },
  };

  const tieredPricingType = isReadOnly
    ? tieredPricingMap[variant].tieredPricingType
    : watch(tieredPricingTypeFieldName);

  const tieredPricingTiers = isReadOnly
    ? tieredPricingMap[variant].tieredPricingTiers
    : tieredPricingTiersFromForm;

  // helper functions
  // Update tier limits whenever there is a change in the tiers
  const updateTierLimits = (changedField: "incrementalAmt" | "upperLimit") => {
    const currentTiers = getValues(tieredPricingTiersFieldName);
    if (!currentTiers?.length) return;

    const updatedTiers = [...currentTiers];

    // Update all tiers' limits
    for (let i = 0; i < updatedTiers.length; i++) {
      if (i === 0) {
        // First tier always has lower limit set to 0
        if (changedField === "incrementalAmt") {
          updatedTiers[i] = {
            ...updatedTiers[i],
            lowerLimit: 0,
            upperLimit: updatedTiers[i].incrementalAmt,
          };
        } else {
          // upperLimit changed
          updatedTiers[i] = {
            ...updatedTiers[i],
            lowerLimit: 0,
            incrementalAmt: updatedTiers[i].upperLimit,
          };
        }
      } else {
        // all other tiers should have lower limit set to the upper limit of the previous tier
        const prevUpperLimit = updatedTiers[i - 1].upperLimit;
        const isLastTier = i === updatedTiers.length - 1;

        if (isLastTier) {
          // Last tier always has upper limit set to -1
          updatedTiers[i] = {
            ...updatedTiers[i],
            lowerLimit: prevUpperLimit,
            upperLimit: -1,
            incrementalAmt: -1,
          };
        } else {
          if (changedField === "incrementalAmt") {
            updatedTiers[i] = {
              ...updatedTiers[i],
              lowerLimit: prevUpperLimit,
              upperLimit:
                updatedTiers[i].incrementalAmt === null
                  ? null
                  : prevUpperLimit + updatedTiers[i].incrementalAmt,
            };
          } else {
            // upperLimit changed
            updatedTiers[i] = {
              ...updatedTiers[i],
              lowerLimit: prevUpperLimit,
              incrementalAmt:
                updatedTiers[i].upperLimit === null
                  ? null
                  : updatedTiers[i].upperLimit - prevUpperLimit,
            };
          }
        }
      }
    }

    replace(updatedTiers);
    trigger(tieredPricingTiersFieldName); // re-run validation
  };

  const getTierModifierText = () =>
    variant === "transfer" ? (isMiles ? "miles" : "km") : "hours";

  // handlers
  const handleAddTier = () => {
    if (tieredPricingTiers.length >= MAX_TIERS) {
      return;
    }

    // insert at second to last
    insert(Math.max(1, tieredPricingTiers.length - 1), {
      lowerLimit: null,
      upperLimit: null,
      incrementalAmt: null,
      rate: null,
      type:
        variant === "transfer"
          ? PricingVariant.PerMile
          : PricingVariant.PerHour,
    });

    updateTierLimits("upperLimit");
  };

  const handleRemoveTier = (index: number) => {
    remove(index);
    updateTierLimits("upperLimit");
  };

  const handleTierRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === "" ? null : parseInt(e.target.value);
    if (newValue < 0) {
      return;
    }

    if (newValue > 9999) {
      return 9999;
    }

    return newValue;
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
        alignItems={isMobileView ? "flex-start" : "center"}
        justifyContent="space-between"
        gap={1}
        mb={2}
      >
        <Typography variant="body2" color={grayDark}>
          {tieredPricingMap[variant].title}
        </Typography>

        <ControllerWrapper
          control={control}
          name={tieredPricingTypeFieldName}
          transformOnChange={(value) => value}
          deps={[tieredPricingTiersFieldName]}
          afterChange={(value) => {
            if (value === TieredPricingVariant.Fixed) {
              updateTierLimits("upperLimit");
            } else {
              updateTierLimits("incrementalAmt");
            }
          }}
        >
          <MoovsToggleSwitch
            dataTestId={`${variant}-tiered-pricing-variant-toggle`}
            options={TIERED_PRICING_VARIANT_BUTTONS}
            fullWidth={isMobileView}
            disabled={isDisabled}
            // placeholder values
            value={TieredPricingVariant.Incremental}
            onChange={() => {}}
            {...(isReadOnly && {
              value: vehiclePricing?.tieredPricingTransfer?.type,
            })}
          />
        </ControllerWrapper>
      </Box>

      <Box>
        {tieredPricingTiers?.map((tier, index) => {
          const isFirstTier = index === 0;
          const isLastTier = index === tieredPricingTiers.length - 1;

          return (
            <Box key={tier.id} mb={2}>
              <Box display="flex" justifyContent="space-between">
                <Box
                  display="flex"
                  flexDirection={isMobileView ? "column" : "row"}
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1.5}
                  width="100%"
                >
                  {/* Tier Range */}
                  <Box
                    display="flex"
                    flex={1}
                    width={isMobileView ? "100%" : "auto"}
                    mr={1}
                  >
                    {tieredPricingType === TieredPricingVariant.Incremental ? (
                      isLastTier ? (
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="body2"
                            color={isDisabled ? grayDark : black}
                          >
                            Remaining {getTierModifierText()}
                          </Typography>
                        </Box>
                      ) : (
                        <Box display="flex" alignItems="center" gap={1}>
                          <Typography
                            variant="body2"
                            color={isDisabled ? grayDark : black}
                          >
                            {isFirstTier ? "First" : "Next"}
                          </Typography>

                          <ControllerWrapper
                            control={control}
                            name={`${tieredPricingTiersFieldName}.${index}.incrementalAmt`}
                            transformOnChange={handleTierRateChange}
                          >
                            <TextField
                              data-testid={`${variant}-tier-${index}-incremental-amt-input`}
                              type="number"
                              sx={{ width: "100px" }}
                              inputProps={TIER_RANGE_INPUT_PROPS}
                              disabled={isDisabled}
                              onBlur={() => updateTierLimits("incrementalAmt")}
                              {...(isReadOnly && {
                                value:
                                  vehiclePricing?.tieredPricingTransfer?.tiers[
                                    index
                                  ]?.incrementalAmt,
                              })}
                            />
                          </ControllerWrapper>

                          <Typography
                            variant="body2"
                            color={isDisabled ? grayDark : black}
                          >
                            {getTierModifierText()}
                          </Typography>
                        </Box>
                      )
                    ) : isLastTier ? (
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="body2"
                          color={isDisabled ? grayDark : black}
                        >
                          {`${tier.lowerLimit || 0}+ ${getTierModifierText()}`}
                        </Typography>
                      </Box>
                    ) : (
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography
                          variant="body2"
                          color={isDisabled ? grayDark : black}
                        >
                          {`${tier.lowerLimit || 0} to`}
                        </Typography>

                        <ControllerWrapper
                          control={control}
                          name={`${tieredPricingTiersFieldName}.${index}.upperLimit`}
                          transformOnChange={handleTierRateChange}
                        >
                          <TextField
                            data-testid={`${variant}-tier-${index}-upper-limit-input`}
                            type="number"
                            sx={{ width: "100px" }}
                            inputProps={TIER_RANGE_INPUT_PROPS}
                            disabled={isDisabled}
                            onBlur={() => updateTierLimits("upperLimit")}
                            {...(isReadOnly && {
                              value:
                                vehiclePricing?.tieredPricingTransfer?.tiers[
                                  index
                                ]?.upperLimit,
                            })}
                          />
                        </ControllerWrapper>

                        <Typography
                          variant="body2"
                          color={isDisabled ? grayDark : black}
                        >
                          {getTierModifierText()}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  {/* Tier Rate */}
                  <Box
                    width={isMobileView ? "100%" : "auto"}
                    display="flex"
                    alignItems="center"
                  >
                    <ControllerWrapper
                      control={control}
                      name={`${tieredPricingTiersFieldName}.${index}.rate`}
                      transformOnChange={(e) =>
                        e.target.value === "" ? null : Number(e.target.value)
                      }
                    >
                      <TextField
                        id={`tier${index}-trip-rate-per-mile`}
                        data-testid={`${variant}-tier-${index}-rate-input`}
                        sx={{ width: "150px" }}
                        disabled={isDisabled}
                        variant="outlined"
                        InputProps={{
                          inputComponent: NumberFormatDollar as any,
                        }}
                        {...(isReadOnly && {
                          value:
                            vehiclePricing?.tieredPricingTransfer?.tiers[index]
                              ?.rate,
                        })}
                      />
                    </ControllerWrapper>

                    <ControllerWrapper
                      control={control}
                      name={`${tieredPricingTiersFieldName}.${index}.type`}
                      transformOnChange={(_, value) => value}
                    >
                      <MoovsToggleButtonGroup
                        testId={`${variant}-tier-${index}-rate-type-toggle`}
                        options={
                          tieredPricingMap[variant].tieredPricingVariantButtons
                        }
                        disabled={isDisabled}
                        value={
                          vehiclePricing?.tieredPricingTransfer?.tiers[index]
                            ?.type
                        }
                        onChange={() => {}}
                        sx={{ ml: 1 }}
                      />
                    </ControllerWrapper>
                  </Box>

                  <Box
                    display="flex"
                    flex={0.2}
                    justifyContent="flex-end"
                    ml={1}
                  >
                    {index !== 0 &&
                      index !== tieredPricingTiers.length - 1 &&
                      !isMobileView && (
                        <IconButton
                          data-testid={`${variant}-remove-tier-${index}`}
                          disabled={isDisabled}
                          onClick={() => handleRemoveTier(index)}
                          size="small"
                        >
                          <TrashIcon color={moovsBlue} />
                        </IconButton>
                      )}
                  </Box>
                </Box>

                {isMobileView && (
                  <Box display="flex" flex={0.2} justifyContent="flex-end">
                    {index !== 0 && index !== tieredPricingTiers.length - 1 && (
                      <IconButton
                        data-testid={`${variant}-remove-tier-${index}`}
                        disabled={isDisabled}
                        onClick={() => handleRemoveTier(index)}
                        size="small"
                      >
                        <TrashIcon color={moovsBlue} />
                      </IconButton>
                    )}
                  </Box>
                )}
              </Box>

              {index !== tieredPricingTiers.length - 1 && (
                <Divider sx={{ mt: 2 }} />
              )}
            </Box>
          );
        })}

        {tieredPricingTiers.length < MAX_TIERS && (
          <Button
            data-testid={`${variant}-add-tier-button`}
            variant="outlined"
            color="primary"
            disabled={isDisabled}
            onClick={handleAddTier}
          >
            + Add Tier
          </Button>
        )}

        {tieredPricingMap[variant].error && (
          <Typography
            variant="body2"
            color="error"
            display="flex"
            justifyContent={isMobileView ? "flex-start" : "flex-end"}
          >
            {TIERED_PRICING_ERROR_MSG}
          </Typography>
        )}
      </Box>
    </>
  );
}
