import React, { useState } from "react";
import first from "lodash/first";

import {
  Request,
  Trip,
  FarmedRouteStatusEnum,
  FarmRelationshipEnum,
} from "types";
import { DollarIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { useAnalytics, useScreenSize } from "globals/hooks";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";
import { deriveIsFarmee } from "globals/utils/farm";
import { normalizeTripsPricingForPricingDialog } from "pages/requests/utils/normalizeTripsPricingForPricingDialog";
import { PricingDialogPricingData } from "components/pricing/utils";

// constants
const { Farmee } = FarmRelationshipEnum;
const { Accepted, Completed } = FarmedRouteStatusEnum;

type UseUpdatePricingClickProps = {
  request: Request;
  trip: Trip;
  analyticsName?: string;
};

function useUpdatePricingClick(
  props: UseUpdatePricingClickProps
): EllipsisMenuOption & {
  pricings: PricingDialogPricingData[];
  open: boolean;
  onClose: () => void;
  onClick: () => void;
} {
  const { request, trip, analyticsName } = props;

  // hooks
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();

  // state
  const [tripPricingDialogOpen, setTripPricingDialogOpen] = useState(false);

  // derived state
  const route = first(trip.routes);
  const isFarmee = deriveIsFarmee(route);
  const { farmRelationship, farmStatus } = route;

  const pricings =
    normalizeTripsPricingForPricingDialog({
      trips: request.trips,
      requestOrderNumber: request.orderNumber,
      isFarmee,
    }) || [];

  // event handlers
  const handleTripPricingDialogOpen = () => setTripPricingDialogOpen(true);
  const handleTripPricingDialogClose = () => setTripPricingDialogOpen(false);

  const handleUpdatePricingClick = () => {
    analyticsName && track(analyticsName);
    handleTripPricingDialogOpen();
  };

  // derived state
  const isFarmeeAndNotAccepted =
    farmRelationship === Farmee &&
    farmStatus !== Accepted &&
    farmStatus !== Completed;

  return {
    onClick: handleUpdatePricingClick,
    text: "Update Pricing",
    icon: <DollarIcon color={grayDark} size="small" />,
    hideOption: !isMobileView || isFarmee,
    disableOption: !route.canEditPricing,
    tooltip: !route.canEditPricing
      ? {
          titleText: trip.cancelledAt
            ? "Cannot update pricing on a cancelled trip."
            : isFarmeeAndNotAccepted
            ? "Farm-in must be accepted"
            : "Cannot adjust pricing when there is an active invoice.",
        }
      : undefined,

    // dialog state
    pricings,
    open: tripPricingDialogOpen,
    onClose: handleTripPricingDialogClose,
  };
}

export { useUpdatePricingClick };
