import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import first from "lodash/first";

import { Box, Button, Divider, Typography } from "@mui/material";

import VehiclesDialog from "../../reservations/VehiclesDialog";
import VehicleCard from "../../vehicles/VehicleCard";
import { Trip, TripCategory, Vehicle } from "../../../types";
import {
  REMOVE_VEHICLE_FROM_ROUTE_MUTATION,
  UPDATE_ROUTE_DISPATCH_MUTATION,
} from "../../../globals/graphql";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import VehicleAvailabilityDialog from "../../../components/vehicles/VehicleAvailabilityDialog/VehicleAvailabilityDialog";
import { useAnalytics } from "../../../globals/hooks";
import FarmAffiliateVehiclesDialog from "components/reservations/FarmAffiliateVehiclesDialog";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type AddVehicleUpdateBlockProps = {
  trip: Trip;
  setSaveIndicatorState: (
    saveState: "loading" | "default" | "saved" | "error"
  ) => void;
  refetch: Function;
  mode?: "reservations" | "quotes" | "dispatch";
  farmAffiliateId?: string;
  hideVehicleAvailability?: boolean;
};

function AddVehicleUpdateBlock(props: AddVehicleUpdateBlockProps) {
  const {
    trip,
    setSaveIndicatorState,
    refetch,
    mode,
    farmAffiliateId,
    hideVehicleAvailability,
  } = props;

  // derived state
  const isShuttleReservation =
    trip.tripCategory === TripCategory.ShuttlePickUp ||
    trip.tripCategory === TripCategory.ShuttleReturn;

  // state
  const [swoopFarmVehiclesDialogOpen, setSwoopFarmVehiclesDialogOpen] =
    useState(false);
  const [open, setDialogOpen] = useState(false);
  const [openVehicleAvailabilityDialog, setOpenVehicleAvailabilityDialog] =
    useState(false);

  const [vehicleAvailabilityDialogProps, setVehicleAvailabilityDialogProps] =
    useState({
      title: "",
      vehicleId: "",
    });

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  const [updateRouteDispatch] = useMutation(UPDATE_ROUTE_DISPATCH_MUTATION, {
    onCompleted() {
      refetch();
      setSaveIndicatorState("saved");
      setDialogOpen(false);
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error(getErrorMessage(error) || "Error updating vehicle");
    },
  });

  const [removeVehicleFromRoute] = useMutation(
    REMOVE_VEHICLE_FROM_ROUTE_MUTATION,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
        snackbar.success("Successfully remove vehicle from route!");
      },
      onError(error) {
        setSaveIndicatorState("error");

        const errorMessage =
          getErrorMessage(error) || "Error removing vehicle from route";

        snackbar.error(errorMessage);
      },
    }
  );

  // event handlers
  const handleDialogClose = () => setDialogOpen(false);
  const handlePressReplace = () => setDialogOpen(true);

  const handleUpdateVehicle = (selectedVehicles: Vehicle[]) => {
    setSaveIndicatorState("loading");

    if (selectedVehicles.length > 0 && selectedVehicles[0]?.id) {
      updateRouteDispatch({
        variables: {
          input: {
            routeId: first(trip.routes).id,
            vehicleId: selectedVehicles[0].id,
          },
        },
      });
    }
  };

  const handlePressRemove = () => {
    setSaveIndicatorState("loading");

    removeVehicleFromRoute({
      variables: {
        input: {
          id: first(trip.routes).id,
        },
      },
    });
  };

  const handleShowVehicleAvailabilityClick =
    (vehicleId: string) => (vehicleAvailabilityDialogTitle: string) => {
      setVehicleAvailabilityDialogProps({
        title: vehicleAvailabilityDialogTitle,
        vehicleId,
      });
      setOpenVehicleAvailabilityDialog(true);
      track("vehicleAvailability_opened");
    };

  // dialog handlers
  const handleSwoopFarmVehiclesDialogClose = () => {
    setSwoopFarmVehiclesDialogOpen(false);
  };
  const handleSwoopFarmVehiclesDialogOpen = () => {
    setSwoopFarmVehiclesDialogOpen(true);
  };

  return (
    <>
      <Box mb={4}>
        <Box mt={4} mb={1.5}>
          <Typography variant="h5">Vehicle</Typography>
        </Box>
        <Box mb={2}>
          <Divider />
        </Box>
        <Box>
          {trip.routes.map(({ vehicle }, index) => {
            return vehicle ? (
              <VehicleCard
                key={vehicle?.id}
                vehicle={vehicle}
                stops={trip.stops}
                ellipsisMenuActions={{
                  onPressReplace: handlePressReplace,
                  ...(mode !== "dispatch" &&
                    mode !== "reservations" && {
                      onPressRemove: handlePressRemove,
                    }),
                }}
                onSwoopFarmVehiclesDialogOpen={
                  handleSwoopFarmVehiclesDialogOpen
                }
                {...(!hideVehicleAvailability && {
                  onShowVehicleAvailabilityClick:
                    handleShowVehicleAvailabilityClick(vehicle?.id),
                })}
              />
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="130px"
                key={index}
                flex="1"
                style={{
                  borderRadius: "4px",
                  background: "rgba(229, 238, 255, 0.3)",
                  border: "1px solid rgba(115, 156, 255, 0.3)",
                }}
              >
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={handlePressReplace}
                >
                  Add Vehicle
                </Button>
              </Box>
            );
          })}
        </Box>
      </Box>

      <VehiclesDialog
        open={open}
        dialogMode="edit"
        onClose={handleDialogClose}
        onCreate={handleUpdateVehicle}
        stops={trip.stops}
        disableBRA={isShuttleReservation}
        {...(farmAffiliateId && {
          farmOutProps: { farmAffiliateId, tripId: trip.id },
        })}
      />
      <VehicleAvailabilityDialog
        title={vehicleAvailabilityDialogProps.title}
        open={openVehicleAvailabilityDialog}
        onClose={() => setOpenVehicleAvailabilityDialog(false)}
        vehicleId={vehicleAvailabilityDialogProps.vehicleId}
        stops={trip.stops}
        routeId={first(trip.routes).id}
      />
      {swoopFarmVehiclesDialogOpen && (
        <FarmAffiliateVehiclesDialog
          open={swoopFarmVehiclesDialogOpen}
          title="Affiliate Vehicles"
          isReadOnly
          onClose={handleSwoopFarmVehiclesDialogClose}
          tripId={trip.id}
        />
      )}
    </>
  );
}

export default AddVehicleUpdateBlock;
