import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import pickBy from "lodash/pickBy";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import isNull from "lodash/isNull";
import isEqual from "lodash/isEqual";

import { Box } from "@mui/material";

import {
  CREATE_VEHICLE_MUTATION_V2,
  LOAD_CANCELLATION_POLICIES_QUERY,
  LOAD_INSURANCE_POLICIES_QUERY,
  LOAD_VEHICLES_QUERY,
} from "globals/graphql";
import { useSnackbar } from "globals/hooks/useSnackbar";
import CreateDrawer from "../../globals/CreateDrawer";
import CommentCreateBlock from "../../CommentCreateBlock";
import { useAnalytics, useOperator } from "globals/hooks";
import { HistoryState } from "../UpdateVehicleDrawer/UpdateVehicleDrawer";
import cloneDeep from "lodash/cloneDeep";
import { vehicleWeekendsArr } from "globals/utils/vehicleWeekends";
import { VehicleTabViewVariant } from "./components/VehicleTabViews/types";
import {
  VehicleTabs,
  RequiredInfo,
  OptionalInfo,
  Photos,
  Features,
  Pricing,
  BookingToolSettings,
} from "./components";
import DiscardChangesDialog from "components/DiscardChangesDialog";
import {
  validateRequiredInfo,
  getInitialChildSeat,
  validateChildSeats,
} from "./util";
import { VehicleChildSeatsError, VehicleRequiredInfoError } from "./types";
import {
  ChildSeatVariant,
  CreateVehicleV2Input,
  CreateVehicleV2Payload,
  DistanceUnitEnum,
  PricelessBookingTarget,
  VehicleChildSeat,
} from "types";
import {
  CreateVehiclePricingFormProvider,
  useCreateVehiclePricingForm,
  defaultValues as defaultPricingValues,
} from "./hooks/useCreateVehiclePricingForm";

const initialVehicle = {
  weekendHourlyCost: "", // required for BRA
  weekdayHourlyCost: "", // required for BRA
  weekendMinMinutes: "", // dependent on weekend fields
  weekdayMinMinutes: "", // dependent on weekend fields
  licensePlate: "",
  typeSlug: "", // required
  name: "", // required
  exteriorColor: "",
  vinNumber: "",
  capacity: "", // required
  available: true, // required
  description: "",
  photos: [],
  features: [],
  cancellationPolicyId: "",
  insuranceId: "",
  comments: [{ bodyText: "" }],
  minimumTotalBaseRate: null, // dependent on transfer fields
  deadheadRatePerMile: null, // dependent on transfer fields
  tripRatePerMile: null, // dependent on transfer fields
  totalDeadheadDurationMinutes: null, // required for BRA
  enableBaseRateAutomation: false,
  enableBaseRateAutomationBookingTool: false,
  settings: {
    // required
    weekends: [],
    conflictBlockQuote: false,
    conflictBlockReservation: false,
    forwardFacingSeat: getInitialChildSeat(ChildSeatVariant.ForwardFacing),
    rearFacingSeat: getInitialChildSeat(ChildSeatVariant.RearFacing),
    boosterSeat: getInitialChildSeat(ChildSeatVariant.Booster),
    pricelessBookingEnabled: false,
    pricelessBookingTarget: PricelessBookingTarget.All,
    pricelessBookingCompanyIds: [],
    pricelessBookingContactIds: [],
  },
};

const initialRequiredInfoErrors = {
  typeSlug: "",
  name: "",
  capacity: "",
};

const initialChildSeatErrors = {
  forwardFacingSeat: {
    description: "",
  },
  rearFacingSeat: {
    description: "",
  },
  boosterSeat: {
    description: "",
  },
};

const cloneablePricingFields = [
  "minimumTotalBaseRate",
  "deadheadRatePerMile",
  "tripRatePerMile",
  "weekdayHourlyCost",
  "weekdayMinMinutes",
  "weekendHourlyCost",
  "weekendMinMinutes",
  "totalDeadheadDurationMinutes",
  "enableBaseRateAutomation",
  "useTieredTransfer",
  "tieredPricingTransfer",
  "useTieredHourly",
  "tieredPricingHourly",
  "useTieredHourlyWeekend",
  "tieredPricingHourlyWeekend",
];

const cloneableFields = [
  // "licensePlate",
  "typeSlug",
  "name",
  "exteriorColor",
  // "vinNumber",
  "capacity",
  "available",
  "description",
  "photos",
  "features",
  "cancellationPolicyId",
  "insuranceId",
  "comments",
  "settings",
  ...cloneablePricingFields,
];

function CreateVehicleDrawer() {
  const snackbar = useSnackbar();
  const history = useHistory<HistoryState>();
  const { track } = useAnalytics();

  // state
  const [vehicle, setVehicle] = useState(initialVehicle);
  const [vehicleRequiredInfoErrors, setVehicleRequiredInfoErrors] =
    useState<VehicleRequiredInfoError>(initialRequiredInfoErrors);
  const [vehiclePricingErrors, setVehiclePricingErrors] =
    useState<boolean>(false);
  const [vehicleChildSeatErrors, setVehicleChildSeatErrors] =
    useState<VehicleChildSeatsError>(initialChildSeatErrors);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [vehicleTabView, setVehicleTabView] = useState(
    VehicleTabViewVariant.REQUIRED_INFO
  );
  const [discardChangesDialogOpen, setDiscardChangesDialogOpen] =
    useState(false);
  const operator = useOperator();
  const distanceUnit = operator?.settings?.distanceUnit;
  const isMiles = distanceUnit === DistanceUnitEnum.Miles;
  const methods = useCreateVehiclePricingForm(isMiles);

  // derived stated
  const hasRequiredInfoError = !isEmpty(pickBy(vehicleRequiredInfoErrors));
  const hasPricingError = vehiclePricingErrors;
  const hasChildSeatError = !isEmpty(
    pickBy(vehicleChildSeatErrors, (seat) => seat.description)
  );

  // queries
  const { data: cancellationPoliciesData } = useQuery(
    LOAD_CANCELLATION_POLICIES_QUERY
  );

  const { data: insurancePoliciesData } = useQuery(
    LOAD_INSURANCE_POLICIES_QUERY
  );

  // mutations
  const [createVehicle] = useMutation<
    {
      createVehicleV2: CreateVehicleV2Payload;
    },
    { input: CreateVehicleV2Input }
  >(CREATE_VEHICLE_MUTATION_V2, {
    refetchQueries: [
      {
        query: LOAD_VEHICLES_QUERY,
        variables: {
          searchTerm: "",
          includeExternalOperators: true,
        },
      },
    ],
    onCompleted(data) {
      if (data.createVehicleV2.result.__typename === "CreateVehicleFailure") {
        setSubmitDisabled(false);
        snackbar.error(data.createVehicleV2.result.message);
        return;
      }

      const {
        settings,
        id: vehicleId,
        name: vehicleName,
      } = data.createVehicleV2.result;

      if (settings.pricelessBookingEnabled) {
        track("pricelessBooking_enabled", {
          type: settings.pricelessBookingTarget,
        });
      } else {
        track("pricelessBooking_disabled", {
          type: settings.pricelessBookingTarget,
        });
      }

      snackbar.success(`Vehicle "${vehicleName}" created`, {
        link: `/vehicles/update/${vehicleId}`,
        linkLabel: "View Vehicle",
        onLinkClick: () => {
          track("snackbar_vehicleViewed");
        },
      });

      //RENAME?: vehicle_created
      track("create_vehicle");
      handleCreateVehicleDrawerClose();
    },
    onError(error) {
      setSubmitDisabled(false);
      snackbar.error();
    },
  });

  // set defaults if cloning a vehicle
  useEffect(() => {
    if (!history.location.state?.clonedVehicle) {
      return;
    }

    const clonedVehicleTemplate = history.location.state?.clonedVehicle || {};
    const clonedVehicle = cloneDeep(initialVehicle);
    const clonedRequiredInfoErrors = cloneDeep(initialRequiredInfoErrors);
    const clonedChildSeatErrors = cloneDeep(initialChildSeatErrors);

    for (const prop in clonedVehicleTemplate) {
      if (cloneableFields.includes(prop)) {
        clonedVehicle[prop] = clonedVehicleTemplate[prop];
      }
    }

    const clonedVehicleCancellationPolicy =
      clonedVehicleTemplate?.cancellationPolicy?.id || null;
    if (
      clonedVehicleCancellationPolicy &&
      cloneableFields.includes("cancellationPolicyId")
    ) {
      clonedVehicle.cancellationPolicyId = clonedVehicleCancellationPolicy;
    }

    const clonedVehicleInsurancePolicy =
      clonedVehicleTemplate?.insurancePolicy?.id || null;
    if (
      clonedVehicleInsurancePolicy &&
      cloneableFields.includes("insuranceId")
    ) {
      clonedVehicle.insuranceId = clonedVehicleInsurancePolicy;
    }

    const clonedTypeSlug = clonedVehicleTemplate?.vehicleType?.typeSlug;
    if (clonedTypeSlug && cloneableFields.includes("typeSlug")) {
      clonedVehicle.typeSlug = clonedTypeSlug;
    }

    const clonedPhotos = history.location.state?.clonedPhotos;
    if (clonedPhotos && cloneableFields.includes("photos")) {
      clonedVehicle.photos = clonedPhotos;
    }

    const clonedFeatures = history.location.state?.clonedFeatures;
    if (clonedFeatures && cloneableFields.includes("features")) {
      clonedVehicle.features = clonedFeatures.map((feature) => ({
        id: feature.id,
      }));
    }

    const clonedSettings = history.location.state?.clonedSettings;

    if (clonedSettings) {
      let weekendsArray = [];
      for (const setting of clonedSettings.weekends) {
        const weekendDay = vehicleWeekendsArr.filter(
          (day) => setting.value === day.value
        )[0];
        weekendsArray = [...weekendsArray, weekendDay];
      }

      const pricelessBookingContactIds =
        clonedSettings.pricelessBookingContacts.map((contact) => contact.id);
      const pricelessBookingCompanyIds =
        clonedSettings.pricelessBookingCompanies.map((company) => company.id);

      clonedVehicle.settings = {
        ...omit(clonedSettings, "id", "__typename"),
        weekends: weekendsArray,
        pricelessBookingContactIds,
        pricelessBookingCompanyIds,
      };
    }

    // reset pricing with fields from cloned vehicle
    const pricingFields = cloneablePricingFields.reduce((acc, field) => {
      const isTieredPricing = [
        "tieredPricingTransfer",
        "tieredPricingHourly",
        "tieredPricingHourlyWeekend",
      ].includes(field);

      acc[field] =
        isTieredPricing && isNull(clonedVehicle[field])
          ? defaultPricingValues.vehicle[field]
          : clonedVehicle[field];

      return acc;
    }, {});

    methods.reset({
      vehicle: {
        ...pricingFields,
        weekends: clonedVehicle.settings.weekends,
      },
    });

    setVehicle(clonedVehicle);
    setVehicleRequiredInfoErrors(clonedRequiredInfoErrors);
    setVehicleChildSeatErrors(clonedChildSeatErrors);
  }, [history.location.state, methods]);

  const handleCreateVehicleDrawerClose = () => {
    setTimeout(() => {
      history.push("/vehicles");
    }, 200);
  };

  const handleVehicleCommentChange = (value: string) => {
    setVehicle({
      ...vehicle,
      comments: [{ bodyText: value }],
    });
  };

  const handleClickSave = async () => {
    // validate pricing form
    const requiredInfoErrors = validateRequiredInfo(vehicle);
    const pricingFormIsValid = await methods.trigger();
    const childSeatErrors = validateChildSeats(vehicle);

    const hasRequiredInfoErrors = !isEmpty(requiredInfoErrors);
    const hasPricingErrors = !pricingFormIsValid;
    const hasChildSeatErrors = !isEmpty(childSeatErrors);

    if (hasRequiredInfoErrors || hasPricingErrors || hasChildSeatErrors) {
      setVehicleRequiredInfoErrors(requiredInfoErrors);
      setVehiclePricingErrors(hasPricingErrors);
      setVehicleChildSeatErrors(childSeatErrors);

      if (hasRequiredInfoErrors) {
        setVehicleTabView(VehicleTabViewVariant.REQUIRED_INFO);
      } else if (hasPricingErrors) {
        setVehicleTabView(VehicleTabViewVariant.PRICING);
      } else if (hasChildSeatErrors) {
        setVehicleTabView(VehicleTabViewVariant.FEATURES);
      }

      return;
    }

    // get pricing form values
    const {
      vehicle: {
        weekends,
        tieredPricingTransfer,
        tieredPricingHourly,
        tieredPricingHourlyWeekend,
        ...pricingFormValues
      },
    } = methods.getValues();

    // modify photos for mutation
    const inputVehicle = {
      ...vehicle,
      ...pricingFormValues,
      settings: {
        ...vehicle.settings,
        weekends,
      },
      // include tiered pricing fields if flag is true
      tieredPricingTransfer: pricingFormValues.useTieredTransfer
        ? {
            ...omit(tieredPricingTransfer, "__typename"),
            tiers: tieredPricingTransfer.tiers.map((tier) => ({
              lowerLimit: tier.lowerLimit,
              upperLimit: tier.upperLimit,
              rate: tier.rate,
              type: tier.type,
            })),
          }
        : null,
      tieredPricingHourly: pricingFormValues.useTieredHourly
        ? {
            ...omit(tieredPricingHourly, "__typename"),
            tiers: tieredPricingHourly.tiers.map((tier) => ({
              lowerLimit: tier.lowerLimit,
              upperLimit: tier.upperLimit,
              rate: tier.rate,
              type: tier.type,
            })),
          }
        : null,
      tieredPricingHourlyWeekend: pricingFormValues.useTieredHourlyWeekend
        ? {
            ...omit(tieredPricingHourlyWeekend, "__typename"),
            tiers: tieredPricingHourlyWeekend.tiers.map((tier) => ({
              lowerLimit: tier.lowerLimit,
              upperLimit: tier.upperLimit,
              rate: tier.rate,
              type: tier.type,
            })),
          }
        : null,
    };
    inputVehicle.photos = inputVehicle.photos.map(({ url, photoIndex }) => ({
      url,
      photoIndex,
    }));

    // modify features for mutation
    inputVehicle.features = inputVehicle.features.map((feature) => ({
      id: feature.id,
    }));

    // modify mutation to handle create / duplicate vehicle
    inputVehicle.settings = {
      conflictBlockQuote: inputVehicle.settings.conflictBlockQuote,
      conflictBlockReservation: inputVehicle.settings.conflictBlockReservation,
      pricelessBookingEnabled: inputVehicle.settings.pricelessBookingEnabled,
      pricelessBookingTarget: inputVehicle.settings.pricelessBookingTarget,
      pricelessBookingCompanyIds:
        inputVehicle.settings.pricelessBookingCompanyIds,
      pricelessBookingContactIds:
        inputVehicle.settings.pricelessBookingContactIds,
      weekends: inputVehicle.settings.weekends,
      forwardFacingSeat: omit(
        inputVehicle.settings.forwardFacingSeat,
        "id",
        "type",
        "__typename"
      ) as VehicleChildSeat,
      rearFacingSeat: omit(
        inputVehicle.settings.rearFacingSeat,
        "id",
        "type",
        "__typename"
      ) as VehicleChildSeat,
      boosterSeat: omit(
        inputVehicle.settings.boosterSeat,
        "id",
        "type",
        "__typename"
      ) as VehicleChildSeat,
    };

    setSubmitDisabled(true);

    createVehicle({
      variables: {
        input: {
          ...inputVehicle,
          capacity: Number(inputVehicle.capacity),
        },
      },
    });
  };

  const checkIfChangesAreMade = useCallback(() => {
    const formHasChanges = !isEqual(vehicle, initialVehicle);
    const pricingFormIsDirty = methods.formState.isDirty;

    if (formHasChanges || pricingFormIsDirty) {
      setDiscardChangesDialogOpen(true);
      return false;
    }
    return true;
  }, [vehicle, methods.formState.isDirty]);

  return (
    <CreateDrawer
      onClose={handleCreateVehicleDrawerClose}
      validateBeforeClose={checkIfChangesAreMade}
      pageLabel="Create New Vehicle"
      submitLabel="Save"
      onSubmit={handleClickSave}
      saveError={hasRequiredInfoError || hasPricingError}
      saveErrorText="Required fields missing. Please enter all required details"
      submitDisabled={submitDisabled}
      subHeaderContent={
        <VehicleTabs
          setVehicleTabView={setVehicleTabView}
          vehicleTabView={vehicleTabView}
          hasRequiredInfoError={hasRequiredInfoError}
          hasPricingError={hasPricingError}
          hasChildSeatError={hasChildSeatError}
        />
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        {/* Selected Vehicle Tab View */}
        <Box>
          {vehicleTabView === VehicleTabViewVariant.REQUIRED_INFO && (
            <RequiredInfo
              vehicle={vehicle}
              setVehicle={setVehicle}
              vehicleRequiredInfoError={vehicleRequiredInfoErrors}
              setVehicleRequiredInfoErrors={setVehicleRequiredInfoErrors}
            />
          )}
          {vehicleTabView === VehicleTabViewVariant.OPTIONAL_INFO && (
            <OptionalInfo
              vehicle={vehicle}
              setVehicle={setVehicle}
              cancellationPolicies={
                cancellationPoliciesData?.cancellationPolicies
              }
              insurancePolicies={insurancePoliciesData?.insurancePolicies}
            />
          )}
          {vehicleTabView === VehicleTabViewVariant.PHOTOS && (
            <Photos vehicle={vehicle} setVehicle={setVehicle} />
          )}
          {vehicleTabView === VehicleTabViewVariant.FEATURES && (
            <Features
              vehicle={vehicle}
              setVehicle={setVehicle}
              setVehicleChildSeatErrors={setVehicleChildSeatErrors}
            />
          )}
          {vehicleTabView === VehicleTabViewVariant.PRICING && (
            <CreateVehiclePricingFormProvider methods={methods}>
              <Pricing />
            </CreateVehiclePricingFormProvider>
          )}
          {vehicleTabView === VehicleTabViewVariant.BOOKING_TOOL_SETTINGS && (
            <BookingToolSettings vehicle={vehicle} setVehicle={setVehicle} />
          )}
        </Box>

        <Box mt={7} mb={4}>
          <CommentCreateBlock
            commentBodyText={vehicle.comments[0].bodyText}
            onChangeComment={handleVehicleCommentChange}
          />
        </Box>

        <DiscardChangesDialog
          open={discardChangesDialogOpen}
          onClose={() => setDiscardChangesDialogOpen(false)}
          onDiscard={handleCreateVehicleDrawerClose}
        />
      </Box>
    </CreateDrawer>
  );
}

export default CreateVehicleDrawer;
