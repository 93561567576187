import React, { useEffect, useMemo, useState } from "react";
import {
  useForm,
  FormProvider,
  UseFormReturn,
  useFormContext,
  FieldNamesMarkedBoolean,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApolloError, ApolloQueryResult, useLazyQuery } from "@apollo/client";
import omit from "lodash/omit";
import isNil from "lodash/isNil";

import {
  DistanceUnitEnum,
  PricingVariant,
  TieredPricingVariant,
  Vehicle,
  VehiclePhoto,
} from "types";
import { LOAD_VEHICLE_QUERY } from "globals/graphql";
import { HistoryState } from "../UpdateVehicleDrawer";
import { useOperator } from "globals/hooks";

const childSeatSchema = yup.object({
  id: yup.string(),
  type: yup.string(),
  active: yup.boolean(),
  quantity: yup.number(),
  amt: yup.number(),
  imageUrl: yup.string(),
  description: yup
    .string()
    .nullable()
    .max(35, "Description cannot be more than 35 characters"),
});

const tierPricingSchema = (
  fieldName: "Transfer" | "Hourly" | "HourlyWeekend"
) =>
  yup
    .object({
      type: yup.mixed<TieredPricingVariant>(),
      tiers: yup.array().of(
        yup.object({
          lowerLimit: yup.number().nullable(),
          upperLimit: yup.number().nullable(),
          incrementalAmt: yup.number().nullable(),
          rate: yup.number().nullable(),
          type: yup.mixed<PricingVariant>(),
        })
      ),
    })
    .when(`useTiered${fieldName}`, {
      is: true,
      then: (schema) =>
        schema.shape({
          type: yup.mixed<TieredPricingVariant>(),
          tiers: yup.array().of(
            yup.object({
              lowerLimit: yup
                .number()
                .typeError("")
                .min(0, "Lower limit must be greater than 0")
                .required(),
              upperLimit: yup
                .number()
                .typeError("")
                .test("min-upper-limit", "", function (value) {
                  if (value === -1) {
                    return true;
                  }
                  const { lowerLimit } = this.parent;
                  return value >= lowerLimit;
                })
                .required(),
              incrementalAmt: yup.number().typeError("").required(),
              rate: yup.number().typeError("").required(),
              type: yup.string(),
            })
          ),
        }),
      otherwise: (schema) => schema,
    });

export const updateVehicleFormSchema = (isMiles: boolean = true) =>
  yup.object({
    vehicle: yup.object({
      id: yup.string(),
      insuranceId: yup.string().nullable(),
      cancellationPolicyId: yup.string().nullable(),
      // Details Tab
      name: yup.string().required("Vehicle name is required"),
      typeSlug: yup.string().required("Vehicle type is required"),
      capacity: yup
        .number()
        .typeError("Vehicle passenger capacity is required")
        .required("Vehicle passenger capacity is required")
        .min(1, "Capacity must be at least 1")
        .max(100, "Capacity must be less than 100"),
      licensePlate: yup
        .string()
        .nullable()
        .max(8, "License plate cannot be more than 8 characters"),
      description: yup.string().nullable(),
      exteriorColor: yup.string().nullable(),
      vinNumber: yup.string().nullable(),
      // Pricing Tab
      // Transfer Pricing
      minimumTotalBaseRate: yup
        .number()
        .nullable()
        .test(
          "transfer-pricing-coupling",
          "Minimum total base rate required",
          function (value) {
            const {
              deadheadRatePerMile,
              tripRatePerMile,
              useTieredTransfer,
              enableBaseRateAutomation,
              weekdayHourlyCost,
              weekdayMinMinutes,
            } = this.parent;

            // not required if useTieredTransfer is true
            if (useTieredTransfer) {
              return true;
            }

            const hasHourlyFields =
              weekdayHourlyCost !== null || weekdayMinMinutes !== null;
            const hasTransferFields =
              deadheadRatePerMile !== null || tripRatePerMile !== null;

            // case 1: required if other transfer are set
            if (hasTransferFields) {
              return value !== null;
            }

            // case 2: required if base rate automation is enabled and no hourly fields are set
            if (enableBaseRateAutomation && !hasHourlyFields) {
              return value !== null;
            }

            return true;
          }
        ),
      deadheadRatePerMile: yup
        .number()
        .nullable()
        .test(
          "transfer-pricing-coupling",
          `Deadhead rate per ${isMiles ? "mile" : "km"} required`,
          function (value) {
            const {
              minimumTotalBaseRate,
              tripRatePerMile,
              enableBaseRateAutomation,
              weekdayHourlyCost,
              weekdayMinMinutes,
            } = this.parent;

            const hasHourlyFields =
              weekdayHourlyCost !== null || weekdayMinMinutes !== null;
            const hasTransferFields =
              minimumTotalBaseRate !== null || tripRatePerMile !== null;

            // case 1: required if other transfer are set
            if (hasTransferFields) {
              return value !== null;
            }

            // case 2: required if base rate automation is enabled and no hourly fields are set
            if (enableBaseRateAutomation && !hasHourlyFields) {
              return value !== null;
            }

            return true;
          }
        )
        .when("useTieredTransfer", {
          is: true,
          then: (schema) =>
            schema.required(
              `Deadhead rate per ${
                isMiles ? "mile" : "km"
              } required for tiered pricing`
            ),
          otherwise: (schema) => schema,
        }),
      tripRatePerMile: yup
        .number()
        .nullable()
        .test(
          "transfer-pricing-coupling",
          "Transfer rate required",
          function (value) {
            const {
              minimumTotalBaseRate,
              deadheadRatePerMile,
              useTieredTransfer,
              enableBaseRateAutomation,
              weekdayHourlyCost,
              weekdayMinMinutes,
            } = this.parent;

            // not required if useTieredTransfer is true
            if (useTieredTransfer) {
              return true;
            }

            const hasHourlyFields =
              weekdayHourlyCost !== null || weekdayMinMinutes !== null;
            const hasTransferFields =
              minimumTotalBaseRate !== null || deadheadRatePerMile !== null;

            // case 1: required if other transfer are set
            if (hasTransferFields) {
              return value !== null;
            }

            // case 2: required if base rate automation is enabled and no hourly fields are set
            if (enableBaseRateAutomation && !hasHourlyFields) {
              return value !== null;
            }

            return true;
          }
        ),
      // Hourly Pricing
      totalDeadheadDurationMinutes: yup.number().nullable(),
      weekdayHourlyCost: yup
        .number()
        .nullable()
        .test(
          "hourly-pricing-coupling",
          "Hourly rate required",
          function (value) {
            const {
              weekdayMinMinutes,
              useTieredHourly,
              minimumTotalBaseRate,
              deadheadRatePerMile,
              tripRatePerMile,
              enableBaseRateAutomation,
              weekendHourlyCost,
              weekendMinMinutes,
            } = this.parent;

            const hasTransferFields =
              minimumTotalBaseRate !== null ||
              deadheadRatePerMile !== null ||
              tripRatePerMile !== null;
            const hasWeekendHourlyFields =
              weekendHourlyCost !== null || weekendMinMinutes !== null;

            // case 1: required if other hourly fields are set
            if (weekdayMinMinutes !== null || useTieredHourly) {
              return value !== null;
            }

            // case 2: required if weekend hourly fields are set
            if (hasWeekendHourlyFields) {
              return value !== null;
            }

            // case 3: required if base rate automation is enabled and no transfer fields are set
            if (enableBaseRateAutomation && !hasTransferFields) {
              return value !== null;
            }

            return true;
          }
        ),
      weekdayMinMinutes: yup
        .number()
        .nullable()
        .test(
          "hourly-pricing-coupling",
          "Hourly minimum required",
          function (value) {
            const {
              weekdayHourlyCost,
              useTieredHourly,
              minimumTotalBaseRate,
              deadheadRatePerMile,
              tripRatePerMile,
              enableBaseRateAutomation,
              weekendHourlyCost,
              weekendMinMinutes,
            } = this.parent;

            const hasTransferFields =
              minimumTotalBaseRate !== null ||
              deadheadRatePerMile !== null ||
              tripRatePerMile !== null;
            const hasWeekendHourlyFields =
              weekendHourlyCost !== null || weekendMinMinutes !== null;

            // case 1: required if other hourly fields are set
            if (weekdayHourlyCost !== null || useTieredHourly) {
              return value !== null;
            }

            // case 2: required if weekend hourly fields are set
            if (hasWeekendHourlyFields) {
              return value !== null;
            }

            // case 3: required if base rate automation is enabled and no transfer fields are set
            if (enableBaseRateAutomation && !hasTransferFields) {
              return value !== null;
            }

            return true;
          }
        ),
      // Weekend Pricing
      weekendHourlyCost: yup
        .number()
        .nullable()
        .test(
          "hourly-weekend-pricing-coupling",
          "Hourly rate required",
          function (value) {
            const { weekendMinMinutes, weekends } = this.parent;
            if (weekendMinMinutes === null && weekends.length === 0) {
              return true;
            }

            return value !== null;
          }
        )
        .when("useTieredHourlyWeekend", {
          is: true,
          then: (schema) => schema.required("Hourly rate required"),
          otherwise: (schema) => schema,
        }),
      weekendMinMinutes: yup
        .number()
        .nullable()
        .test(
          "hourly-weekend-pricing-coupling",
          "Hourly minimum required",
          function (value) {
            const { weekendHourlyCost, weekends } = this.parent;
            if (weekendHourlyCost === null && weekends.length === 0) {
              return true;
            }
            return value !== null;
          }
        )
        .when("useTieredHourlyWeekend", {
          is: true,
          then: (schema) => schema.required("Hourly minimum required"),
          otherwise: (schema) => schema,
        }),
      weekends: yup
        .array()
        .of(
          yup.object({
            name: yup.string(),
            value: yup.string(),
            index: yup.number(),
          })
        )
        .test(
          "hourly-weekend-pricing-coupling",
          "Select weekends to use weekend hourly pricing",
          function (value) {
            const { weekendHourlyCost, weekendMinMinutes } = this.parent;
            if (weekendHourlyCost === null && weekendMinMinutes === null) {
              return true;
            }
            return Array.isArray(value) && value.length > 0;
          }
        )
        .when("useTieredHourlyWeekend", {
          is: true,
          then: (schema) =>
            schema.test({
              test: (value) => value.length > 0,
              message: "Select weekends to use weekend hourly pricing",
            }),
          otherwise: (schema) => schema,
        }),
      enableBaseRateAutomation: yup.boolean(),
      available: yup.boolean().required("Vehicle availability is required"),
      enableBaseRateAutomationBookingTool: yup.boolean(),
      publishedToDudaSite: yup.boolean(),
      settings: yup
        .object({
          id: yup.string(),
          conflictBlockQuote: yup.boolean(),
          conflictBlockReservation: yup.boolean(),
          pricelessBookingEnabled: yup.boolean(),
          pricelessBookingTarget: yup.string(),
          pricelessBookingCompanies: yup.array().of(
            yup.object({
              id: yup.string(),
              name: yup.string(),
            })
          ),
          pricelessBookingContacts: yup.array().of(
            yup.object({
              id: yup.string(),
              name: yup.string(),
            })
          ),
        })
        .nullable(),
      // tiered pricing
      useTieredTransfer: yup.boolean(),
      tieredPricingTransfer: tierPricingSchema("Transfer"),
      useTieredHourly: yup.boolean(),
      tieredPricingHourly: tierPricingSchema("Hourly"),
      useTieredHourlyWeekend: yup.boolean(),
      tieredPricingHourlyWeekend: tierPricingSchema("HourlyWeekend"),
    }),
    features: yup.array().of(
      yup.object({
        id: yup.string(),
        name: yup.string(),
        category: yup.string(),
      })
    ),
    childSeats: yup
      .object({
        rearFacingSeat: childSeatSchema,
        forwardFacingSeat: childSeatSchema,
        boosterSeat: childSeatSchema,
      })
      .nullable(),
  });

export type UpdateVehicleFormData = yup.InferType<
  ReturnType<typeof updateVehicleFormSchema>
>;

type UseUpdateVehicleFormReturn = {
  readyOnlyVehicle: Vehicle | null;
  vehicleRefetch: () => Promise<ApolloQueryResult<{ node: Vehicle }>>;
  photos: VehiclePhoto[] | null;
  setPhotos: (photos: VehiclePhoto[] | null) => void;
  isLoading: boolean;
  loadVehicleError?: ApolloError;
  methods: UseFormReturn<UpdateVehicleFormData>;
  isDirty: boolean;
  dirtyFields: FieldNamesMarkedBoolean<UpdateVehicleFormData>;
  getDuplicateVehicle: () => HistoryState;
};

export const useUpdateVehicleForm = (
  vehicleId: string
): UseUpdateVehicleFormReturn => {
  // state
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [photos, setPhotos] = useState<VehiclePhoto[] | null>(null);

  // queries
  const [loadVehicle, { called, loading, error }] = useLazyQuery<{
    node: Vehicle;
  }>(LOAD_VEHICLE_QUERY, {
    variables: {
      id: vehicleId,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setVehicle(data.node);
      setPhotos(data.node.photos);
    },
  });
  const vehicleRefetch = () => loadVehicle({ fetchPolicy: "network-only" });

  // hooks
  const operator = useOperator();
  const distanceUnit = operator?.settings?.distanceUnit;
  const isMiles = distanceUnit === DistanceUnitEnum.Miles;

  const defaultValues = useMemo(() => getDefaultValues(vehicle), [vehicle]);
  const methods = useForm<UpdateVehicleFormData>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(updateVehicleFormSchema(isMiles)),
  });

  const {
    formState: { isDirty, dirtyFields },
    reset,
  } = methods;

  // effects
  useEffect(() => {
    loadVehicle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  // return error state
  if (error) {
    return {
      readyOnlyVehicle: vehicle,
      vehicleRefetch,
      photos,
      setPhotos,
      isLoading: false,
      methods,
      isDirty,
      dirtyFields,
      getDuplicateVehicle: (): HistoryState => ({
        clonedVehicle: null,
        clonedPhotos: null,
        clonedFeatures: null,
        clonedSettings: null,
      }),
      loadVehicleError: error,
    };
  }

  // return loading state
  if ((loading && called) || !vehicle) {
    return {
      readyOnlyVehicle: vehicle,
      vehicleRefetch,
      photos,
      setPhotos,
      isLoading: true,
      methods,
      isDirty,
      dirtyFields,
      getDuplicateVehicle: (): HistoryState => ({
        clonedVehicle: null,
        clonedPhotos: null,
        clonedFeatures: null,
        clonedSettings: null,
      }),
    };
  }

  // getters
  const getDuplicateVehicle = (): HistoryState => {
    return {
      clonedVehicle: omit(vehicle, "comments"),
      clonedPhotos: photos,
      clonedFeatures: vehicle.features,
      clonedSettings: vehicle.settings,
    };
  };

  return {
    readyOnlyVehicle: vehicle,
    vehicleRefetch,
    photos,
    setPhotos,
    isLoading: loading,
    // form data
    methods,
    isDirty,
    dirtyFields,
    getDuplicateVehicle,
  };
};

export const UpdateVehicleFormProvider = (props: {
  methods: UseFormReturn<UpdateVehicleFormData>;
  children: React.ReactNode;
}) => {
  const { methods, children } = props;

  return (
    <FormProvider {...methods}>
      <form>{children}</form>
    </FormProvider>
  );
};

export const useUpdateVehicleFormContext =
  (): UseFormReturn<UpdateVehicleFormData> | null => {
    const context = useFormContext<UpdateVehicleFormData>();
    if (!context) {
      console.error(
        "useUpdateVehicleFormContext must be used within an UpdateVehicleFormProvider"
      );
      return null;
    }

    return context;
  };

const getDefaultValues = (vehicle: Vehicle): UpdateVehicleFormData => ({
  vehicle: {
    id: vehicle?.id || "",
    insuranceId: vehicle?.insurancePolicy?.id || null,
    cancellationPolicyId: vehicle?.cancellationPolicy?.id || null,
    available: vehicle?.available || false,
    name: vehicle?.name || "",
    capacity: vehicle?.capacity || 0,
    licensePlate: vehicle?.licensePlate || null,
    description: vehicle?.description || null,
    weekendHourlyCost: isNil(vehicle?.weekendHourlyCost)
      ? null
      : vehicle?.weekendHourlyCost,
    weekdayHourlyCost: isNil(vehicle?.weekdayHourlyCost)
      ? null
      : vehicle?.weekdayHourlyCost,
    weekendMinMinutes: isNil(vehicle?.weekendMinMinutes)
      ? null
      : vehicle?.weekendMinMinutes,
    weekdayMinMinutes: isNil(vehicle?.weekdayMinMinutes)
      ? null
      : vehicle?.weekdayMinMinutes,
    weekends: vehicle?.settings?.weekends || null,
    typeSlug: vehicle?.vehicleType?.typeSlug || "sedan",
    exteriorColor: vehicle?.exteriorColor || null,
    vinNumber: vehicle?.vinNumber || null,
    minimumTotalBaseRate: isNil(vehicle?.minimumTotalBaseRate)
      ? null
      : vehicle?.minimumTotalBaseRate,
    deadheadRatePerMile: isNil(vehicle?.deadheadRatePerMile)
      ? null
      : vehicle?.deadheadRatePerMile,
    tripRatePerMile: isNil(vehicle?.tripRatePerMile)
      ? null
      : vehicle?.tripRatePerMile,
    totalDeadheadDurationMinutes: isNil(vehicle?.totalDeadheadDurationMinutes)
      ? null
      : vehicle?.totalDeadheadDurationMinutes,
    enableBaseRateAutomation: vehicle?.enableBaseRateAutomation || false,
    enableBaseRateAutomationBookingTool:
      vehicle?.enableBaseRateAutomationBookingTool || false,
    publishedToDudaSite: vehicle?.publishedToDudaSite || false,
    settings: vehicle?.settings
      ? {
          ...vehicle.settings,
          pricelessBookingCompanies:
            vehicle.settings.pricelessBookingCompanies || [],
          pricelessBookingContacts:
            vehicle.settings.pricelessBookingContacts.map((contact) => ({
              id: contact.id,
              name: `${contact.firstName} ${contact.lastName}`,
            })) || [],
        }
      : null,
    // tiered pricing
    useTieredTransfer: vehicle?.useTieredTransfer || false,
    tieredPricingTransfer: vehicle?.tieredPricingTransfer || {
      type: TieredPricingVariant.Incremental,
      tiers: [
        {
          lowerLimit: 0,
          upperLimit: null,
          incrementalAmt: null,
          rate: null,
          type: PricingVariant.PerMile,
        },
        {
          lowerLimit: null,
          upperLimit: -1,
          incrementalAmt: null,
          rate: null,
          type: PricingVariant.PerMile,
        },
      ],
    },
    useTieredHourly: vehicle?.useTieredHourly || false,
    tieredPricingHourly: vehicle?.tieredPricingHourly || {
      type: TieredPricingVariant.Incremental,
      tiers: [
        {
          lowerLimit: 0,
          upperLimit: null,
          incrementalAmt: null,
          rate: null,
          type: PricingVariant.PerHour,
        },
        {
          lowerLimit: null,
          upperLimit: -1,
          incrementalAmt: null,
          rate: null,
          type: PricingVariant.PerHour,
        },
      ],
    },
    useTieredHourlyWeekend: vehicle?.useTieredHourlyWeekend || false,
    tieredPricingHourlyWeekend: vehicle?.tieredPricingHourlyWeekend || {
      type: TieredPricingVariant.Incremental,
      tiers: [
        {
          lowerLimit: 0,
          upperLimit: null,
          incrementalAmt: null,
          rate: null,
          type: PricingVariant.PerHour,
        },
        {
          lowerLimit: null,
          upperLimit: -1,
          incrementalAmt: null,
          rate: null,
          type: PricingVariant.PerHour,
        },
      ],
    },
  },
  features: vehicle?.features || null,
  childSeats: vehicle?.settings
    ? {
        rearFacingSeat: {
          ...vehicle.settings.rearFacingSeat,
          description: vehicle.settings.rearFacingSeat.description || null,
        },
        forwardFacingSeat: {
          ...vehicle.settings.forwardFacingSeat,
          description: vehicle.settings.forwardFacingSeat.description || null,
        },
        boosterSeat: {
          ...vehicle.settings.boosterSeat,
          description: vehicle.settings.boosterSeat.description || null,
        },
      }
    : null,
});
