import React from 'react';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import MultiSelectFilterInput from './MultiSelectFilterInput';
import { StatusSlug } from 'types';

const statusOptions = [
  { name: "Pending", id: StatusSlug.Pending },
  { name: "Confirmed", id: StatusSlug.Confirmed },
  { name: "On The Way", id: StatusSlug.Otw },
  { name: "On Location", id: StatusSlug.OnLocation },
  { name: "Passenger On Board", id: StatusSlug.Pob },
  { name: "Done", id: StatusSlug.Done },
  { name: "Cancelled", id: StatusSlug.Cancelled }
];

export default function StatusFilterInput(props: GridFilterInputValueProps) {
  return <MultiSelectFilterInput {...props} options={statusOptions} />;
} 