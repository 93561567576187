import React from "react";
import {
  useForm,
  FormProvider,
  UseFormReturn,
  useFormContext,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { PricingVariant, TieredPricingVariant } from "types";

const tierPricingSchema = (
  fieldName: "Transfer" | "Hourly" | "HourlyWeekend"
) =>
  yup
    .object({
      type: yup.mixed<TieredPricingVariant>(),
      tiers: yup.array().of(
        yup.object({
          lowerLimit: yup.number().nullable(),
          upperLimit: yup.number().nullable(),
          incrementalAmt: yup.number().nullable(),
          rate: yup.number().nullable(),
          type: yup.mixed<PricingVariant>(),
        })
      ),
    })
    .when(`useTiered${fieldName}`, {
      is: true,
      then: (schema) =>
        schema.shape({
          type: yup.mixed<TieredPricingVariant>(),
          tiers: yup.array().of(
            yup.object({
              lowerLimit: yup
                .number()
                .typeError("")
                .min(0, "Lower limit must be greater than 0")
                .required(),
              upperLimit: yup
                .number()
                .typeError("")
                .test("min-upper-limit", "", function (value) {
                  if (value === -1) {
                    return true;
                  }
                  const { lowerLimit } = this.parent;
                  return value >= lowerLimit;
                })
                .required(),
              incrementalAmt: yup.number().typeError("").required(),
              rate: yup.number().typeError("").required(),
              type: yup.string(),
            })
          ),
        }),
      otherwise: (schema) => schema,
    });

export const createVehiclePricingFormSchema = (isMiles: boolean = true) =>
  yup.object({
    vehicle: yup.object({
      minimumTotalBaseRate: yup
        .number()
        .nullable()
        .test(
          "transfer-pricing-coupling",
          "Minimum total base rate required",
          function (value) {
            const {
              deadheadRatePerMile,
              tripRatePerMile,
              useTieredTransfer,
              enableBaseRateAutomation,
              weekdayHourlyCost,
              weekdayMinMinutes,
            } = this.parent;

            // not required if useTieredTransfer is true
            if (useTieredTransfer) {
              return true;
            }

            const hasHourlyFields =
              weekdayHourlyCost !== null || weekdayMinMinutes !== null;
            const hasTransferFields =
              deadheadRatePerMile !== null || tripRatePerMile !== null;

            // case 1: required if other transfer are set
            if (hasTransferFields) {
              return value !== null;
            }

            // case 2: required if base rate automation is enabled and no hourly fields are set
            if (enableBaseRateAutomation && !hasHourlyFields) {
              return value !== null;
            }

            return true;
          }
        ),
      deadheadRatePerMile: yup
        .number()
        .nullable()
        .test(
          "transfer-pricing-coupling",
          `Deadhead rate per ${isMiles ? "mile" : "km"} required`,
          function (value) {
            const {
              minimumTotalBaseRate,
              tripRatePerMile,
              enableBaseRateAutomation,
              weekdayHourlyCost,
              weekdayMinMinutes,
            } = this.parent;

            const hasHourlyFields =
              weekdayHourlyCost !== null || weekdayMinMinutes !== null;
            const hasTransferFields =
              minimumTotalBaseRate !== null || tripRatePerMile !== null;

            // case 1: required if other transfer are set
            if (hasTransferFields) {
              return value !== null;
            }

            // case 2: required if base rate automation is enabled and no hourly fields are set
            if (enableBaseRateAutomation && !hasHourlyFields) {
              return value !== null;
            }

            return true;
          }
        )
        .when("useTieredTransfer", {
          is: true,
          then: (schema) =>
            schema.required(
              `Deadhead rate per ${
                isMiles ? "mile" : "km"
              } required for tiered pricing`
            ),
          otherwise: (schema) => schema,
        }),
      tripRatePerMile: yup
        .number()
        .nullable()
        .test(
          "transfer-pricing-coupling",
          "Transfer rate required",
          function (value) {
            const {
              minimumTotalBaseRate,
              deadheadRatePerMile,
              useTieredTransfer,
              enableBaseRateAutomation,
              weekdayHourlyCost,
              weekdayMinMinutes,
            } = this.parent;

            // not required if useTieredTransfer is true
            if (useTieredTransfer) {
              return true;
            }

            const hasHourlyFields =
              weekdayHourlyCost !== null || weekdayMinMinutes !== null;
            const hasTransferFields =
              minimumTotalBaseRate !== null || deadheadRatePerMile !== null;

            // case 1: required if other transfer are set
            if (hasTransferFields) {
              return value !== null;
            }

            // case 2: required if base rate automation is enabled and no hourly fields are set
            if (enableBaseRateAutomation && !hasHourlyFields) {
              return value !== null;
            }

            return true;
          }
        ),
      totalDeadheadDurationMinutes: yup.number().nullable(),
      weekdayHourlyCost: yup
        .number()
        .nullable()
        .test(
          "hourly-pricing-coupling",
          "Hourly rate required",
          function (value) {
            const {
              weekdayMinMinutes,
              useTieredHourly,
              minimumTotalBaseRate,
              deadheadRatePerMile,
              tripRatePerMile,
              enableBaseRateAutomation,
              weekendHourlyCost,
              weekendMinMinutes,
            } = this.parent;

            const hasTransferFields =
              minimumTotalBaseRate !== null ||
              deadheadRatePerMile !== null ||
              tripRatePerMile !== null;
            const hasWeekendHourlyFields =
              weekendHourlyCost !== null || weekendMinMinutes !== null;

            // case 1: required if other hourly fields are set
            if (weekdayMinMinutes !== null || useTieredHourly) {
              return value !== null;
            }

            // case 2: required if weekend hourly fields are set
            if (hasWeekendHourlyFields) {
              return value !== null;
            }

            // case 3: required if base rate automation is enabled and no transfer fields are set
            if (enableBaseRateAutomation && !hasTransferFields) {
              return value !== null;
            }

            return true;
          }
        ),
      weekdayMinMinutes: yup
        .number()
        .nullable()
        .test(
          "hourly-pricing-coupling",
          "Hourly minimum required",
          function (value) {
            const {
              weekdayHourlyCost,
              useTieredHourly,
              minimumTotalBaseRate,
              deadheadRatePerMile,
              tripRatePerMile,
              enableBaseRateAutomation,
              weekendHourlyCost,
              weekendMinMinutes,
            } = this.parent;

            const hasTransferFields =
              minimumTotalBaseRate !== null ||
              deadheadRatePerMile !== null ||
              tripRatePerMile !== null;
            const hasWeekendHourlyFields =
              weekendHourlyCost !== null || weekendMinMinutes !== null;

            // case 1: required if other hourly fields are set
            if (weekdayHourlyCost !== null || useTieredHourly) {
              return value !== null;
            }

            // case 2: required if weekend hourly fields are set
            if (hasWeekendHourlyFields) {
              return value !== null;
            }

            // case 3: required if base rate automation is enabled and no transfer fields are set
            if (enableBaseRateAutomation && !hasTransferFields) {
              return value !== null;
            }

            return true;
          }
        ),
      weekendHourlyCost: yup
        .number()
        .nullable()
        .test(
          "hourly-weekend-pricing-coupling",
          "Hourly rate required",
          function (value) {
            const { weekendMinMinutes, weekends } = this.parent;
            if (weekendMinMinutes === null && weekends.length === 0) {
              return true;
            }

            return value !== null;
          }
        )
        .when("useTieredHourlyWeekend", {
          is: true,
          then: (schema) => schema.required("Hourly rate required"),
          otherwise: (schema) => schema,
        }),
      weekendMinMinutes: yup
        .number()
        .nullable()
        .test(
          "hourly-weekend-pricing-coupling",
          "Hourly minimum required",
          function (value) {
            const { weekendHourlyCost, weekends } = this.parent;
            if (weekendHourlyCost === null && weekends.length === 0) {
              return true;
            }
            return value !== null;
          }
        )
        .when("useTieredHourlyWeekend", {
          is: true,
          then: (schema) => schema.required("Hourly minimum required"),
          otherwise: (schema) => schema,
        }),
      weekends: yup
        .array()
        .of(
          yup.object({
            name: yup.string(),
            value: yup.string(),
            index: yup.number(),
          })
        )
        .test(
          "hourly-weekend-pricing-coupling",
          "Select weekends to use weekend hourly pricing",
          function (value) {
            const { weekendHourlyCost, weekendMinMinutes } = this.parent;
            if (weekendHourlyCost === null && weekendMinMinutes === null) {
              return true;
            }
            return Array.isArray(value) && value.length > 0;
          }
        )
        .when("useTieredHourlyWeekend", {
          is: true,
          then: (schema) =>
            schema.test({
              test: (value) => value.length > 0,
              message: "Select weekends to use weekend hourly pricing",
            }),
          otherwise: (schema) => schema,
        }),
      enableBaseRateAutomation: yup.boolean(),
      useTieredTransfer: yup.boolean(),
      tieredPricingTransfer: tierPricingSchema("Transfer"),
      useTieredHourly: yup.boolean(),
      tieredPricingHourly: tierPricingSchema("Hourly"),
      useTieredHourlyWeekend: yup.boolean(),
      tieredPricingHourlyWeekend: tierPricingSchema("HourlyWeekend"),
    }),
  });

export type CreateVehiclePricingFormData = yup.InferType<
  ReturnType<typeof createVehiclePricingFormSchema>
>;

export const defaultValues: CreateVehiclePricingFormData = {
  vehicle: {
    minimumTotalBaseRate: null,
    deadheadRatePerMile: null,
    tripRatePerMile: null,
    weekdayHourlyCost: null,
    weekdayMinMinutes: null,
    weekendHourlyCost: null,
    weekendMinMinutes: null,
    weekends: [],
    totalDeadheadDurationMinutes: null,
    enableBaseRateAutomation: false,
    useTieredTransfer: false,
    tieredPricingTransfer: {
      type: TieredPricingVariant.Incremental,
      tiers: [
        {
          lowerLimit: 0,
          upperLimit: null,
          incrementalAmt: null,
          rate: null,
          type: PricingVariant.PerMile,
        },
        {
          lowerLimit: null,
          upperLimit: -1,
          incrementalAmt: null,
          rate: null,
          type: PricingVariant.PerMile,
        },
      ],
    },
    useTieredHourly: false,
    tieredPricingHourly: {
      type: TieredPricingVariant.Incremental,
      tiers: [
        {
          lowerLimit: 0,
          upperLimit: null,
          incrementalAmt: null,
          rate: null,
          type: PricingVariant.PerHour,
        },
        {
          lowerLimit: null,
          upperLimit: -1,
          incrementalAmt: null,
          rate: null,
          type: PricingVariant.PerHour,
        },
      ],
    },
    useTieredHourlyWeekend: false,
    tieredPricingHourlyWeekend: {
      type: TieredPricingVariant.Incremental,
      tiers: [
        {
          lowerLimit: 0,
          upperLimit: null,
          incrementalAmt: null,
          rate: null,
          type: PricingVariant.PerHour,
        },
        {
          lowerLimit: null,
          upperLimit: -1,
          incrementalAmt: null,
          rate: null,
          type: PricingVariant.PerHour,
        },
      ],
    },
  },
};

export const useCreateVehiclePricingForm = (isMiles: boolean = true) => {
  const methods = useForm<CreateVehiclePricingFormData>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(createVehiclePricingFormSchema(isMiles)),
  });

  return methods;
};

export const CreateVehiclePricingFormProvider = (props: {
  methods: UseFormReturn<CreateVehiclePricingFormData>;
  children: React.ReactNode;
}) => {
  const { methods, children } = props;

  return (
    <FormProvider {...methods}>
      <form>{children}</form>
    </FormProvider>
  );
};

export const useCreateVehiclePricingFormContext =
  (): UseFormReturn<CreateVehiclePricingFormData> | null => {
    const context = useFormContext<CreateVehiclePricingFormData>();
    if (!context) {
      console.error(
        "useCreateVehiclePricingFormContext must be used within a CreateVehiclePricingFormProvider"
      );
      return null;
    }

    return context;
  };
