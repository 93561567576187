import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment-timezone";
import startCase from "lodash/startCase";
import clipboardCopy from "clipboard-copy";
import * as Sentry from "@sentry/react";

import { Box, CircularProgress } from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import UpdateDrawer from "../globals/UpdateDrawer";
import {
  LOAD_OPERATOR_ROUTE_QUERY,
  REMOVE_TRIP_MUTATION,
  UNDO_REMOVE_TRIP_MUTATION,
  UPDATE_ROUTE_MUTATION,
} from "../../globals/graphql";
import TopOverviewBar from "../globals/TopOverviewBar";
import GQLQueryStatusIndicator from "../GQLQueryStatusIndicator";
import { grayDark } from "../../design-system/colors";
import PassengerInfoUpdateBlock from "../requests/update/PassengerInfoUpdateBlock";
import TripInfoUpdateBlock from "../requests/update/TripInfoUpdateBlock";
import AddVehicleUpdateBlock from "../requests/update/AddVehicleUpdateBlock";
import DispatchAlertsBlock from "./DispatchAlertsBlock";
import RouteStatusSelect from "./RouteStatusSelect";
import DriverUpdateBlock from "./DriverUpdateBlock";
import {
  ClipboardIcon,
  DurationIcon,
  EstimationIcon,
  TrashIcon,
  TripIcon,
  UsersIcon,
  VehicleIcon,
  WheelIcon,
} from "../../design-system/icons";
import { useSnackbar } from "../../globals/hooks/useSnackbar";
import { FarmRelationshipEnum, OperatorRoute } from "../../types";
import CommentUpdateBlock from "../CommentUpdateBlock";
import {
  confirmationNumberFromRequest,
  convertMinutesToHoursMinutes,
  fromGlobalId,
  toGlobalId,
} from "../../globals/utils/helpers";
import RemoveDialog from "../RemoveDialog";
import TripDetailsUpdateBlock from "../requests/update/TripDetailsUpdateBlock";
import { useAnalytics } from "../../globals/hooks";
import { encodeTripId } from "globals/utils/encodeStringQueryParams";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { LuggageFields } from "components/requests/luggage/types";

function UpdateDispatchRouteDrawer() {
  // hooks
  const history = useHistory();
  const { routeId } = useParams<{ routeId: string }>();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  const shouldSkipQuery = !routeId;

  // queries
  const {
    data: operatorRouteData,
    loading: operatorRouteLoading,
    refetch: operatorRouteRefetch,
    error: operatorRouteError,
  } = useQuery(LOAD_OPERATOR_ROUTE_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      id: routeId,
    },
    skip: shouldSkipQuery,
    ...(!shouldSkipQuery && { pollInterval: 60 * 1000 }), // every 60 seconds
  });

  // mutations
  const [removeTrip] = useMutation(REMOVE_TRIP_MUTATION, {
    refetchQueries: ["Requests", "OperatorRoutes"],
    onCompleted(data) {
      snackbar.success(`Successfully deleted ${confirmationNumber}!`, {
        onUndo: handleClickUndo,
      });
    },
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || `Error deleting ${confirmationNumber}`;

      snackbar.error(errorMessage);
    },
  });

  const [undoRemoveTrip] = useMutation(UNDO_REMOVE_TRIP_MUTATION, {
    refetchQueries: ["Requests", "OperatorRoutes"],
    onCompleted(data) {
      snackbar.success(`Successfully retrieved ${confirmationNumber}!`);
    },
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || "Error retrieving deleted reservation";

      snackbar.error(errorMessage);
    },
  });

  const [updateRoute] = useMutation(UPDATE_ROUTE_MUTATION, {
    refetchQueries: ["Requests"],
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || "Error adding vehicle to route.";

      snackbar.error(errorMessage);
    },
  });

  // state
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "loading" | "saved" | "error"
  >("default");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [suggestedAddressInfo, setSuggestedAddressInfo] = useState([]);

  const operatorRoute: OperatorRoute = operatorRouteData?.node;
  const { trip, request, routeDriver } = operatorRoute || {};
  const confirmationNumber = confirmationNumberFromRequest(request, trip);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    let keys = [];

    const bookingContact = operatorRoute?.request?.bookingContact;

    if (bookingContact?.workAddress) {
      keys.push({
        address: bookingContact.workAddress,
        firstName: bookingContact.firstName,
        lastName: bookingContact.lastName,
        mode: "workAddress",
      });
    }
    if (bookingContact?.homeAddress) {
      keys.push({
        address: bookingContact.homeAddress,
        firstName: bookingContact.firstName,
        lastName: bookingContact.lastName,
        mode: "homeAddress",
      });
    }

    if (operatorRoute?.trip?.contact?.workAddress) {
      keys.push({
        address: operatorRoute.trip.contact.workAddress,
        firstName: operatorRoute.trip.contact.firstName,
        lastName: operatorRoute.trip.contact.lastName,
        mode: "workAddress",
      });
    }
    if (operatorRoute?.trip?.contact?.homeAddress) {
      keys.push({
        address: operatorRoute.trip.contact.homeAddress,
        firstName: operatorRoute.trip.contact.firstName,
        lastName: operatorRoute.trip.contact.lastName,
        mode: "homeAddress",
      });
    }

    setSuggestedAddressInfo(keys);
  }, [operatorRoute]);

  // event handlers
  const handleGoToReservation = () => {
    track("goTo_pageSelected");

    const encodedTripId = encodeTripId(trip.id);

    history.push(`/reservations/${request.id}?${encodedTripId}`);
  };

  const handleGoToContact = () => {
    track("goTo_pageSelected");
    history.push(`/contacts/update/${request.bookingContact?.id}`);
  };

  const handleGoToVehicle = () => {
    track("goTo_pageSelected");
    history.push(`/vehicles/update/${trip.routes[0].vehicle.id}`);
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleClickRemove = () => {
    removeTrip({
      variables: {
        input: {
          tripId: trip.id,
        },
      },
    });
    setDeleteDialogOpen(false);
    handleDrawerClose();
  };

  const handleClickUndo = () => {
    undoRemoveTrip({
      variables: {
        input: {
          tripId: trip.id,
        },
      },
    });
    history.push(`${pagePath}/update/${operatorRoute.id}`);
  };

  const handleCopyLink = async (name: string) => {
    try {
      let clipboardText;
      if (name === "dispatch") {
        clipboardText = `${process.env.REACT_APP_CUSTOMERAPP_URL}/${operatorRoute.operator.nameSlug}/trip/${operatorRoute.publicId}`;
      } else {
        clipboardText = `${process.env.REACT_APP_DRIVERAPP_URL}/driver/${routeDriver.driver.id}/dispatch/${operatorRoute.id}`;
      }
      await clipboardCopy(clipboardText);
      snackbar.success(`Successfully copied ${name} link!`);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleDrawerClose = () => {
    history.push(pagePath);
  };

  const adjustLuggageAmount = useCallback(
    (luggageAmount: LuggageFields) => {
      // Only update if there is a change or else updateRoute is stuck in a loop
      if (
        luggageAmount.carryOnLuggage !== trip?.routes[0].carryOnLuggage ||
        luggageAmount.checkedLuggage !== trip?.routes[0].checkedLuggage ||
        luggageAmount.oversizeLuggage !== trip?.routes[0].oversizeLuggage
      ) {
        setSaveIndicatorState("loading");
        updateRoute({
          variables: {
            input: {
              id: trip?.routes[0].id,
              ...luggageAmount,
            },
          },
        });
      }
    },
    [trip?.routes, updateRoute]
  );

  // derived state
  const pagePath = "/dispatch";
  const isFarmedRoute = operatorRoute?.isFarmedRoute;
  const isFarmor =
    operatorRoute?.farmRelationship === FarmRelationshipEnum.Farmor;

  return (
    <>
      <UpdateDrawer
        onClose={handleDrawerClose}
        createdAt={
          operatorRouteData
            ? moment(operatorRouteData?.node?.createdAt).format("LLL")
            : ""
        }
        updatedAt={
          operatorRouteData
            ? moment(operatorRouteData?.node?.updatedAt).format("LLL")
            : ""
        }
        saveIndicatorState={saveIndicatorState}
        headerContent={
          <Box mr={2}>
            <RouteStatusSelect
              operatorRouteId={operatorRouteData?.node?.id}
              statusSlug={operatorRouteData?.node?.statusSlug || "pending"}
              setSaveIndicatorState={setSaveIndicatorState}
              requestId={request?.id}
            />
          </Box>
        }
        ellipsisMenuOptions={
          trip && request
            ? [
                "COPY",
                {
                  onClick: () => handleCopyLink("driver"),
                  text: "Driver Link",
                  icon: <WheelIcon color={grayDark} size="small" />,
                  disableOption: routeDriver?.driver.id ? false : true,
                  tooltip: !routeDriver?.driver.id
                    ? {
                        titleText: "Assign Driver to View Link",
                      }
                    : undefined,
                },
                {
                  onClick: () => handleCopyLink("dispatch"),
                  text: "Customer Link",
                  icon: <ClipboardIcon color={grayDark} size="small" />,
                  disableOption: operatorRoute.publicId ? false : true,
                },
                "divider",
                "GO TO",
                {
                  text: "Reservation",
                  icon: <TripIcon color={grayDark} size="small" />,
                  onClick: handleGoToReservation,
                },
                {
                  onClick: handleGoToContact,
                  text: "Contact",
                  icon: <UsersIcon color={grayDark} size="small" />,
                  disableOption: !!request.bookingContact,
                },
                {
                  onClick: handleGoToVehicle,
                  text: "Vehicle",
                  icon: <VehicleIcon color={grayDark} size="small" />,
                },
                "divider",
                {
                  onClick: handleDeleteClick,
                  text: "Delete",
                  icon: <TrashIcon color={grayDark} size="small" />,
                },
              ]
            : ["Loading"]
        }
      >
        {operatorRouteLoading && !operatorRouteData?.node && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={40} thickness={2} />
          </Box>
        )}
        {operatorRouteError && !operatorRouteLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <GQLQueryStatusIndicator
              name="Route"
              data={operatorRouteData}
              error={operatorRouteError}
              refetch={operatorRouteRefetch}
            />
          </Box>
        )}
        {operatorRoute && trip && request && (
          <Box mb={2}>
            <Box my={2}>
              <TopOverviewBar
                items={[
                  {
                    title: "conf. no.",
                    value: confirmationNumber,
                    icon: (
                      <AssignmentTurnedInIcon
                        viewBox="-4 -4 30 30"
                        style={{ color: grayDark }}
                      />
                    ),
                  },
                  {
                    title: "order type",
                    value: startCase(request?.orderType.toLowerCase()),
                    icon: (
                      <TripIcon
                        fill={grayDark}
                        color={grayDark}
                        viewBox="-5 -5 30 30"
                        height="22"
                        width="22"
                      />
                    ),
                  },
                  {
                    title: trip.useTotalDuration
                      ? "total duration"
                      : "estimated duration",
                    value: trip.useTotalDuration ? (
                      convertMinutesToHoursMinutes(trip.totalDuration)
                    ) : (
                      <Box display="flex" alignItems="center">
                        <Box mr={0.5}>
                          {convertMinutesToHoursMinutes(trip.estimatedDuration)}
                        </Box>
                        <EstimationIcon size="small" />
                      </Box>
                    ),
                    icon: (
                      <DurationIcon
                        color={grayDark}
                        viewBox="-9 -9 31 31"
                        height="20"
                        width="20"
                      />
                    ),
                  },
                ]}
              />
            </Box>
            <PassengerInfoUpdateBlock
              tripId={trip.id}
              contact={trip.contact}
              bookingContact={trip.request.bookingContact}
              temporaryPassenger={trip.temporaryPassenger}
              refetch={operatorRouteRefetch}
              setSaveIndicatorState={setSaveIndicatorState}
            />

            <DriverUpdateBlock
              // driver query expects ID of type Route
              operatorRouteId={toGlobalId(
                fromGlobalId(operatorRoute.id).id,
                "Route"
              )}
              driver={operatorRoute.routeDriver}
              driverNote={operatorRoute.driverNote}
              driverStatus={operatorRoute.driverStatus}
              setSaveIndicatorState={setSaveIndicatorState}
              {...(isFarmedRoute &&
                isFarmor && {
                  farmAffiliateId: operatorRoute.farmAffiliate.id,
                })}
            />

            <TripInfoUpdateBlock
              trip={trip}
              setSaveIndicatorState={setSaveIndicatorState}
              suggestedAddressInfo={suggestedAddressInfo}
            />

            <TripDetailsUpdateBlock
              trip={trip}
              setSaveIndicatorState={setSaveIndicatorState}
              adjustLuggageAmount={adjustLuggageAmount}
            />

            <DispatchAlertsBlock
              operatorRoute={operatorRoute}
              setSaveIndicatorState={setSaveIndicatorState}
            />

            <AddVehicleUpdateBlock
              mode="dispatch"
              trip={trip}
              refetch={operatorRouteRefetch}
              setSaveIndicatorState={setSaveIndicatorState}
              {...(isFarmedRoute &&
                isFarmor && {
                  farmAffiliateId: operatorRoute.farmAffiliate.id,
                })}
            />
            <Box my={5} pt={4}>
              <CommentUpdateBlock
                comments={trip.comments}
                tripId={trip.id}
                mode="trip"
                refetchQuery={operatorRouteRefetch}
                setSaveIndicatorState={setSaveIndicatorState}
              />
            </Box>
          </Box>
        )}
      </UpdateDrawer>
      <RemoveDialog
        open={deleteDialogOpen}
        onRemove={handleClickRemove}
        onClose={() => setDeleteDialogOpen(false)}
        title={`Are you sure you want to delete this trip?`}
        body={`This trip will be removed from your reservation, invoice, and dispatch pages. You will not be able to see these deleted trips on saved contacts.`}
        removeButtonText="Delete"
      />
    </>
  );
}

export default UpdateDispatchRouteDrawer;
