import { useFieldArray, useForm } from "react-hook-form";

/**
 * Custom hook that provides field array functionality with a fallback for when control is not available.
 * This hook was created as a workaround for cases where the form control does not exist, such as in read-only views.
 * It uses a dummy form control when none is provided to prevent errors, and returns empty field array methods.
 *
 * @param control - The form control from react-hook-form, could be undefined
 * @param name - The name of the field array, could be undefined
 * @returns Either the actual field array methods if control exists, or empty stub methods if it doesn't
 */
const usePricingTiersFieldArray = (control, name) => {
  // workaround to circumvent useFieldArray handling of undefined control
  const { control: dummyControl } = useForm();

  const fieldArray = useFieldArray({
    control: control || dummyControl,
    name: name,
  });

  return control
    ? fieldArray
    : // return empty field array methods if control is undefined
      {
        fields: undefined,
        insert: () => {},
        remove: () => {},
        replace: () => {},
      };
};

export default usePricingTiersFieldArray;
