import React from "react";

import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";

import { granite, grayLight } from "design-system/colors";
import { CrossIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";
import { currency } from "globals/utils/helpers";
import { BRALineItem } from ".";
import { BaseRateAutomation } from "types";

type BRACalculationsMenuProps = {
  onClose: () => void;
  anchorEl: HTMLElement;
  baseRateAutomation: BaseRateAutomation;
};

function BRACalculationsMenu(props: BRACalculationsMenuProps) {
  const { onClose, anchorEl, baseRateAutomation } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    anchorEl && (
      <Menu
        anchorEl={anchorEl}
        id="basic-menu"
        open={!!anchorEl}
        onClose={onClose}
        keepMounted
        sx={{
          borderRadius: 16,
          padding: 16,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {/* Header */}
        <MenuItem
          sx={{
            minWidth: isMobileView ? 375 : 425,
            padding: 2,
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&:hover:not(.Mui-disabled)": {
              cursor: "default",
            },
            marginBottom: 2,
          }}
          divider
        >
          <Box display="flex" flex="1" flexDirection="column">
            <Box display="flex">
              <Box display="flex" flex="1" alignItems="center">
                <Typography variant="h3">BRA Calculations</Typography>
              </Box>
              <IconButton
                onClick={onClose}
                style={{ marginRight: isMobileView ? "4px" : "-12px" }}
              >
                <CrossIcon color={granite} />
              </IconButton>
            </Box>
          </Box>
        </MenuItem>

        {/* Line Items */}
        {baseRateAutomation?.lineItems.map((lineItem) => {
          const { name, description, lineTotal } = lineItem;

          return (
            <BRALineItem
              key={name}
              name={name}
              description={description}
              lineTotal={lineTotal}
            />
          );
        })}

        {/* Total */}
        <MenuItem
          sx={{
            alignContent: "center",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&:hover:not(.Mui-disabled)": {
              cursor: "default",
            },
            padding: "12px 16px",
            marginTop: 2,
          }}
        >
          <Box
            borderRadius="4px"
            display="flex"
            flex="1"
            flexDirection="column"
            padding="12px 16px"
            width="327px"
            style={{ backgroundColor: grayLight }}
          >
            <Box display="flex">
              <Box display="flex" flex="1" alignItems="center">
                <Typography variant={"subtitle1"} style={{ padding: 2 }}>
                  Total
                </Typography>
              </Box>
              <Typography
                variant={"subtitle1"}
                style={{
                  letterSpacing: "0.7px",
                  marginRight: "-12px",
                  padding: "2px 16px",
                }}
              >
                {currency(baseRateAutomation?.total)}
              </Typography>
            </Box>
          </Box>
        </MenuItem>
      </Menu>
    )
  );
}

export default BRACalculationsMenu;
