import React from 'react';
import { useQuery } from '@apollo/client';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import MultiSelectFilterInput from './MultiSelectFilterInput';
import { LOAD_FARM_AFFILIATES_NAME_AND_ID_QUERY } from 'globals/graphql';
import { FarmAffiliateVariantEnum } from 'types';

export default function AffiliateFilterInput(props: GridFilterInputValueProps) {
  const { data } = useQuery(LOAD_FARM_AFFILIATES_NAME_AND_ID_QUERY, {
    variables: {
      viewFilter: Object.values(FarmAffiliateVariantEnum),
    },
  });
  
  const affiliateOptions = data?.loadFarmAffiliates.edges.map(({ node: affiliate }) => ({
    name: affiliate.operatorName,
    id: affiliate.id,
  })) || [];

  return <MultiSelectFilterInput {...props} options={affiliateOptions} />;
} 