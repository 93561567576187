import { toBase64, fromBase64 } from "b64-lite";
import startCase from "lodash/startCase";
import moment, { Moment } from "moment-timezone";
import { DeepPartial } from "ts-essentials";

import {
  dispatchSchedulerColors,
  errorRed,
  grayLight,
  grayMedium,
  green,
  orange,
  tintGreen,
  tintOrange,
  tintRed,
} from "../../design-system/colors";
import {
  CardItem,
  FarmedRouteStatusEnum,
  Request,
  Stop,
  Trip,
  User,
} from "../../types";
import airportTimeZone from "./airportTimeZone.json";
import { DispatchStatusEnum } from "./enumMaps";

export function fromGlobalId(globalId: string) {
  const unBasedGlobalId = fromBase64(globalId);
  const delimiterPos = unBasedGlobalId.indexOf(":");
  return {
    type: unBasedGlobalId.substring(0, delimiterPos),
    id: unBasedGlobalId.substring(delimiterPos + 1),
  };
}

export function toGlobalId(id: string, type: string) {
  return toBase64([type, id].join(":"));
}

export function fromRouteIdToOperatorRouteId(routeId: string) {
  const decoded = fromGlobalId(routeId);
  return toGlobalId(decoded.id, "OperatorRoute");
}

export function isValidPhoneNumber(phoneNumber: string) {
  if (!phoneNumber) return false;

  const unmaskedPhone = phoneNumber
    .toString()
    .replace(/[+]/, "")
    .replace(/^1/, "")
    .replace(/\s/g, "")
    .replace(/[()]/g, "")
    .replace(/-/g, "");

  return unmaskedPhone.length === 10;
}

export function currency(money: number) {
  if (money === undefined) return "-";

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(money);
}

export const currencyConverter = {
  toCents: (amount?: number) => {
    if (!amount && amount !== 0) return amount;
    return Math.round(amount * 100);
  },
  toDollars: (amount?: number) => {
    if (!amount && amount !== 0) return amount;
    return Number((amount / 100).toFixed(2));
  },
};

/**
 * @function confirmationNumberFromRequest
 * Takes a request and trip and outputs the confirmation number.
 *
 * Note: requires trip to be more forward thinking. While right now
 * we could just take the trip at first index from request, as we add
 * multiple trips we would want to know which trip it is.
 */
export function confirmationNumberFromRequest(request?: Request, trip?: Trip) {
  if (!request) return "";

  return trip?.tripNumber
    ? `${request.orderNumber}-${trip.tripNumber}`
    : request.orderNumber;
}

// time related functions
export function convertMinutesToHoursMinutes(
  inputMinutes: number,
  variant: "default" | "semi-formal" | "formal" = "default"
) {
  const hours = Math.floor(inputMinutes / 60);
  const minutes = Math.round(inputMinutes % 60);

  let result = "";

  if (!hours && !minutes) return "N/A";

  if (variant === "formal") {
    if (hours) result += `${hours} Hour${hours > 1 ? "s" : ""}`;
    if (minutes) result += ` ${minutes} Minute${minutes > 1 ? "s" : ""}`;
  }

  if (variant === "semi-formal") {
    if (hours) result += `${hours} hour${hours > 1 ? "s" : ""}`;
    if (minutes) result += ` ${minutes} min${minutes > 1 ? "s" : ""}`;
  }

  if (variant === "default") {
    if (hours) result += `${hours}h`;
    if (minutes) result += ` ${minutes} min`;
  }

  return result;
}

export function checkIfStopTimesAreInOrder(
  stops: DeepPartial<Stop[]>
): boolean[] {
  let largestTime = 0;

  return stops.map((stop) => {
    const time = stop.dateTime;
    if (!time) return false;

    const unixTime = moment(time).unix();
    const prevLargestTime = largestTime;

    largestTime = Math.max(largestTime, unixTime);

    return unixTime < prevLargestTime;
  });
}

export function checkIfDropOffTimeIsMissing(
  stops: DeepPartial<Stop[]>
): boolean[] {
  let stopHasDateTime = false;
  const addedStops = stops.slice(1, stops.length - 1);
  if (addedStops.some((stop: Stop) => !!stop.dateTime)) {
    stopHasDateTime = true;
  }
  return stops.map((stop, index) => {
    if (index !== stops.length - 1) return false;
    return stop.dateTime ? false : stopHasDateTime;
  });
}

// disable date picker date with proper UTC date comparison
export function disablePastDatePickerDates(date) {
  const dateUnix = date.unix();
  const currentUnixTime = moment().unix();

  // disable if the date is before current time
  return dateUnix + 24 * 60 * 60 < currentUnixTime;
}

export function applyUTCOffsetToTime(time: any, event: "add" | "subtract") {
  // use subtract to fix automatic timezone adjustment by mui date-picker/ or devexpress scheduler.
  // use add to set/send correct time after mui date-picker selects.

  if (!time) return time;
  const isMomentObj = time?._isAMomentObject;
  const timezoneGuess = moment.tz.guess(true);

  const UTCOffset = isMomentObj
    ? moment
        .tz(time.format("MM DD YY hh:mm:a"), "MM DD YY hh:mm:a", timezoneGuess)
        .utcOffset()
    : moment
        .tz(
          moment.utc(time).format("MM DD YY hh:mm:a"),
          "MM DD YY hh:mm:a",
          timezoneGuess
        )
        .utcOffset();

  return isMomentObj
    ? time[event](UTCOffset, "m")
    : moment(time)[event](UTCOffset, "m");
}

export function convertTimeToLocalAirlineTimezone(date: Date, icao: string) {
  return moment(date).tz(airportTimeZone[icao]);
}

type DateFormatterOptions = {
  mode?: "date" | "dateTime";
  returnFormatString?: boolean;
};

export function dateFormatter(
  date: Moment | null,
  variant:
    | "long"
    | "medium"
    | "abbreviated"
    | "numerical"
    | "fullNumerical"
    | "timestamp",
  options?: DateFormatterOptions
) {
  if (variant === "timestamp") {
    return date.calendar({
      sameDay: "[Today], h:mm A",
      nextDay: "[Tomorrow], h:mm A",
      lastDay: "[Yesterday], h:mm A",
      sameElse: "MMM D, h:mm A",
    });
  }

  const formatStringMap = {
    long: "dddd, MMMM Do, YYYY", // Monday, February 21st, 2020
    medium: "dddd, MMM Do, YYYY", // Monday, Feb 21st, 2020
    abbreviated: "ddd, MMM Do, YYYY", // Mon, Feb 21st, 2020
    numerical: "MM/DD/YY", // 02/21/20
    fullNumerical: "ddd MM/DD/YY",
  };

  let formatString = formatStringMap[variant];

  if (options?.mode === "dateTime") formatString = `${formatString} h:mm A`; // 12:00 PM

  return options?.returnFormatString ? formatString : date.format(formatString);
}

export function getMinutesOfCurrentDay(m: Moment) {
  return m.minutes() + m.hours() * 60;
}

// reorders stops during and after drags
export function reorderStops(
  stops: any[],
  sourceIndex: number,
  destinationIndex: number
) {
  const nextStops = [...stops];

  const [removedStop] = nextStops.splice(sourceIndex, 1);
  nextStops.splice(destinationIndex, 0, removedStop);

  return stops.map((stop, index) => ({
    ...stop,
    stopIndex: index + 1,
    id: nextStops[index].id,
    location: nextStops[index].location,
    locationAlias: nextStops[index].locationAlias,
    airport: nextStops[index].airport,
    airline: nextStops[index].airline,
    flightNumber: nextStops[index].flightNumber,
    trackedFlight: nextStops[index].trackedFlight,
    variant: nextStops[index].variant,
  }));
}

export function assignColorsByIndex<T>(items: T[] = []) {
  return items.map((item, index) => {
    // if more items than we have colors, loops back to the first color.
    const colorIndex = index % dispatchSchedulerColors.length;

    return {
      ...item,
      backgroundColor: dispatchSchedulerColors[colorIndex].backgroundColor,
      textColor: dispatchSchedulerColors[colorIndex].textColor,
    };
  });
}

export function convertToAmtOrPct(
  amt: number,
  isPct: boolean,
  baseRateAmt: number
) {
  return !amt
    ? null
    : isPct
    ? (amt / baseRateAmt) * 100 // convert $amt to %amt
    : amt;
}

export function stringOfUsers(operatorUsers: User[]) {
  const getName = (user) => {
    return `${user.firstName} ${user.lastName}`;
  };

  let userNames;
  if (operatorUsers.length === 1) {
    userNames = getName(operatorUsers[0]);
  } else if (operatorUsers.length === 2) {
    userNames = `${getName(operatorUsers[0])} and ${getName(operatorUsers[1])}`;
  } else {
    const operatorUserNames = operatorUsers.map((user) => getName(user));
    const lastUser = operatorUserNames.pop();
    const joinedUsers = operatorUserNames.join(", ");
    userNames = `${joinedUsers}, and ${lastUser}`;
  }
  return userNames;
}

export function getElementWidthById(id: string) {
  return document.getElementById(id)?.offsetWidth;
}

// Visa ****-1111 EXP: 12/2024
export const getPaymentMethodInfoString = (card: CardItem) => {
  const { brand, last4, expMonth, expYear } = card;

  const value = `${startCase(brand)} ****-${last4} EXP: ${expMonth}/${expYear
    .toString()
    .slice(-2)}`;

  return value;
};

export const parseNumberFromFlightNumber = (ident: string): string => {
  if (!ident) return "";
  return ident.replace(/([^0-9])/g, "");
};

export const getColorForFarmStatus = {
  // remove string type when we are passing actual values
  bgcolor: (farmedRouteStatus: FarmedRouteStatusEnum | string) => {
    switch (farmedRouteStatus) {
      case FarmedRouteStatusEnum.Pending:
        return tintOrange;
      case FarmedRouteStatusEnum.Accepted:
        return tintGreen;
      case FarmedRouteStatusEnum.Declined:
        return tintRed;
      case FarmedRouteStatusEnum.Completed:
        return grayLight;
    }
  },
  // remove string type when we are passing actual values
  circleAndBorderColor: (farmedRouteStatus: FarmedRouteStatusEnum | string) => {
    switch (farmedRouteStatus) {
      case FarmedRouteStatusEnum.Pending:
        return orange;
      case FarmedRouteStatusEnum.Accepted:
        return green;
      case FarmedRouteStatusEnum.Declined:
        return errorRed;
      case FarmedRouteStatusEnum.Completed:
        return grayMedium;
    }
  },
};
export const getDispatchStatus = (
  statusSlug,
  options?: { upperCase?: boolean; shortened?: boolean }
) => {
  const dispatchStatus = DispatchStatusEnum[statusSlug];

  // May have to add an additional shortenedStatatusEnum
  if (options?.shortened && statusSlug === "pob") return "POB";
  if (options?.shortened && statusSlug === "otw") return "OTW";

  return options?.upperCase ? dispatchStatus.toUpperCase() : dispatchStatus;
};

export const pluralize = (word: string) => word + "s";
