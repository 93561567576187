import React, { useEffect, useMemo, useState, Ref, ChangeEvent } from "react";

import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

import {
  TextField,
  Box,
  Grid,
  Typography,
  InputProps as MuiInputProps,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { HomeIcon, OfficeIcon, AddressIcon } from "../../../icons";
import MoovsAvatar from "../../MoovsAvatar";
import { black, grayMedium, alabaster } from "../../../colors";

const autocompleteService: any = { current: null };

const inputSx = {
  fontSize: "14px",
  backgroundColor: "white",
  "& svg": {
    fill: "transparent",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid transparent",
  },
  ":hover": {
    "& svg": {
      fill: grayMedium,
      transition: "all 0.3s",
      transitionDelay: "0s",
    },
  },
  ":hover:not(.Mui-error)": {
    ".MuiOutlinedInput-notchedOutline": {
      transition: "all 0.3s",
      transitionDelay: "0s",
      borderColor: grayMedium,
    },
  },
  "&.Mui-focused:not(.Mui-error)": {
    ".MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: black,
    },
  },
  "&.Mui-focused": {
    backgroundColor: alabaster + "!important",
    "& svg": {
      fill: black + "!important",
    },
  },
};

type PlaceType = {
  structured_formatting: {
    secondary_text: string;
    main_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
};

function InlineLocationAutocomplete({
  id,
  onChange,
  errorText,
  value,
  placeholder,
  inputRef,
  disabled,
  required,
  name,
  suggestedAddressInfo,
  TextFieldInputProps,
}: {
  id?: string;
  label?: string;
  onChange: (value: any, event?: any) => void;
  errorText?: string;
  value: any;
  placeholder?: string;
  inputRef?: Ref<HTMLInputElement>;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  suggestedAddressInfo?: any[];
  TextFieldInputProps?: MuiInputProps;
}) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<PlaceType[]>([]);

  const error = required && !value;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleUpdateField = (event: object, value: any, reason: string) => {
    if (reason === "clear") {
      value = onChange(
        {
          description: "",
        },
        event
      );
      setInputValue("");
    }

    if (value?.address) {
      onChange({
        description: value.address,
      });
      return;
    }

    if (onChange && value) {
      onChange(value, event);
    }
    if (onChange && !value) {
      value = {
        description: "",
      };
      onChange(value, event);
    }
  };

  const fetch = useMemo(
    () =>
      throttle((input: any, callback: any) => {
        (autocompleteService.current as any).getPlacePredictions(
          input,
          callback
        );
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google?.maps?.places) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }

    if (inputValue === "" && suggestedAddressInfo?.length > 0) {
      setOptions(suggestedAddressInfo);
      return undefined;
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch, suggestedAddressInfo]);

  return (
    <Autocomplete
      fullWidth
      freeSolo
      includeInputInList
      disabled={disabled}
      onFocus={(e) => e.stopPropagation()}
      onChange={handleUpdateField}
      id={id}
      getOptionLabel={(option: any) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete={false}
      placeholder={placeholder}
      value={value}
      renderInput={(params) => {
        const { InputProps, ...restParams } = params;
        return (
          <TextField
            multiline
            name={name}
            inputRef={inputRef}
            {...restParams}
            InputProps={{
              ...InputProps,
              ...TextFieldInputProps,
              sx: inputSx,
              autoComplete: "new-password",
            }}
            fullWidth
            variant="outlined"
            onChange={handleChange}
            error={error}
            placeholder={placeholder}
            helperText={error ? errorText : ""}
          />
        );
      }}
      renderOption={(props, option) => {
        // preferred address from contacts
        if (option.address) {
          const { mode, address, firstName, lastName } = option;
          return (
            <li {...props}>
              <Box
                display="flex"
                flex="1"
                flexDirection="row"
                alignItems="center"
              >
                <Box
                  mr={1}
                  display="flex"
                  alignItems="center"
                  flex="1"
                  flexShrink={0}
                >
                  <Box display="flex" alignItems="center" marginRight={2}>
                    {mode === "homeAddress" ? (
                      <HomeIcon size="small" />
                    ) : (
                      <OfficeIcon />
                    )}
                  </Box>
                  <Typography variant="body2">{address}</Typography>
                </Box>
                <Box display="flex" flexShrink={0}>
                  <MoovsAvatar
                    size="small"
                    placeholder={[firstName, lastName]}
                  />
                </Box>
              </Box>
            </li>
          );
        }

        // google address
        if (option.description) {
          const matches =
            option.structured_formatting?.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting?.main_text || "",
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length,
            ])
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <AddressIcon />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }
      }}
    />
  );
}

export { InlineLocationAutocomplete };
