import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import axios from "axios";

import * as Sentry from "@sentry/react";

import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useAnalytics, useGoogleTracking, useSnackbar } from "globals/hooks";
import { UPDATE_ONBOARDING_OPERATOR_MUTATION } from "globals/graphql";
import { white } from "design-system/colors";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { InfoIcon } from "design-system/icons";
import LocationAutoComplete from "components/autocompletes/LocationAutoComplete";
import { Operator } from "types";
import { fromGlobalId } from "globals/utils/helpers";
import { hubSpotFleetSizeOptions } from "./constants";

type OperatorInfoFieldsProps = {
  setActiveStep: React.Dispatch<React.SetStateAction<3>>;
};

const OPERATING_COUNTRY_OPTIONS = [
  {
    label: "United States",
    value: "US",
  },
  {
    label: "Canada",
    value: "CA",
  },
];

const COUNTRY_CONFIGS = {
  US: {
    currencyCode: "usd",
    distanceUnit: "miles",
  },
  CA: {
    currencyCode: "cad",
    distanceUnit: "kilometres",
  },
};

function OperatorInfoFields(props: OperatorInfoFieldsProps) {
  const { setActiveStep } = props;

  // state
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState({
    companyName: "",
    location: "",
    websiteUrl: "",
  });
  const [fleetSize, setFleetSize] = useState("");
  const [companyNameError, setCompanyNameError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [operatingCountry, setOperatingCountry] = useState("US");
  const [fleetSizeError, setFleetSizeError] = useState(false);

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const { googleTrack } = useGoogleTracking();

  const [updateOnboardingOperator] = useMutation(
    UPDATE_ONBOARDING_OPERATOR_MUTATION,
    {
      onCompleted: (data) => {
        setLoading(false);
        track("onboarding_companyInfoSubmitted");

        // if production submit the hubspot form with operator info
        if (process.env.REACT_APP_ENVIRONMENT === "production") {
          const hubspotAccountId = "2917284";
          const hubspotFormId = "e00de794-f1e5-4514-8edb-febd612db675";
          const cookie = getCookie("hubspotutk");
          const operator = data.updateOnboardingOperator.operator as Operator;
          const user = operator.users[0];

          const basefields = [
            {
              objectTypeId: "0-1",
              name: "firstname",
              value: user.firstName,
            },
            {
              objectTypeId: "0-1",
              name: "lastName",
              value: user.lastName,
            },
            {
              objectTypeId: "0-1",
              name: "email",
              value: user.email,
            },
            {
              objectTypeId: "0-1",
              name: "phone",
              value: user.mobilePhone,
            },
            {
              objectTypeId: "0-2",
              name: "name", // company name
              value: operator.name,
            },
            {
              objectTypeId: "0-2",
              name: "postgres_operator_id",
              value: fromGlobalId(operator.id).id,
            },
            {
              objectTypeId: "0-2",
              name: "moovs___fleet_size",
              value: fleetSize,
            },
          ];

          axios
            .post(
              `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotAccountId}/${hubspotFormId}`,
              {
                fields: operator.websiteUrl
                  ? [
                      ...basefields,
                      {
                        objectTypeId: "0-2",
                        name: "website",
                        value: operator.websiteUrl,
                      },
                    ]
                  : basefields,
                context: {
                  pageUri: window.location.origin + window.location.pathname,
                  pageName: "Onboarding",
                  ...(cookie && { hutk: cookie }),
                },
              },
              { withCredentials: false }
            )
            .catch(Sentry.captureException)
            .then(() => googleTrack("hubspot-form-success"));
        }

        setActiveStep(3);
      },
      onError: (error) => {
        setLoading(false);

        snackbar.error(getErrorMessage(error));
      },
    }
  );

  const resetErrors = (targetName: string) => {
    if (targetName === "companyName") setCompanyNameError(false);
    if (targetName === "location") setLocationError(false);
    if (targetName === "fleetSize") setFleetSizeError(false);
  };

  const isFormValid = () => {
    let showErrorMessage = false;

    if (!companyData.companyName || !companyData.location || !fleetSize) {
      showErrorMessage = true;
    }

    if (!companyData.companyName) {
      setCompanyNameError(true);
    }

    if (!companyData.location) {
      setLocationError(true);
    }

    if (!fleetSize) {
      setFleetSizeError(true);
    }

    if (showErrorMessage) {
      snackbar.error("Please fill all of the required fields", {
        hideBoxShadow: true,
      });

      return false;
    }

    return true;
  };

  const handleOnSubmit = () => {
    if (!isFormValid()) return;

    setLoading(true);

    const { currencyCode, distanceUnit } = COUNTRY_CONFIGS[operatingCountry];

    updateOnboardingOperator({
      variables: {
        input: {
          companyName: companyData.companyName,
          address: companyData.location,
          currencyCode,
          distanceUnit,
          ...(companyData.websiteUrl && { websiteUrl: companyData.websiteUrl }),
        },
      },
    });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleOnSubmit();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetErrors(e.target.name);

    setCompanyData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleLocationChange = (value) => {
    resetErrors("location");

    setCompanyData((prevState) => {
      return {
        ...prevState,
        location: value.description,
      };
    });
  };

  const handleOperatingCountryChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOperatingCountry(e.target.value);
  };

  const handleFleetSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetErrors(e.target.name);

    setFleetSize(e.target.value);
  };

  // effects
  useEffect(() => {
    track("onboarding_companyInfoInitiated");
  }, [track]);

  return (
    <>
      <TextField
        required
        fullWidth
        variant="outlined"
        label="Company Name"
        name="companyName"
        onChange={handleChange}
        error={companyNameError}
        value={companyData.companyName}
        onKeyDown={handleKeyPress}
        sx={{ backgroundColor: white }}
      />
      <Box mt={1}>
        <TextField
          fullWidth
          variant="outlined"
          label="Company Website"
          name="websiteUrl"
          value={companyData.websiteUrl}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          sx={{ backgroundColor: white }}
        />
      </Box>
      <Box mt={1}>
        <LocationAutoComplete
          required
          fullWidth
          value={companyData.location}
          error={locationError}
          label="Location"
          onChange={handleLocationChange}
          name="location"
          styles={{
            '&&[class*="MuiOutlinedInput-root"] $input': {
              padding: 0,
            },
          }}
          disableClearable
          TextFieldInputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="In order to provide the best experience with pricing, farming and reporting, we need the specific address or nearest address your vehicles are located">
                  <IconButton size="large">
                    <InfoIcon size="small" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box mt={1}>
        <TextField
          required
          fullWidth
          variant="outlined"
          value={operatingCountry}
          label="Which country do you operate in?"
          name="operatingCountry"
          onChange={handleOperatingCountryChange}
          onKeyDown={handleKeyPress}
          sx={{ backgroundColor: white }}
          select
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ position: "absolute", p: 0, right: 24 }}
              >
                <Tooltip title="Please reach out to support if you would like your country to be supported.">
                  <IconButton size="large">
                    <InfoIcon size="small" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        >
          {OPERATING_COUNTRY_OPTIONS.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box mt={1}>
        <TextField
          required
          fullWidth
          error={fleetSizeError}
          variant="outlined"
          value={fleetSize}
          label="How many vehicles in your fleet?"
          name="fleetSize"
          onChange={handleFleetSizeChange}
          onKeyDown={handleKeyPress}
          sx={{ backgroundColor: white }}
          select
        >
          {hubSpotFleetSizeOptions.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box mt={1}>
        <Box display="flex" flexDirection="column" alignItems="center" my={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            fullWidth
            onClick={handleOnSubmit}
          >
            Continue
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
}

export default OperatorInfoFields;

function getCookie(name: string) {
  const parts = document.cookie.split(name + "=");

  if (parts.length !== 2) return "";

  return parts.pop().split(";").shift();
}
