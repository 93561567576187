import React from "react";

import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";

import { black, grayLight, white } from "design-system/colors";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  padding: "6px",
  width: "100%",
  justifyContent: "space-between",
  "& .MuiToggleButtonGroup-grouped": {
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 4,
    },
    "&:first-of-type": {
      borderRadius: 4,
    },
  },
});

const StyledToggleButton = styled(ToggleButton)({
  padding: "12px 16px",
  flexWrap: "nowrap",
  color: black,
  "&:hover": {
    background: "none",
  },
  "&.Mui-selected": {
    color: black,
    backgroundColor: white,
    transition: "background-color 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: white,
    },
  },
});

export type MoovsToggleSwitchProps<T extends string> = {
  value: T;
  onChange: (value: T) => void;
  options: {
    value: T;
    content: React.ReactNode;
  }[];
  width?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  dataTestId?: string;
};

function MoovsToggleSwitch<T extends string>(props: MoovsToggleSwitchProps<T>) {
  const {
    value,
    onChange,
    options,
    width,
    fullWidth = false,
    disabled = false,
    dataTestId,
  } = props;

  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    newValue: T | null | undefined
  ) => {
    if (newValue !== null && newValue !== undefined) {
      onChange(newValue);
    }
  };

  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="space-around"
      height="54px"
      width={fullWidth ? "100%" : width}
      sx={{
        backgroundColor: grayLight,
        opacity: disabled ? 0.6 : 1,
        borderRadius: "6px",
      }}
    >
      <StyledToggleButtonGroup
        color="primary"
        value={value}
        exclusive
        onChange={handleChange}
        aria-label="toggle-switch"
        fullWidth={fullWidth}
        disabled={disabled}
        data-testid={dataTestId || ""}
      >
        {options.map((option) => (
          <StyledToggleButton
            key={option.value}
            disableRipple
            value={option.value}
          >
            {option.content}
          </StyledToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Box>
  );
}

export default MoovsToggleSwitch;
