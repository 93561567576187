import React, { useMemo } from "react";
import isNumber from "lodash/isNumber";
import isNull from "lodash/isNull";

import {
  Box,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { hourlyOptions } from "globals/utils/hourlyOptions";
import {
  NumberFormatDollar,
  NumberFormatNatural,
} from "design-system/components/inputs/NumberFormat";
import { grayDark, granite } from "design-system/colors";
import { InfoIcon } from "design-system/icons";
import MoovsTooltip from "components/MoovsTooltip";
import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";
import { PricingVariant } from "types";
import { tieredHourlyPricingVariantToggleButtons } from "../constant";
import { useVehiclePricing } from "../context";
import { VehicleWeekendsBlock } from "./VehicleWeekendsBlock";
import { ControllerWrapper } from "./ControllerWrapper";
import { PricingTiersBlock } from "./PricingTiersBlock";

export function HourlyPricingSection() {
  const { isReadOnly, isDisabled, control, vehiclePricing, formContext } =
    useVehiclePricing();
  const { watch, setValue, getValues } = formContext || {};

  // derived state
  const isUsingTieredHourlyPricing = isReadOnly
    ? vehiclePricing?.useTieredHourly
    : watch("vehicle.useTieredHourly");

  const isUsingTieredHourlyWeekendPricing = isReadOnly
    ? vehiclePricing?.useTieredHourlyWeekend
    : watch("vehicle.useTieredHourlyWeekend");

  // memo
  const hourlyOptionsMemoized = useMemo(() => {
    if (isUsingTieredHourlyPricing) {
      return hourlyOptions.slice(1); // Remove the first option "None"
    }
    return hourlyOptions;
  }, [isUsingTieredHourlyPricing]);

  const weekendHourlyOptionsMemoized = useMemo(() => {
    if (isUsingTieredHourlyWeekendPricing) {
      return hourlyOptions.slice(1); // Remove the first option "None"
    }
    return hourlyOptions;
  }, [isUsingTieredHourlyWeekendPricing]);

  // handlers
  const handleToggleTieredHourlyPricing = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // if the user is enabling tiered pricing, and the minimum is to 0 since null will not be selectable
    if (e.target.checked && getValues("vehicle.weekdayMinMinutes") === null) {
      setValue("vehicle.weekdayMinMinutes", 0);
    }

    return e.target.checked;
  };

  const handleToggleTieredHourlyWeekendPricing = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // if the user is enabling tiered pricing, and the minimum is to 0 since null will not be selectable
    if (e.target.checked && getValues("vehicle.weekendMinMinutes") === null) {
      setValue("vehicle.weekendMinMinutes", 0);
    }

    return e.target.checked;
  };

  return (
    <>
      <Box my={1}>
        <Typography variant="subtitle2" sx={{ color: granite }} mb={1.5}>
          Hourly
        </Typography>
      </Box>

      {/* Covered Deadhead Duration */}
      <ControllerWrapper
        control={control}
        name="vehicle.totalDeadheadDurationMinutes"
        transformOnChange={(e) =>
          isNull(e.target.value) ? e.target.value : Number(e.target.value) * 60
        }
        transformValue={(value) => (isNull(value) ? value : value / 60)}
      >
        <TextField
          select
          fullWidth
          id="total-deadhead-duration"
          data-testid="total-deadhead-duration-input"
          label="Covered Deadhead Duration"
          variant="outlined"
          disabled={isDisabled}
          InputProps={{
            inputComponent: NumberFormatNatural as any,
            endAdornment: (
              <Box pr={2} position="relative">
                <MoovsTooltip
                  tooltipText={`Duration from/to your garage that won't be charged to the customer.`}
                >
                  <InfoIcon color={grayDark} />
                </MoovsTooltip>
              </Box>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            displayEmpty: true,
          }}
          {...(isReadOnly && {
            value: isNumber(vehiclePricing?.totalDeadheadDurationMinutes)
              ? vehiclePricing.totalDeadheadDurationMinutes / 60
              : null,
          })}
        >
          {hourlyOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {isNull(option) ? "Deadhead disabled" : `${option} Hours`}
            </MenuItem>
          ))}
        </TextField>
      </ControllerWrapper>

      {/* Weekdays */}
      <Box display="flex" flexDirection="row" alignItems="center" mt={3} mb={2}>
        <Typography variant="overline" sx={{ color: granite }}>
          weekdays
        </Typography>

        <ControllerWrapper
          control={control}
          name="vehicle.useTieredHourly"
          transformOnChange={handleToggleTieredHourlyPricing}
          deps={["vehicle.weekdayMinMinutes", "vehicle.weekdayHourlyCost"]}
        >
          <FormControlLabel
            control={
              <Switch
                color="primary"
                data-testid="hourly-tiered-pricing-toggle"
              />
            }
            checked={isUsingTieredHourlyPricing}
            disabled={isDisabled}
            label={<Typography variant="body2">Tiered Pricing</Typography>}
            sx={{ ml: 1 }}
          />
        </ControllerWrapper>
      </Box>

      <ControllerWrapper
        control={control}
        name="vehicle.weekdayMinMinutes"
        transformOnChange={(e) =>
          isNull(e.target.value) ? e.target.value : Number(e.target.value) * 60
        }
        transformValue={(value) => (isNull(value) ? value : value / 60)}
        deps={[
          "vehicle.weekdayHourlyCost",
          "vehicle.minimumTotalBaseRate",
          "vehicle.deadheadRatePerMile",
          "vehicle.tripRatePerMile",
        ]}
      >
        <TextField
          data-testid="hourly-min-minutes-input"
          select
          fullWidth
          sx={{ mb: 1 }}
          id="weekday-min-minutes"
          label="Hourly Minimum"
          variant="outlined"
          disabled={isDisabled}
          InputProps={{
            inputComponent: NumberFormatNatural as any,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            displayEmpty: true,
          }}
          {...(isReadOnly && {
            value: isNumber(vehiclePricing?.weekdayMinMinutes)
              ? vehiclePricing.weekdayMinMinutes / 60
              : null,
          })}
        >
          {hourlyOptionsMemoized.map((option) => (
            <MenuItem key={option} value={option}>
              {isNull(option) ? "None" : `${option} Hours`}
            </MenuItem>
          ))}
        </TextField>
      </ControllerWrapper>

      <Box display="flex" alignItems="center" mb={1}>
        <ControllerWrapper
          control={control}
          name="vehicle.weekdayHourlyCost"
          transformOnChange={(e) =>
            e.target.value === "" ? null : Number(e.target.value)
          }
          deps={[
            "vehicle.weekdayMinMinutes",
            "vehicle.minimumTotalBaseRate",
            "vehicle.deadheadRatePerMile",
            "vehicle.tripRatePerMile",
          ]}
        >
          <TextField
            data-testid="hourly-rate-input"
            fullWidth
            id="weekday-hourly-rate"
            label={
              isUsingTieredHourlyPricing
                ? "Deadhead Hourly Rate"
                : "Hourly Rate"
            }
            variant="outlined"
            sx={{ width: "200px" }}
            disabled={isDisabled}
            InputProps={{
              inputComponent: NumberFormatDollar as any,
            }}
            {...(isReadOnly && {
              value: vehiclePricing?.weekdayHourlyCost,
            })}
          />
        </ControllerWrapper>
        <MoovsToggleButtonGroup
          testId="hourly-rate-type-toggle"
          options={tieredHourlyPricingVariantToggleButtons}
          value={PricingVariant.PerHour}
          onChange={() => {}}
          disabled={true}
          sx={{ ml: 2 }}
        />
      </Box>

      {isUsingTieredHourlyPricing && (
        <PricingTiersBlock
          variant="hourly"
          mode="update"
          tieredPricingTypeFieldName="vehicle.tieredPricingHourly.type"
          tieredPricingTiersFieldName="vehicle.tieredPricingHourly.tiers"
        />
      )}

      {/* Weekends */}
      <Box display="flex" flexDirection="row" alignItems="center" mt={3} mb={2}>
        <Typography variant="overline" sx={{ color: granite }}>
          weekends
        </Typography>

        <ControllerWrapper
          control={control}
          name="vehicle.useTieredHourlyWeekend"
          transformOnChange={handleToggleTieredHourlyWeekendPricing}
          deps={[
            "vehicle.weekendMinMinutes",
            "vehicle.weekendHourlyCost",
            "vehicle.weekends",
          ]}
        >
          <FormControlLabel
            control={
              <Switch
                color="primary"
                data-testid="weekend-hourly-tiered-pricing-toggle"
              />
            }
            checked={isUsingTieredHourlyWeekendPricing}
            disabled={isDisabled}
            label={<Typography variant="body2">Tiered Pricing</Typography>}
            sx={{ ml: 1 }}
          />
        </ControllerWrapper>
      </Box>

      <ControllerWrapper
        control={control}
        name="vehicle.weekends"
        deps={[
          "vehicle.weekendHourlyCost",
          "vehicle.weekendMinMinutes",
          "vehicle.weekdayHourlyCost",
          "vehicle.weekdayMinMinutes",
        ]}
        transformOnChange={(_, newWeekendsValue) => newWeekendsValue}
      >
        <VehicleWeekendsBlock
          dataTestId="weekends-select"
          disabled={isDisabled}
          {...(isReadOnly && {
            value: vehiclePricing?.settings?.weekends,
          })}
          // onChange is required for the component to work, onChange will be handled by the wrapper
          onChange={() => {}}
          sx={{ mb: 1 }}
        />
      </ControllerWrapper>

      <ControllerWrapper
        control={control}
        name="vehicle.weekendMinMinutes"
        transformOnChange={(e) => {
          return isNull(e.target.value)
            ? e.target.value
            : Number(e.target.value) * 60;
        }}
        transformValue={(value) => (isNull(value) ? value : value / 60)}
        deps={[
          "vehicle.weekendHourlyCost",
          "vehicle.weekends",
          "vehicle.weekdayHourlyCost",
          "vehicle.weekdayMinMinutes",
        ]}
      >
        <TextField
          data-testid="weekend-hourly-min-minutes-input"
          select
          fullWidth
          sx={{ mb: 1 }}
          id="weekend-min-minutes"
          label="Hourly Minimum"
          variant="outlined"
          disabled={isDisabled}
          InputProps={{
            inputComponent: NumberFormatNatural as any,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            displayEmpty: true,
          }}
          {...(isReadOnly && {
            value: isNumber(vehiclePricing?.weekendMinMinutes)
              ? vehiclePricing.weekendMinMinutes / 60
              : null,
          })}
        >
          {weekendHourlyOptionsMemoized.map((option) => (
            <MenuItem key={option} value={option}>
              {isNull(option) ? "None" : `${option} Hours`}
            </MenuItem>
          ))}
        </TextField>
      </ControllerWrapper>

      <Box display="flex" alignItems="center" mb={1}>
        <ControllerWrapper
          control={control}
          name="vehicle.weekendHourlyCost"
          transformOnChange={(e) =>
            e.target.value === "" ? null : Number(e.target.value)
          }
          deps={[
            "vehicle.weekendMinMinutes",
            "vehicle.weekends",
            "vehicle.weekdayHourlyCost",
            "vehicle.weekdayMinMinutes",
          ]}
        >
          <TextField
            data-testid="weekend-hourly-rate-input"
            fullWidth
            id="weekend-hourly-rate"
            label={
              isUsingTieredHourlyWeekendPricing
                ? "Deadhead Hourly Rate"
                : "Hourly Rate"
            }
            variant="outlined"
            sx={{ width: "200px" }}
            disabled={isDisabled}
            InputProps={{
              inputComponent: NumberFormatDollar as any,
            }}
            {...(isReadOnly && {
              value: vehiclePricing?.weekendHourlyCost,
            })}
          />
        </ControllerWrapper>
        <MoovsToggleButtonGroup
          testId="weekend-hourly-rate-type-toggle"
          options={tieredHourlyPricingVariantToggleButtons}
          value={PricingVariant.PerHour}
          onChange={() => {}}
          disabled={true}
          sx={{ ml: 2 }}
        />
      </Box>

      {isUsingTieredHourlyWeekendPricing && (
        <PricingTiersBlock
          variant="weekendHourly"
          mode="update"
          tieredPricingTypeFieldName="vehicle.tieredPricingHourlyWeekend.type"
          tieredPricingTiersFieldName="vehicle.tieredPricingHourlyWeekend.tiers"
        />
      )}
    </>
  );
}
