import moment from 'moment';

/**
 * Converts a UTC datetime to a specific timezone, preserving the same time of day.
 * For example, if the datetime is 12:30 PM UTC, the function will return 12:30 PM in the specified timezone.
 * We need this utility because we store all dattimes in utc regardless of timezone.
 * @param datetime - The UTC datetime to convert
 * @param timezoneId - The timezone to convert to
 * @returns The datetime in the specified timezone, preserving the same time of day.
 */
export const convertUtcDatetimeToSpecificTimezone = (datetime: Date, timezoneId: string) => {
    const time = moment(datetime)
    return moment.tz({
      year: time.year(),
      month: time.month(),
      day: time.day(),
      hour: time.hour(),
      minute: time.minute(),
      second: time.second(),
    }, timezoneId)
}