import React, { useState } from "react";
import moment from "moment";

import {
  Box,
  Button,
  Collapse,
  Fade,
  IconButton,
  Typography,
} from "@mui/material";

import { TrackedFlight } from "types";
import { AirportInfo, DivertedFlightInfo, FlightProgressDisplay } from "..";
import CompactFlightInfoCard from "./CompactFlightInfoCard";
import FlightTrackerDetails from "./FlightTrackerDetails";
import { granite, grayLight, moovsBlue } from "design-system/colors";
import { CollapseIcon, MaximiseFullIcon, OpenIcon } from "design-system/icons";
import { useAnalytics } from "globals/hooks";

export type FlightTrackerProps = {
  trackedFlight: TrackedFlight;
  stopFlightType: "origin" | "destination";
};

function FlightTracker(props: FlightTrackerProps) {
  const { trackedFlight, stopFlightType } = props;
  const { origin, destination, flightStatus, updatedAt, faFlightId } =
    trackedFlight;

  const originAirportCode =
    origin.airport.iataCode || origin.airport.icaoCode || "-"; // if airport not in our db, will only have icao code
  const destinationAirportCode =
    destination.airport.iataCode || destination.airport.icaoCode || "-"; // if airport not in our db, will only have icao code

  const originAirportName = origin.airport.airportName || "";
  const destinationAirportName = destination.airport.airportName || "";

  // hooks
  const { track } = useAnalytics();

  // state
  const [isExpanded, setIsExpanded] = useState(false);

  // event handlers
  const handleFlightAwareLink = (e: React.MouseEvent) => {
    e.stopPropagation();
    track("reservations_flightAwareOpened");

    window
      .open(`https://flightaware.com/live/flight/id/${faFlightId}`, "_blank")
      ?.focus();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={grayLight}
      borderRadius="4px"
      p={1.5}
    >
      <Box
        display="flex"
        alignItems="flexStart"
        justifyContent="space-between"
        data-testid="compact-flight-info"
        sx={{
          mb: isExpanded ? -3 : 0,
          transition: "margin-bottom 0.25s linear",
        }}
      >
        <CompactFlightInfoCard
          transitStopType={stopFlightType}
          trackedFlight={trackedFlight}
          isExpanded={isExpanded}
        />
        <Box width="36px" pl={0.5}>
          <IconButton
            style={{ marginTop: "-4px", marginRight: "-8px" }}
            onClick={() => setIsExpanded(!isExpanded)}
            data-testid="expand-collapse-trigger-button"
          >
            {isExpanded ? (
              <MaximiseFullIcon color={granite} />
            ) : (
              <CollapseIcon color={granite} />
            )}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Fade in={isExpanded}>
          <Box display="flex" flexDirection="column" textAlign="right">
            <Typography variant="h6">Updated:</Typography>
            <Typography variant="caption">
              {moment(updatedAt).calendar()}
            </Typography>
          </Box>
        </Fade>
        <FlightProgressDisplay
          flightStatus={flightStatus}
          removeHorizontalPadding
        />
        <Box
          display="flex"
          flexDirection="column"
          data-testid="expanded-flight-info"
        >
          <Box display="flex" justifyContent="space-between">
            <Box maxWidth="104px">
              <AirportInfo
                airportCode={originAirportCode}
                airportName={originAirportName}
                transitStopType="origin"
                useBoldTextForName={false}
              />
            </Box>
            <Box maxWidth="104px">
              <AirportInfo
                airportCode={destinationAirportCode}
                airportName={destinationAirportName}
                transitStopType="destination"
                useBoldTextForName={false}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <FlightTrackerDetails
              transitStop={origin}
              transitStopType="origin"
              trackedFlight={trackedFlight}
            />
            <FlightTrackerDetails
              transitStop={destination}
              transitStopType="destination"
              trackedFlight={trackedFlight}
            />
          </Box>
        </Box>

        {faFlightId && (
          <Box mt={2}>
            <Button
              variant="outlined"
              fullWidth
              endIcon={<OpenIcon size="xs" color={moovsBlue} />}
              onClick={handleFlightAwareLink}
            >
              <Typography variant="body2" fontWeight={500}>
                FlightAware
              </Typography>
            </Button>
          </Box>
        )}
      </Collapse>
      {flightStatus === "DIVERTED" && (
        <DivertedFlightInfo flightNumber={trackedFlight.flightNumber} />
      )}
    </Box>
  );
}

export default FlightTracker;
