import { useQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import size from "lodash/size";

import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";

import LabeledSwitchInput from "components/settings/general/LabeledSwitchInput";
import { moovsBlue } from "design-system/colors";
import {
  LOAD_TERMS_QUERY,
  UPDATE_OPERATOR_SETTINGS,
  LOAD_OPERATOR_QUERY,
} from "globals/graphql";
import { useSnackbar, useOperator } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

const REQUIRE_CONSENT = {
  requireConsentWhenBooking: true,
  requireSignatureWhenBooking: false,
};

const REQUIRE_CONSENT_SIGNATURE = {
  requireConsentWhenBooking: true,
  requireSignatureWhenBooking: true,
};

const NO_CONSENT_SIGNATURE = {
  requireConsentWhenBooking: false,
  requireSignatureWhenBooking: false,
};

type CustomerSignatureProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function CustomerSignature(props: CustomerSignatureProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const snackbar = useSnackbar();
  const operator = useOperator();

  // derived state
  const {
    settings: { requireConsentWhenBooking, requireSignatureWhenBooking },
  } = operator;

  // state
  const [consentAndSignatureSetting, setConsentAndSignatureSetting] = useState({
    requireConsentWhenBooking,
    requireSignatureWhenBooking,
    toggleEnabled: requireConsentWhenBooking || requireSignatureWhenBooking,
  });

  // queries
  const { data: termsData } = useQuery(LOAD_TERMS_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  // mutations
  const [updateConsentSignatureSelection] = useMutation(
    UPDATE_OPERATOR_SETTINGS,
    {
      refetchQueries: [
        { query: LOAD_OPERATOR_QUERY, variables: { id: operator.id } },
      ],
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        const errorMessage =
          getErrorMessage(error) ||
          "Error updating customer consent when booking.";

        snackbar.error(errorMessage);
      },
    }
  );

  // event handlers
  const handleRequireConsentToggleChange = () => {
    if (!size(termsData?.terms) && !consentAndSignatureSetting.toggleEnabled) {
      snackbar.error(
        "Must have at least one terms & condition to turn consent setting on"
      );
      return;
    }

    setSaveIndicatorState("loading");

    setConsentAndSignatureSetting({
      ...(consentAndSignatureSetting.toggleEnabled
        ? NO_CONSENT_SIGNATURE
        : REQUIRE_CONSENT),
      toggleEnabled: !consentAndSignatureSetting.toggleEnabled,
    });

    updateConsentSignatureSelection({
      variables: {
        input: consentAndSignatureSetting.toggleEnabled
          ? NO_CONSENT_SIGNATURE
          : REQUIRE_CONSENT,
      },
    });
  };

  const handleConsentSignatureRadioButtonChange = (e) => {
    setSaveIndicatorState("loading");
    const selectedRadioButton = e.target.value;

    setConsentAndSignatureSetting({
      ...consentAndSignatureSetting,
      ...(selectedRadioButton === "consent-and-signature"
        ? REQUIRE_CONSENT_SIGNATURE
        : REQUIRE_CONSENT),
    });

    updateConsentSignatureSelection({
      variables: {
        input:
          selectedRadioButton === "consent-and-signature"
            ? REQUIRE_CONSENT_SIGNATURE
            : REQUIRE_CONSENT,
      },
    });
  };

  // effects
  // initial load for consent + signature setting
  useEffect(() => {
    setConsentAndSignatureSetting({
      requireConsentWhenBooking,
      requireSignatureWhenBooking,
      toggleEnabled: requireConsentWhenBooking || requireSignatureWhenBooking,
    });
  }, [requireConsentWhenBooking, requireSignatureWhenBooking]);

  return (
    <Box mb={4}>
      <Typography variant="h3" mb={2}>
        Customer Signature
      </Typography>
      <Box ml={-1}>
        <LabeledSwitchInput
          labelPlacement="start"
          name="requireConsentWhenBookingSwitch"
          checked={consentAndSignatureSetting.toggleEnabled}
          onChange={handleRequireConsentToggleChange}
          text="Require customer consent to book"
          subText={
            consentAndSignatureSetting.requireConsentWhenBooking ||
            consentAndSignatureSetting.requireSignatureWhenBooking
              ? "Terms & Conditions and Cancellation Policy checkbox enabled"
              : "Terms & Conditions and Cancellation Policy checkbox disabled"
          }
        />
        <Box ml={1} mt={2}>
          {(consentAndSignatureSetting.requireConsentWhenBooking ||
            consentAndSignatureSetting.requireSignatureWhenBooking) && (
            <RadioGroup
              row
              name="consent-signature-radio-groups"
              value={
                consentAndSignatureSetting.requireConsentWhenBooking &&
                !consentAndSignatureSetting.requireSignatureWhenBooking
                  ? "consent"
                  : "consent-and-signature"
              }
              onChange={handleConsentSignatureRadioButtonChange}
            >
              <FormControlLabel
                value="consent"
                control={<Radio sx={{ color: moovsBlue }} />}
                label={<Typography variant="body2">Require consent</Typography>}
              />
              <FormControlLabel
                value="consent-and-signature"
                control={<Radio sx={{ color: moovsBlue }} />}
                label={
                  <Typography variant="body2">
                    Require consent and signature
                  </Typography>
                }
              />
            </RadioGroup>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default CustomerSignature;
