import React, {
  Dispatch,
  SetStateAction,
  useState,
  Fragment,
  useRef,
  useEffect,
} from "react";
import { ApolloQueryResult, useMutation } from "@apollo/client";
import isUndefined from "lodash/isUndefined";

import {
  Typography,
  Box,
  TextField,
  InputAdornment,
  Button,
  Divider,
} from "@mui/material";

import MoovsAvatar from "../design-system/components/MoovsAvatar";
import { grayDark } from "../design-system/colors";
import { ArrowRightIcon } from "../design-system/icons";
import {
  CREATE_TRIP_COMMENT,
  CREATE_CONTACT_COMMENT,
  CREATE_VEHICLE_COMMENT,
  CREATE_FARMED_ROUTE_COMMENT,
} from "../globals/graphql/comment.graphql";
import { useSnackbar } from "../globals/hooks/useSnackbar";
import { Comment } from "../types";
import InternalCommentsDialog, {
  CommentListItem,
} from "./globals/InternalCommentsDialog";
import { primaryMainColor } from "../theme";
import { useAnalytics, useCurrentUser, usePrevious } from "../globals/hooks";

const CHAR_LIMIT = 3000;

type CommentUpdateBlockProps = {
  refetchQuery: (variables?: { id: string }) => Promise<ApolloQueryResult<any>>;
  mode: "trip" | "tripDetails" | "contact" | "vehicle" | "farmee";
  comments: Comment[];
  tripId?: string;
  contactId?: string;
  vehicleId?: string;
  setSaveIndicatorState?: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function CommentUpdateBlock(props: CommentUpdateBlockProps) {
  const {
    refetchQuery,
    mode,
    comments,
    tripId,
    contactId,
    vehicleId,
    setSaveIndicatorState,
  } = props;

  // hooks
  const snackbar = useSnackbar();
  const { firstName, lastName } = useCurrentUser() || {};
  const { track } = useAnalytics();
  const previousCommentsLength = usePrevious(comments?.length || 0);

  // refs
  const inputRef = useRef(null);

  // state
  const [bodyText, setBodyText] = useState("");
  const [bodyTextError, setBodyTextError] = useState(null);
  const [internalCommentsDialogOpen, setInternalCommentsDialogOpen] =
    useState(false);

  // mutations
  const onCommentMutationComplete = () => {
    setSaveIndicatorState && setSaveIndicatorState("saved");
    setBodyText("");
    refetchQuery();
    track("internalComment_created");
  };

  const onCommentMutationError = () => {
    setSaveIndicatorState && setSaveIndicatorState("error");
    snackbar.error("Error submitting comment");
  };

  const [createTripComment] = useMutation(CREATE_TRIP_COMMENT, {
    onCompleted: onCommentMutationComplete,
    onError: onCommentMutationError,
  });
  const [createFarmedRouteComment] = useMutation(CREATE_FARMED_ROUTE_COMMENT, {
    onCompleted: onCommentMutationComplete,
    onError: onCommentMutationError,
  });
  const [createContactComment] = useMutation(CREATE_CONTACT_COMMENT, {
    onCompleted: onCommentMutationComplete,
    onError: onCommentMutationError,
  });
  const [createVehicleComment] = useMutation(CREATE_VEHICLE_COMMENT, {
    onCompleted: onCommentMutationComplete,
    onError: onCommentMutationError,
  });

  // event handlers
  const handleCommentBodyTextChange = (event) => {
    let value = event.target.value;
    if (bodyText.length >= CHAR_LIMIT) {
      setBodyTextError("Reached character limit");
      value = value.substring(0, CHAR_LIMIT);
    } else {
      setBodyTextError(null);
    }

    setBodyText(value);
  };

  const handleInternalCommentDialogClick = () => {
    setInternalCommentsDialogOpen(true);
  };

  const handleInternalCommentDialogClose = () => {
    setInternalCommentsDialogOpen(false);
  };

  const handleSubmitComment = () => {
    if (setSaveIndicatorState) setSaveIndicatorState("loading");

    if (mode === "trip" || mode === "tripDetails") {
      createTripComment({
        variables: {
          input: {
            tripId,
            bodyText,
          },
        },
      });
    }

    if (mode === "farmee") {
      createFarmedRouteComment({
        variables: {
          input: {
            tripId,
            bodyText,
          },
        },
      });
    }

    if (mode === "contact") {
      createContactComment({
        variables: {
          input: {
            contactId,
            bodyText,
          },
        },
      });
    }
    if (mode === "vehicle") {
      createVehicleComment({
        variables: {
          input: {
            vehicleId,
            bodyText,
          },
        },
      });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      // on Enter key press
      // Shift+Enter will allow for go to next line instead of submit
      if (!event.shiftKey) {
        event.preventDefault();
        bodyText && handleSubmitComment();
      }
    }
  };

  // effects
  useEffect(() => {
    if (
      comments.length !== previousCommentsLength &&
      !isUndefined(previousCommentsLength)
    ) {
      inputRef.current.scrollIntoView({
        block: "end",
        inline: "nearest",
        behavior: "smooth",
      });
    }
  }, [comments.length, previousCommentsLength]);

  // derived state
  const commentsLength = comments?.length;
  const disableCommentButton = !!bodyTextError || !bodyText;

  return (
    <>
      <Box mb={1.5}>
        <Typography variant="h5">Internal Comments</Typography>
      </Box>
      {comments &&
        comments.slice(-3).map((comment, index) => (
          <Fragment key={comment.id}>
            {(mode !== "tripDetails" || index > 0) && (
              <Box my={2}>
                <Divider />
              </Box>
            )}
            <CommentListItem comment={comment} />
          </Fragment>
        ))}

      {commentsLength > 3 && (
        <Box display="flex" flex="1" justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleInternalCommentDialogClick}
          >
            Show All ({commentsLength})
          </Button>
        </Box>
      )}

      <InternalCommentsDialog
        open={internalCommentsDialogOpen}
        handleClose={handleInternalCommentDialogClose}
        comments={comments}
      />

      <Box
        ref={inputRef}
        display="flex"
        flexDirection="row"
        flex="1"
        alignItems="center"
        pb={1}
        mb={-1}
        mt={3}
      >
        <MoovsAvatar placeholder={[firstName, lastName]} />
        <Box width="100%" ml={1.5}>
          <TextField
            fullWidth
            multiline
            label="Comment"
            variant="outlined"
            value={bodyText}
            onChange={handleCommentBodyTextChange}
            onKeyDown={handleKeyPress}
            helperText={bodyTextError}
            error={!!bodyTextError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    disabled={disableCommentButton}
                    onClick={handleSubmitComment}
                    color="primary"
                    endIcon={
                      <ArrowRightIcon
                        color={
                          disableCommentButton ? grayDark : primaryMainColor
                        }
                        size="small"
                      />
                    }
                  >
                    Submit
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </>
  );
}

export default CommentUpdateBlock;
