import React, { useState, Fragment, useEffect, useCallback } from "react";
import { DeepPartial } from "ts-essentials";
import { useMutation, useLazyQuery } from "@apollo/client";
import last from "lodash/last";
import first from "lodash/first";
import size from "lodash/size";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import toLower from "lodash/toLower";
import isNull from "lodash/isNull";
import isNil from "lodash/isNil";
import { useHistory, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import {
  Request,
  Trip,
  Contact,
  Vehicle,
  Stop,
  RoutePricing,
  TripCategory,
  Company,
  VehicleChildSeat,
} from "types";
import { useSnackbar } from "globals/hooks/useSnackbar";
import {
  CREATE_REQUEST_MUTATION,
  LOAD_AUTOMATED_BASE_RATE,
} from "globals/graphql";
import { useLocalStorage } from "globals/hooks/useLocalStorage";
import { useOperator, useAnalytics, useScreenSize } from "globals/hooks";
import { calculateTotalDurationMinutes } from "globals/utils/stops";
import { shapeStopsToAutomatedBaseRateQueryStopsArg } from "globals/graphql/automatedPricing";
import CreateDrawer from "../../../globals/CreateDrawer";
import AddVehicleCreateBlock from "../../../vehicles/AddVehicleCreateBlock";
import CommentCreateBlock from "../../../CommentCreateBlock";
import {
  initialRequestTemplate,
  initialRequestErrors,
  convertTripToBeInputReady,
  removeStop,
  createStop,
} from "globals/utils/createRequests";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { initialLuggageFields } from "components/requests/luggage/initialValues";
import { useCreateRequestTripType } from "./hooks/useCreateRequestTripType";
import {
  AdditionalInfoCreateBlock,
  OrderDetailsCreateBlock,
  PricingInfoCreateBlock,
  TripDetailsCreateBlock,
  TripInfoCreateBlock,
} from "./components";
import { parsePricingLayoutForInitialRoute } from "./helpers/parsePricingLayoutForInitialRoute";
import { getChildSeatOptionsFromVehicle } from "./helpers/getChildSeatOptionsFromVehicle";
import { getContactForReservation } from "globals/utils/contact";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";

function CreateQuoteDrawer() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { track } = useAnalytics();
  const { enableLinkedPassenger } = useLaunchDarklyFlags();
  const { enabledOrderTypes, settings, enabledTripCategories } =
    useOperator() || {};
  const { isMobileView } = useScreenSize();

  const pageLabel = "Create New Quote";
  const modeEnum = "QUOTE";
  const localStorageKey = "submitQuoteSettings";
  const getSnackbarLink = (requestId: string) => `/quotes/${requestId}`;
  const snackbarLinkLabel = "View Quote";
  const getCloseDrawerLink = (requestId?: string) => {
    return requestId ? `/quotes/${requestId}/add-return-trip/` : "/quotes";
  };

  // state
  const [request, setRequest] = useState<DeepPartial<Request>>(
    JSON.parse(JSON.stringify(initialRequestTemplate))
  );
  const [requestErrors, setRequestErrors] = useState(
    JSON.parse(JSON.stringify(initialRequestErrors))
  );
  const [saveErrorText, setSaveErrorText] = useState("");
  const [selectedSubmitButtonIndex, setSelectedSubmitButtonIndex] =
    useLocalStorage(localStorageKey, 0);
  const [suggestedAddressInfo, setSuggestedAddressInfo] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [createNewContact, setCreateNewContact] = useState(false);
  const [bookingContact, setBookingContact] =
    useState<DeepPartial<Contact>>(null);
  const [luggageAmount, setLuggageAmount] = useState(initialLuggageFields);
  const [isVehicleBaseRateAutomated, setIsVehicleBaseRateAutomated] =
    useState(false);
  const [automatedBaseRate, setAutomatedBaseRate] = useState(null);
  const [isBaseRateAutomationClicked, setIsBaseRateAutomationClicked] =
    useState(false);
  const [company, setCompany] = useState<DeepPartial<Company>>(null);
  const [linkCompany, setLinkCompany] = useState(false);
  const [childSeatOptions, setChildSeatOptions] = useState<VehicleChildSeat[]>(
    []
  );

  // more hooks
  const { tripType, onTripTypeChange, submitLabelOptions } =
    useCreateRequestTripType({
      enabledTripCategories,
      setRequest,
      defaultSubmitLabelOptions: ["Save quote", "Save quote & send email"],
    });

  // query for automated base rate
  const [getAutomatedBaseRate, { loading: automatedBaseRateLoading }] =
    useLazyQuery(LOAD_AUTOMATED_BASE_RATE, {
      onCompleted(data) {
        setAutomatedBaseRate(data.automatedBaseRate);
      },
      onError(error) {
        snackbar.error("Failed to get automated base rate");
      },
      fetchPolicy: "network-only",
    });

  // derived state
  const stops = request.trips[0].stops;
  const vehicleId = request.trips[0].routes[0].vehicle?.id;
  const hasEnabledVehicleBRA =
    request.trips[0].routes[0].vehicle?.enableBaseRateAutomation;
  const shouldSendEmail = selectedSubmitButtonIndex === 1;
  const linkedPassengerIds =
    bookingContact?.linkedPassengers?.map((passenger) => passenger.id) || [];

  // mutations
  const [createNewRequest] = useMutation(CREATE_REQUEST_MUTATION, {
    onCompleted(data) {
      const createdTrips = data.createNewRequest.request.trips;

      const promoCodeApplied = !isNull(
        data.createNewRequest.request.trips[0].routes[0].pricing.promoCodeAmt
      );
      const hasChildSeatApplied = [
        "rearFacingSeatAmt",
        "boosterSeatAmt",
        "forwardFacingSeatAmt",
      ].some(
        (childSeatField) =>
          !isNil(
            data.createNewRequest.request.trips[0].routes[0].pricing[
              childSeatField
            ]
          )
      );

      createdTrips.forEach((trip) => {
        if (trip.tempPassenger?.name) {
          track("quote_created", {
            passengerType: "Temporary Passenger",
            ...(tripType && { tripType: toLower(tripType) }),
            ...(promoCodeApplied && { promo: "code_applied" }),
            ...(hasChildSeatApplied && { child_seat: "seat_added" }),
          });
        } else if (
          trip.contact.id === bookingContact.id &&
          !trip.tempPassenger?.name
        ) {
          track("quote_created", {
            passengerType: "Booking Contact",
            ...(tripType && { tripType: toLower(tripType) }),
            ...(promoCodeApplied && { promo: "code_applied" }),
            ...(hasChildSeatApplied && { child_seat: "seat_added" }),
          });
        } else if (linkedPassengerIds.includes(trip.contact.id)) {
          track("quote_created", {
            passengerType: "Linked Passenger",
            ...(tripType && { tripType: toLower(tripType) }),
            ...(promoCodeApplied && { promo: "code_applied" }),
            ...(hasChildSeatApplied && { child_seat: "seat_added" }),
          });
        }
      });

      if (shouldSendEmail) {
        track("send_quote", { location: "drawer" });
      }

      const isRoundTrip = tripType === TripCategory.RoundTrip;
      const snackbarText = isRoundTrip
        ? "Pick-up trip successfully saved"
        : `Successfully saved quote`;

      snackbar.success(snackbarText, {
        ...(!isRoundTrip && {
          onLinkClick: () => {
            track("snackbar_quote_viewed");
          },
          link: getSnackbarLink(data.createNewRequest.request.id),
          linkLabel: snackbarLinkLabel,
        }),
      });

      handleCreateRequestDrawerClose(data.createNewRequest.request);
    },
    onError(error) {
      setSubmitDisabled(false);
      const errorMessage = getErrorMessage(error) || "Error creating quote";

      snackbar.error(errorMessage);
    },
    refetchQueries: ["Requests", "Operator"],
  });

  // event handlers
  const updateTrip = useCallback(
    (newTrip: DeepPartial<Trip>, tripIndex: number) => {
      setRequest((prevRequest) => {
        const newTrips = [...prevRequest.trips];
        newTrips.splice(tripIndex, 1, newTrip);
        return {
          ...prevRequest,
          trips: newTrips,
        };
      });
    },
    []
  );

  const handleCreateRequestDrawerClose = (request?: Request) => {
    let search = location.search;

    setTimeout(() => {
      tripType === TripCategory.RoundTrip && request
        ? history.push({
            pathname: `${getCloseDrawerLink(request.id)}${search}`,
            state: { request, trip: first(request.trips), from: "create" },
          })
        : history.push({
            search,
            pathname: getCloseDrawerLink(),
          });
    }, 200);
  };

  const updateRoute =
    (tripIndex: number) =>
    (targetName: string, targetValue: number | string | boolean) => {
      const newRoutes = [...request.trips[tripIndex].routes];
      const newRoute = newRoutes[0];

      newRoute[targetName] = targetValue;
      const newTrip = { ...request.trips[tripIndex], routes: newRoutes };

      updateTrip(newTrip, tripIndex);
    };

  const handleCreateStop = (tripIndex: number) => () => {
    const newTrips = createStop(request.trips, tripIndex);

    const stopsErrors = [...requestErrors.stops];
    const lastStopErrors = stopsErrors.pop();
    requestErrors.stops = [...requestErrors.stops];

    setRequestErrors({
      ...requestErrors,
      stops: [
        ...stopsErrors,
        {
          location: "",
          dateTime: "",
          stopIndex: requestErrors.stops.length,
        },
        {
          ...lastStopErrors,
          stopIndex: requestErrors.stops.length + 1,
        },
      ],
    });

    setRequest({
      ...request,
      trips: newTrips,
    });
  };

  const handleRemoveStop = (tripIndex: number) => (stopIndex: number) => () => {
    const newTrips = removeStop(request.trips, tripIndex, stopIndex);

    setRequest({
      ...request,
      trips: newTrips,
    });

    requestErrors.stops.splice(stopIndex - 1, 1);

    setRequestErrors({
      ...requestErrors,
      stops: requestErrors.stops,
    });
  };

  const updateRequest = (update: Partial<Request>) => {
    setRequest({
      ...request,
      ...update,
    });
  };

  const addVehicles = (tripIndex: number) => (vehicles: Partial<Vehicle>[]) => {
    if (vehicles.length > 0) {
      setIsVehicleBaseRateAutomated(
        vehicles[0].enableBaseRateAutomation ?? false
      );

      const childSeats = getChildSeatOptionsFromVehicle(vehicles[0]);
      setChildSeatOptions(childSeats);

      const newTrips = [...request.trips];
      newTrips[tripIndex].routes[0].vehicle = { id: vehicles[0].id };

      setRequest({
        ...request,
        trips: newTrips,
      });
    }
  };

  const removeVehicle = (tripIndex: number) => () => {
    setIsVehicleBaseRateAutomated(false);
    setAutomatedBaseRate(null);
    setIsBaseRateAutomationClicked(false);
    setChildSeatOptions([]);
    const newTrips = [...request.trips];
    delete newTrips[tripIndex].routes[0].vehicle;

    setRequest({
      ...request,
      trips: newTrips,
    });
  };

  const handleSubmitQuoteClick = () => {
    let errors = [];

    let newSaveErrorText = "Oops! Please check the above fields for errors.";

    if (!request.orderType) {
      errors = [...errors, { type: "Please choose order type" }];
    }

    // booking contact
    if (!bookingContact) {
      errors = [...errors, { bookingContact: "Please add a booking contact" }];
    }

    // vehicle and pricing errors
    const shouldValidateVehicleAndBaseRate = selectedSubmitButtonIndex === 1;

    if (
      shouldValidateVehicleAndBaseRate &&
      !request.trips[0].routes[0].baseRateAmt
    ) {
      errors = [...errors, { baseRateAmt: "Please enter a base rate" }];
      newSaveErrorText =
        "Vehicle and Base Rate are required to send quote to customer";
    }

    if (
      shouldValidateVehicleAndBaseRate &&
      !request.trips[0].routes[0].vehicle
    ) {
      errors = [...errors, { vehicle: "Please select a vehicle" }];

      newSaveErrorText =
        "Vehicle and Base Rate are required to send quote to customer";
    }

    // stop errors
    const stopErrors = request.trips[0].stops.map((stop) => {
      const addressErrors = {
        location: !stop.location ? "Please enter location" : "",
      };

      const airportErrors = {
        airport: !stop.airport ? "Please select an Airport" : "",
        airline:
          !!stop.flightNumber && !stop.airline
            ? "Please select an Airline"
            : "",
      };

      const isLocationError = !!addressErrors.location;
      const isAirportError = Object.values(airportErrors).some(
        (error) => !!error.length
      );

      const isPickUpDateTimeError = !stop.dateTime && stop.stopIndex === 1;
      const isDropOffDateTimeError =
        tripType === TripCategory.Hourly &&
        !stop.dateTime &&
        stop.stopIndex === size(first(request.trips).stops);

      const dateTimeErrors = {
        dateTime:
          isPickUpDateTimeError || isDropOffDateTimeError
            ? "Please enter date"
            : "",
      };

      // if error in both show errors
      // if only one has an error that means it
      // isnt the variant that is selected
      if (isLocationError && isAirportError) {
        return {
          ...addressErrors,
          ...airportErrors,
          ...dateTimeErrors,
        };
      }

      return {
        ...dateTimeErrors,
      };
    });

    const isErrorStops = stopErrors.some((error) =>
      Object.values(error).some((value) => value.length)
    );

    if (errors.length > 0 || isErrorStops) {
      errors = [...errors, { stops: stopErrors }];

      setRequestErrors(
        errors.reduce((acc, value) => ({ ...acc, ...value }), {})
      );

      setSaveErrorText(newSaveErrorText);
      return;
    }

    // sets type as input type (as stop input is different than stop type)
    const requestInput: DeepPartial<Request> = cloneDeep(request);

    // converts airport and airlines from objects to just the code for input
    requestInput.trips = requestInput.trips.map((trip: Trip) =>
      convertTripToBeInputReady(trip, luggageAmount)
    );

    // set default booking contact if nothing is picked
    requestInput.trips = requestInput.trips.map((trip: Trip) => {
      if (!trip.contact?.firstName) {
        trip.contact = getContactForReservation(
          bookingContact as Contact
        ) as Contact;
      } else {
        trip.contact = getContactForReservation(trip.contact) as Contact;
      }
      return trip;
    });

    setSaveErrorText("");
    setSubmitDisabled(true);

    createNewRequest({
      variables: {
        input: {
          ...requestInput,
          bookingContactId: bookingContact.id,
          isLinkedPassengerEnabled: enableLinkedPassenger,
          shouldSendEmail,
          mode: modeEnum,
          linkCompany,
          ...(isBaseRateAutomationClicked &&
            automatedBaseRate && {
              baseRateAutomation: {
                total: automatedBaseRate.total,
                lineItems: automatedBaseRate.lineItems.map((lineItem) =>
                  omit(lineItem, "__typename")
                ),
              },
            }),
        },
      },
    });
  };

  const handleTripCommentChange =
    (trip: Trip, tripIndex: number) => (value: string) => {
      updateTrip(
        {
          ...trip,
          comments: [{ bodyText: value }],
        },
        tripIndex
      );
    };

  const handleClearBookingContact = () => {
    setBookingContact({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      mobilePhone: "",
    });

    setCompany(null);

    // reset passenger when booking contact is cleared
    setRequest((prevRequest) => {
      const trip = prevRequest.trips[0];
      const tripContactId = trip.contact.id;
      const bookingContactId = bookingContact?.id;

      // if booking contact is same as first trip passenger,
      // clear passenger
      if (tripContactId !== bookingContactId) {
        return prevRequest;
      }
      return {
        ...prevRequest,
        trips: [
          {
            ...trip,
            contact: {
              id: "",
              firstName: "",
              workAddress: "",
              homeAddress: "",
              lastName: "",
              email: "",
              mobilePhone: "",
            },
          },
        ],
      };
    });
  };

  // effects

  // if requirements are met, get automated pricing
  useEffect(() => {
    const firstStop = first(stops);
    const lastStop = last(stops);
    if (
      isVehicleBaseRateAutomated &&
      (firstStop.location || firstStop.airport) &&
      firstStop.dateTime &&
      (lastStop.location || lastStop.airport)
    ) {
      getAutomatedBaseRate({
        variables: {
          vehicleId: vehicleId,
          // TODO: Remove 'Partial' Utility types from types in this file and redefine types as necessary.
          // If properly resolved, removing this type cast should automatically resolve to Stop[].
          stops: shapeStopsToAutomatedBaseRateQueryStopsArg(stops as Stop[]),
        },
      });
    }
  }, [vehicleId, isVehicleBaseRateAutomated, getAutomatedBaseRate, stops]);

  // suggested addresses
  useEffect(() => {
    let keys = [];
    request.trips.forEach((trip) => {
      if (trip.contact.workAddress) {
        keys.push({
          address: trip.contact.workAddress,
          firstName: trip.contact.firstName,
          lastName: trip.contact.lastName,
          mode: "workAddress",
        });
      }
      if (trip.contact.homeAddress) {
        keys.push({
          address: trip.contact.homeAddress,
          firstName: trip.contact.firstName,
          lastName: trip.contact.lastName,
          mode: "homeAddress",
        });
      }
    });

    if (!isEqual(keys, suggestedAddressInfo)) {
      setSuggestedAddressInfo(keys);
    }
  }, [request, suggestedAddressInfo]);

  // populate trip's passenger with booking contact
  // for new quotes/reservations
  useEffect(() => {
    if (bookingContact) {
      setRequest((prevRequest) => {
        const trip = prevRequest.trips[0];
        if (!bookingContact.linkedPassengers) {
          bookingContact.linkedPassengers = [];
        }
        const contactMap = {
          0: bookingContact,
          1: bookingContact.linkedPassengers[0],
        };

        const size = bookingContact?.linkedPassengers?.length;
        const contact = enableLinkedPassenger
          ? contactMap[size] || {
              id: "",
              firstName: "",
              workAddress: "",
              homeAddress: "",
              lastName: "",
              email: "",
              mobilePhone: "",
            }
          : bookingContact;

        return {
          ...prevRequest,
          trips: [
            {
              ...trip,
              contact,
            },
          ],
        };
      });
    }
  }, [bookingContact, enableLinkedPassenger]);

  // populate initial request with data from pricingLayout
  useEffect(() => {
    setRequest((prevRequest) => {
      const trip = prevRequest.trips[0];

      return {
        ...prevRequest,
        trips: [
          {
            ...trip,
            routes: trip.routes.map((route: any) => {
              return {
                ...route,
                ...parsePricingLayoutForInitialRoute(settings?.pricingLayout),
              };
            }),
          },
        ],
      };
    });
  }, [settings?.pricingLayout]);

  return (
    <CreateDrawer
      onClose={handleCreateRequestDrawerClose}
      pageLabel={pageLabel}
      onSubmit={handleSubmitQuoteClick}
      saveError={!!saveErrorText}
      saveErrorText={saveErrorText}
      submitLabelOptions={submitLabelOptions}
      selectedSubmitButtonIndex={selectedSubmitButtonIndex}
      setSelectedSubmitButtonIndex={setSelectedSubmitButtonIndex}
      submitDisabled={submitDisabled}
    >
      <Box alignItems="flex-start" data-testid="create-request-drawer">
        {/* Order Details */}
        <Box my={4}>
          <OrderDetailsCreateBlock
            bookingContact={bookingContact}
            requestErrors={requestErrors}
            setRequestErrors={setRequestErrors}
            requestType={request.orderType}
            updateRequest={updateRequest}
            enabledOrderTypes={enabledOrderTypes}
            createNewContact={createNewContact}
            setCreateNewContact={setCreateNewContact}
            setBookingContact={setBookingContact}
            onClearBookingContact={handleClearBookingContact}
            tripType={tripType}
            onTripTypeChange={onTripTypeChange}
            enabledTripCategories={enabledTripCategories}
            isQuote
            company={company}
            setCompany={setCompany}
            linkCompany={linkCompany}
            setLinkCompany={setLinkCompany}
          />
        </Box>
        {request.trips.map((trip: Trip, tripIndex: number) => (
          <Fragment key={tripIndex}>
            {/* Trip Details */}
            <TripDetailsCreateBlock
              trip={trip}
              updateTrip={updateTrip}
              tripIndex={tripIndex}
              companyId={linkCompany ? company?.id : null}
              bookingContact={bookingContact as Contact}
            />
            <Box my={4}>
              <TripInfoCreateBlock
                trip={trip}
                updateTrip={updateTrip}
                tripIndex={tripIndex}
                onCreateStop={handleCreateStop(tripIndex)}
                onRemoveStop={handleRemoveStop(tripIndex)}
                requestErrors={requestErrors}
                setRequestErrors={setRequestErrors}
                suggestedAddressInfo={suggestedAddressInfo}
                tripType={tripType}
              />
            </Box>
            <Box my={4}>
              <AdditionalInfoCreateBlock
                trip={trip}
                updateTrip={updateTrip}
                tripIndex={tripIndex}
                requestType={request.orderType}
                setLuggageAmount={setLuggageAmount}
                luggageAmount={luggageAmount}
              />
            </Box>
            <AddVehicleCreateBlock
              requestErrors={requestErrors}
              addVehicles={addVehicles(tripIndex)}
              removeVehicle={removeVehicle(tripIndex)}
              vehicle={trip.routes[0].vehicle}
              stops={trip.stops}
            />
            <PricingInfoCreateBlock
              // initialized request template uses deprecated route pricing (pricing field on route type instead of on route.pricing)
              routePricing={trip.routes[0] as unknown as RoutePricing}
              updateRoute={updateRoute(tripIndex)}
              requestErrors={requestErrors}
              setRequestErrors={setRequestErrors}
              totalDurationMinutes={calculateTotalDurationMinutes(trip.stops)}
              baseRateAmtError={requestErrors.baseRateAmt}
              BRAProps={{
                automatedBaseRate,
                automatedBaseRateLoading,
                showAutomatedBaseRateIcon: isMobileView && !!automatedBaseRate,
                vehicleId,
                hasEnabledVehicleBRA,
                stops: trip.stops,
              }}
              isVehicleBaseRateAutomated={isVehicleBaseRateAutomated}
              setIsBaseRateAutomationClicked={setIsBaseRateAutomationClicked}
              childSeatOptions={childSeatOptions}
            />
            <Box mt={7} mb={4}>
              <CommentCreateBlock
                commentBodyText={trip.comments[0].bodyText}
                onChangeComment={handleTripCommentChange(trip, tripIndex)}
              />
            </Box>
          </Fragment>
        ))}
      </Box>
    </CreateDrawer>
  );
}

export default CreateQuoteDrawer;
