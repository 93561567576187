import React, { ChangeEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import isNumber from "lodash/isNumber";
import first from "lodash/first";
import isEqual from "lodash/isEqual";
import isNull from "lodash/isNull";

import { Box, Typography } from "@mui/material";

import MoovsDialog from "../../MoovsDialog";
import MoovsSelectDropdown from "components/pricing/MoovsSelectDropdown";
import DispatchLogDialog from "components/dispatch/DispatchLogDialog";
import ConfirmSwitchPricingDialog from "components/pricing/ConfirmSwitchPricingDialog";
import DriverPayoutUnavaliableView from "./DriverPayoutUnavailableView";
import DriverPayoutFields from "./DriverPayoutFields";
import {
  LOAD_DRIVER_PAYOUT_FIELDS_QUERY,
  UPDATE_DRIVER_PAYOUT_MUTATION,
} from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { moovsBlue } from "../../../design-system/colors";
import { FarmRelationshipEnum, Route, Trip } from "../../../types";
import { useViewDispatchLogClick } from "pages/reservations/components/ActionButtonPanel/components/ViewMoreActionButton/hooks";
import {
  calculateAutomatedGratuityAmount,
  isAutomationPayoutUpdated,
} from "./utils";

const defaultDriverPayoutFields = {
  driverPayoutFlatRate: null,
  driverPayoutHours: null,
  driverPayoutRatePerHour: null,
  driverPayoutGratuity: null,
};

type DriverPayoutDialogProps = {
  open: boolean;
  onClose: () => void;
  orderNumber: string;
  currentTripIndex: number;
  trips: Trip[];
  requestStage: string;
};

function DriverPayoutDialog(props: DriverPayoutDialogProps) {
  const { open, onClose, currentTripIndex, trips, orderNumber, requestStage } =
    props;
  const { Farmee } = FarmRelationshipEnum;

  // hooks
  const snackbar = useSnackbar();
  const viewDispatchLog = useViewDispatchLogClick({ requestStage });
  const { track } = useAnalytics();

  // state
  const [driverPayoutFields, setDriverPayoutFields] = useState(
    defaultDriverPayoutFields
  );
  const [serverDriverPayoutFields, setServerDriverPayoutFields] = useState(
    defaultDriverPayoutFields
  );
  // selecting a new trip sets pendingTripIndex to be a number & renders the confirmation dialog
  // to verify whether changes need to be saved before viewing the selected trip
  const [pendingTripIndex, setPendingTripIndex] = useState(null);
  const [activeTripIndex, setActiveTripIndex] = useState(currentTripIndex || 0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // derived state
  // if current route corresponding to current trip within dialog is not farmed,
  // or operator is a Farmee who has accepted or completed a ride, display editable payout fields
  const { isFarmedRoute, farmRelationship } =
    trips?.[activeTripIndex]?.routes?.[0] || {};
  const canUpdateDriverPayout = !isFarmedRoute || farmRelationship === Farmee;
  const isPendingTripChange = !isNull(pendingTripIndex);
  const submitDisabled =
    (!canUpdateDriverPayout || isSubmitting) && !isPendingTripChange;

  // queries
  const { data: tripData } = useQuery(LOAD_DRIVER_PAYOUT_FIELDS_QUERY, {
    variables: {
      id: trips[activeTripIndex].id,
    },
    skip: !trips[activeTripIndex].id || !open,
  });

  const route: Route = first(tripData?.node.routes) || null;

  // more derived state
  const isPayoutsNull =
    route?.driverPayoutFlatRate === null &&
    !route?.driverPayoutHours &&
    route?.driverPayoutRatePerHour === null &&
    route?.driverPayoutGratuity === null;

  const isPayoutUpdated = isAutomationPayoutUpdated(
    {
      driverPayoutFlatRate: route?.driverPayoutFlatRate,
      driverPayoutHours: route?.driverPayoutHours,
      driverPayoutRatePerHour: route?.driverPayoutRatePerHour,
      driverPayoutGratuity: route?.driverPayoutGratuity,
    },
    {
      ...route,
      driverGratuityAmt:
        route?.pricing?.driverGratuityAmt ||
        route?.farmeePricing?.driverGratuityAmt,
    }
  );

  // Automation can be applied if the follow are true:
  // 1) route has automated driver payouts AND
  // 2.1) current route payouts are null OR
  // 2.2) current route payouts match automation
  const canApplyDriverPayoutAutomation =
    route?.hasAutomatedDriverPayout && (isPayoutsNull || !isPayoutUpdated);

  // mutation
  const [updateDriverPayout] = useMutation(UPDATE_DRIVER_PAYOUT_MUTATION, {
    onCompleted() {
      if (isPendingTripChange) {
        setActiveTripIndex(pendingTripIndex);
        setPendingTripIndex(null);
      } else {
        onClose();
      }

      snackbar.success("Successfully updated driver payout!");
      handleCloseConfirmDialog();
      setIsSubmitting(false);
    },
    onError() {
      snackbar.error("Error updating driver payout");
      setIsSubmitting(false);
    },
  });

  // effects
  // pass in payout values when opening & reset farmed status
  // if payout fields not filled prior, attempt to fill w/ automated driver payout settings
  useEffect(() => {
    if (!open) return;

    let routePayoutFields;

    if (canApplyDriverPayoutAutomation) {
      routePayoutFields = {
        driverPayoutFlatRate: route?.automatedDriverPayoutFlatRate,
        driverPayoutHours: route?.automatedDriverPayoutHours,
        driverPayoutRatePerHour: route?.automatedDriverPayoutRatePerHour,
        driverPayoutGratuity: calculateAutomatedGratuityAmount(
          route?.pricing?.driverGratuityAmt ||
            route?.farmeePricing?.driverGratuityAmt,
          route?.automatedDriverPayoutGratuity
        ),
      };
    } else {
      routePayoutFields = {
        driverPayoutFlatRate: route?.driverPayoutFlatRate,
        driverPayoutHours: route?.driverPayoutHours,
        driverPayoutRatePerHour: route?.driverPayoutRatePerHour,
        driverPayoutGratuity: route?.driverPayoutGratuity,
      };
    }

    setDriverPayoutFields(routePayoutFields);
    setServerDriverPayoutFields(routePayoutFields);
  }, [canApplyDriverPayoutAutomation, open, route, setDriverPayoutFields]);

  // reset active trip index when currentTripIndex changes
  useEffect(() => {
    if (!open) return;

    setActiveTripIndex(currentTripIndex);
  }, [currentTripIndex, open]);

  // event handlers
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name;
    const value =
      event.target.value === ""
        ? event.target.value
        : Number(Number(event.target.value).toFixed(2));

    setDriverPayoutFields({
      ...driverPayoutFields,
      [name]: value,
    });
  };

  const handleDriverPayoutFieldsUpdate = () => {
    setIsSubmitting(true);
    handleDriverPayoutSubmit();
  };

  const handleDriverPayoutSubmit = () => {
    const payoutsToNumber = {
      driverPayoutFlatRate: Number(driverPayoutFields.driverPayoutFlatRate),
      driverPayoutHours: Number(driverPayoutFields.driverPayoutHours),
      driverPayoutRatePerHour: Number(
        driverPayoutFields.driverPayoutRatePerHour
      ),
      driverPayoutGratuity: Number(driverPayoutFields.driverPayoutGratuity),
    };

    if (canApplyDriverPayoutAutomation) {
      const isPayoutAutomated = isAutomationPayoutUpdated(payoutsToNumber, {
        ...route,
        driverGratuityAmt:
          route.pricing?.driverGratuityAmt ||
          route.farmeePricing?.driverGratuityAmt,
      });

      track(
        isPayoutAutomated
          ? "driverPayoutDialog_automatedDriverPayoutModified"
          : "driverPayoutDialog_automatedDriverPayoutSaved"
      );
    }

    if (route?.id) {
      updateDriverPayout({
        variables: {
          input: {
            ...payoutsToNumber,
            routeId: route.id,
          },
        },
      });
    }
  };

  const handleTripOptionClick = (index: number) => {
    if (!isEqual(driverPayoutFields, serverDriverPayoutFields)) {
      setPendingTripIndex(index);
    } else {
      setActiveTripIndex(index);
    }
  };

  // confirm dialog handlers
  const handleCloseConfirmDialog = () => {
    setPendingTripIndex(null);
  };

  const handleDiscardChanges = () => {
    setActiveTripIndex(pendingTripIndex);
    handleCloseConfirmDialog();
  };

  const options = trips.map((trip, index) => ({
    label: `Trip ${index + 1} ${orderNumber}-${trip.tripNumber} ${
      trip.cancelledAt ? "Cancelled" : ""
    }`,
    disabled: !!trip.cancelledAt,
  }));

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      dialogTitle="Driver Payout"
      size="xs"
      xsFullscreenMobile
      acceptButtonText="Save"
      onAccept={handleDriverPayoutFieldsUpdate}
      acceptDisabled={submitDisabled}
      hideLoadingIndicator={!isSubmitting}
    >
      <Box mb={3} pt={2}>
        {trips.length > 1 && (
          <>
            <Box mb={2}>
              <Typography variant="h4">Selected Trip</Typography>
            </Box>
            <Box mb={2}>
              <MoovsSelectDropdown
                options={options}
                onOptionClick={handleTripOptionClick}
                activeIndex={activeTripIndex}
              />
            </Box>
          </>
        )}
        {canUpdateDriverPayout && (
          <Typography
            style={{
              color: moovsBlue,
              cursor: "pointer",
              fontWeight: 500,
            }}
            // @ts-ignore  - fix later
            onClick={viewDispatchLog.onClick}
          >
            {/** @ts-ignore */}
            {viewDispatchLog.text}
          </Typography>
        )}
      </Box>
      {canUpdateDriverPayout ? (
        <DriverPayoutFields
          route={route}
          driverPayoutFields={driverPayoutFields}
          onInputChange={handleInputChange}
          isPayoutAutomated={canApplyDriverPayoutAutomation}
        />
      ) : (
        <DriverPayoutUnavaliableView />
      )}
      {route && (
        <DispatchLogDialog
          open={viewDispatchLog.open}
          onClose={viewDispatchLog.onClose}
          routeId={route.id}
        />
      )}
      <ConfirmSwitchPricingDialog
        open={isNumber(pendingTripIndex)}
        onConfirm={handleDriverPayoutFieldsUpdate}
        onDiscard={handleDiscardChanges}
        onCancel={handleCloseConfirmDialog}
        newPricingLabel={options.map(({ label }) => label)[activeTripIndex]}
        loadingChanges={isSubmitting}
      />
    </MoovsDialog>
  );
}

export default DriverPayoutDialog;
