import React from "react";
import { UseFormReturn } from "react-hook-form";

import { Typography, Box, Divider } from "@mui/material";

import { FarmAffiliateVehicle } from "types";
import { UpdateVehicleFormData } from "../UpdateVehicleDrawer/hooks/useUpdateVehicleForm";
import { CreateVehiclePricingFormData } from "../CreateVehicleDrawer/hooks/useCreateVehiclePricingForm";
import {
  BaseRateAutomationSection,
  HourlyPricingSection,
  TransferPricingSection,
} from "./components";
import { useVehiclePricing, VehiclePricingProvider } from "./context";
import { useScreenSize } from "globals/hooks";

export type VehiclePricingBlockProps =
  | {
      vehicle: Partial<FarmAffiliateVehicle>;
      isReadOnly: true;
      formContext?: never;
    }
  | {
      vehicle?: never;
      isReadOnly?: never;
      formContext: UseFormReturn<
        CreateVehiclePricingFormData | UpdateVehicleFormData
      >;
    };

function VehiclePricingBlock(props: VehiclePricingBlockProps) {
  return (
    <VehiclePricingProvider {...props}>
      <VehiclePricingBlockContent />
    </VehiclePricingProvider>
  );
}

function VehiclePricingBlockContent() {
  // hooks
  const { isReadOnly } = useVehiclePricing();
  const { isMobileView } = useScreenSize();

  return (
    <>
      <Box mb={2} display="flex" flexDirection="column">
        <Box mb={2}>
          {isReadOnly && <Typography variant="h4">Pricing</Typography>}
          <Box
            sx={{
              display: "flex",
              justifyContent: isMobileView ? "flex-start" : "flex-end",
              marginTop: isMobileView ? 0 : -5,
            }}
          >
            <BaseRateAutomationSection />
          </Box>
        </Box>

        {/* Transfer Pricing */}
        <TransferPricingSection />

        <Divider sx={{ my: 3 }} />

        {/* Hourly Pricing */}
        <HourlyPricingSection />
      </Box>
    </>
  );
}

export default VehiclePricingBlock;
