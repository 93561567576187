import React from "react";

import { Box, Typography } from "@mui/material";

import VehiclePricingBlock from "components/vehicles/VehiclePricingBlock";
import VehicleFormSection from "components/vehicles/VehicleFormSection";
import { useUpdateVehicleFormContext } from "../../hooks/useUpdateVehicleForm";

function Pricing() {
  // hooks
  const formContext = useUpdateVehicleFormContext();

  if (!formContext) {
    console.error(
      "Pricing component must be used within UpdateVehicleFormContext"
    );
    return (
      <Box mt={4}>
        <Typography variant="body2" color="error">
          Error loading vehicle pricing. Please refresh the page.
        </Typography>
      </Box>
    );
  }

  return (
    <Box mt={4}>
      <VehicleFormSection title="Pricing">
        <VehiclePricingBlock
          formContext={formContext as any} // type assertion to fix error
        />
      </VehicleFormSection>
    </Box>
  );
}

export default Pricing;
