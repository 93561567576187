import moment from "moment";

/**
 * Gets the timezone abbreviation for a specific timezone ID at the current time
 * For example: "America/New_York" -> "EDT" or "EST" depending on daylight savings
 * @param timezoneId - The IANA timezone identifier (e.g. "America/New_York")
 * @returns The timezone abbreviation (e.g. "EDT")
 */
export const getTimezoneAbbreviation = (timezoneId: string): string => {
  if (!timezoneId) {
    return "";
  }
  return moment().tz(timezoneId).format("z");
};
