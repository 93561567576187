import React from 'react';
import { useQuery } from '@apollo/client';
import { LOAD_OPERATOR_DRIVERS_NAME_AND_ID_QUERY } from 'globals/graphql';
import MultiSelectFilterInput from './MultiSelectFilterInput';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';

export default function DriverFilterInput(props: GridFilterInputValueProps) {
  const { data: driverData } = useQuery(LOAD_OPERATOR_DRIVERS_NAME_AND_ID_QUERY);
  
  const driverOptions = driverData?.drivers.map((driver) => ({
    name: `${driver.firstName} ${driver.lastName}`,
    id: driver.id
  })) || [];

  return <MultiSelectFilterInput {...props} options={driverOptions} />;
} 