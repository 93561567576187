import { useMutation, useQuery } from "@apollo/client";
import {
  Autocomplete,
  Box,
  Button,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { GridRenderEditCellParams } from "@mui/x-data-grid-pro";

import {
  LOAD_VEHICLES_QUERY,
  UPDATE_ROUTE_DISPATCH_MUTATION,
} from "globals/graphql";
import VehicleAvailabilityDialog from "components/vehicles/VehicleAvailabilityDialog/VehicleAvailabilityDialog";
import { CalendarIcon } from "design-system/icons";
import { moovsBlue } from "design-system/colors";
import { Stop } from "types";
import { useSnackbar } from "globals/hooks";

type VehicleOption = {
  label: string;
  vehicleId: string;
};

type VehicleEditColumnType = {
  params: GridRenderEditCellParams;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function VehicleEditColumn(props: VehicleEditColumnType) {
  const { params, setSaveIndicatorState } = props;
  const { row } = params;

  const routeId = row?.routes[0]?.id;
  const farmAffiliateId = row?.routes[0]?.farmAffiliate?.id;
  const stopsInput = row?.stops?.map((stop: Stop) => ({
    dateTime: stop.dateTime,
    location: stop.location,
  }));

  // query
  const { data: vehiclesData } = useQuery(LOAD_VEHICLES_QUERY, {
    variables: {
      stops: stopsInput,
      farmAffiliateId,
      shouldCalculateBRA: false,
    },
    fetchPolicy: "cache-and-network",
  });

  const vehicleOptions: VehicleOption[] = vehiclesData?.loadVehicles?.edges?.map(
    ({ node }) => {
      return { label: node.name, vehicleId: node.id };
    }
  ) || [];

  // hooks
  const snackbar = useSnackbar();

  // state
  const [vehicleAvailabilityDialogOpen, setVehicleAvailabilityDialogOpen] =
    useState(false);
  const [selectedVehicleAvailability, setSelectedVehicleAvailability] =
    useState({
      name: "",
      vehicleId: "",
    });
  const [dropDownOpen, setDropdownOpen] = useState(false);

  // mutation
  const [updateRouteDispatch] = useMutation(UPDATE_ROUTE_DISPATCH_MUTATION, {
    refetchQueries: ["OperatorRoutes"],
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error("Error updating vehicle");
    },
  });

  // event handlers
  const handleAutoCompleteChange = (
    _e: ChangeEvent<HTMLInputElement>,
    vehicleData: VehicleOption
  ) => {
    setSaveIndicatorState("loading");

    if (routeId && vehicleData?.vehicleId) {
      updateRouteDispatch({
        variables: {
          input: {
            routeId,
            vehicleId: vehicleData.vehicleId,
          },
        },
      });
    }
  };

  const handleVehicleAvailabilityClick = ({ name, vehicleId }) => {
    setSelectedVehicleAvailability({
      name,
      vehicleId,
    });
    setVehicleAvailabilityDialogOpen(true);
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Autocomplete
        id="dispatch-vehicle-autocomplete"
        sx={{
          "& .MuiAutocomplete-inputRoot": {
            padding: 0,
            fontSize: "14px",
            textOverflow: "ellipsis",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
          },
        }}
        open={dropDownOpen}
        onOpen={() => setDropdownOpen(true)}
        onClose={() => {
          if (vehicleAvailabilityDialogOpen) {
            setDropdownOpen(true);
          } else {
            setDropdownOpen(false);
          }
        }}
        options={vehicleOptions}
        onChange={handleAutoCompleteChange}
        renderInput={(params) => (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
            width="100%"
          >
            <TextField
              {...params}
              sx={{ px: 0.5 }}
              fullWidth
              placeholder="Enter Vehicle Name"
            />
          </Box>
        )}
        renderOption={(props, option: VehicleOption) => {
          return (
            <li {...props}>
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                gap={1.3}
                my={0.5}
              >
                <Tooltip title="Check Availability">
                  <Button
                    sx={{
                      padding: 0,
                      minHeight: 0,
                      minWidth: 0,
                      flexShrink: 0,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      handleVehicleAvailabilityClick({
                        name: option.label,
                        vehicleId: option.vehicleId,
                      });
                    }}
                  >
                    <CalendarIcon color={moovsBlue} />
                  </Button>
                </Tooltip>

                <Typography variant="body2">{option.label}</Typography>
              </Box>
            </li>
          );
        }}
        PaperComponent={({ children }) => (
          <Paper variant="outlined" sx={{ mt: 1.5 }}>
            {children}
          </Paper>
        )}
      />

      <VehicleAvailabilityDialog
        title={selectedVehicleAvailability.name}
        open={vehicleAvailabilityDialogOpen}
        vehicleId={selectedVehicleAvailability.vehicleId}
        stops={row.stops}
        routeId={routeId}
        onClose={() => setVehicleAvailabilityDialogOpen(false)}
      />
    </Box>
  );
}

export default VehicleEditColumn;
