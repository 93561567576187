import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import {
  Box,
  Card,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { errorRed, grayDark } from "design-system/colors";
import {
  ReplaceVehicleIcon,
  TrashIcon,
  CalendarUnavailableIcon,
  CancelTripIcon,
} from "design-system/icons";
import { primaryMainColor } from "theme";
import RouteStatusSelect from "components/dispatch/RouteStatusSelect";
import {
  LOAD_REQUEST_QUERY,
  UPDATE_ROUTE_DISPATCH_MUTATION,
} from "globals/graphql";
import DriverAutoComplete from "components/autocompletes/DriverAutoComplete";
import {
  useAnalytics,
  useSendDriverAssignNotification,
  useSnackbar,
} from "globals/hooks";
import VehiclesDialog from "components/reservations/VehiclesDialog";
import {
  Vehicle,
  Trip,
  Request,
  FarmRelationshipEnum,
  RouteDriver,
  RequestStage,
} from "types";
import { driverStatusEnum } from "globals/utils/enumMaps";
import SavedIndicatorChip from "design-system/components/chips/SaveIndicatorChip";
import SelectedEntityContainer from "./SelectedEntityContainer";
import AvailabilityConflictDialog from "components/AvailabilityConflictDialog";
import DriverNoteBlock from "./DriverNoteBlock";
import DispatchAlertBlock from "./DispatchAlertBlock";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
type DispatchBlockProps = {
  request: Request;
  trip: Trip;
};

function DispatchBlock(props: DispatchBlockProps) {
  const { request, trip } = props;

  const route = trip.routes[0];
  const {
    id: routeId,
    dispatchStatus,
    driverStatus,
    vehicle,
    farmAffiliate,
    routeDriver,
    driverNote,
  } = route;

  // state
  const [vehicleDialogOpen, setVehicleDialogOpen] = useState(false);
  const [availabilityConflictDialogOpen, setAvailabilityConflictDialogOpen] =
    useState(false);
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "saved" | "loading" | "error"
  >("default");
  const [conflictedDriver, setConflictedDriver] = useState<RouteDriver>(null);
  const [isIndicatorVisible, setIsIndicatorVisible] = useState(false);

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const { onSendDriverAssignNotification } = useSendDriverAssignNotification();

  // derived state
  const isFarmor = route.farmRelationship === FarmRelationshipEnum.Farmor;

  // mutations
  const [updateRouteDispatch] = useMutation(UPDATE_ROUTE_DISPATCH_MUTATION, {
    onCompleted(data) {
      setSaveIndicatorState("saved");
      setVehicleDialogOpen(false);
      setTimeout(() => setIsIndicatorVisible(false), 3000);
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error(getErrorMessage(error) || "Error updating route");
    },
    refetchQueries: [
      {
        query: LOAD_REQUEST_QUERY,
        variables: { id: request.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [assignDriver] = useMutation(UPDATE_ROUTE_DISPATCH_MUTATION, {
    onCompleted: (data) => {
      setSaveIndicatorState("saved");
      setVehicleDialogOpen(false);

      track("reservation_driverAssigned");
      onSendDriverAssignNotification(data.updateRouteDispatch.route.id);
      setTimeout(() => setIsIndicatorVisible(false), 3000);
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error(getErrorMessage(error) || "Error updating route");
    },
    refetchQueries: ["Request"],
    awaitRefetchQueries: true,
  });

  // event handlers
  const handleDriverSelect = async (driver: RouteDriver) => {
    if (
      !driver?.routeAvailability.available ||
      !driver?.personalAvailability.available
    ) {
      setConflictedDriver(driver);
      setAvailabilityConflictDialogOpen(true);
    } else {
      handleAssignDriver(driver);
    }
  };

  const handleAssignDriver = async (routeDriver: RouteDriver) => {
    setAvailabilityConflictDialogOpen(false);
    setSaveIndicatorState("loading");
    setIsIndicatorVisible(true);

    await assignDriver({
      variables: {
        input: {
          routeId,
          driverId: routeDriver.driver.id,
        },
      },
    });
  };

  const handleUpdateVehicle = (selectedVehicles: Vehicle[]) => {
    setSaveIndicatorState("loading");
    setIsIndicatorVisible(true);

    if (selectedVehicles?.[0]?.id) {
      updateRouteDispatch({
        variables: {
          input: {
            routeId,
            vehicleId: selectedVehicles[0].id,
          },
        },
      });
    }
  };

  const handleRemoveDriverClick = () => {
    setSaveIndicatorState("loading");
    setIsIndicatorVisible(true);

    updateRouteDispatch({
      variables: {
        input: {
          routeId,
          driverId: null,
        },
      },
    });
  };

  const handleVehicleDialogClose = () => setVehicleDialogOpen(false);

  const handleReplaceVehicleIconClick = () => {
    setVehicleDialogOpen(true);
  };

  const isUnconfirmedReservation = request.stage === RequestStage.Unconfirmed;

  return (
    <Box width="100%">
      <Card variant="outlined">
        <Box display="flex" flexDirection="column" data-testid="dispatch-block">
          {/* title and ellipsis menu button row */}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            pl={2}
            mr={1}
            mt={1}
            mb={1}
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h5" style={{ color: grayDark }}>
                Dispatch
              </Typography>
              <Fade in={isIndicatorVisible}>
                <Box>
                  <SavedIndicatorChip isIconOnly mode={saveIndicatorState} />
                </Box>
              </Fade>
            </Box>
          </Box>

          <Box pr={2} pl={2} pb={2}>
            {/* status row */}
            <Box display="flex" flexDirection="column" pb={1}>
              <Typography sx={{ mb: 1 }} variant="overline">
                status
              </Typography>
              <RouteStatusSelect
                operatorRouteId={routeId}
                statusSlug={dispatchStatus || "pending"}
                requestId={request.id}
                setSaveIndicatorState={setSaveIndicatorState}
              />
            </Box>

            {/* vehicle row */}
            <Box display="flex" flexDirection="column" pb={1}>
              <Typography sx={{ mb: 1 }} variant="overline">
                vehicle
              </Typography>
              <SelectedEntityContainer
                label={vehicle?.name}
                unselect={{
                  icon: <ReplaceVehicleIcon color={primaryMainColor} />,
                  eventHandler: handleReplaceVehicleIconClick,
                }}
              />
            </Box>

            {/* driver row */}
            <Box display="flex" flexDirection="column" mb={1}>
              <Typography sx={{ mb: 1 }} variant="overline">
                driver
              </Typography>
              {!routeDriver ? (
                <DriverAutoComplete
                  disabled={isUnconfirmedReservation}
                  value={routeDriver}
                  routeId={route.id}
                  onDriverAutoCompleteChange={handleDriverSelect}
                  hideLabel
                  {...(isFarmor && {
                    farmAffiliateId: farmAffiliate?.id,
                  })}
                />
              ) : (
                <SelectedEntityContainer
                  label={`${routeDriver.driver.firstName} ${routeDriver.driver.lastName}`}
                  unselect={{
                    icon: <TrashIcon color={primaryMainColor} />,
                    eventHandler: handleRemoveDriverClick,
                  }}
                  leftIcon={
                    driverStatus && (
                      <Box display="flex" alignItems="center" mr={1}>
                        {driverStatusEnum[driverStatus.name]?.icon}
                      </Box>
                    )
                  }
                  rightIcon={
                    !routeDriver.routeAvailability.available ? (
                      <Tooltip placement="top" title="Trip Conflict">
                        <IconButton>
                          <CancelTripIcon color={errorRed} />
                        </IconButton>
                      </Tooltip>
                    ) : !routeDriver.personalAvailability.available ? (
                      <Tooltip placement="top" title="Availability Conflict">
                        <IconButton>
                          <CalendarUnavailableIcon color={errorRed} />
                        </IconButton>
                      </Tooltip>
                    ) : null
                  }
                />
              )}
            </Box>

            {/* Driver Note */}
            <DriverNoteBlock
              requestId={request.id}
              routeId={routeId}
              driverNote={driverNote}
              setSaveIndicatorState={setSaveIndicatorState}
              setIsIndicatorVisible={setIsIndicatorVisible}
              disabled={isUnconfirmedReservation}
            />

            {/* Dispatch Alerts */}
            <DispatchAlertBlock
              routeId={routeId}
              setSaveIndicatorState={setSaveIndicatorState}
              setIsIndicatorVisible={setIsIndicatorVisible}
            />
          </Box>
        </Box>
      </Card>
      <VehiclesDialog
        open={vehicleDialogOpen}
        dialogMode="edit"
        onClose={handleVehicleDialogClose}
        onCreate={handleUpdateVehicle}
        stops={trip.stops}
        routeId={routeId}
        {...(isFarmor && {
          farmOutProps: { farmAffiliateId: farmAffiliate?.id },
        })}
      />
      <AvailabilityConflictDialog
        open={availabilityConflictDialogOpen}
        onClose={() => setAvailabilityConflictDialogOpen(false)}
        onAssign={() => handleAssignDriver(conflictedDriver)}
        routeDriver={conflictedDriver}
      />
    </Box>
  );
}

export default DispatchBlock;
