import React, { useState, useEffect, useRef } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { Box, Dialog, Button, ClickAwayListener } from "@mui/material";

import {
  LOAD_SEARCH_RESULTS_QUERY,
  LOAD_RECENT_SEARCHES_QUERY,
} from "globals/graphql";
import { white } from "design-system/colors";
import { useKeyPress, useScreenSize } from "globals/hooks";
import { GlobalSearchInput, GlobalSearchResultList } from "./components";

type GlobalSearchProps = {
  variant: "desktop" | "mobile";
  open?: boolean;
  onClose?: () => void;
};

function GlobalSearch(props: GlobalSearchProps) {
  const { variant, open, onClose } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // refs
  const inputRef = useRef(null);

  // state
  const [searchResults, setSearchResults] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [debouncedInputValue] = useDebounce(inputValue, 200);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeSearchResultIndex, setActiveSearchResultIndex] =
    useState<number>(0);

  // queries
  const [loadGlobalSearchList, { loading }] = useLazyQuery(
    LOAD_SEARCH_RESULTS_QUERY,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setSearchResults(data?.searchResults || []);
        setActiveSearchResultIndex(0);
      },
    }
  );

  const { data: recentSearchesData, refetch: recentSearchesRefetch } = useQuery(
    LOAD_RECENT_SEARCHES_QUERY,
    {
      fetchPolicy: "cache-and-network",
      skip: !isDropdownOpen && !isMobileView,
    }
  );

  const recentSearches = recentSearchesData?.recentSearches || [];

  // event handlers
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleGlobalSearchClose = () => {
    const handleClose = () => {
      inputRef?.current?.blur();
      setIsDropdownOpen(false);
      setInputValue("");
      recentSearchesRefetch();
      if (variant === "mobile") onClose();
    };

    // timeout to delay closing until after redirect
    setTimeout(handleClose, variant === "mobile" ? 200 : 0);
  };

  const handleArrowUpPress = (e) => {
    e.preventDefault();

    setActiveSearchResultIndex((prevState) =>
      prevState > 0 ? prevState - 1 : prevState
    );
  };

  const handleArrowDownPress = (e) => {
    e.preventDefault();
    const resultsLength =
      (searchResults?.length || 0) + recentSearches.length - 1;

    setActiveSearchResultIndex((prevState) =>
      prevState < resultsLength ? prevState + 1 : prevState
    );
  };

  const handleInputBlur = () => {
    setIsDropdownOpen(false);
    inputRef?.current?.blur();
  };

  useKeyPress("ArrowDown", {
    ref: inputRef,
    action: handleArrowDownPress,
  });
  useKeyPress("ArrowUp", {
    ref: inputRef,
    action: handleArrowUpPress,
  });
  useKeyPress("Escape", {
    ref: inputRef,
    action: handleInputBlur,
  });
  useKeyPress("Tab", {
    ref: inputRef,
    action: handleInputBlur,
  });

  // debounce find search results
  useEffect(() => {
    if (debouncedInputValue.length) {
      loadGlobalSearchList({
        variables: {
          searchTerm: debouncedInputValue,
        },
      });
    }
  }, [loadGlobalSearchList, debouncedInputValue]);

  if (variant === "mobile") {
    return (
      <Dialog fullScreen open={open}>
        <Box
          mt={2}
          py={1}
          px={2}
          display="flex"
          alignItems="center"
          borderBottom="1px solid #E1E1E1"
          position="sticky"
          top="0"
        >
          <Box flex="1">
            <GlobalSearchInput
              variant="mobile"
              value={inputValue}
              onChange={handleInputChange}
              ref={inputRef}
            />
          </Box>
          <Button
            style={{ marginLeft: "8px" }}
            color="primary"
            onClick={handleGlobalSearchClose}
          >
            Cancel
          </Button>
        </Box>
        <GlobalSearchResultList
          loading={loading}
          searchResults={searchResults}
          recentSearches={recentSearches}
          variant="mobile"
          onGlobalSearchClose={handleGlobalSearchClose}
          activeSearchResultIndex={activeSearchResultIndex}
        />
      </Dialog>
    );
  }

  return (
    <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
      <Box position="relative" flex="1">
        <GlobalSearchInput
          variant="desktop"
          value={inputValue}
          onChange={handleInputChange}
          isDropdownOpen={isDropdownOpen}
          onDropdownOpen={() => setIsDropdownOpen(true)}
          ref={inputRef}
        />
        <Box
          position="absolute"
          bgcolor={white}
          width="100%"
          display={isDropdownOpen ? "block" : "none"}
          mt="-1px"
          borderRadius="0px 0px 4px 4px"
          border="1px solid #D3D3D3"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.08)"
        >
          <GlobalSearchResultList
            loading={loading}
            searchResults={searchResults}
            recentSearches={recentSearches}
            variant="desktop"
            onGlobalSearchClose={handleGlobalSearchClose}
            ref={inputRef}
            activeSearchResultIndex={activeSearchResultIndex}
          />
        </Box>
      </Box>
    </ClickAwayListener>
  );
}

export default GlobalSearch;
