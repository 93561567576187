import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import MoovsSearchableCheckboxDropdown from 'components/MoovsSearchableCheckboxDropdown';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';

export type MultiSelectFilterInputProps = GridFilterInputValueProps & {
  options?: { id: string; name: string }[];
};

export default function MultiSelectFilterInput(props: MultiSelectFilterInputProps) {
  const { item, options = [], applyValue } = props;
  const [selection, setSelection] = useState<{ id: string; name: string }[]>(
    Array.isArray(item.value) ? item.value : []
  );

  useEffect(() => {
    setSelection([]);
    applyValue({ ...item, value: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.columnField]);

  useEffect(() => {
    if (!Array.isArray(item.value)) {
      setSelection([]);
    }
  }, [item.value]);

  const handleSelectionChange = (newValue: { id: string; name: string }[]) => {
    setSelection(newValue);
    applyValue({ ...item, value: newValue });
  };

  return (
    <Box >
      <MoovsSearchableCheckboxDropdown
        fieldName={item.columnField}
        options={options}
        selection={selection}
        setSelection={handleSelectionChange}
        placeholder="Select..."
      />
    </Box>
  );
} 