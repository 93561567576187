import React from 'react';
import { useQuery } from '@apollo/client';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import MultiSelectFilterInput from './MultiSelectFilterInput';
import { LOAD_VEHICLES_NAME_AND_ID_QUERY } from 'globals/graphql';

export default function VehicleFilterInput(props: GridFilterInputValueProps) {
  const { data } = useQuery(LOAD_VEHICLES_NAME_AND_ID_QUERY);
  
  const vehicleOptions = data?.loadVehicles.edges.map(({ node: vehicle }) => ({
    name: vehicle.name,
    id: vehicle.id,
  })) || [];

  return <MultiSelectFilterInput {...props} options={vehicleOptions} />;
} 