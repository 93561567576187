import React from "react";
import { useHistory } from "react-router-dom";

import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { moovsBlue } from "design-system/colors";
import { deriveIsFarmee, deriveIsFarmor } from "globals/utils/farm";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";

function ConfNumberColumn(props: GridRenderCellParams<string>) {
  const { value, row } = props;
  const history = useHistory();
  const { enableDispatchV2 } = useLaunchDarklyFlags();

  const navigateToUpdateRequestDrawer = () => {
    // dispatch v1
    if (!enableDispatchV2) {
      history.push(`/reservations/${row.request.id}?tripId=${row.id}`, {
        backNavigationPath: `${history.location.pathname}${history.location.search}`,
      });
    } else {
      // dispatch v2
      const isFarmee = deriveIsFarmee(row.routes[0]);
      const isFarmor = deriveIsFarmor(row.routes[0]);

      const isFarmorAndFarmeeHasNotAcceptedTrip =
        isFarmor && row.routes[0]?.farmStatus === "PENDING";

      const toPath =
        isFarmee || isFarmorAndFarmeeHasNotAcceptedTrip
          ? `/reservations/${row.request.id}?tripId=${row.id}` // reservations page
          : `/dispatch/${row.request.id}/update/${row.id}`; // update request drawer on dispatch screen

      history.push(toPath, {
        backNavigationPath: `${history.location.pathname}${history.location.search}`,
      });
    }
  };

  return (
    <Typography
      variant="subtitle2"
      onClick={navigateToUpdateRequestDrawer}
      sx={{
        color: moovsBlue,
        cursor: "pointer",
        "&:hover": { textDecoration: "underline" },
      }}
    >
      {value}
    </Typography>
  );
}

export default ConfNumberColumn;
