import React from "react";

import { Typography, Box } from "@mui/material";

import { moovsBlue } from "design-system/colors";
import MoovsToggleSwitch from "components/MoovsToggleSwitch";

type PlanLength = "monthly" | "annual";

type PlanLengthToggleProps = {
  planLength: PlanLength;
  handleToggle: (_: any, newType: PlanLength) => void;
};

function PlanLengthToggle(props: PlanLengthToggleProps) {
  const { planLength, handleToggle } = props;

  const options: {
    value: PlanLength;
    content: React.ReactNode;
  }[] = [
    {
      value: "monthly",
      content: <Typography variant="body2">Monthly</Typography>,
    },
    {
      value: "annual",
      content: (
        <Box display="flex" flexDirection="row" gap={1}>
          <Typography variant="body2">Annual</Typography>
          <Typography variant="body2" color={moovsBlue}>
            Save up to 20%
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <MoovsToggleSwitch<PlanLength>
      value={planLength}
      onChange={(value) => handleToggle(null, value)}
      options={options}
    />
  );
}

export default PlanLengthToggle;
