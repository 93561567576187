import React from "react";
import VehicleFormSection from "components/vehicles/VehicleFormSection";
import VehiclePricingBlock from "components/vehicles/VehiclePricingBlock";
import { useCreateVehiclePricingFormContext } from "../../hooks/useCreateVehiclePricingForm";
import { Typography } from "@mui/material";

function Pricing() {
  const formContext = useCreateVehiclePricingFormContext();

  if (!formContext)
    return (
      <VehicleFormSection title="Pricing">
        <Typography variant="body1" color="error">
          Unable to load pricing form, please refresh the page.
        </Typography>
      </VehicleFormSection>
    );

  return (
    <VehicleFormSection title="Pricing">
      <VehiclePricingBlock formContext={formContext} />
    </VehicleFormSection>
  );
}

export default Pricing;
