import moment from "moment";
import { useMutation } from "@apollo/client";
import React, { Dispatch, SetStateAction, useState } from "react";
import last from "lodash/last";

import { MobileTimePicker } from "@mui/lab";
import {
  Box,
  createTheme,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { GridRenderEditCellParams } from "@mui/x-data-grid-pro";

import ArrowDownIcon from "design-system/icons/layout/ArrowDownIcon";
import { UPDATE_STOP_MUTATION } from "globals/graphql";
import { useSnackbar } from "globals/hooks";
import { applyUTCOffsetToTime } from "globals/utils/helpers";
import { Stop } from "types";
import { grayLight } from "design-system/colors";

const timePickerTheme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          "& .MuiIconButton-colorInherit": {
            display: "none !important",
          },
          "& .PrivateTimePickerToolbar-ampmLabel": {
            padding: "6px",
            "&.Mui-selected": {
              backgroundColor: grayLight,
              borderRadius: "50%",
              padding: "6px",
            },
          },
        },
      },
    },
  },
});

type PickUpEditColumnType = {
  params: GridRenderEditCellParams & {
    value: {
      datetimeUtc: string;
      timezoneId: string;
    };
  };
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function PickupEditColumn(props: PickUpEditColumnType) {
  const {
    params: { row, value, api, id, field },
    setSaveIndicatorState,
  } = props;

  const pickUpStop = row.stops[0];
  const dropOffDateTimeUTC = last(row.stops as Stop[]).dateTime;

  // state
  const [error, setError] = useState(false);
  const [clockOpen, setClockOpen] = useState(true);
  const [timePickerValue, setTimePickerValue] = useState(
    value.datetimeUtc
      ? applyUTCOffsetToTime(
          moment(value.datetimeUtc),
          "subtract"
        ).toISOString()
      : null
  );

  // hooks
  const snackbar = useSnackbar();

  // mutations
  const [updateStop, { loading: updateStopLoading }] = useMutation(
    UPDATE_STOP_MUTATION,
    {
      refetchQueries: ["LoadFilterableDispatchTrips"],
      onCompleted() {
        setSaveIndicatorState("saved");
        setClockOpen(false);
        api.setCellMode(id, field, "view");
      },
      onError() {
        setSaveIndicatorState("error");
        snackbar.error("Can not update pick-up time.");
      },
    }
  );

  // event handlers
  const handleTimeChange = async (newValue: Date) => {
    if (!newValue) return;

    setError(false);
    setTimePickerValue(newValue);
  };

  const handleAccept = async () => {
    const pickUpDateTime = moment.utc(value.datetimeUtc);
    pickUpDateTime.hours(moment(timePickerValue).hours());
    pickUpDateTime.minutes(moment(timePickerValue).minutes());

    if (pickUpDateTime.isSameOrAfter(moment.utc(dropOffDateTimeUTC))) {
      setError(true);
      snackbar.error("Pick-up time must be before drop-off time");
      return;
    }

    setSaveIndicatorState("loading");

    const pickUpDateTimeISOString = pickUpDateTime.toISOString();

    await updateStop({
      variables: {
        input: { id: pickUpStop.id, dateTime: pickUpDateTimeISOString },
      },
    });
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <ThemeProvider theme={timePickerTheme}>
        <MobileTimePicker
          value={timePickerValue}
          onChange={handleTimeChange}
          open={clockOpen}
          onOpen={() => setClockOpen(true)}
          onClose={() => setClockOpen(false)}
          onAccept={handleAccept}
          disabled={updateStopLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              sx={{ px: 0.5 }}
              inputProps={{
                ...params.inputProps,
                placeholder: "Select Time",
                readOnly: true,
                style: {
                  fontSize: "0.875rem",
                  padding: "10px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{
                        padding: "8px",
                      }}
                      onClick={() => setClockOpen(true)}
                    >
                      <ArrowDownIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </ThemeProvider>
    </Box>
  );
}

export default PickupEditColumn;
