import React from "react";

import { Box, Switch, Typography } from "@mui/material";

import { granite } from "design-system/colors";
import { ControllerWrapper } from "./ControllerWrapper";
import { useVehiclePricing } from "../context";

export function BaseRateAutomationSection() {
  // hooks
  const { isReadOnly, isBRAEnabled = false, control } = useVehiclePricing();

  if (isReadOnly) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box display="flex" ml={-1}>
        <ControllerWrapper
          control={control}
          name="vehicle.enableBaseRateAutomation"
          transformOnChange={(e) => e.target.checked}
          deps={[
            "vehicle.minimumTotalBaseRate",
            "vehicle.deadheadRatePerMile",
            "vehicle.tripRatePerMile",
            "vehicle.weekdayHourlyCost",
            "vehicle.weekdayMinMinutes",
            "vehicle.weekendHourlyCost",
            "vehicle.weekendMinMinutes",
            "vehicle.weekends",
          ]}
        >
          <Switch
            data-testid="bra-toggle"
            checked={isBRAEnabled}
            color="primary"
          />
        </ControllerWrapper>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box>
          <Typography
            variant="caption"
            style={{
              color: granite,
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            Enable Base Rate Automation
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
