export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any;
};

export type AcceptUnconfirmedReservationInput = {
  requestId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AcceptUnconfirmedReservationPayload = {
  __typename?: 'AcceptUnconfirmedReservationPayload';
  request?: Maybe<Request>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum ActionName {
  Add = 'ADD',
  Subtract = 'SUBTRACT'
}

export type AddStopInput = {
  location: Scalars['String'];
  stopIndex: Scalars['Int'];
  dateTime?: Maybe<Scalars['DateTime']>;
  groupSize?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  flightNumber?: Maybe<Scalars['String']>;
  airportIcao?: Maybe<Scalars['String']>;
  airlineIcao?: Maybe<Scalars['String']>;
  flightData?: Maybe<Scalars['JSON']>;
  trackedFlight?: Maybe<Scalars['JSON']>;
  variant?: Maybe<Scalars['String']>;
  logisticStreetViewUrl?: Maybe<Scalars['String']>;
  operatorNote?: Maybe<Scalars['String']>;
  locationAlias?: Maybe<Scalars['String']>;
};

export type AddVehicleToDudaWebsiteInput = {
  vehicleId?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddVehicleToDudaWebsitePayload = {
  __typename?: 'AddVehicleToDudaWebsitePayload';
  vehicle?: Maybe<Vehicle>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Airline = {
  __typename?: 'Airline';
  icaoCode: Scalars['String'];
  iataCode?: Maybe<Scalars['String']>;
  airlineName?: Maybe<Scalars['String']>;
  callsign?: Maybe<Scalars['String']>;
  fleetSize?: Maybe<Scalars['Int']>;
  countryName?: Maybe<Scalars['String']>;
  countryIso2?: Maybe<Scalars['String']>;
  dateFounded?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type AirlinesConnection = {
  __typename?: 'AirlinesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AirlinesEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AirlinesEdge = {
  __typename?: 'AirlinesEdge';
  /** The item at the end of the edge */
  node?: Maybe<Airline>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Airport = {
  __typename?: 'Airport';
  icaoCode: Scalars['String'];
  iataCode?: Maybe<Scalars['String']>;
  airportName?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  countryIso2?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  gmt?: Maybe<Scalars['Int']>;
  coordinates?: Maybe<Scalars['JSONObject']>;
};

/** A connection to a list of items. */
export type AirportsConnection = {
  __typename?: 'AirportsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AirportsEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AirportsEdge = {
  __typename?: 'AirportsEdge';
  /** The item at the end of the edge */
  node?: Maybe<Airport>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Alert = {
  __typename?: 'Alert';
  newDriver: Scalars['Boolean'];
  uniqueLocation: Scalars['Boolean'];
  airport: Scalars['Boolean'];
  largeVehicle: Scalars['Boolean'];
  partner: Scalars['Boolean'];
  luggage: Scalars['Boolean'];
  signage: Scalars['Boolean'];
  carSeat: Scalars['Boolean'];
};

export type AlertInput = {
  newDriver?: Maybe<Scalars['Boolean']>;
  uniqueLocation?: Maybe<Scalars['Boolean']>;
  airport?: Maybe<Scalars['Boolean']>;
  largeVehicle?: Maybe<Scalars['Boolean']>;
  partner?: Maybe<Scalars['Boolean']>;
  luggage?: Maybe<Scalars['Boolean']>;
  signage?: Maybe<Scalars['Boolean']>;
  carSeat?: Maybe<Scalars['Boolean']>;
};

export enum AssignedUnassigned {
  Assigned = 'ASSIGNED',
  Unassigned = 'UNASSIGNED'
}

export type Author = User | Driver;

export type AutoPaymentDeposit = {
  __typename?: 'AutoPaymentDeposit';
  /** Sending amt to client in dollars. Amt can be 0 */
  amt?: Maybe<Scalars['Float']>;
  /** Sending amt to client in decimal. Percent can be 0. */
  percent?: Maybe<Scalars['Float']>;
};

/** Establishing auto payment deposit settings */
export type AutoPaymentDepositType = {
  amt?: Maybe<Scalars['Float']>;
  percent?: Maybe<Scalars['Float']>;
};

export enum AutoPaymentPreference {
  NoCharge = 'NO_CHARGE',
  Deposit = 'DEPOSIT',
  FullCharge = 'FULL_CHARGE'
}

export type AwaitingPayoutAmounts = {
  __typename?: 'AwaitingPayoutAmounts';
  standard: Scalars['Float'];
  instant: Scalars['Float'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  /** The ID of an object */
  id: Scalars['ID'];
  bankAccountNumberLast4: Scalars['String'];
  routingNumber: Scalars['String'];
  bankName: Scalars['String'];
  currency: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isInstantPayoutEligible: Scalars['Boolean'];
};

export type BaseRateAutomation = {
  __typename?: 'BaseRateAutomation';
  total: Scalars['Float'];
  lineItems?: Maybe<Array<Maybe<LineItem>>>;
};

export type BaseRateAutomationInput = {
  total: Scalars['Float'];
  lineItems?: Maybe<Array<Maybe<LineItemInput>>>;
};

export type BaseRateInput = {
  vehicleId: Scalars['ID'];
  baseRate?: Maybe<Scalars['Float']>;
};

export enum BaseRateVariantEnum {
  Flat = 'FLAT',
  Hourly = 'HOURLY'
}

export type Billing = Node & {
  __typename?: 'Billing';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  amount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  action: ActionName;
};

export type BillingDetails = {
  __typename?: 'BillingDetails';
  phone?: Maybe<Scalars['String']>;
  /** Stripe card name */
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type CancelTripFailure = {
  __typename?: 'CancelTripFailure';
  message?: Maybe<Scalars['String']>;
};

export type CancelTripInput = {
  tripId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CancelTripPayload = {
  __typename?: 'CancelTripPayload';
  trip: Trip;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CancelTripResult = Trip | CancelTripFailure;

export type CancelTripV2Input = {
  tripId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CancelTripV2Payload = {
  __typename?: 'CancelTripV2Payload';
  result?: Maybe<CancelTripResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CancellationPolicy = Node & {
  __typename?: 'CancellationPolicy';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** @deprecated type is special keyword, variant is our new standard. */
  type: CancellationPolicyVariant;
  variant: CancellationPolicyVariant;
  refund100?: Maybe<Refund>;
  refund50?: Maybe<Refund>;
  refund25?: Maybe<Refund>;
  vehicles?: Maybe<Array<Maybe<Vehicle>>>;
  vehicleIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export enum CancellationPolicyVariant {
  Flexible = 'FLEXIBLE',
  Moderate = 'MODERATE',
  Strict = 'STRICT'
}

export enum CardCheckEnum {
  Passed = 'PASSED',
  Failed = 'FAILED',
  Unavailable = 'UNAVAILABLE',
  Unchecked = 'UNCHECKED'
}

export type CardItem = {
  __typename?: 'CardItem';
  /** The ID of an object */
  id: Scalars['ID'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  last4: Scalars['String'];
  brand: Scalars['String'];
  country: Scalars['String'];
  cvcCheck?: Maybe<CardCheckEnum>;
  zipCodeCheck?: Maybe<CardCheckEnum>;
};

export type CenterCoordinatesInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type ChatParticipant = {
  __typename?: 'ChatParticipant';
  conversationSid: Scalars['String'];
  participant?: Maybe<ChatParticipantUnion>;
};

export type ChatParticipantInput = {
  conversationSid?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
};

export type ChatParticipantUnion = Driver | Contact;

export enum ChildSeatVariant {
  ForwardFacing = 'FORWARD_FACING',
  RearFacing = 'REAR_FACING',
  Booster = 'BOOSTER'
}

export enum CloseOutDisabledEnum {
  AwaitingTripEnd = 'AWAITING_TRIP_END',
  AwaitingFarmeeCloseout = 'AWAITING_FARMEE_CLOSEOUT',
  AwaitingFarmeeAcceptTrip = 'AWAITING_FARMEE_ACCEPT_TRIP'
}

export type CloseOutTripInput = {
  tripId: Scalars['ID'];
  routeId: Scalars['ID'];
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  closeoutNote?: Maybe<Scalars['String']>;
  driverPayoutFlatRate?: Maybe<Scalars['Float']>;
  driverPayoutGratuity?: Maybe<Scalars['Float']>;
  driverPayoutRatePerHour?: Maybe<Scalars['Float']>;
  driverPayoutHours?: Maybe<Scalars['Float']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CloseOutTripPayload = {
  __typename?: 'CloseOutTripPayload';
  trip: Trip;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum ColorThemeVariant {
  Moovs = 'MOOVS',
  Sky = 'SKY',
  Navy = 'NAVY',
  Lavender = 'LAVENDER',
  Violet = 'VIOLET',
  Rose = 'ROSE',
  Burgundy = 'BURGUNDY',
  Midnight = 'MIDNIGHT',
  Forest = 'FOREST',
  Grass = 'GRASS',
  Ocean = 'OCEAN'
}

export type Comment = Node & {
  __typename?: 'Comment';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  bodyText: Scalars['String'];
  user: User;
};

export type Company = Node & {
  __typename?: 'Company';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  contactsTotalCount: Scalars['Int'];
  contacts: Array<Maybe<Contact>>;
  email?: Maybe<Scalars['String']>;
  operator?: Maybe<Operator>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type ConfirmRequestFromOperatorInput = {
  requestId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ConfirmRequestFromOperatorPayload = {
  __typename?: 'ConfirmRequestFromOperatorPayload';
  request: Request;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum ConfirmReservationDisabledVariantEnum {
  /** At least trip on the quote does not have a required pricing fields */
  NoPricing = 'NO_PRICING',
  /** At least trip on the quote does not have a vehicle */
  NoVehicle = 'NO_VEHICLE',
  /** Request has been manually archived */
  RequestArchived = 'REQUEST_ARCHIVED',
  /** Request is in the past */
  RequestExpired = 'REQUEST_EXPIRED',
  /** NOTE: Operator can choose to override this, so it shouldn't confirming from operator app. Request is currently within the reservation cutoff period, and therefore it cannot be confirmed by customer via booking tool. */
  ReservationCutoff = 'RESERVATION_CUTOFF'
}

export type Contact = Node & {
  __typename?: 'Contact';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  mobilePhone: Scalars['String'];
  mobilePhoneInternational: Scalars['String'];
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneCountryDialCode?: Maybe<Scalars['String']>;
  phoneCountryName?: Maybe<Scalars['String']>;
  phoneCountryFormat?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyPosition?: Maybe<Scalars['String']>;
  /** Only returns payment methods that have stripe setup intent status of succeeded. */
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
  /** Returns all payment methods regardless of stripe setup intent status. */
  allPaymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
  preferences?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  workAddress?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<Scalars['JSON']>;
  /** Returns all contacts that are linked passengers of this contact */
  linkedPassengers: Array<Maybe<Contact>>;
  /** Returns all contacts that this contact is a linked passenger of */
  passengerOf: Array<Maybe<Contact>>;
  /** Returns payment methods that have this contact as the linkedPassengerId */
  passengerLinkedPaymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type ContactCommentInput = {
  bodyText: Scalars['String'];
};

/** Union of contact and farm affiliates and companies. Currently only need to support moovsNetworkOperator farm affiliates */
export type ContactsAndFarmAffiliatesAndCompanies = Contact | Company | MoovsNetworkOperator | GriddnetOperator | MoovsAiOperator;

export type Coordinates = {
  __typename?: 'Coordinates';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type CreateAuthorizationPaymentInput = {
  paymentMethodId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAuthorizationPaymentPayload = {
  __typename?: 'CreateAuthorizationPaymentPayload';
  payment?: Maybe<Payment>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCancellationPolicyFailure = {
  __typename?: 'CreateCancellationPolicyFailure';
  message?: Maybe<Scalars['String']>;
};

export type CreateCancellationPolicyInput = {
  /** Variant of policy. I.e. strict, moderate, lenient */
  variant?: Maybe<CancellationPolicyVariant>;
  refund100: RefundInput;
  refund50: RefundInput;
  refund25: RefundInput;
  /** List of vehicle ids */
  vehicles?: Maybe<Array<Scalars['ID']>>;
  /** deprecated in favor of variant for less confusing naming convention */
  type?: Maybe<CancellationPolicyVariant>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCancellationPolicyPayload = {
  __typename?: 'CreateCancellationPolicyPayload';
  cancellationPolicy?: Maybe<CancellationPolicy>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCancellationPolicyResult = CancellationPolicy | CreateCancellationPolicyFailure;

export type CreateCancellationPolicyV2Input = {
  /** Variant of policy. I.e. strict, moderate, lenient */
  variant?: Maybe<CancellationPolicyVariant>;
  refund100: RefundInput;
  refund50: RefundInput;
  refund25: RefundInput;
  /** List of vehicle ids */
  vehicles?: Maybe<Array<Scalars['ID']>>;
  /** deprecated in favor of variant for less confusing naming convention */
  type?: Maybe<CancellationPolicyVariant>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCancellationPolicyV2Payload = {
  __typename?: 'CreateCancellationPolicyV2Payload';
  result?: Maybe<CreateCancellationPolicyResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCompanyInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCompanyPayload = {
  __typename?: 'CreateCompanyPayload';
  company?: Maybe<Company>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContactCommentInput = {
  contactId: Scalars['ID'];
  bodyText: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContactCommentPayload = {
  __typename?: 'CreateContactCommentPayload';
  contactComment: Comment;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContactInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  mobilePhone: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  phoneCountryDialCode: Scalars['String'];
  phoneCountryName: Scalars['String'];
  phoneCountryFormat: Scalars['String'];
  companyPosition?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  preferences?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Scalars['String']>;
  workAddress?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<Scalars['JSON']>;
  cardNote?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Maybe<ContactCommentInput>>>;
  linkedPassengerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  linkedContactId?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContactPayload = {
  __typename?: 'CreateContactPayload';
  contact?: Maybe<Contact>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDriverInput = {
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  mobilePhone: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  phoneCountryDialCode: Scalars['String'];
  phoneCountryName: Scalars['String'];
  phoneCountryFormat: Scalars['String'];
  homePhone?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseClass?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  licenseExpirationDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  driverProfilePhoto?: Maybe<Scalars['String']>;
  settings?: Maybe<DriverSettingsInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDriverPayload = {
  __typename?: 'CreateDriverPayload';
  driver?: Maybe<Driver>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDriverPayoutInput = {
  routeIds: Array<Maybe<Scalars['ID']>>;
  amount: Scalars['Float'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDriverPayoutPayload = {
  __typename?: 'CreateDriverPayoutPayload';
  driverPayout: DriverPayout;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDuplicateReservationInput = {
  reservationDates: Array<Maybe<Scalars['DateTime']>>;
  requestId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDuplicateReservationPayload = {
  __typename?: 'CreateDuplicateReservationPayload';
  reservations: Array<Maybe<Request>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDuplicateTripInput = {
  tripId: Scalars['String'];
  tripDates: Array<Maybe<Scalars['DateTime']>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDuplicateTripPayload = {
  __typename?: 'CreateDuplicateTripPayload';
  request: Request;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDynamicPricingRuleInput = {
  name: Scalars['String'];
  amount: Scalars['Float'];
  isPercent: Scalars['Boolean'];
  /** true for increase, false for decrease */
  isIncrease: Scalars['Boolean'];
  vehicleIds?: Maybe<Array<Scalars['ID']>>;
  daysOfWeek?: Maybe<Array<DayOfWeek>>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  repeatAnnually?: Maybe<Scalars['Boolean']>;
  activate?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDynamicPricingRulePayload = {
  __typename?: 'CreateDynamicPricingRulePayload';
  rule: DynamicPricingRule;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateExternalOperatorInput = {
  operatorName: Scalars['String'];
  operatorEmail: Scalars['String'];
  operatorPhone: Scalars['String'];
  operatorAddress: Scalars['String'];
  contactName: Scalars['String'];
  internalNote?: Maybe<Scalars['String']>;
  /** array of drivers who are a part of operator's company */
  drivers?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** array of vehicles who are a part of operator's company */
  vehicles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateExternalOperatorPayload = {
  __typename?: 'CreateExternalOperatorPayload';
  externalOperator: ExternalOperator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateFarmedRouteCommentInput = {
  tripId: Scalars['ID'];
  bodyText: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateFarmedRouteCommentPayload = {
  __typename?: 'CreateFarmedRouteCommentPayload';
  farmedRouteComment: Comment;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateFarmedRouteInput = {
  vehicleId: Scalars['String'];
  routeId: Scalars['String'];
  farmoutNote?: Maybe<Scalars['String']>;
  baseRateAmt?: Maybe<Scalars['Float']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateFarmedRoutePayload = {
  __typename?: 'CreateFarmedRoutePayload';
  route: Route;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateInstantPayoutInput = {
  payoutAmount: Scalars['Float'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateInstantPayoutPayload = {
  __typename?: 'CreateInstantPayoutPayload';
  payout: StripePayout;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateInsuranceAttachmentInput = {
  insuranceId: Scalars['ID'];
  url: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateInsuranceAttachmentPayload = {
  __typename?: 'CreateInsuranceAttachmentPayload';
  insurancePolicy: InsurancePolicy;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateInsurancePolicyInput = {
  insuranceCompany?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  coverageAmount: Scalars['Float'];
  monthlyPremium: Scalars['Float'];
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  insuranceAttachments?: Maybe<Array<InsuranceAttachmentInput>>;
  /** List of vehicle ids */
  vehicles?: Maybe<Array<Scalars['ID']>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateInsurancePolicyPayload = {
  __typename?: 'CreateInsurancePolicyPayload';
  insurancePolicy: InsurancePolicy;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateInvoiceInput = {
  contactId?: Maybe<Scalars['ID']>;
  /** Either GriddnetOperator, MoovsAiOperator, or MoovsNetworkOperator id */
  farmorOperatorId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  requestIds: Array<Maybe<Scalars['ID']>>;
  dueDate: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateInvoicePayload = {
  __typename?: 'CreateInvoicePayload';
  invoice: Invoice;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateInvoicePaymentInput = {
  invoiceId: Scalars['ID'];
  method: PaymentMethodEnum;
  paymentMethodId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateInvoicePaymentPayload = {
  __typename?: 'CreateInvoicePaymentPayload';
  invoice: Invoice;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLagoCustomerAndAttachPaymentMethodInput = {
  lagoPlanCode: LagoPlanCode;
  stripePaymentMethod: Scalars['JSON'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLagoCustomerAndAttachPaymentMethodPayload = {
  __typename?: 'CreateLagoCustomerAndAttachPaymentMethodPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLinkedPassengerInput = {
  contactId: Scalars['ID'];
  passengerId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLinkedPassengerPayload = {
  __typename?: 'CreateLinkedPassengerPayload';
  contact?: Maybe<Contact>;
  passenger?: Maybe<Contact>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMessageInput = {
  /** list of phone numbers to send message to */
  recipients: Array<Maybe<Scalars['String']>>;
  /** the body of the message */
  body: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMessagePayload = {
  __typename?: 'CreateMessagePayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateNewRequestInput = {
  bookingContactId?: Maybe<Scalars['String']>;
  isLinkedPassengerEnabled?: Maybe<Scalars['Boolean']>;
  trips: Array<Maybe<RequestTripInformationInput>>;
  mode?: Maybe<RequestStage>;
  orderType: OrderTypeEnum;
  shouldSendEmail?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Array<Maybe<RequestCommentInput>>>;
  stripePaymentMethod?: Maybe<Scalars['JSON']>;
  cardNote?: Maybe<Scalars['String']>;
  baseRateAutomation?: Maybe<BaseRateAutomationInput>;
  linkCompany?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateNewRequestPayload = {
  __typename?: 'CreateNewRequestPayload';
  request?: Maybe<Request>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOnboardingDriverInput = {
  firstName: Scalars['String'];
  mobilePhone: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  phoneCountryDialCode: Scalars['String'];
  phoneCountryName: Scalars['String'];
  phoneCountryFormat: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOnboardingDriverPayload = {
  __typename?: 'CreateOnboardingDriverPayload';
  driver?: Maybe<Driver>;
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOnboardingStripeAccountInput = {
  businessType: Scalars['String'];
  returnUrl: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOnboardingStripeAccountPayload = {
  __typename?: 'CreateOnboardingStripeAccountPayload';
  operator: Operator;
  accountLink: StripeAccountLink;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOperatorDudaWebsiteUserInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOperatorDudaWebsiteUserPayload = {
  __typename?: 'CreateOperatorDudaWebsiteUserPayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOperatorGettingStartedInput = {
  goal: GettingStartedGoal;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOperatorGettingStartedPayload = {
  __typename?: 'CreateOperatorGettingStartedPayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOperatorInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOperatorOrderTypeInput = {
  orderTypeId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOperatorOrderTypePayload = {
  __typename?: 'CreateOperatorOrderTypePayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOperatorPayload = {
  __typename?: 'CreateOperatorPayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePaymentMethodInput = {
  contactId: Scalars['ID'];
  cardNumber: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  cvc?: Maybe<Scalars['String']>;
  cardNote?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePaymentMethodPayload = {
  __typename?: 'CreatePaymentMethodPayload';
  paymentMethod: PaymentMethod;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePriceZoneInput = {
  zoneName: Scalars['String'];
  zoneVariant: ZoneVariantEnum;
  /** zip_code zoneVariant only */
  zipCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** location zoneVariant only */
  radius?: Maybe<Scalars['Float']>;
  /** location zoneVariant only */
  centerCoordinates?: Maybe<CenterCoordinatesInput>;
  /** location zoneVariant only */
  geoJson?: Maybe<Scalars['JSON']>;
  /** location zoneVariant only */
  address?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePriceZonePayload = {
  __typename?: 'CreatePriceZonePayload';
  priceZone: PriceZone;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePromoCodeInput = {
  promoCodeName: Scalars['String'];
  promoCodeAmt?: Maybe<Scalars['Float']>;
  promoCodePercent?: Maybe<Scalars['Float']>;
  totalLimit?: Maybe<Scalars['Int']>;
  bookingContactLimit?: Maybe<Scalars['Int']>;
  bookingStartDate?: Maybe<Scalars['DateTime']>;
  bookingEndDate?: Maybe<Scalars['DateTime']>;
  tripStartDate?: Maybe<Scalars['DateTime']>;
  tripEndDate?: Maybe<Scalars['DateTime']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePromoCodePayload = {
  __typename?: 'CreatePromoCodePayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRecentSearchInput = {
  searchTerm: Scalars['String'];
  requestId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRecentSearchPayload = {
  __typename?: 'CreateRecentSearchPayload';
  recentSearch: RecentSearch;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRefundInput = {
  amount: Scalars['Float'];
  subPaymentId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRefundPayload = {
  __typename?: 'CreateRefundPayload';
  refund?: Maybe<PaymentRefund>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRequestPaymentInput = {
  paymentPayer: PaymentPayer;
  method: PaymentMethodEnum;
  requestId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['ID']>;
  subPayments: Array<Maybe<SubPaymentInput>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRequestPaymentPayload = {
  __typename?: 'CreateRequestPaymentPayload';
  payment: Payment;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateReturnTripInput = {
  requestId: Scalars['ID'];
  firstTripId: Scalars['ID'];
  contactId: Scalars['ID'];
  temporaryPassenger?: Maybe<Scalars['JSON']>;
  routes: Array<Maybe<RouteInput>>;
  stops: Array<Maybe<AddStopInput>>;
  totalGroupSize: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Maybe<ReturnTripCommentInput>>>;
  baseRateAutomation?: Maybe<BaseRateAutomationInput>;
  shouldSendEmail?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateReturnTripPayload = {
  __typename?: 'CreateReturnTripPayload';
  trip: Trip;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSendgridCustomDomainInput = {
  customDomain: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSendgridCustomDomainPayload = {
  __typename?: 'CreateSendgridCustomDomainPayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateShuttleReservationInput = {
  tripType: TripCategory;
  orderType: OrderTypeEnum;
  stops: Array<Maybe<ShuttleRequestStopInformationInput>>;
  route: ShuttleRequestRouteInformationInput;
  bookingContactId: Scalars['ID'];
  shuttleRouteId: Scalars['ID'];
  tripComments?: Maybe<Array<Maybe<ShuttleRequestCommentInput>>>;
  shouldSendEmail?: Maybe<Scalars['Boolean']>;
  stripePaymentMethod?: Maybe<Scalars['JSON']>;
  cardNote?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateShuttleReservationPayload = {
  __typename?: 'CreateShuttleReservationPayload';
  request?: Maybe<Request>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateShuttleRouteInput = {
  companyId: Scalars['ID'];
  name: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateShuttleRoutePayload = {
  __typename?: 'CreateShuttleRoutePayload';
  shuttleRoute: ShuttleRoute;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateStopInput = {
  tripId: Scalars['ID'];
  location: Scalars['String'];
  dateTime?: Maybe<Scalars['DateTime']>;
  locationAlias?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateStopPayload = {
  __typename?: 'CreateStopPayload';
  stop?: Maybe<Stop>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateStripeAccountLinkInput = {
  returnUrl: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateStripeAccountLinkPayload = {
  __typename?: 'CreateStripeAccountLinkPayload';
  accountLink: StripeAccountLink;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateStripeExternalAccountInput = {
  externalAccountToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateStripeExternalAccountPayload = {
  __typename?: 'CreateStripeExternalAccountPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSubscriptionPaymentMethodInput = {
  stripePaymentMethod: Scalars['JSON'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSubscriptionPaymentMethodPayload = {
  __typename?: 'CreateSubscriptionPaymentMethodPayload';
  paymentMethod: PaymentMethod;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTermInput = {
  termTypeId: Scalars['ID'];
  description: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTermPayload = {
  __typename?: 'CreateTermPayload';
  term: Term;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTripCommentInput = {
  tripId: Scalars['ID'];
  bodyText: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTripCommentPayload = {
  __typename?: 'CreateTripCommentPayload';
  tripComment: Comment;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTripInput = {
  requestId: Scalars['ID'];
  trip: TripTripInformationInput;
  mode?: Maybe<RequestStage>;
  shouldSendEmail?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Array<Maybe<TripCommentInput>>>;
  baseRateAutomation?: Maybe<BaseRateAutomationInput>;
  /** TripId of trip thats being duplicated. Only supplied if there is not baseRateAutomation supplied */
  duplicateTripId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTripPayload = {
  __typename?: 'CreateTripPayload';
  trip?: Maybe<Trip>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTripPaymentInput = {
  amount: Scalars['Float'];
  method: PaymentMethodEnum;
  routeId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTripPaymentPayload = {
  __typename?: 'CreateTripPaymentPayload';
  payment?: Maybe<Payment>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTripStopInformationInput = {
  location: Scalars['String'];
  dateTime?: Maybe<Scalars['DateTime']>;
  stopIndex: Scalars['Int'];
  groupSize?: Maybe<Scalars['Int']>;
  logisticStreetViewUrl?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  flightNumber?: Maybe<Scalars['String']>;
  airportIcao?: Maybe<Scalars['String']>;
  airlineIcao?: Maybe<Scalars['String']>;
  flightData?: Maybe<Scalars['JSON']>;
  trackedFlight?: Maybe<Scalars['JSON']>;
  locationAlias?: Maybe<Scalars['String']>;
};

export type CreateUserAccessPermissionInput = {
  userId: Scalars['ID'];
  accessPermissionId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUserAccessPermissionPayload = {
  __typename?: 'CreateUserAccessPermissionPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUserAndSendInviteInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobilePhone: Scalars['String'];
  email: Scalars['String'];
  roleSlug: Scalars['String'];
  defaultScreen: DefaultScreenVariant;
  phoneCountryCode: Scalars['String'];
  phoneCountryDialCode: Scalars['String'];
  phoneCountryName: Scalars['String'];
  phoneCountryFormat: Scalars['String'];
  /** List of accessPermission ids */
  accessPermissions: Array<Maybe<UserAccessPermissionInput>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUserAndSendInvitePayload = {
  __typename?: 'CreateUserAndSendInvitePayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateVehicleChildSeatInputV2 = {
  active: Scalars['Boolean'];
  quantity: Scalars['Int'];
  amt: Scalars['Float'];
  imageUrl: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type CreateVehicleCommentInput = {
  vehicleId: Scalars['ID'];
  bodyText: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateVehicleCommentInputV2 = {
  bodyText: Scalars['String'];
};

export type CreateVehicleCommentPayload = {
  __typename?: 'CreateVehicleCommentPayload';
  vehicleComment: Comment;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateVehicleFailure = {
  __typename?: 'CreateVehicleFailure';
  message?: Maybe<Scalars['String']>;
};

export type CreateVehicleFeatureInput = {
  vehicleId: Scalars['ID'];
  featureId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateVehicleFeatureInputV2 = {
  id?: Maybe<Scalars['ID']>;
};

export type CreateVehicleFeaturePayload = {
  __typename?: 'CreateVehicleFeaturePayload';
  vehicle?: Maybe<Vehicle>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateVehicleInput = {
  available: Scalars['Boolean'];
  name: Scalars['String'];
  typeSlug: Scalars['String'];
  capacity: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  weekendHourlyCost?: Maybe<Scalars['Float']>;
  weekdayHourlyCost?: Maybe<Scalars['Float']>;
  weekendMinMinutes?: Maybe<Scalars['Int']>;
  weekdayMinMinutes?: Maybe<Scalars['Int']>;
  licensePlate?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<Maybe<VehiclePhotoInput>>>;
  /** List of feature ids */
  features?: Maybe<Array<Maybe<VehicleFeatureInput>>>;
  vinNumber?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  insuranceId?: Maybe<Scalars['ID']>;
  cancellationPolicyId?: Maybe<Scalars['ID']>;
  comments?: Maybe<Array<Maybe<VehicleCommentInput>>>;
  minimumTotalBaseRate?: Maybe<Scalars['Float']>;
  deadheadRatePerMile?: Maybe<Scalars['Float']>;
  tripRatePerMile?: Maybe<Scalars['Float']>;
  totalDeadheadDurationMinutes?: Maybe<Scalars['Int']>;
  enableBaseRateAutomation?: Maybe<Scalars['Boolean']>;
  enableBaseRateAutomationBookingTool?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<CreateVehicleSettingsInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateVehiclePayload = {
  __typename?: 'CreateVehiclePayload';
  vehicle?: Maybe<Vehicle>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateVehiclePhotoInput = {
  vehicleId: Scalars['ID'];
  url: Scalars['String'];
  photoIndex: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateVehiclePhotoInputV2 = {
  url?: Maybe<Scalars['String']>;
  photoIndex?: Maybe<Scalars['Int']>;
};

export type CreateVehiclePhotoPayload = {
  __typename?: 'CreateVehiclePhotoPayload';
  vehicle?: Maybe<Vehicle>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateVehicleResult = Vehicle | CreateVehicleFailure;

export type CreateVehicleSettingsInput = {
  weekends?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  conflictBlockQuote?: Maybe<Scalars['Boolean']>;
  conflictBlockReservation?: Maybe<Scalars['Boolean']>;
  forwardFacingSeat?: Maybe<VehicleChildSeatInput>;
  rearFacingSeat?: Maybe<VehicleChildSeatInput>;
  boosterSeat?: Maybe<VehicleChildSeatInput>;
  pricelessBookingEnabled?: Maybe<Scalars['Boolean']>;
  pricelessBookingTarget?: Maybe<PricelessBookingTarget>;
  pricelessBookingContactIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  pricelessBookingCompanyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateVehicleSettingsInputV2 = {
  weekends?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  conflictBlockQuote?: Maybe<Scalars['Boolean']>;
  conflictBlockReservation?: Maybe<Scalars['Boolean']>;
  forwardFacingSeat?: Maybe<CreateVehicleChildSeatInputV2>;
  rearFacingSeat?: Maybe<CreateVehicleChildSeatInputV2>;
  boosterSeat?: Maybe<CreateVehicleChildSeatInputV2>;
  pricelessBookingEnabled?: Maybe<Scalars['Boolean']>;
  pricelessBookingTarget?: Maybe<PricelessBookingTarget>;
  pricelessBookingContactIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  pricelessBookingCompanyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateVehicleTierInput = {
  lowerLimit: Scalars['Int'];
  upperLimit: Scalars['Int'];
  rate: Scalars['Float'];
  type: PricingVariant;
};

export type CreateVehicleTieredPricingInput = {
  type: TieredPricingVariant;
  tiers: Array<Maybe<CreateVehicleTierInput>>;
};

export type CreateVehicleV2Input = {
  available: Scalars['Boolean'];
  name: Scalars['String'];
  typeSlug: Scalars['String'];
  capacity: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  weekendHourlyCost?: Maybe<Scalars['Float']>;
  weekdayHourlyCost?: Maybe<Scalars['Float']>;
  weekendMinMinutes?: Maybe<Scalars['Int']>;
  weekdayMinMinutes?: Maybe<Scalars['Int']>;
  licensePlate?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<Maybe<CreateVehiclePhotoInputV2>>>;
  /** List of feature ids */
  features?: Maybe<Array<Maybe<CreateVehicleFeatureInputV2>>>;
  vinNumber?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  insuranceId?: Maybe<Scalars['ID']>;
  cancellationPolicyId?: Maybe<Scalars['ID']>;
  comments?: Maybe<Array<Maybe<CreateVehicleCommentInputV2>>>;
  minimumTotalBaseRate?: Maybe<Scalars['Float']>;
  deadheadRatePerMile?: Maybe<Scalars['Float']>;
  tripRatePerMile?: Maybe<Scalars['Float']>;
  totalDeadheadDurationMinutes?: Maybe<Scalars['Int']>;
  enableBaseRateAutomation?: Maybe<Scalars['Boolean']>;
  enableBaseRateAutomationBookingTool?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<CreateVehicleSettingsInputV2>;
  useTieredTransfer?: Maybe<Scalars['Boolean']>;
  useTieredHourly?: Maybe<Scalars['Boolean']>;
  useTieredHourlyWeekend?: Maybe<Scalars['Boolean']>;
  tieredPricingTransfer?: Maybe<CreateVehicleTieredPricingInput>;
  tieredPricingHourly?: Maybe<CreateVehicleTieredPricingInput>;
  tieredPricingHourlyWeekend?: Maybe<CreateVehicleTieredPricingInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateVehicleV2Payload = {
  __typename?: 'CreateVehicleV2Payload';
  result: CreateVehicleResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateWebsiteInput = {
  templateId: Scalars['String'];
  websitePlan: Scalars['String'];
  siteDomain: Scalars['String'];
  seoDescription: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateWebsitePayload = {
  __typename?: 'CreateWebsitePayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  /** Total reservation amount for a date range. */
  totalAmount: Scalars['Float'];
  /** List of reservation amount per month over a date range. */
  reservationAmountPerMonth: Scalars['JSON'];
  /** List of reservation amount per vehicle over a date range */
  vehicleBreakdown: Scalars['JSON'];
  /** List of reservation amount per order type over a date range */
  orderTypeBreakdown: Scalars['JSON'];
};



export enum DayOfWeek {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export type DeclineUnconfirmedReservationInput = {
  requestId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeclineUnconfirmedReservationPayload = {
  __typename?: 'DeclineUnconfirmedReservationPayload';
  request?: Maybe<Request>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum DefaultScreenVariant {
  Quotes = 'QUOTES',
  Reservations = 'RESERVATIONS',
  Dispatch = 'DISPATCH',
  DriverTracking = 'DRIVER_TRACKING',
  Invoices = 'INVOICES',
  Payables = 'PAYABLES',
  Dashboard = 'DASHBOARD'
}

export type DeleteMoovsMarketAppInput = {
  app: Scalars['String'];
  deleteReason: Scalars['String'];
  otherReason?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMoovsMarketAppPayload = {
  __typename?: 'DeleteMoovsMarketAppPayload';
  moovsMarketApps?: Maybe<MoovsMarketApps>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableAutomatedEmailsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableAutomatedEmailsPayload = {
  __typename?: 'DisableAutomatedEmailsPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableAutomatedTextsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableAutomatedTextsPayload = {
  __typename?: 'DisableAutomatedTextsPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableCreditCardWhenBookingInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableCreditCardWhenBookingPayload = {
  __typename?: 'DisableCreditCardWhenBookingPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableDriverStatusTextNotificationsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableDriverStatusTextNotificationsPayload = {
  __typename?: 'DisableDriverStatusTextNotificationsPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableGratuityWhenBookingInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableGratuityWhenBookingPayload = {
  __typename?: 'DisableGratuityWhenBookingPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableInboundEmailNotificationsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableInboundEmailNotificationsPayload = {
  __typename?: 'DisableInboundEmailNotificationsPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableInboundTextNotificationsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableInboundTextNotificationsPayload = {
  __typename?: 'DisableInboundTextNotificationsPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableRequireConsentWhenBookingInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableRequireConsentWhenBookingPayload = {
  __typename?: 'DisableRequireConsentWhenBookingPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableTripReminderTextNotificationsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableTripReminderTextNotificationsPayload = {
  __typename?: 'DisableTripReminderTextNotificationsPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisconnectGoogleCalendarAuthInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisconnectGoogleCalendarAuthPayload = {
  __typename?: 'DisconnectGoogleCalendarAuthPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum DistanceUnitEnum {
  Miles = 'MILES',
  Kilometres = 'KILOMETRES'
}

export type Driver = Node & {
  __typename?: 'Driver';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobilePhone: Scalars['String'];
  mobilePhoneInternational: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  phoneCountryDialCode: Scalars['String'];
  phoneCountryName: Scalars['String'];
  phoneCountryFormat: Scalars['String'];
  homePhone?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseClass?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  licenseExpirationDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  operator: Operator;
  additionalOperators?: Maybe<Array<Maybe<Operator>>>;
  externalOperator?: Maybe<ExternalOperator>;
  driverProfilePhoto?: Maybe<Scalars['String']>;
  driverAvailability?: Maybe<DriverAvailability>;
  settings?: Maybe<DriverSettings>;
};

export type DriverAvailability = Node & {
  __typename?: 'DriverAvailability';
  /** The ID of an object */
  id: Scalars['ID'];
  sundayAvailability: Scalars['Boolean'];
  sundayAvailableStartTime: Scalars['Time'];
  sundayAvailableEndTime: Scalars['Time'];
  mondayAvailability: Scalars['Boolean'];
  mondayAvailableStartTime: Scalars['Time'];
  mondayAvailableEndTime: Scalars['Time'];
  tuesdayAvailability: Scalars['Boolean'];
  tuesdayAvailableStartTime: Scalars['Time'];
  tuesdayAvailableEndTime: Scalars['Time'];
  wednesdayAvailability: Scalars['Boolean'];
  wednesdayAvailableStartTime: Scalars['Time'];
  wednesdayAvailableEndTime: Scalars['Time'];
  thursdayAvailability: Scalars['Boolean'];
  thursdayAvailableStartTime: Scalars['Time'];
  thursdayAvailableEndTime: Scalars['Time'];
  fridayAvailability: Scalars['Boolean'];
  fridayAvailableStartTime: Scalars['Time'];
  fridayAvailableEndTime: Scalars['Time'];
  saturdayAvailability: Scalars['Boolean'];
  saturdayAvailableStartTime: Scalars['Time'];
  saturdayAvailableEndTime: Scalars['Time'];
};

/** Grouped by driver, for driver dashboard report */
export type DriverDashboard = {
  __typename?: 'DriverDashboard';
  /** The ID of an object */
  id: Scalars['ID'];
  driver: Driver;
  totalPayoutAmount: Scalars['Float'];
  amountPaid: Scalars['Float'];
  amountDue: Scalars['Float'];
  tripCount: Scalars['Int'];
};

/** Grouped by driver, list of closed trips. Can be paid already as a part of driverPayout or queried individually (for potential payout). */
export type DriverPayable = {
  __typename?: 'DriverPayable';
  /** The ID of an object */
  id: Scalars['ID'];
  driver: Driver;
  operatorRoutes: Array<Maybe<OperatorRoute>>;
  flatRateAmt?: Maybe<Scalars['Float']>;
  gratuityAmt?: Maybe<Scalars['Float']>;
  hourlyAmt?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
  routesCount: Scalars['Int'];
  /** time when the driver payables associated payout was created */
  payoutCreatedAt?: Maybe<Scalars['DateTime']>;
};

/** Group of DriverPayables and summary of all driver payout ammounts from route level */
export type DriverPayout = {
  __typename?: 'DriverPayout';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  flatRateAmt?: Maybe<Scalars['Float']>;
  gratuityAmt?: Maybe<Scalars['Float']>;
  hourlyAmt?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
  driverPayables: Array<Maybe<DriverPayable>>;
  driversCount: Scalars['Int'];
  routesCount: Scalars['Int'];
};

export type DriverPlatformDriver = {
  __typename?: 'DriverPlatformDriver';
  driver: Driver;
  driverTrips: Scalars['Int'];
};

export type DriverPlatformReport = {
  __typename?: 'DriverPlatformReport';
  operatorTotalTrips: Scalars['Int'];
  drivers?: Maybe<Array<Maybe<DriverPlatformDriver>>>;
};

export type DriverSettings = {
  __typename?: 'DriverSettings';
  /** The ID of an object */
  id: Scalars['ID'];
  /** Hourly rate paid to driver for hourly trips (in dollars) */
  hourlyTripHourlyRate?: Maybe<Scalars['Float']>;
  /** Percent of base rate paid to driver for hourly trips */
  hourlyTripBaseRatePct?: Maybe<Scalars['Float']>;
  /** Time used for hourly trips, "location-to-dropoff" or "garage-to-garage". garageToGarage requites driver app tracking */
  hourlyTripLocationsVariant: TripLocationsVariant;
  /** Hourly rate paid to driver for one way trips (in dollars) */
  oneWayTripHourlyRate?: Maybe<Scalars['Float']>;
  /** Minimum number of hours a driver is paid for a one way trip. e.g. if a driver works 20 minutes, but the minumum is 2hrs, then they are paid for 2hrs of work */
  oneWayTripMinimumHours?: Maybe<Scalars['Float']>;
  /** Flat rate paid to drivers on one-way, regardless of number of hours worked (in dollars) */
  oneWayTripFlatRate?: Maybe<Scalars['Float']>;
  /** Percent of base rate paid to driver for one way trips */
  oneWayTripBaseRatePct?: Maybe<Scalars['Float']>;
  /** Time used for one way trips, "location-to-dropoff" or "garage-to-garage". garageToGarage requites driver app tracking */
  oneWayTripLocationsVariant: TripLocationsVariant;
  /** Percent of gratuity allocated for the driver */
  gratuityPct?: Maybe<Scalars['Float']>;
};

/** Driver Settings Create Input */
export type DriverSettingsInput = {
  hourlyTripHourlyRate?: Maybe<Scalars['Float']>;
  hourlyTripBaseRatePct?: Maybe<Scalars['Float']>;
  hourlyTripLocationsVariant?: Maybe<TripLocationsVariant>;
  oneWayTripHourlyRate?: Maybe<Scalars['Float']>;
  oneWayTripMinimumHours?: Maybe<Scalars['Float']>;
  oneWayTripBaseRatePct?: Maybe<Scalars['Float']>;
  oneWayTripLocationsVariant?: Maybe<TripLocationsVariant>;
  oneWayTripFlatRate?: Maybe<Scalars['Float']>;
  gratuityPct?: Maybe<Scalars['Float']>;
};

export type DriverStatus = {
  __typename?: 'DriverStatus';
  /** The ID of an object */
  id: Scalars['ID'];
  name: DriverStatusName;
  assignedAt?: Maybe<Scalars['DateTime']>;
  acceptedAt?: Maybe<Scalars['DateTime']>;
  declinedAt?: Maybe<Scalars['DateTime']>;
};

export enum DriverStatusName {
  Unassigned = 'UNASSIGNED',
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED'
}

export type DudaWebsite = {
  __typename?: 'DudaWebsite';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  desktopThumbnailUrl?: Maybe<Scalars['String']>;
  previewSiteUrl?: Maybe<Scalars['String']>;
  publishStatus?: Maybe<Scalars['String']>;
  siteDomain?: Maybe<Scalars['String']>;
  /** duda's unique identifier */
  siteName?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  websitePlan?: Maybe<WebsitePlan>;
  websitePermissions?: Maybe<WebsitePermissions>;
};

export type DynamicPricingRule = Node & {
  __typename?: 'DynamicPricingRule';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  vehicles: Array<Maybe<Vehicle>>;
  startDate?: Maybe<Scalars['DateTime']>;
  /** only present if a date range */
  endDate?: Maybe<Scalars['DateTime']>;
  /** List of days this rule applies to. If this rule applies to all days 'null' is returned */
  daysOfWeek?: Maybe<Array<Maybe<DayOfWeek>>>;
  startTime?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  isIncrease: Scalars['Boolean'];
  isPercent: Scalars['Boolean'];
  /** effect amount in absolute terms (always positive) */
  amount: Scalars['Float'];
  repeatsAnnually: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
};

/** Events related to emails being interacted with sent via sendgrid webhook */
export type EmailEvent = {
  __typename?: 'EmailEvent';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  eventType: Scalars['String'];
  triggeredAt: Scalars['DateTime'];
  recipient: Scalars['String'];
};

export type EmailLog = Node & {
  __typename?: 'EmailLog';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  emailType: Scalars['String'];
  /** @deprecated use toRecipients to allow multiple to recipients per emailLog Record */
  to?: Maybe<Scalars['String']>;
  toRecipients?: Maybe<Array<Maybe<Scalars['String']>>>;
  ccRecipients?: Maybe<Array<Maybe<Scalars['String']>>>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  sendgridResponse?: Maybe<Scalars['JSON']>;
  emailEvents: Array<Maybe<EmailEvent>>;
  confirmationNumber?: Maybe<Scalars['String']>;
};

export enum EmailModeEnum {
  Reservation = 'RESERVATION',
  PriceSummary = 'PRICE_SUMMARY',
  Invoice = 'INVOICE',
  Custom = 'CUSTOM'
}

export type EnableAutomatedEmailsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableAutomatedEmailsPayload = {
  __typename?: 'EnableAutomatedEmailsPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableAutomatedTextsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableAutomatedTextsPayload = {
  __typename?: 'EnableAutomatedTextsPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableCreditCardWhenBookingInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableCreditCardWhenBookingPayload = {
  __typename?: 'EnableCreditCardWhenBookingPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableDriverStatusTextNotificationsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableDriverStatusTextNotificationsPayload = {
  __typename?: 'EnableDriverStatusTextNotificationsPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableGratuityWhenBookingInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableGratuityWhenBookingPayload = {
  __typename?: 'EnableGratuityWhenBookingPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableInboundEmailNotificationsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableInboundEmailNotificationsPayload = {
  __typename?: 'EnableInboundEmailNotificationsPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableInboundTextNotificationsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableInboundTextNotificationsPayload = {
  __typename?: 'EnableInboundTextNotificationsPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableMoovsNetworkInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableMoovsNetworkPayload = {
  __typename?: 'EnableMoovsNetworkPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnablePocketflowsCrmInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnablePocketflowsCrmPayload = {
  __typename?: 'EnablePocketflowsCRMPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableRequireConsentWhenBookingInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableRequireConsentWhenBookingPayload = {
  __typename?: 'EnableRequireConsentWhenBookingPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableTripReminderTextNotificationsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableTripReminderTextNotificationsPayload = {
  __typename?: 'EnableTripReminderTextNotificationsPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ExternalOperator = Node & {
  __typename?: 'ExternalOperator';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  operatorName: Scalars['String'];
  operatorEmail: Scalars['String'];
  operatorPhone: Scalars['String'];
  operatorAddress?: Maybe<Scalars['String']>;
  serviceArea?: Maybe<Scalars['String']>;
  contactName: Scalars['String'];
  /** we don't currently support adding photos here */
  logoUrl?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  vehicleTypes: Array<Maybe<VehicleType>>;
  drivers: Array<Maybe<Driver>>;
  vehicles: Array<Maybe<FarmAffiliateVehicle>>;
  /**
   * Cannot attach credit cards to external operators currently.
   *         This keeps the ExternalOperator type in sync with the other farm affiliate types.
   */
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

/** Union of all variants of FarmAffiliates, i.e. other operators that you farm rides to and from */
export type FarmAffiliate = ExternalOperator | MoovsNetworkOperator | GriddnetOperator | MoovsAiOperator;

export enum FarmAffiliateVariantEnum {
  MoovsNetworkOperator = 'MoovsNetworkOperator',
  ExternalOperator = 'ExternalOperator',
  GriddnetOperator = 'GriddnetOperator',
  MoovsAiOperator = 'MoovsAiOperator'
}

export type FarmAffiliateVehicle = {
  __typename?: 'FarmAffiliateVehicle';
  /** The ID of an object */
  id: Scalars['ID'];
  name: Scalars['String'];
  available: Scalars['Boolean'];
  capacity: Scalars['Int'];
  minimumTotalBaseRate?: Maybe<Scalars['Float']>;
  deadheadRatePerMile?: Maybe<Scalars['Float']>;
  tripRatePerMile?: Maybe<Scalars['Float']>;
  totalDeadheadDurationMinutes?: Maybe<Scalars['Int']>;
  enableBaseRateAutomation?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  weekendHourlyCost?: Maybe<Scalars['Float']>;
  weekdayHourlyCost?: Maybe<Scalars['Float']>;
  weekendMinMinutes?: Maybe<Scalars['Float']>;
  weekdayMinMinutes?: Maybe<Scalars['Float']>;
  photos: Array<Maybe<VehiclePhoto>>;
  features: Array<Maybe<VehicleFeature>>;
  cancellationPolicy?: Maybe<CancellationPolicy>;
  insurancePolicy?: Maybe<InsurancePolicy>;
  vehicleType: VehicleType;
  farmAffiliateName?: Maybe<Scalars['String']>;
  farmAffiliateVariant: FarmAffiliateVariantEnum;
  /** @deprecated use isMoovsLiteOperator. Remove after 1/8/22 */
  isMoovsLightOperator?: Maybe<Scalars['Boolean']>;
  /**
   * activeForAnalytics field is used to determine whether an operator is paying for a full package (true) or farming
   *     account (false). This field is set by Product team to upgrade an account, so false or null entries indicate Moovs Lite.
   */
  isMoovsLiteOperator?: Maybe<Scalars['Boolean']>;
  /** Distance in miles from first stop of the trip being farmed out. */
  distanceFromFirstStop?: Maybe<Scalars['Float']>;
  /** Distance in miles from last stop of the trip being farmed out. */
  distanceFromLastStop?: Maybe<Scalars['Float']>;
  automatedBaseRate?: Maybe<Scalars['Float']>;
  baseRateAutomation?: Maybe<BaseRateAutomation>;
  settings?: Maybe<VehicleSettings>;
  useTieredTransfer?: Maybe<Scalars['Boolean']>;
  useTieredHourly?: Maybe<Scalars['Boolean']>;
  useTieredHourlyWeekend?: Maybe<Scalars['Boolean']>;
  tieredPricingTransfer?: Maybe<TieredPricingTransfer>;
  tieredPricingHourly?: Maybe<TieredPricingHourly>;
  tieredPricingHourlyWeekend?: Maybe<TieredPricingHourly>;
};

export enum FarmRelationshipEnum {
  Farmor = 'FARMOR',
  Farmee = 'FARMEE'
}

export enum FarmedRouteStatusEnum {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED'
}

export enum FlightStatus {
  Scheduled = 'SCHEDULED',
  Departed = 'DEPARTED',
  Arrived = 'ARRIVED',
  Cancelled = 'CANCELLED',
  Diverted = 'DIVERTED'
}

export type GenerateSsoLinkToDudaWebsiteEditorInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GenerateSsoLinkToDudaWebsiteEditorPayload = {
  __typename?: 'GenerateSSOLinkToDudaWebsiteEditorPayload';
  ssoLink: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GetOperatorDudaWebsiteUserInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GetOperatorDudaWebsiteUserPayload = {
  __typename?: 'GetOperatorDudaWebsiteUserPayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GetPocketflowsEmbedIdInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GetPocketflowsEmbedIdPayload = {
  __typename?: 'GetPocketflowsEmbedIdPayload';
  embedId?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum GettingStartedGoal {
  GrowBusinessOnline = 'GROW_BUSINESS_ONLINE',
  AccessDriverApp = 'ACCESS_DRIVER_APP',
  ExploreOnMyOwn = 'EXPLORE_ON_MY_OWN'
}

export type GoogleCalendar = {
  __typename?: 'GoogleCalendar';
  /** The ID of an object */
  id: Scalars['ID'];
  emailAddress?: Maybe<Scalars['String']>;
};

export type GriddnetOperator = Node & {
  __typename?: 'GriddnetOperator';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  operatorName: Scalars['String'];
  operatorEmail: Scalars['String'];
  operatorPhone: Scalars['String'];
  operatorAddress?: Maybe<Scalars['String']>;
  /** Moovs operators don't have service areas (mirroring MoovsNetworkOperator) */
  serviceArea?: Maybe<Scalars['String']>;
  /** name of user who is owner of operator business */
  contactName: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  vehicleTypes: Array<Maybe<VehicleType>>;
  websiteUrl?: Maybe<Scalars['String']>;
  griddnetId: Scalars['String'];
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type InstallMoovsMarketAppInput = {
  app: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type InstallMoovsMarketAppPayload = {
  __typename?: 'InstallMoovsMarketAppPayload';
  moovsMarketApps?: Maybe<MoovsMarketApps>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type InsuranceAttachment = {
  __typename?: 'InsuranceAttachment';
  /** The ID of an object */
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type InsuranceAttachmentInput = {
  url?: Maybe<Scalars['String']>;
};

export type InsurancePolicy = Node & {
  __typename?: 'InsurancePolicy';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  insuranceCompany: Scalars['String'];
  policyNumber: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  coverageAmount: Scalars['Float'];
  monthlyPremium: Scalars['Float'];
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  insuranceAttachments?: Maybe<Array<Maybe<InsuranceAttachment>>>;
  vehicles?: Maybe<Array<Maybe<Vehicle>>>;
  vehicleIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Invoice = Node & {
  __typename?: 'Invoice';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  memo?: Maybe<Scalars['String']>;
  status: InvoiceStatus;
  amountDue: Scalars['Float'];
  totalAmount: Scalars['Float'];
  isOutstanding: Scalars['Boolean'];
  invoiceNumber: Scalars['String'];
  contact?: Maybe<Contact>;
  /** The Farm Affiliate tied to the invoice. Always a MoovsNetworkOperator. */
  farmAffiliate?: Maybe<FarmAffiliate>;
  company?: Maybe<Company>;
  requests: Array<Maybe<Request>>;
  /** All subpayments made towards this invoice */
  subPayments?: Maybe<Array<Maybe<SubPayment>>>;
  removedAt?: Maybe<Scalars['DateTime']>;
  emailLogs: Array<Maybe<EmailLog>>;
};

export enum InvoiceStatus {
  Pending = 'PENDING',
  Paid = 'PAID'
}



export type JoinTeamInput = {
  userId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type JoinTeamPayload = {
  __typename?: 'JoinTeamPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum LagoPlanCode {
  StandardMonthly = 'STANDARD_MONTHLY',
  StandardAnnual = 'STANDARD_ANNUAL',
  ProMonthly = 'PRO_MONTHLY',
  ProAnnual = 'PRO_ANNUAL',
  VipMonthly = 'VIP_MONTHLY',
  VipAnnual = 'VIP_ANNUAL'
}

export type LineItem = {
  __typename?: 'LineItem';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  source: LineItemSourceEnum;
  lineTotal: Scalars['Float'];
};

export type LineItemInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  source: LineItemSourceEnum;
  lineTotal: Scalars['Float'];
};

export enum LineItemSourceEnum {
  BaseRateAutomation = 'BASE_RATE_AUTOMATION',
  DynamicPricing = 'DYNAMIC_PRICING',
  ZonePricing = 'ZONE_PRICING'
}

export type LinkExternalOperatorDriverInput = {
  externalOperatorId: Scalars['ID'];
  driverId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LinkExternalOperatorDriverPayload = {
  __typename?: 'LinkExternalOperatorDriverPayload';
  externalOperator: ExternalOperator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LinkExternalOperatorVehicleInput = {
  externalOperatorId: Scalars['ID'];
  vehicleId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LinkExternalOperatorVehiclePayload = {
  __typename?: 'LinkExternalOperatorVehiclePayload';
  externalOperator: ExternalOperator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LinkPaymentMethodInput = {
  linkedPassengerId: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LinkPaymentMethodPayload = {
  __typename?: 'LinkPaymentMethodPayload';
  paymentMethod: PaymentMethod;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LoadChatParticipantInput = {
  conversationSid?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
};

export type LoadInvitePendingUser = {
  __typename?: 'LoadInvitePendingUser';
  invitePending: Scalars['Boolean'];
  id: Scalars['String'];
  email: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadVehiclesConnection = {
  __typename?: 'LoadVehiclesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadVehiclesEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadVehiclesEdge = {
  __typename?: 'LoadVehiclesEdge';
  /** The item at the end of the edge */
  node?: Maybe<Vehicle>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type LocationZone = {
  __typename?: 'LocationZone';
  variant: ZoneVariantEnum;
  radius: Scalars['Float'];
  centerCoordinates: Coordinates;
  address: Scalars['String'];
};

export type MetadataUnion = TripChangeMetadata;

export type MoovsAiOperator = Node & {
  __typename?: 'MoovsAiOperator';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  operatorName: Scalars['String'];
  operatorEmail: Scalars['String'];
  operatorPhone: Scalars['String'];
  operatorAddress?: Maybe<Scalars['String']>;
  /** Moovs operators don't have service areas (mirroring MoovsNetworkOperator) */
  serviceArea?: Maybe<Scalars['String']>;
  vehicleTypes: Array<Maybe<VehicleType>>;
  /** name of user who is owner of operator business */
  contactName: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type MoovsMarketApp = {
  __typename?: 'MoovsMarketApp';
  /** The ID of an object */
  id: Scalars['ID'];
  chargeAt?: Maybe<Scalars['DateTime']>;
  active: Scalars['Boolean'];
};

export type MoovsMarketApps = {
  __typename?: 'MoovsMarketApps';
  /** The ID of an object */
  id: Scalars['ID'];
  driverApp: MoovsMarketApp;
  dynamicPricing: MoovsMarketApp;
  salesAutomation: MoovsMarketApp;
  reviewPlatform: MoovsMarketApp;
  googleCalendar: MoovsMarketApp;
};

export type MoovsNetworkOperator = Node & {
  __typename?: 'MoovsNetworkOperator';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  operatorName: Scalars['String'];
  operatorEmail: Scalars['String'];
  operatorPhone: Scalars['String'];
  operatorAddress?: Maybe<Scalars['String']>;
  /** Moovs operators don't have service areas */
  serviceArea?: Maybe<Scalars['String']>;
  /** name of user who is owner of operator business */
  contactName: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  vehicleTypes: Array<Maybe<VehicleType>>;
  vehicles: Array<Maybe<FarmAffiliateVehicle>>;
  internalNote?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
  /**
   * activeForAnalytics field is used to determine whether an operator is paying for a full package (true) or farming
   *         account (false). This field is set by Product team to upgrade an account, so false or null entries indicate Moovs Lite.
   */
  isMoovsLiteOperator?: Maybe<Scalars['Boolean']>;
};

export type MoovsTransaction = Payment | PaymentRefund;

export type Mutation = {
  __typename?: 'Mutation';
  /** Enable emails by removing it from disable_email table */
  enableAutomatedEmails?: Maybe<EnableAutomatedEmailsPayload>;
  /** Disable email by adding it from disable_email table */
  disableAutomatedEmails?: Maybe<DisableAutomatedEmailsPayload>;
  /** Create recent search (i.e. recently viewed) record.  */
  createRecentSearch?: Maybe<CreateRecentSearchPayload>;
  /** Updates internal note for a griddnet operator */
  updateGriddnetOperatorNote?: Maybe<UpdateGriddnetOperatorNotePayload>;
  /** Create insurance policy */
  createInsurancePolicy?: Maybe<CreateInsurancePolicyPayload>;
  /** Update InsurancePolicy Information */
  updateInsurancePolicy?: Maybe<UpdateInsurancePolicyPayload>;
  /** Remove insurance policy */
  removeInsurancePolicy?: Maybe<RemoveInsurancePolicyPayload>;
  /** Create insurance attachment */
  createInsuranceAttachment?: Maybe<CreateInsuranceAttachmentPayload>;
  /** Remove insurance attachment */
  removeInsuranceAttachment?: Maybe<RemoveInsuranceAttachmentPayload>;
  /** Sends invoice email to customer */
  sendInvoiceEmailToCustomer?: Maybe<SendInvoiceEmailToCustomerPayload>;
  /** Enables Moovs affiliate network by opting in operator. */
  enableMoovsNetwork?: Maybe<EnableMoovsNetworkPayload>;
  /** Update operator information */
  updateOperator?: Maybe<UpdateOperatorPayload>;
  /** Update operator name slug */
  updateNameSlug?: Maybe<UpdateNameSlugPayload>;
  /** Adds custom domain to moovs Sendgrid account and generates DNS information. */
  createSendgridCustomDomain?: Maybe<CreateSendgridCustomDomainPayload>;
  /** Removes (resets) custom domain fields. */
  removeSendgridCustomDomain?: Maybe<RemoveSendgridCustomDomainPayload>;
  /** Validates custom domain is connected to moovs Sendgrid account. */
  validateSendgridCustomDomain?: Maybe<ValidateSendgridCustomDomainPayload>;
  /** Enable inbound text notifications sent via twilio */
  enableInboundTextNotifications?: Maybe<EnableInboundTextNotificationsPayload>;
  /** Disable inbound text notifications sent via twilio */
  disableInboundTextNotifications?: Maybe<DisableInboundTextNotificationsPayload>;
  /** Enable inbound email notifications sent via sendgrid */
  enableInboundEmailNotifications?: Maybe<EnableInboundEmailNotificationsPayload>;
  /** Disable inbound email notifications sent via sendgrid */
  disableInboundEmailNotifications?: Maybe<DisableInboundEmailNotificationsPayload>;
  /** Enable credit card information to be entered by customers on customer widget */
  enableCreditCardWhenBooking?: Maybe<EnableCreditCardWhenBookingPayload>;
  /** Prevent credit card payment information to be entered by customers on customer widget */
  disableCreditCardWhenBooking?: Maybe<DisableCreditCardWhenBookingPayload>;
  /** Enable create automated texts to booking contact when driver updates trip status */
  enableDriverStatusTextNotifications?: Maybe<EnableDriverStatusTextNotificationsPayload>;
  /** Disable sending automated texts to booking contact when driver updates trip status */
  disableDriverStatusTextNotifications?: Maybe<DisableDriverStatusTextNotificationsPayload>;
  /** Toggle driver's access to amount due on trip */
  toggleDriverAppAmountDueDisplay?: Maybe<ToggleDriverAppAmountDueDisplayPayload>;
  /** Update booking cutoff time value and type */
  updateBookingCutoffTime?: Maybe<UpdateBookingCutoffTimePayload>;
  /** Turn gratuity when booking on */
  enableGratuityWhenBooking?: Maybe<EnableGratuityWhenBookingPayload>;
  /** Turn gratuity when booking off */
  disableGratuityWhenBooking?: Maybe<DisableGratuityWhenBookingPayload>;
  /** Turn requiring customer consent when booking on */
  enableRequireConsentWhenBooking?: Maybe<EnableRequireConsentWhenBookingPayload>;
  /** Turn requiring customer consent when booking off */
  disableRequireConsentWhenBooking?: Maybe<DisableRequireConsentWhenBookingPayload>;
  /** Enable create automated texts to booking contact 24hrs and 1hr before trip */
  enableTripReminderTextNotifications?: Maybe<EnableTripReminderTextNotificationsPayload>;
  /** Disable sending automated texts to booking contact 24hrs and 1hr before trip */
  disableTripReminderTextNotifications?: Maybe<DisableTripReminderTextNotificationsPayload>;
  /** Enable Pocketflows CRM */
  enablePocketflowsCRM?: Maybe<EnablePocketflowsCrmPayload>;
  /** Get Pocketflows embed id */
  getPocketflowsEmbedId?: Maybe<GetPocketflowsEmbedIdPayload>;
  /** Update operator route information by routeId. */
  updateOperatorRoute?: Maybe<UpdateOperatorRoutePayload>;
  /** Add an existing order type to an operator's enabled order types */
  createOperatorOrderType?: Maybe<CreateOperatorOrderTypePayload>;
  /** Remove an order type from operator's enabled order types */
  removeOperatorOrderType?: Maybe<RemoveOperatorOrderTypePayload>;
  /** Create payment method based on card information */
  createPaymentMethod?: Maybe<CreatePaymentMethodPayload>;
  /**
   * Create price zone and price zone routes
   *       linked between new zone and all other operator zones
   */
  createPriceZone?: Maybe<CreatePriceZonePayload>;
  /** Update price zone */
  updatePriceZone?: Maybe<UpdatePriceZonePayload>;
  /** Remove price zone */
  removePriceZone?: Maybe<RemovePriceZonePayload>;
  /**
   * Updates, creates, or deletes all price_zone_route_vehicle_base_rate
   *     records with inputted base rates for a prize zone route. Will also update the
   *     "opposite" (pickup_zone_id and dropoff_zone_id flipped) priceZoneRoute.
   */
  updatePriceZoneRouteBaseRates?: Maybe<UpdatePriceZoneRouteBaseRatesPayload>;
  /** Create whole new request by supplying filled in request object */
  createNewRequest?: Maybe<CreateNewRequestPayload>;
  /** Confirms a request from dooms operator */
  confirmRequestFromOperator?: Maybe<ConfirmRequestFromOperatorPayload>;
  /** Sends email to customer and saves to db */
  sendEmailToCustomer?: Maybe<SendEmailToCustomerPayload>;
  /** Remove vehicle from route by routeId */
  removeVehicleFromRoute?: Maybe<RemoveVehicleFromRoutePayload>;
  /** Create stop by tripId and add insert before drop-off. */
  createStop?: Maybe<CreateStopPayload>;
  /** Update stop information by stopId. */
  updateStop?: Maybe<UpdateStopPayload>;
  /** Remove last stop by stopId. */
  removeStop?: Maybe<RemoveStopPayload>;
  /** Create term by term type id. */
  createTerm?: Maybe<CreateTermPayload>;
  /** Update term description. */
  updateTerm?: Maybe<UpdateTermPayload>;
  /** Remove term by id. */
  removeTerm?: Maybe<RemoveTermPayload>;
  /** Create new trip on existing Order */
  createTrip?: Maybe<CreateTripPayload>;
  /** Update trip information by tripId. */
  updateTrip?: Maybe<UpdateTripPayload>;
  /** Remove Trip from operator UI */
  removeTrip?: Maybe<RemoveTripPayload>;
  /** Undo action of remove trip from operator UI */
  undoRemoveTrip?: Maybe<UndoRemoveTripPayload>;
  /** Removes an operator's existing Twilio number so it can use another number ported into Twilio. */
  portTwilioNumber?: Maybe<PortTwilioNumberPayload>;
  /** Provisions (purchases) a phone number for operators. */
  provisionPhoneNumberTwilio?: Maybe<ProvisionPhoneNumberTwilioPayload>;
  /** Enable Texts sent via twilio */
  enableAutomatedTexts?: Maybe<EnableAutomatedTextsPayload>;
  /** Disable Texts sent via twilio */
  disableAutomatedTexts?: Maybe<DisableAutomatedTextsPayload>;
  /**
   * Assign feature to a vehicle
   * @deprecated use CreateVehicle instead
   */
  createVehicleFeature?: Maybe<CreateVehicleFeaturePayload>;
  /**
   * Remove feature from vehicle
   * @deprecated use UpdateVehicle instead
   */
  removeVehicleFeature?: Maybe<RemoveVehicleFeaturePayload>;
  /** Create vehicle photo */
  createVehiclePhoto?: Maybe<CreateVehiclePhotoPayload>;
  /** Update vehicle photo */
  updateVehiclePhoto?: Maybe<UpdateVehiclePhotoPayload>;
  /** Remove vehicle photo */
  removeVehiclePhoto?: Maybe<RemoveVehiclePhotoPayload>;
  /** Update vehicle order */
  updateVehiclePhotoOrder?: Maybe<UpdateVehiclePhotoOrderPayload>;
  /** Create website */
  createWebsite?: Maybe<CreateWebsitePayload>;
  /** Publish duda website */
  publishDudaWebsite?: Maybe<PublishDudaWebsitePayload>;
  /** Add to MoovsVehicles collection to add a Moovs vehicle in a Duda website. */
  addVehicleToDudaWebsite?: Maybe<AddVehicleToDudaWebsitePayload>;
  /** Creates user account for operator on duda, tied to operatorId. Then, gives operator permissions for site. */
  createOperatorDudaWebsiteUser?: Maybe<CreateOperatorDudaWebsiteUserPayload>;
  /** Loads user account for operator on duda, tied to operatorId */
  getOperatorDudaWebsiteUser?: Maybe<GetOperatorDudaWebsiteUserPayload>;
  /** Generates SSO link to Duda site editor. */
  generateSSOLinkToDudaWebsiteEditor?: Maybe<GenerateSsoLinkToDudaWebsiteEditorPayload>;
  /** Sets basic permissions on duda user account for operator on duda */
  setBasicOperatorDudaWebsiteUserPermissions?: Maybe<SetBasicOperatorDudaWebsiteUserPermissionsPayload>;
  /** Sets premium permissions on duda user account for operator on duda */
  setPremiumOperatorDudaWebsiteUserPermissions?: Maybe<SetPremiumOperatorDudaWebsiteUserPermissionsPayload>;
  /** Create a new contact, optionally with credit card */
  createContact?: Maybe<CreateContactPayload>;
  /** Remove contact by contactId. */
  removeContact?: Maybe<RemoveContactPayload>;
  /** Update a single contact */
  updateContact?: Maybe<UpdateContactPayload>;
  /** Create cancellation policy */
  createCancellationPolicy?: Maybe<CreateCancellationPolicyPayload>;
  /** Create cancellation policy */
  createCancellationPolicyV2?: Maybe<CreateCancellationPolicyV2Payload>;
  /** Update CancellationPolicy Information */
  updateCancellationPolicy?: Maybe<UpdateCancellationPolicyPayload>;
  /** Remove cancellation policy */
  removeCancellationPolicy?: Maybe<RemoveCancellationPolicyPayload>;
  /** Creates a driver */
  createDriver?: Maybe<CreateDriverPayload>;
  /** Mutation to update driver */
  updateDriver?: Maybe<UpdateDriverPayload>;
  /** Remove driver by driverId. */
  removeDriver?: Maybe<RemoveDriverPayload>;
  /** Creates a driver during onboarding process */
  createOnboardingDriver?: Maybe<CreateOnboardingDriverPayload>;
  /**
   * Sends an internal email with trip details. Used when no vehicles
   *     are available for farm-out
   */
  sendRequestAffiliateEmail?: Maybe<SendRequestAffiliateEmailPayload>;
  /**
   * Creates external operator. Used to farm-out rides to
   *     independent contractors or operators without griddnet linked software
   */
  createExternalOperator?: Maybe<CreateExternalOperatorPayload>;
  /** Remove external operator by externalOperatorId */
  removeExternalOperator?: Maybe<RemoveExternalOperatorPayload>;
  /** Updates External Operator (except linked vehicles and drivers, see separate mutations) */
  updateExternalOperator?: Maybe<UpdateExternalOperatorPayload>;
  /** Links an existing driver to an external operator */
  linkExternalOperatorDriver?: Maybe<LinkExternalOperatorDriverPayload>;
  /** Unlinks an existing driver to an external operator */
  unlinkExternalOperatorDriver?: Maybe<UnlinkExternalOperatorDriverPayload>;
  /** Links an existing vehicle to an external operator */
  linkExternalOperatorVehicle?: Maybe<LinkExternalOperatorVehiclePayload>;
  /** Unlinks an existing driver to an external operator */
  unlinkExternalOperatorVehicle?: Maybe<UnlinkExternalOperatorVehiclePayload>;
  createInvoice?: Maybe<CreateInvoicePayload>;
  removeInvoice?: Maybe<RemoveInvoicePayload>;
  /**
   * Creates farmed route, which is a route that is
   *     farmed out to another operator.
   */
  createFarmedRoute?: Maybe<CreateFarmedRoutePayload>;
  /**
   * Updates a farmed status, which is the status signifying
   *   the status of a farmed route in the context of the relationship
   *   between farmor/farmee. (i.e accepted, declined, cancelled)
   */
  updateFarmedRouteStatus?: Maybe<UpdateFarmedRouteStatusPayload>;
  /** Updates pricing fields on a farmed route. */
  updateFarmedRoutePricing?: Maybe<UpdateFarmedRoutePricingPayload>;
  /** Creates a new operator (and user). */
  createOperator?: Maybe<CreateOperatorPayload>;
  /** Updates Operator's Griddnet ID */
  updateOperatorGnetId?: Maybe<UpdateOperatorGnetIdPayload>;
  /** Updates operator settings */
  updateOperatorSettings?: Maybe<UpdateOperatorSettingsPayload>;
  /** Updates operator trip type settings for customer trip type select */
  updateOperatorTripType?: Maybe<UpdateOperatorTripTypePayload>;
  /** Updates operator settings for review site and id */
  updateOperatorReviewSiteSettings?: Maybe<UpdateOperatorReviewSiteSettingsPayload>;
  /** Updates operator during onboarding */
  updateOnboardingOperator?: Maybe<UpdateOnboardingOperatorPayload>;
  /** When operator selects a getting started goal during onboarding */
  createOperatorGettingStarted?: Maybe<CreateOperatorGettingStartedPayload>;
  /** When operator clicks skip for quote task or remove banner on getting started page */
  updateOperatorGettingStarted?: Maybe<UpdateOperatorGettingStartedPayload>;
  /** accepts an unconfirmed reservation */
  acceptUnconfirmedReservation?: Maybe<AcceptUnconfirmedReservationPayload>;
  /** Create a shuttle trip reservation */
  createShuttleReservation?: Maybe<CreateShuttleReservationPayload>;
  /** Handles request changes */
  updateRequest?: Maybe<UpdateRequestPayload>;
  /** Undo ConfirmRequestFromOperator mutation. */
  undoConvertQuote?: Maybe<UndoConvertQuotePayload>;
  /** Duplicate a reservation */
  createDuplicateReservation?: Maybe<CreateDuplicateReservationPayload>;
  /** Links or unlinks company to request */
  updateRequestCompanyLinking?: Maybe<UpdateRequestCompanyLinkingPayload>;
  /** accepts an unconfirmed reservation */
  declineUnconfirmedReservation?: Maybe<DeclineUnconfirmedReservationPayload>;
  /** Update route information by routeId */
  updateRoute?: Maybe<UpdateRoutePayload>;
  /** Updates dispatch related route/farmedRoute fields */
  updateRouteDispatch?: Maybe<UpdateRouteDispatchPayload>;
  /** Update route pricing information by routeId */
  updateRoutePricing?: Maybe<UpdateRoutePricingPayload>;
  /** Creates a dynamic pricing rule */
  createDynamicPricingRule?: Maybe<CreateDynamicPricingRulePayload>;
  /**
   * Updates a dynamic pricing rule. Due to the complexities of the rule data structure
   *     this mutation rebuilds rules, and therefore it is important to include all applicable data in the
   *     mutation call, not just the fields that are being updated.
   */
  updateDynamicPricingRule?: Maybe<UpdateDynamicPricingRulePayload>;
  /** Updates the active status of a dynamic pricing rule */
  updateDynamicPricingRuleActiveStatus?: Maybe<UpdateDynamicPricingRuleActiveStatusPayload>;
  cancelTrip?: Maybe<CancelTripPayload>;
  reopenTrip?: Maybe<ReopenTripPayload>;
  /** Duplicate a trip */
  createDuplicateTrip?: Maybe<CreateDuplicateTripPayload>;
  createReturnTrip?: Maybe<CreateReturnTripPayload>;
  closeOutTrip?: Maybe<CloseOutTripPayload>;
  undoCloseOutTrip?: Maybe<UndoCloseOutTripPayload>;
  /** Update the text recipients for a trip */
  updateTripTextRecipients?: Maybe<UpdateTripTextRecipientsPayload>;
  /** Update the calendar invites for a trip */
  updateCalendarTripInvite?: Maybe<UpdateCalendarTripInvitePayload>;
  cancelTripV2?: Maybe<CancelTripV2Payload>;
  /** Updates driver payout fields on a route. */
  updateDriverPayout?: Maybe<UpdateDriverPayoutPayload>;
  /** Creates driver payout. This is a grouping of driver payables. */
  createDriverPayout?: Maybe<CreateDriverPayoutPayload>;
  /** Create vehicle */
  createVehicle?: Maybe<CreateVehiclePayload>;
  /** Remove vehicle by vehicleId. */
  removeVehicle?: Maybe<RemoveVehiclePayload>;
  /** Update vehicle */
  updateVehicle?: Maybe<UpdateVehiclePayload>;
  /** Create vehicle */
  createVehicleV2?: Maybe<CreateVehicleV2Payload>;
  /** Update vehicle */
  updateVehicleV2?: Maybe<UpdateVehicleV2Payload>;
  /**
   * Update a vehicle's child seat setting
   * @deprecated use UpdateVehicle instead
   */
  updateVehicleChildSeat?: Maybe<UpdateVehicleChildSeatPayload>;
  /**
   * Update a vehicle's settings
   * @deprecated use UpdateVehicle instead
   */
  updateVehicleSettings?: Maybe<UpdateVehicleSettingsPayload>;
  updateUserSettings?: Maybe<UpdateUserSettingsPayload>;
  /**
   * Sends a message to an existing conversation,
   *     or creates a new conversation if none exists (or existing conversation was closed)
   */
  createMessage?: Maybe<CreateMessagePayload>;
  /** Create company */
  createCompany?: Maybe<CreateCompanyPayload>;
  /** Update Company Information */
  updateCompany?: Maybe<UpdateCompanyPayload>;
  /** Remove company by companyId. */
  removeCompany?: Maybe<RemoveCompanyPayload>;
  /** Create user and email invite */
  createUserAndSendInvite?: Maybe<CreateUserAndSendInvitePayload>;
  /** Join an existing team. Requires a pending invite. */
  joinTeam?: Maybe<JoinTeamPayload>;
  /** Update User Information */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Remove user by userId. */
  removeUser?: Maybe<RemoveUserPayload>;
  /** Update Onboarding User Information */
  updateOnboardingUser?: Maybe<UpdateOnboardingUserPayload>;
  /** Registers a quote in sales automation */
  registerSalesAutomation?: Maybe<RegisterSalesAutomationPayload>;
  /** Installs a specified Moovs Market app for an operator */
  installMoovsMarketApp?: Maybe<InstallMoovsMarketAppPayload>;
  /** Deletes a specified Moovs Market app for an operator */
  deleteMoovsMarketApp?: Maybe<DeleteMoovsMarketAppPayload>;
  /** Update a tracked flight record */
  updateTrackedFlight?: Maybe<UpdateTrackedFlightPayload>;
  /** Reorders stop location/airport data. Rest of info stays the same. */
  updateStopOrder?: Maybe<UpdateStopOrderPayload>;
  /** creates an internal comment on a farmed route, for only the farmee to see. */
  createFarmedRouteComment?: Maybe<CreateFarmedRouteCommentPayload>;
  /** creates an internal comment on a trip. */
  createTripComment?: Maybe<CreateTripCommentPayload>;
  /** creates an internal comment on a vehicle. */
  createVehicleComment?: Maybe<CreateVehicleCommentPayload>;
  /** creates an internal comment on a contact. */
  createContactComment?: Maybe<CreateContactCommentPayload>;
  /** creates a shuttle route for a company. */
  createShuttleRoute?: Maybe<CreateShuttleRoutePayload>;
  createPromoCode?: Maybe<CreatePromoCodePayload>;
  /** Remove promoCode by promoCodeId. */
  removePromoCode?: Maybe<RemovePromoCodePayload>;
  /** Mutation to update promo code */
  updatePromoCode?: Maybe<UpdatePromoCodePayload>;
  /** Create user access permission */
  createUserAccessPermission?: Maybe<CreateUserAccessPermissionPayload>;
  /** Remove user access permission */
  removeUserAccessPermission?: Maybe<RemoveUserAccessPermissionPayload>;
  /** Create linked passenger */
  createLinkedPassenger?: Maybe<CreateLinkedPassengerPayload>;
  /** Remove linked passenger */
  removeLinkedPassenger?: Maybe<RemoveLinkedPassengerPayload>;
  /** Create a request level payment for the routes and amounts specified */
  createRequestPayment?: Maybe<CreateRequestPaymentPayload>;
  /** Creates a payment for a single trip */
  createTripPayment?: Maybe<CreateTripPaymentPayload>;
  /** Create Stripe account with onboarding */
  createOnboardingStripeAccount?: Maybe<CreateOnboardingStripeAccountPayload>;
  createRefund?: Maybe<CreateRefundPayload>;
  /** Creates a payment method attached to the farm link of the provided farm affiliate */
  createPaymentMethodForFarmAffiliate?: Maybe<CreatePaymentMethodForFarmAffiliatePayload>;
  /** Creates a payment method attached to the contact */
  createPaymentMethodForContact?: Maybe<CreatePaymentMethodForContactPayload>;
  /** Creates a payment method attached to the company */
  createPaymentMethodForCompany?: Maybe<CreatePaymentMethodForCompanyPayload>;
  /** Remove payment method based on card information */
  removePaymentMethod?: Maybe<RemovePaymentMethodPayload>;
  /** Update payment method based on card information */
  updatePaymentMethod?: Maybe<UpdatePaymentMethodPayload>;
  /** Sets the input payment method as the only primary payment method. */
  updatePrimaryPaymentMethod?: Maybe<UpdatePrimaryPaymentMethodPayload>;
  /** Create a payment calculated from its individual route subpayments */
  createInvoicePayment?: Maybe<CreateInvoicePaymentPayload>;
  /** Remove bank account based on bank account Id. */
  removeStripeBankAccount?: Maybe<RemoveStripeBankAccountPayload>;
  /** Create Stripe external account for bank account and link to Stripe account */
  createStripeExternalAccount?: Maybe<CreateStripeExternalAccountPayload>;
  /** Create link to Stripe Connect Onboarding/Updating */
  createStripeAccountLink?: Maybe<CreateStripeAccountLinkPayload>;
  /** Update bank account to be default currency */
  updateDefaultBankAccount?: Maybe<UpdateDefaultBankAccountPayload>;
  /** Initiates an authorization charge for a card */
  createAuthorizationPayment?: Maybe<CreateAuthorizationPaymentPayload>;
  /** Allows a connected account with treasury enabled to switch to instant payouts */
  updatePayoutSchedule?: Maybe<UpdatePayoutSchedulePayload>;
  createInstantPayout?: Maybe<CreateInstantPayoutPayload>;
  /** Link a payment method to a linked passenger */
  linkPaymentMethod?: Maybe<LinkPaymentMethodPayload>;
  /** Unlink a payment method from a linked passenger */
  unlinkPaymentMethod?: Maybe<UnlinkPaymentMethodPayload>;
  /**
   * Send text to driver letting them know they were assigned a ride.
   *     Separate mutation from assigning driver so that failure
   *     on assigning due to message does not cause failure to assign.
   * @deprecated use sendDriverAssignTextOrNotification
   */
  sendDriverAssignText?: Maybe<SendDriverAssignTextPayload>;
  /** Operator can send reservation, price summary, invoice, or custom email towards To or/and CC recipients */
  sendEmailToMultipleCustomers?: Maybe<SendEmailToMultipleCustomersPayload>;
  /** Resend email invite to user */
  resendInvite?: Maybe<ResendInvitePayload>;
  /** Send a notification to driver letting them know they were assigned a ride. */
  sendDriverAssignTextOrNotification?: Maybe<SendDriverAssignTextOrNotificationPayload>;
  /** Resend email invite to user */
  resendInviteV2?: Maybe<ResendInviteV2Payload>;
  /** Operator can send reservation, price summary, invoice, or custom email towards To or/and CC recipients */
  sendEmailToMultipleCustomersV2?: Maybe<SendEmailToMultipleCustomersV2Payload>;
  /** Send a notification to driver letting them know they were assigned a ride. */
  sendDriverAssignTextOrNotificationV2?: Maybe<SendDriverAssignTextOrNotificationV2Payload>;
  /** Disconnects google calendar authorization from operator */
  disconnectGoogleCalendarAuth?: Maybe<DisconnectGoogleCalendarAuthPayload>;
  /** Operator subscribing to a plan */
  createLagoCustomerAndAttachPaymentMethod?: Maybe<CreateLagoCustomerAndAttachPaymentMethodPayload>;
  /** Operator adding payment method for subscription billing */
  createSubscriptionPaymentMethod?: Maybe<CreateSubscriptionPaymentMethodPayload>;
  /** Sets payment method as the only primary payment method for subscription billing */
  updatePrimarySubscriptionPaymentMethod?: Maybe<UpdatePrimarySubscriptionPaymentMethodPayload>;
  /** Removes given payment method for subscription billing */
  removeSubscriptionPaymentMethod?: Maybe<RemoveSubscriptionPaymentMethodPayload>;
  /** Operator upgrading an existing plan */
  upgradeSubscriptionPlan?: Maybe<UpgradeSubscriptionPlanPayload>;
};


export type MutationEnableAutomatedEmailsArgs = {
  input: EnableAutomatedEmailsInput;
};


export type MutationDisableAutomatedEmailsArgs = {
  input: DisableAutomatedEmailsInput;
};


export type MutationCreateRecentSearchArgs = {
  input: CreateRecentSearchInput;
};


export type MutationUpdateGriddnetOperatorNoteArgs = {
  input: UpdateGriddnetOperatorNoteInput;
};


export type MutationCreateInsurancePolicyArgs = {
  input: CreateInsurancePolicyInput;
};


export type MutationUpdateInsurancePolicyArgs = {
  input: UpdateInsurancePolicyInput;
};


export type MutationRemoveInsurancePolicyArgs = {
  input: RemoveInsurancePolicyInput;
};


export type MutationCreateInsuranceAttachmentArgs = {
  input: CreateInsuranceAttachmentInput;
};


export type MutationRemoveInsuranceAttachmentArgs = {
  input: RemoveInsuranceAttachmentInput;
};


export type MutationSendInvoiceEmailToCustomerArgs = {
  input: SendInvoiceEmailToCustomerInput;
};


export type MutationEnableMoovsNetworkArgs = {
  input: EnableMoovsNetworkInput;
};


export type MutationUpdateOperatorArgs = {
  input: UpdateOperatorInput;
};


export type MutationUpdateNameSlugArgs = {
  input: UpdateNameSlugInput;
};


export type MutationCreateSendgridCustomDomainArgs = {
  input: CreateSendgridCustomDomainInput;
};


export type MutationRemoveSendgridCustomDomainArgs = {
  input: RemoveSendgridCustomDomainInput;
};


export type MutationValidateSendgridCustomDomainArgs = {
  input: ValidateSendgridCustomDomainInput;
};


export type MutationEnableInboundTextNotificationsArgs = {
  input: EnableInboundTextNotificationsInput;
};


export type MutationDisableInboundTextNotificationsArgs = {
  input: DisableInboundTextNotificationsInput;
};


export type MutationEnableInboundEmailNotificationsArgs = {
  input: EnableInboundEmailNotificationsInput;
};


export type MutationDisableInboundEmailNotificationsArgs = {
  input: DisableInboundEmailNotificationsInput;
};


export type MutationEnableCreditCardWhenBookingArgs = {
  input: EnableCreditCardWhenBookingInput;
};


export type MutationDisableCreditCardWhenBookingArgs = {
  input: DisableCreditCardWhenBookingInput;
};


export type MutationEnableDriverStatusTextNotificationsArgs = {
  input: EnableDriverStatusTextNotificationsInput;
};


export type MutationDisableDriverStatusTextNotificationsArgs = {
  input: DisableDriverStatusTextNotificationsInput;
};


export type MutationToggleDriverAppAmountDueDisplayArgs = {
  input: ToggleDriverAppAmountDueDisplayInput;
};


export type MutationUpdateBookingCutoffTimeArgs = {
  input: UpdateBookingCutoffTimeInput;
};


export type MutationEnableGratuityWhenBookingArgs = {
  input: EnableGratuityWhenBookingInput;
};


export type MutationDisableGratuityWhenBookingArgs = {
  input: DisableGratuityWhenBookingInput;
};


export type MutationEnableRequireConsentWhenBookingArgs = {
  input: EnableRequireConsentWhenBookingInput;
};


export type MutationDisableRequireConsentWhenBookingArgs = {
  input: DisableRequireConsentWhenBookingInput;
};


export type MutationEnableTripReminderTextNotificationsArgs = {
  input: EnableTripReminderTextNotificationsInput;
};


export type MutationDisableTripReminderTextNotificationsArgs = {
  input: DisableTripReminderTextNotificationsInput;
};


export type MutationEnablePocketflowsCrmArgs = {
  input: EnablePocketflowsCrmInput;
};


export type MutationGetPocketflowsEmbedIdArgs = {
  input: GetPocketflowsEmbedIdInput;
};


export type MutationUpdateOperatorRouteArgs = {
  input: UpdateOperatorRouteInput;
};


export type MutationCreateOperatorOrderTypeArgs = {
  input: CreateOperatorOrderTypeInput;
};


export type MutationRemoveOperatorOrderTypeArgs = {
  input: RemoveOperatorOrderTypeInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationCreatePriceZoneArgs = {
  input: CreatePriceZoneInput;
};


export type MutationUpdatePriceZoneArgs = {
  input: UpdatePriceZoneInput;
};


export type MutationRemovePriceZoneArgs = {
  input: RemovePriceZoneInput;
};


export type MutationUpdatePriceZoneRouteBaseRatesArgs = {
  input: UpdatePriceZoneRouteBaseRatesInput;
};


export type MutationCreateNewRequestArgs = {
  input: CreateNewRequestInput;
};


export type MutationConfirmRequestFromOperatorArgs = {
  input: ConfirmRequestFromOperatorInput;
};


export type MutationSendEmailToCustomerArgs = {
  input: SendEmailToCustomerInput;
};


export type MutationRemoveVehicleFromRouteArgs = {
  input: RemoveVehicleFromRouteInput;
};


export type MutationCreateStopArgs = {
  input: CreateStopInput;
};


export type MutationUpdateStopArgs = {
  input: UpdateStopInput;
};


export type MutationRemoveStopArgs = {
  input: RemoveStopInput;
};


export type MutationCreateTermArgs = {
  input: CreateTermInput;
};


export type MutationUpdateTermArgs = {
  input: UpdateTermInput;
};


export type MutationRemoveTermArgs = {
  input: RemoveTermInput;
};


export type MutationCreateTripArgs = {
  input: CreateTripInput;
};


export type MutationUpdateTripArgs = {
  input: UpdateTripInput;
};


export type MutationRemoveTripArgs = {
  input: RemoveTripInput;
};


export type MutationUndoRemoveTripArgs = {
  input: UndoRemoveTripInput;
};


export type MutationPortTwilioNumberArgs = {
  input: PortTwilioNumberInput;
};


export type MutationProvisionPhoneNumberTwilioArgs = {
  input: ProvisionPhoneNumberTwilioInput;
};


export type MutationEnableAutomatedTextsArgs = {
  input: EnableAutomatedTextsInput;
};


export type MutationDisableAutomatedTextsArgs = {
  input: DisableAutomatedTextsInput;
};


export type MutationCreateVehicleFeatureArgs = {
  input: CreateVehicleFeatureInput;
};


export type MutationRemoveVehicleFeatureArgs = {
  input: RemoveVehicleFeatureInput;
};


export type MutationCreateVehiclePhotoArgs = {
  input: CreateVehiclePhotoInput;
};


export type MutationUpdateVehiclePhotoArgs = {
  input: UpdateVehiclePhotoInput;
};


export type MutationRemoveVehiclePhotoArgs = {
  input: RemoveVehiclePhotoInput;
};


export type MutationUpdateVehiclePhotoOrderArgs = {
  input: UpdateVehiclePhotoOrderInput;
};


export type MutationCreateWebsiteArgs = {
  input: CreateWebsiteInput;
};


export type MutationPublishDudaWebsiteArgs = {
  input: PublishDudaWebsiteInput;
};


export type MutationAddVehicleToDudaWebsiteArgs = {
  input: AddVehicleToDudaWebsiteInput;
};


export type MutationCreateOperatorDudaWebsiteUserArgs = {
  input: CreateOperatorDudaWebsiteUserInput;
};


export type MutationGetOperatorDudaWebsiteUserArgs = {
  input: GetOperatorDudaWebsiteUserInput;
};


export type MutationGenerateSsoLinkToDudaWebsiteEditorArgs = {
  input: GenerateSsoLinkToDudaWebsiteEditorInput;
};


export type MutationSetBasicOperatorDudaWebsiteUserPermissionsArgs = {
  input: SetBasicOperatorDudaWebsiteUserPermissionsInput;
};


export type MutationSetPremiumOperatorDudaWebsiteUserPermissionsArgs = {
  input: SetPremiumOperatorDudaWebsiteUserPermissionsInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationRemoveContactArgs = {
  input: RemoveContactInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationCreateCancellationPolicyArgs = {
  input: CreateCancellationPolicyInput;
};


export type MutationCreateCancellationPolicyV2Args = {
  input: CreateCancellationPolicyV2Input;
};


export type MutationUpdateCancellationPolicyArgs = {
  input: UpdateCancellationPolicyInput;
};


export type MutationRemoveCancellationPolicyArgs = {
  input: RemoveCancellationPolicyInput;
};


export type MutationCreateDriverArgs = {
  input: CreateDriverInput;
};


export type MutationUpdateDriverArgs = {
  input: UpdateDriverInput;
};


export type MutationRemoveDriverArgs = {
  input: RemoveDriverInput;
};


export type MutationCreateOnboardingDriverArgs = {
  input: CreateOnboardingDriverInput;
};


export type MutationSendRequestAffiliateEmailArgs = {
  input: SendRequestAffiliateEmailInput;
};


export type MutationCreateExternalOperatorArgs = {
  input: CreateExternalOperatorInput;
};


export type MutationRemoveExternalOperatorArgs = {
  input: RemoveExternalOperatorInput;
};


export type MutationUpdateExternalOperatorArgs = {
  input: UpdateExternalOperatorInput;
};


export type MutationLinkExternalOperatorDriverArgs = {
  input: LinkExternalOperatorDriverInput;
};


export type MutationUnlinkExternalOperatorDriverArgs = {
  input: UnlinkExternalOperatorDriverInput;
};


export type MutationLinkExternalOperatorVehicleArgs = {
  input: LinkExternalOperatorVehicleInput;
};


export type MutationUnlinkExternalOperatorVehicleArgs = {
  input: UnlinkExternalOperatorVehicleInput;
};


export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};


export type MutationRemoveInvoiceArgs = {
  input: RemoveInvoiceInput;
};


export type MutationCreateFarmedRouteArgs = {
  input: CreateFarmedRouteInput;
};


export type MutationUpdateFarmedRouteStatusArgs = {
  input: UpdateFarmedRouteStatusInput;
};


export type MutationUpdateFarmedRoutePricingArgs = {
  input: UpdateFarmedRoutePricingInput;
};


export type MutationCreateOperatorArgs = {
  input: CreateOperatorInput;
};


export type MutationUpdateOperatorGnetIdArgs = {
  input: UpdateOperatorGnetIdInput;
};


export type MutationUpdateOperatorSettingsArgs = {
  input: UpdateOperatorSettingsInput;
};


export type MutationUpdateOperatorTripTypeArgs = {
  input: UpdateOperatorTripTypeInput;
};


export type MutationUpdateOperatorReviewSiteSettingsArgs = {
  input: UpdateOperatorReviewSiteSettingsInput;
};


export type MutationUpdateOnboardingOperatorArgs = {
  input: UpdateOnboardingOperatorInput;
};


export type MutationCreateOperatorGettingStartedArgs = {
  input: CreateOperatorGettingStartedInput;
};


export type MutationUpdateOperatorGettingStartedArgs = {
  input: UpdateOperatorGettingStartedInput;
};


export type MutationAcceptUnconfirmedReservationArgs = {
  input: AcceptUnconfirmedReservationInput;
};


export type MutationCreateShuttleReservationArgs = {
  input: CreateShuttleReservationInput;
};


export type MutationUpdateRequestArgs = {
  input: UpdateRequestInput;
};


export type MutationUndoConvertQuoteArgs = {
  input: UndoConvertQuoteInput;
};


export type MutationCreateDuplicateReservationArgs = {
  input: CreateDuplicateReservationInput;
};


export type MutationUpdateRequestCompanyLinkingArgs = {
  input: UpdateRequestCompanyLinkingInput;
};


export type MutationDeclineUnconfirmedReservationArgs = {
  input: DeclineUnconfirmedReservationInput;
};


export type MutationUpdateRouteArgs = {
  input: UpdateRouteInput;
};


export type MutationUpdateRouteDispatchArgs = {
  input: UpdateRouteDispatchInput;
};


export type MutationUpdateRoutePricingArgs = {
  input: UpdateRoutePricingInput;
};


export type MutationCreateDynamicPricingRuleArgs = {
  input: CreateDynamicPricingRuleInput;
};


export type MutationUpdateDynamicPricingRuleArgs = {
  input: UpdateDynamicPricingRuleInput;
};


export type MutationUpdateDynamicPricingRuleActiveStatusArgs = {
  input: UpdateDynamicPricingRuleActiveStatusInput;
};


export type MutationCancelTripArgs = {
  input: CancelTripInput;
};


export type MutationReopenTripArgs = {
  input: ReopenTripInput;
};


export type MutationCreateDuplicateTripArgs = {
  input: CreateDuplicateTripInput;
};


export type MutationCreateReturnTripArgs = {
  input: CreateReturnTripInput;
};


export type MutationCloseOutTripArgs = {
  input: CloseOutTripInput;
};


export type MutationUndoCloseOutTripArgs = {
  input: UndoCloseOutTripInput;
};


export type MutationUpdateTripTextRecipientsArgs = {
  input: UpdateTripTextRecipientsInput;
};


export type MutationUpdateCalendarTripInviteArgs = {
  input: UpdateCalendarTripInviteInput;
};


export type MutationCancelTripV2Args = {
  input: CancelTripV2Input;
};


export type MutationUpdateDriverPayoutArgs = {
  input: UpdateDriverPayoutInput;
};


export type MutationCreateDriverPayoutArgs = {
  input: CreateDriverPayoutInput;
};


export type MutationCreateVehicleArgs = {
  input: CreateVehicleInput;
};


export type MutationRemoveVehicleArgs = {
  input: RemoveVehicleInput;
};


export type MutationUpdateVehicleArgs = {
  input: UpdateVehicleInput;
};


export type MutationCreateVehicleV2Args = {
  input: CreateVehicleV2Input;
};


export type MutationUpdateVehicleV2Args = {
  input: UpdateVehicleV2Input;
};


export type MutationUpdateVehicleChildSeatArgs = {
  input: UpdateVehicleChildSeatInput;
};


export type MutationUpdateVehicleSettingsArgs = {
  input: UpdateVehicleSettingsInput;
};


export type MutationUpdateUserSettingsArgs = {
  input: UpdateUserSettingsInput;
};


export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


export type MutationRemoveCompanyArgs = {
  input: RemoveCompanyInput;
};


export type MutationCreateUserAndSendInviteArgs = {
  input: CreateUserAndSendInviteInput;
};


export type MutationJoinTeamArgs = {
  input: JoinTeamInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


export type MutationUpdateOnboardingUserArgs = {
  input: UpdateOnboardingUserInput;
};


export type MutationRegisterSalesAutomationArgs = {
  input: RegisterSalesAutomationInput;
};


export type MutationInstallMoovsMarketAppArgs = {
  input: InstallMoovsMarketAppInput;
};


export type MutationDeleteMoovsMarketAppArgs = {
  input: DeleteMoovsMarketAppInput;
};


export type MutationUpdateTrackedFlightArgs = {
  input: UpdateTrackedFlightInput;
};


export type MutationUpdateStopOrderArgs = {
  input: UpdateStopOrderInput;
};


export type MutationCreateFarmedRouteCommentArgs = {
  input: CreateFarmedRouteCommentInput;
};


export type MutationCreateTripCommentArgs = {
  input: CreateTripCommentInput;
};


export type MutationCreateVehicleCommentArgs = {
  input: CreateVehicleCommentInput;
};


export type MutationCreateContactCommentArgs = {
  input: CreateContactCommentInput;
};


export type MutationCreateShuttleRouteArgs = {
  input: CreateShuttleRouteInput;
};


export type MutationCreatePromoCodeArgs = {
  input: CreatePromoCodeInput;
};


export type MutationRemovePromoCodeArgs = {
  input: RemovePromoCodeInput;
};


export type MutationUpdatePromoCodeArgs = {
  input: UpdatePromoCodeInput;
};


export type MutationCreateUserAccessPermissionArgs = {
  input: CreateUserAccessPermissionInput;
};


export type MutationRemoveUserAccessPermissionArgs = {
  input: RemoveUserAccessPermissionInput;
};


export type MutationCreateLinkedPassengerArgs = {
  input: CreateLinkedPassengerInput;
};


export type MutationRemoveLinkedPassengerArgs = {
  input: RemoveLinkedPassengerInput;
};


export type MutationCreateRequestPaymentArgs = {
  input: CreateRequestPaymentInput;
};


export type MutationCreateTripPaymentArgs = {
  input: CreateTripPaymentInput;
};


export type MutationCreateOnboardingStripeAccountArgs = {
  input: CreateOnboardingStripeAccountInput;
};


export type MutationCreateRefundArgs = {
  input: CreateRefundInput;
};


export type MutationCreatePaymentMethodForFarmAffiliateArgs = {
  input: CreatePaymentMethodForFarmAffiliateInput;
};


export type MutationCreatePaymentMethodForContactArgs = {
  input: CreatePaymentMethodForContactInput;
};


export type MutationCreatePaymentMethodForCompanyArgs = {
  input: CreatePaymentMethodForCompanyInput;
};


export type MutationRemovePaymentMethodArgs = {
  input: RemovePaymentMethodInput;
};


export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};


export type MutationUpdatePrimaryPaymentMethodArgs = {
  input: UpdatePrimaryPaymentMethodInput;
};


export type MutationCreateInvoicePaymentArgs = {
  input: CreateInvoicePaymentInput;
};


export type MutationRemoveStripeBankAccountArgs = {
  input: RemoveStripeBankAccountInput;
};


export type MutationCreateStripeExternalAccountArgs = {
  input: CreateStripeExternalAccountInput;
};


export type MutationCreateStripeAccountLinkArgs = {
  input: CreateStripeAccountLinkInput;
};


export type MutationUpdateDefaultBankAccountArgs = {
  input: UpdateDefaultBankAccountInput;
};


export type MutationCreateAuthorizationPaymentArgs = {
  input: CreateAuthorizationPaymentInput;
};


export type MutationUpdatePayoutScheduleArgs = {
  input: UpdatePayoutScheduleInput;
};


export type MutationCreateInstantPayoutArgs = {
  input: CreateInstantPayoutInput;
};


export type MutationLinkPaymentMethodArgs = {
  input: LinkPaymentMethodInput;
};


export type MutationUnlinkPaymentMethodArgs = {
  input: UnlinkPaymentMethodInput;
};


export type MutationSendDriverAssignTextArgs = {
  input: SendDriverAssignTextInput;
};


export type MutationSendEmailToMultipleCustomersArgs = {
  input: SendEmailToMultipleCustomersInput;
};


export type MutationResendInviteArgs = {
  input: ResendInviteInput;
};


export type MutationSendDriverAssignTextOrNotificationArgs = {
  input: SendDriverAssignTextOrNotificationInput;
};


export type MutationResendInviteV2Args = {
  input: ResendInviteV2Input;
};


export type MutationSendEmailToMultipleCustomersV2Args = {
  input: SendEmailToMultipleCustomersV2Input;
};


export type MutationSendDriverAssignTextOrNotificationV2Args = {
  input: SendDriverAssignTextOrNotificationV2Input;
};


export type MutationDisconnectGoogleCalendarAuthArgs = {
  input: DisconnectGoogleCalendarAuthInput;
};


export type MutationCreateLagoCustomerAndAttachPaymentMethodArgs = {
  input: CreateLagoCustomerAndAttachPaymentMethodInput;
};


export type MutationCreateSubscriptionPaymentMethodArgs = {
  input: CreateSubscriptionPaymentMethodInput;
};


export type MutationUpdatePrimarySubscriptionPaymentMethodArgs = {
  input: UpdatePrimarySubscriptionPaymentMethodInput;
};


export type MutationRemoveSubscriptionPaymentMethodArgs = {
  input: RemoveSubscriptionPaymentMethodInput;
};


export type MutationUpgradeSubscriptionPlanArgs = {
  input: UpgradeSubscriptionPlanInput;
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'];
};

export type Notification = {
  __typename?: 'Notification';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The ID of an object */
  notificationRecipientId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: Scalars['String'];
  metadata: MetadataUnion;
};

/** Used to filter open or closed dispatch trips */
export enum OpenClosedTrip {
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export type Operator = Node & {
  __typename?: 'Operator';
  /** The ID of an object */
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  generalEmail: Scalars['String'];
  voicePhoneNumber?: Maybe<Scalars['String']>;
  voicePhoneNumberInternational?: Maybe<Scalars['String']>;
  voicePhoneCountryCode: Scalars['String'];
  voicePhoneCountryDialCode: Scalars['String'];
  voicePhoneCountryName: Scalars['String'];
  voicePhoneCountryFormat: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  permitNumber?: Maybe<Scalars['String']>;
  bookingEmail?: Maybe<Scalars['String']>;
  /** @deprecated no longer used */
  textPhoneNumber?: Maybe<Scalars['String']>;
  widgetBannerUrl?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['JSONObject']>;
  users: Array<Maybe<User>>;
  automatedEmailsEnabled: Scalars['Boolean'];
  nameSlug?: Maybe<Scalars['String']>;
  stripeAccount?: Maybe<StripeAccount>;
  stripeAccountId?: Maybe<Scalars['String']>;
  twilioPhoneNumber?: Maybe<Scalars['JSON']>;
  driverReminderTripReminderTextsEnabled?: Maybe<Scalars['Boolean']>;
  driverStatusUpdateTextsEnabled?: Maybe<Scalars['Boolean']>;
  driverAppAmountDueEnabled: Scalars['Boolean'];
  /** @deprecated Trip reminder can now be sent to either passenger or booking contact, or both. Use operatorSettings.tripReminderTextTo instead. */
  tripReminderTextsEnabled?: Maybe<Scalars['Boolean']>;
  inboundEmailNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  inboundTextNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  tripCancellationEmailEnabled?: Maybe<Scalars['Boolean']>;
  customDomain?: Maybe<Scalars['String']>;
  customDomainFromName?: Maybe<Scalars['String']>;
  customDomainIsValid?: Maybe<Scalars['Boolean']>;
  customDomainSendgridData?: Maybe<Scalars['JSON']>;
  enableCreditCardWhenBooking?: Maybe<Scalars['Boolean']>;
  enabledOrderTypes: Array<Maybe<OrderType>>;
  /** @deprecated use enabledTripCategories instead. which is nicer b/c it just returns the trip category directly instead of the entire object */
  enabledTripTypes: Array<Maybe<OperatorTripType>>;
  enabledTripCategories: Array<Maybe<TripCategory>>;
  dudaWebsite?: Maybe<DudaWebsite>;
  /** @deprecated removed website user */
  isMoovsAllInOne?: Maybe<Scalars['Boolean']>;
  /**
   * activeForAnalytics field is used to determine whether an operator is paying for a full package (true) or farming
   *     account (false). This field is set by Product team to upgrade an account, so false or null entries indicate Moovs Lite.
   */
  isMoovsLiteOperator?: Maybe<Scalars['Boolean']>;
  bookingCutoffTimeValue: Scalars['Float'];
  bookingCutoffTimeType: TimeDuration;
  gratuityWhenBooking?: Maybe<Scalars['Boolean']>;
  moovsNetworkEnabled?: Maybe<Scalars['Boolean']>;
  gnetId?: Maybe<Scalars['String']>;
  driverReminderPendingJobsTextEnabled?: Maybe<Scalars['Boolean']>;
  driverReminderUpdateTripStatusTextEnabled?: Maybe<Scalars['Boolean']>;
  googleTrackingId?: Maybe<Scalars['String']>;
  /** Custom presets values for driver gratuity selector (e.g. 10%, 15%, 20%). Always returned in ascending order */
  driverGratuityPresets?: Maybe<Array<Maybe<Scalars['Int']>>>;
  customGratuityWhenBooking: Scalars['Boolean'];
  cashGratuityWhenBooking: Scalars['Boolean'];
  moovsChatNumberAsPrimary: Scalars['Boolean'];
  settings: OperatorSettings;
  moovsMarketApps: MoovsMarketApps;
  plan?: Maybe<PlanVariant>;
  onboardingCompleted: Scalars['Boolean'];
  pricingLayout?: Maybe<PricingLayout>;
  requireConsentWhenBooking?: Maybe<Scalars['Boolean']>;
  lagoPlanCode?: Maybe<LagoPlanCode>;
  lagoPaymentMethod: Array<Maybe<PaymentMethod>>;
  gettingStarted?: Maybe<OperatorGettingStarted>;
  pocketflowsBusinessId?: Maybe<Scalars['String']>;
};

export type OperatorGettingStarted = {
  __typename?: 'OperatorGettingStarted';
  /** The ID of an object */
  id: Scalars['ID'];
  goal: GettingStartedGoal;
  bannerRemovedAt?: Maybe<Scalars['DateTime']>;
  taskFirstVehicleCompletedAt?: Maybe<Scalars['DateTime']>;
  taskFirstDriverCompletedAt?: Maybe<Scalars['DateTime']>;
  taskFirstQuoteCompletedAt?: Maybe<Scalars['DateTime']>;
  taskFirstReservationCompletedAt?: Maybe<Scalars['DateTime']>;
  taskMoovsPaymentAccountCompletedAt?: Maybe<Scalars['DateTime']>;
  tasksRemaining?: Maybe<Scalars['Int']>;
};

export type OperatorRoute = Node & {
  __typename?: 'OperatorRoute';
  /** The ID of an object */
  id: Scalars['ID'];
  /** @deprecated Not yet deprecated - aim to use completeOrderNumber in the future */
  orderNumber?: Maybe<Scalars['String']>;
  completeOrderNumber?: Maybe<Scalars['String']>;
  operatorConfirmation?: Maybe<Scalars['String']>;
  /** @deprecated use routeDriver instead */
  driver?: Maybe<Driver>;
  /** driver type w/ availability for route */
  routeDriver?: Maybe<RouteDriver>;
  driverStatus?: Maybe<DriverStatus>;
  trip: Trip;
  request: Request;
  vehicle?: Maybe<Vehicle>;
  operator?: Maybe<Operator>;
  statusSlug: Scalars['String'];
  driverNote?: Maybe<Scalars['String']>;
  operatorNote?: Maybe<Scalars['String']>;
  /** @deprecated Use note in future */
  swoopNote?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  alert: Alert;
  driverCloseoutStartDateTime?: Maybe<Scalars['DateTime']>;
  driverCloseoutEndDateTime?: Maybe<Scalars['DateTime']>;
  driverCloseoutDurationMinutes?: Maybe<Scalars['Int']>;
  /** Feedback from driver after completion of ride. */
  driverCloseoutNote?: Maybe<Scalars['String']>;
  operatorCloseoutStartDateTime?: Maybe<Scalars['DateTime']>;
  operatorCloseoutEndDateTime?: Maybe<Scalars['DateTime']>;
  operatorCloseoutDurationMinutes?: Maybe<Scalars['Int']>;
  operatorCloseoutNote?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  gratuityAmt?: Maybe<Scalars['Float']>;
  flatRateAmt?: Maybe<Scalars['Float']>;
  hourlyAmt?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
  amountDue?: Maybe<Scalars['Float']>;
  isFarmedRoute: Scalars['Boolean'];
  farmeeCloseoutStartDateTime?: Maybe<Scalars['DateTime']>;
  farmeeCloseoutEndDateTime?: Maybe<Scalars['DateTime']>;
  farmeeCloseoutDurationMinutes?: Maybe<Scalars['Int']>;
  farmeeCloseoutNote?: Maybe<Scalars['String']>;
  farmRelationship?: Maybe<FarmRelationshipEnum>;
  /** The Farm Affiliate tied to the route. If Farmee, shows the farmor. If Farmor, shows the farmee. */
  farmAffiliate?: Maybe<FarmAffiliate>;
};

/** A connection to a list of items. */
export type OperatorRoutesConnection = {
  __typename?: 'OperatorRoutesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OperatorRoutesEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OperatorRoutesEdge = {
  __typename?: 'OperatorRoutesEdge';
  /** The item at the end of the edge */
  node?: Maybe<OperatorRoute>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type OperatorSettings = {
  __typename?: 'OperatorSettings';
  /** The ID of an object */
  id: Scalars['ID'];
  luggageEnabled?: Maybe<Scalars['Boolean']>;
  stopPrice?: Maybe<Scalars['Int']>;
  dynamicPricingRulesCombineEnabled?: Maybe<Scalars['Boolean']>;
  driverFlightUpdatedTextsEnabled: Scalars['Boolean'];
  internalFlightUpdatedTextsEnabled: Scalars['Boolean'];
  pricingLayout?: Maybe<PricingLayout>;
  colorTheme: ColorThemeVariant;
  requireConsentWhenBooking: Scalars['Boolean'];
  requireSignatureWhenBooking: Scalars['Boolean'];
  reviewSite?: Maybe<ReviewSite>;
  reviewSiteUrlPrefix?: Maybe<Scalars['String']>;
  reviewSiteUrlSuffix?: Maybe<Scalars['String']>;
  reviewSiteId?: Maybe<Scalars['String']>;
  reviewSiteTextIncludeLink: Scalars['Boolean'];
  widgetCta: WidgetCta;
  autoPaymentPreference: AutoPaymentPreference;
  autoPaymentDeposit: AutoPaymentDeposit;
  customerChangeAutomationEnabled: Scalars['Boolean'];
  customerChangeAutomationTimeValue: Scalars['Int'];
  customerChangeAutomationTimeType: TimeDuration;
  shuttleLateTextEnabled?: Maybe<Scalars['Boolean']>;
  memberChangedStatusTextEnabled: Scalars['Boolean'];
  billingDetailsNotRequired: Scalars['Boolean'];
  promoCodes: Array<Maybe<PromoCode>>;
  hasActivePromoCodes: Scalars['Boolean'];
  skipVehicleSelectionEnabled: Scalars['Boolean'];
  skipVehicleDefaultVehicleId?: Maybe<Scalars['String']>;
  skipVehicleDefaultVehicle?: Maybe<Vehicle>;
  driverDeclineJobTextEnabled: Scalars['Boolean'];
  driverDeclineJobEmailEnabled: Scalars['Boolean'];
  /** Used to determine the visibility of the quotes tab in the orders page of the customer portal. */
  customerPortalQuotesVisible: Scalars['Boolean'];
  notifyDriverTripChangeEnabled: Scalars['Boolean'];
  tripReminderTextTo: TextRecipientEnum;
  tripStatusUpdateTextTo: TextRecipientEnum;
  pobStatusTextToBcEnabled?: Maybe<Scalars['Boolean']>;
  customerTripCancellationRequestEnabled: Scalars['Boolean'];
  calendarTripInviteBcEnabled: Scalars['Boolean'];
  calendarTripInvitePassengerEnabled: Scalars['Boolean'];
  isCalendarIntegrationEnabled: Scalars['Boolean'];
  currencyCode: Scalars['String'];
  operatingCountryCode: Scalars['String'];
  distanceUnit: DistanceUnitEnum;
};

export type OperatorTripType = {
  __typename?: 'OperatorTripType';
  tripType: Scalars['String'];
};

export type OrderByPriceZoneInput = {
  zoneName?: Maybe<SortEnum>;
  createdAt?: Maybe<SortEnum>;
};

export type OrderType = {
  __typename?: 'OrderType';
  /** The ID of an object */
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  category: Scalars['String'];
};

export enum OrderTypeEnum {
  Airport = 'AIRPORT',
  AirportDropOff = 'AIRPORT_DROP_OFF',
  AirportPickUp = 'AIRPORT_PICK_UP',
  Anniversary = 'ANNIVERSARY',
  BachelorBachelorette = 'BACHELOR_BACHELORETTE',
  Bar = 'BAR',
  BarBatMitzvah = 'BAR_BAT_MITZVAH',
  Baseball = 'BASEBALL',
  Basketball = 'BASKETBALL',
  Birthday = 'BIRTHDAY',
  Birthday_21 = 'BIRTHDAY_21',
  BrewTour = 'BREW_TOUR',
  BridalParty = 'BRIDAL_PARTY',
  BrideGroom = 'BRIDE_GROOM',
  BusinessTrip = 'BUSINESS_TRIP',
  Concert = 'CONCERT',
  Corporate = 'CORPORATE',
  CorporateEvent = 'CORPORATE_EVENT',
  DayTour = 'DAY_TOUR',
  FamilyReunion = 'FAMILY_REUNION',
  FieldTrip = 'FIELD_TRIP',
  Football = 'FOOTBALL',
  Funeral = 'FUNERAL',
  Golf = 'GOLF',
  Graduation = 'GRADUATION',
  Hockey = 'HOCKEY',
  Holiday = 'HOLIDAY',
  KidsBirthday = 'KIDS_BIRTHDAY',
  Leisure = 'LEISURE',
  Medical = 'MEDICAL',
  NightOut = 'NIGHT_OUT',
  Other = 'OTHER',
  PersonalTrip = 'PERSONAL_TRIP',
  PointToPoint = 'POINT_TO_POINT',
  PromHomecoming = 'PROM_HOMECOMING',
  Quinceanera = 'QUINCEANERA',
  Retail = 'RETAIL',
  School = 'SCHOOL',
  SchoolFundraiser = 'SCHOOL_FUNDRAISER',
  Seaport = 'SEAPORT',
  SpecialOccasion = 'SPECIAL_OCCASION',
  SportingEvent = 'SPORTING_EVENT',
  Sweet_16 = 'SWEET_16',
  TrainStation = 'TRAIN_STATION',
  Wedding = 'WEDDING',
  WineTour = 'WINE_TOUR'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export enum PaidNotPaid {
  NotPaid = 'NOT_PAID',
  Paid = 'PAID'
}

export type Payment = Node & {
  __typename?: 'Payment';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  amount: Scalars['Float'];
  method: PaymentMethodEnum;
  action: PaymentAction;
  paymentPayer: PaymentPayer;
  /** Holds information about the payment type (largely pertaining to stripe information for cards */
  paymentMethod?: Maybe<PaymentMethod>;
  note?: Maybe<Scalars['String']>;
  paymentStatus: PaymentPaymentStatus;
  didPaymentSucceed: Scalars['Boolean'];
  paymentStatusTooltip?: Maybe<Scalars['String']>;
  refunds?: Maybe<Array<Maybe<PaymentRefund>>>;
  stripeBalanceTransactionId?: Maybe<Scalars['String']>;
  stripePayoutDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  paymentIntent?: Maybe<PaymentIntent>;
  contact?: Maybe<Contact>;
  subPayments?: Maybe<Array<Maybe<SubPayment>>>;
  invoice?: Maybe<Invoice>;
};

export enum PaymentAction {
  Charge = 'CHARGE',
  Refund = 'REFUND'
}

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  status?: Maybe<PaymentPaymentStatus>;
  declineCode?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  net?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  riskEvaluation?: Maybe<Scalars['String']>;
  chargeId?: Maybe<Scalars['String']>;
};

export type PaymentMethod = Node & {
  __typename?: 'PaymentMethod';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Stripe's payment method ID */
  stripeId?: Maybe<Scalars['String']>;
  /** @deprecated Moved to new field payerId, because payment methods are no longer limited to contacts */
  contactId?: Maybe<Scalars['ID']>;
  /** The owner attached to the payment method. Currently supports customer (contact) and farm affiliates (griddnetOperator and moovsNetworkOperator). */
  payerId?: Maybe<Scalars['ID']>;
  /** Stripe customer information */
  customer?: Maybe<Scalars['String']>;
  card?: Maybe<CardItem>;
  cardNote?: Maybe<Scalars['String']>;
  billingDetails?: Maybe<BillingDetails>;
  isPrimary: Scalars['Boolean'];
  /** Simplifies stripe's setup intent statuses into one of pending, rejected, or succeeded for the client. */
  stripeStatus: Scalars['String'];
  linkedPassenger?: Maybe<Contact>;
};

export enum PaymentMethodEnum {
  Card = 'CARD',
  Cash = 'CASH',
  Venmo = 'VENMO',
  Check = 'CHECK',
  Wire = 'WIRE',
  GenericCard = 'GENERIC_CARD'
}

export type PaymentMethodTransaction = Payment | PaymentRefund;

export enum PaymentPayer {
  Customer = 'CUSTOMER',
  Farmor = 'FARMOR',
  Authorization = 'AUTHORIZATION'
}

export enum PaymentPaymentStatus {
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED',
  Blocked = 'BLOCKED',
  Full = 'FULL',
  Partial = 'PARTIAL',
  DisputeWon = 'DISPUTE_WON',
  DisputeLost = 'DISPUTE_LOST',
  Disputed = 'DISPUTED',
  EarlyFraudWarning = 'EARLY_FRAUD_WARNING',
  EvidenceInReview = 'EVIDENCE_IN_REVIEW',
  Cancelled = 'CANCELLED',
  Uncaptured = 'UNCAPTURED'
}

export type PaymentRefund = Node & {
  __typename?: 'PaymentRefund';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  amount: Scalars['Float'];
  payment: Payment;
  note?: Maybe<Scalars['String']>;
  action: PaymentAction;
  paymentStatus: PaymentPaymentStatus;
  method?: Maybe<PaymentMethodEnum>;
  stripePayoutDate?: Maybe<Scalars['DateTime']>;
  /** Holds information about the payment type (largely pertaining to stripe information for cards */
  paymentMethod?: Maybe<PaymentMethod>;
  subRefunds?: Maybe<Array<Maybe<SubRefund>>>;
};

export enum PaymentSource {
  Request = 'REQUEST',
  Invoice = 'INVOICE',
  Trip = 'TRIP'
}

export enum PaymentStatus {
  NotPaid = 'NOT_PAID',
  Paid = 'PAID',
  Partial = 'PARTIAL'
}

export enum PayoutSchedule {
  Instant = 'INSTANT',
  Standard = 'STANDARD'
}

export enum PeriodName {
  Week = 'WEEK',
  Day = 'DAY',
  Hour = 'HOUR'
}

export type Permissions = {
  __typename?: 'Permissions';
  canCreateConnectedAccount: Scalars['Boolean'];
};

export type PersonalAvailability = {
  __typename?: 'PersonalAvailability';
  /** The ID of an object */
  id: Scalars['ID'];
  available: Scalars['Boolean'];
};

export enum PickUpVariant {
  MeetGreet = 'MEET_GREET',
  Curbside = 'CURBSIDE'
}

export enum PlanVariant {
  Standard = 'STANDARD',
  Pro = 'PRO',
  Vip = 'VIP',
  Free = 'FREE',
  Ai = 'AI'
}

export type PortTwilioNumberInput = {
  twilioNumberData: Scalars['JSON'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PortTwilioNumberPayload = {
  __typename?: 'PortTwilioNumberPayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Fetch a potential driver payout, which includes list of Driver Payables */
export type PotentialDriverPayout = {
  __typename?: 'PotentialDriverPayout';
  flatRateAmt: Scalars['Float'];
  gratuityAmt: Scalars['Float'];
  hourlyAmt: Scalars['Float'];
  totalAmt: Scalars['Float'];
  driverPayables: Array<Maybe<DriverPayable>>;
  driversCount: Scalars['Int'];
  routesCount: Scalars['Int'];
};

export type PriceZone = Node & {
  __typename?: 'PriceZone';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  zoneName?: Maybe<Scalars['String']>;
  geoJson?: Maybe<Scalars['JSONObject']>;
  /**
   * The fields that exist specificially for a zone variant,
   *         as well as a field 'variant' that specifies which zone variant the zone is.
   */
  zoneVariantData: ZoneVariantData;
  /** Price zone route where current price zone is both the pickup and dropoff zone */
  routeWithinZone: PriceZoneRoute;
};

export type PriceZoneRoute = Node & {
  __typename?: 'PriceZoneRoute';
  /** The ID of an object */
  id: Scalars['ID'];
  dropOffZone: PriceZone;
  pickUpZone: PriceZone;
  distanceMiles: Scalars['Float'];
  /** Returns all operator vehicles with base rate if it exists, or null */
  priceZoneRouteVehicles?: Maybe<Array<Maybe<PriceZoneRouteVehicle>>>;
};

export type PriceZoneRouteVehicle = Node & {
  __typename?: 'PriceZoneRouteVehicle';
  /** The ID of an object */
  id: Scalars['ID'];
  vehicle: Vehicle;
  baseRate?: Maybe<Scalars['Float']>;
};

/** A connection to a list of items. */
export type PriceZonesConnection = {
  __typename?: 'PriceZonesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PriceZonesEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PriceZonesEdge = {
  __typename?: 'PriceZonesEdge';
  /** The item at the end of the edge */
  node?: Maybe<PriceZone>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum PricelessBookingTarget {
  All = 'ALL',
  Specific = 'SPECIFIC'
}

export type PricingLayout = {
  __typename?: 'PricingLayout';
  driverGratuityAmt?: Maybe<Scalars['Float']>;
  driverGratuityPercent?: Maybe<Scalars['Float']>;
  promoDiscountAmt?: Maybe<Scalars['Float']>;
  promoDiscountPercent?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['Float']>;
  tollsAmt?: Maybe<Scalars['Float']>;
  meetGreetAmt?: Maybe<Scalars['Float']>;
  meetGreetAmtEnabled?: Maybe<Scalars['Boolean']>;
  otherName?: Maybe<Scalars['String']>;
  otherAmt?: Maybe<Scalars['Float']>;
  otherPercent?: Maybe<Scalars['Float']>;
  other2Name?: Maybe<Scalars['String']>;
  other2Amt?: Maybe<Scalars['Float']>;
  other2Percent?: Maybe<Scalars['Float']>;
  other3Name?: Maybe<Scalars['String']>;
  other3Amt?: Maybe<Scalars['Float']>;
  other3Percent?: Maybe<Scalars['Float']>;
  driverGratuityIsPct?: Maybe<Scalars['Boolean']>;
  driverGratuityIsActive?: Maybe<Scalars['Boolean']>;
  promoDiscountIsPct?: Maybe<Scalars['Boolean']>;
  promoDiscountIsActive?: Maybe<Scalars['Boolean']>;
  taxIsPct?: Maybe<Scalars['Boolean']>;
  taxIsActive?: Maybe<Scalars['Boolean']>;
  tollsIsActive?: Maybe<Scalars['Boolean']>;
  otherIsPct?: Maybe<Scalars['Boolean']>;
  otherIsActive?: Maybe<Scalars['Boolean']>;
  other2IsPct?: Maybe<Scalars['Boolean']>;
  other3IsPct?: Maybe<Scalars['Boolean']>;
};

/** Configuration for pricing layout */
export type PricingLayoutInputType = {
  driverGratuityAmt?: Maybe<Scalars['Float']>;
  driverGratuityIsPct?: Maybe<Scalars['Boolean']>;
  driverGratuityIsActive?: Maybe<Scalars['Boolean']>;
  promoDiscountAmt?: Maybe<Scalars['Float']>;
  promoDiscountIsPct?: Maybe<Scalars['Boolean']>;
  promoDiscountIsActive?: Maybe<Scalars['Boolean']>;
  taxAmt?: Maybe<Scalars['Float']>;
  taxIsPct?: Maybe<Scalars['Boolean']>;
  taxIsActive?: Maybe<Scalars['Boolean']>;
  tollsAmt?: Maybe<Scalars['Float']>;
  tollsIsActive?: Maybe<Scalars['Boolean']>;
  otherName?: Maybe<Scalars['String']>;
  otherAmt?: Maybe<Scalars['Float']>;
  otherIsPct?: Maybe<Scalars['Boolean']>;
  otherIsActive?: Maybe<Scalars['Boolean']>;
  other2Name?: Maybe<Scalars['String']>;
  other2Amt?: Maybe<Scalars['Float']>;
  other2IsPct?: Maybe<Scalars['Boolean']>;
  other2IsActive?: Maybe<Scalars['Boolean']>;
  other3Name?: Maybe<Scalars['String']>;
  other3Amt?: Maybe<Scalars['Float']>;
  other3IsPct?: Maybe<Scalars['Boolean']>;
  other3IsActive?: Maybe<Scalars['Boolean']>;
};

/** Configuration for pricing layout */
export type PricingLayoutType = {
  driverGratuityAmt?: Maybe<Scalars['Float']>;
  driverGratuityPercent?: Maybe<Scalars['Float']>;
  promoDiscountAmt?: Maybe<Scalars['Float']>;
  promoDiscountPercent?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['Float']>;
  tollsAmt?: Maybe<Scalars['Float']>;
  meetGreetAmt?: Maybe<Scalars['Float']>;
  otherName?: Maybe<Scalars['String']>;
  otherAmt?: Maybe<Scalars['Float']>;
  otherPercent?: Maybe<Scalars['Float']>;
  other2Name?: Maybe<Scalars['String']>;
  other2Amt?: Maybe<Scalars['Float']>;
  other2Percent?: Maybe<Scalars['Float']>;
  other3Name?: Maybe<Scalars['String']>;
  other3Amt?: Maybe<Scalars['Float']>;
  other3Percent?: Maybe<Scalars['Float']>;
};

export enum PricingVariant {
  Flat = 'FLAT',
  PerMile = 'PER_MILE',
  PerHour = 'PER_HOUR'
}

export type PromoCode = {
  __typename?: 'PromoCode';
  /** The ID of an object */
  id: Scalars['ID'];
  promoCodeName: Scalars['String'];
  active: Scalars['Boolean'];
  redemptionCount: Scalars['Int'];
  promoCodeAmt?: Maybe<Scalars['Float']>;
  promoCodePercent?: Maybe<Scalars['Float']>;
  totalLimit?: Maybe<Scalars['Int']>;
  bookingContactLimit?: Maybe<Scalars['Int']>;
  bookingStartDate?: Maybe<Scalars['DateTime']>;
  bookingEndDate?: Maybe<Scalars['DateTime']>;
  tripStartDate?: Maybe<Scalars['DateTime']>;
  tripEndDate?: Maybe<Scalars['DateTime']>;
};

export type ProvisionPhoneNumberTwilioInput = {
  phoneNumber: Scalars['JSON'];
  existingTwilioNumber?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ProvisionPhoneNumberTwilioPayload = {
  __typename?: 'ProvisionPhoneNumberTwilioPayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PublishDudaWebsiteInput = {
  siteName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PublishDudaWebsitePayload = {
  __typename?: 'PublishDudaWebsitePayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The top-level API for "/operator" endpoint */
export type Query = {
  __typename?: 'Query';
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** Fetches objects given their IDs */
  nodes: Array<Maybe<Node>>;
  /** Fetch all airports */
  airports?: Maybe<AirportsConnection>;
  /** Fetch all airlines */
  airlines?: Maybe<AirlinesConnection>;
  /** Get automated base rate amt in dollars */
  automatedBaseRate?: Maybe<BaseRateAutomation>;
  /** Fetch available cancellation policies */
  cancellationPolicies: Array<Maybe<CancellationPolicy>>;
  /** Dashboard Data */
  dashboard: Dashboard;
  drivers: Array<Maybe<Driver>>;
  searchResults: Array<Maybe<SearchResult>>;
  recentSearches: Array<Maybe<RecentSearch>>;
  /** Fetch available insurance policies */
  insurancePolicies: Array<Maybe<InsurancePolicy>>;
  operators: Array<Maybe<Operator>>;
  /** fetch all available roles for an operator */
  operatorRole: Array<Maybe<Role>>;
  /** Fetch list of routes */
  operatorRoutes?: Maybe<OperatorRoutesConnection>;
  /** Fetch all price zones for an operator */
  priceZones?: Maybe<PriceZonesConnection>;
  /** Queries for people to potentially send a message. Either contacts or drivers. */
  recipients: Array<Maybe<SearchResult>>;
  /**
   * Fetch all chat participants (drivers or contacts) by mobile phone.
   *     Returns conversationSid with contact/driver info so front end
   *     can match results to corresponding conversation.
   */
  chatParticipants: Array<Maybe<ChatParticipant>>;
  requests?: Maybe<RequestsConnection>;
  routeStatuses: Array<Maybe<RouteStatus>>;
  routeStatusLogs: Array<Maybe<RouteStatusLog>>;
  termTypes: Array<Maybe<TermType>>;
  terms: Array<Maybe<Term>>;
  /** Get list of available phone numbers to purchase for twilio messaging */
  availableNumbersTwilio?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /** Get / Refresh Access Token for Twilio Conversations */
  accessTokenTwilio?: Maybe<Scalars['JSON']>;
  /** Fetch phone number from Twilio by PhoneSid */
  twilioNumberBySid?: Maybe<Scalars['JSON']>;
  /** Fetch vehicle features */
  vehicleFeatures: Array<Maybe<VehicleFeature>>;
  /** Fetch vehicle types */
  vehicleTypes: Array<Maybe<VehicleType>>;
  /** Get user object based on user context */
  me?: Maybe<User>;
  /** Get operator object based on user context */
  operator?: Maybe<Operator>;
  /** Fetch all (enabled and disabled) order types.  */
  orderTypes: Array<Maybe<OrderType>>;
  /** Fetch duda website template */
  websiteTemplate: Scalars['JSON'];
  /** Fetches all price zone routes where the input zone is the dropoff zone. */
  priceZoneRoutesByZoneId?: Maybe<Array<Maybe<PriceZoneRoute>>>;
  /** Fetch available cancellation policies for a given operator */
  loadCancellationPolicies: Array<Maybe<CancellationPolicy>>;
  loadContacts?: Maybe<LoadContactsConnection>;
  /** Fetch all drivers and their availabilities if supplied a routeId */
  loadRouteDrivers?: Maybe<Array<Maybe<RouteDriver>>>;
  loadFarmAffiliates?: Maybe<LoadFarmAffiliatesConnection>;
  loadFarmAffiliateVehicles?: Maybe<LoadFarmAffiliateVehiclesConnection>;
  loadInvoices?: Maybe<LoadInvoicesConnection>;
  loadInvoiceableRequests?: Maybe<LoadInvoiceableRequestsConnection>;
  loadQuotes?: Maybe<LoadQuotesConnection>;
  loadNewQuotes?: Maybe<LoadNewQuotesConnection>;
  loadReservations?: Maybe<LoadReservationsConnection>;
  /** Fetch all accessible vehicles */
  loadVehicles?: Maybe<LoadVehiclesConnection>;
  loadVehicleAvailabilityCalendar: VehicleAvailabilityCalendar;
  loadTrackedVehicles: Array<Maybe<TrackedVehicle>>;
  /** Determines if trip for operator app has a conflict due to vehicle unavailability */
  loadTripConflict?: Maybe<Scalars['Boolean']>;
  loadContactsAndFarmAffiliates?: Maybe<LoadContactsAndFarmAffiliatesConnection>;
  loadContactsAndFarmAffiliatesAndCompanies?: Maybe<LoadContactsAndFarmAffiliatesAndCompaniesConnection>;
  /** Fetch a potential driver payout, which includes list of Driver Payables */
  loadPotentialDriverPayouts: PotentialDriverPayout;
  /** Fetch all trips for the driver tracking page with a pick-up time +/- 12 hours from Date.now() */
  loadDispatchTrips?: Maybe<Array<Maybe<Trip>>>;
  loadAffiliateTrips?: Maybe<LoadAffiliateTripsConnection>;
  loadTripsForBookingContact?: Maybe<LoadTripsForBookingContactConnection>;
  loadCompanyTrips?: Maybe<LoadCompanyTripsConnection>;
  /** Fetch trips for the dispatch page table */
  loadFilterableDispatchTrips?: Maybe<LoadFilterableDispatchTripsConnection>;
  /** Load rules for the current operator */
  loadDynamicPricingRules?: Maybe<Array<Maybe<DynamicPricingRule>>>;
  companies: Array<Maybe<Company>>;
  loadCompaniesBySearchTerm?: Maybe<LoadCompaniesBySearchTermConnection>;
  /** Report statistics for drivers using drivers app */
  loadDriverPlatformReport?: Maybe<DriverPlatformReport>;
  /** Dashboard Data for total reservations */
  loadDriverDashboard?: Maybe<Array<Maybe<DriverDashboard>>>;
  /** Reservation Dashboard Data */
  loadReservationDashboard: ReservationDashboard;
  loadTripReviews?: Maybe<LoadTripReviewsConnection>;
  loadPotentialTrackedFlight?: Maybe<Array<Maybe<TrackedFlight>>>;
  /** Determine if user exist and has a pending invite when trying to log in or join team */
  loadInvitePendingUser?: Maybe<LoadInvitePendingUser>;
  /**
   * Fetch all chat participants (drivers or contacts) by mobile phone.
   * Returns conversationSid with contact/driver info so front end
   * can match results to corresponding conversation.
   */
  loadChatParticipants: Array<Maybe<ChatParticipant>>;
  loadShuttleRoutes?: Maybe<LoadShuttleRoutesConnection>;
  loadPromoCodeResults?: Maybe<LoadPromoCodeResultsConnection>;
  /** Load all access permissions */
  loadAllAccessPermissions?: Maybe<Array<Maybe<UserAccessPermission>>>;
  loadTransactionsForPaymentMethod?: Maybe<LoadTransactionsForPaymentMethodConnection>;
  loadTransactionsForRequest: Array<Maybe<MoovsTransaction>>;
  /** Fetch paginated payouts for operator's connected account */
  stripePayouts?: Maybe<StripePayoutsConnection>;
  /** Fetch transactions related to a payout */
  stripeTransactions?: Maybe<StripeTransactionsConnection>;
  /** Load stripe link token */
  stripeLinkToken?: Maybe<Scalars['String']>;
  /** Fetch bank accounts for operator's connected account */
  stripeAccount?: Maybe<StripeAccount>;
  /** Load total amount of card payments that have yet to be paid out from stripe */
  loadAmountAwaitingPayout: Scalars['Float'];
  /** Load total amount of card payments that have yet to be paid out from stripe (standard) and amount available for instant payout */
  loadAwaitingPayoutAmounts?: Maybe<AwaitingPayoutAmounts>;
  /** Load the financial account for the current operator, only accessible to owners */
  loadFinancialAccount?: Maybe<StripeFinancialAccount>;
  /** Fetch stripe connect account receipt for given payment */
  stripePaymentReceiptLink?: Maybe<Scalars['String']>;
  loadEmailLogs?: Maybe<LoadEmailLogsConnection>;
  loadGoogleCalendarAuth?: Maybe<GoogleCalendar>;
  /** Load subscription metrics for the current operator */
  loadSubscriptionMetrics?: Maybe<SubscriptionMetrics>;
};


/** The top-level API for "/operator" endpoint */
export type QueryNodeArgs = {
  id: Scalars['ID'];
};


/** The top-level API for "/operator" endpoint */
export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryAirportsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  globalSearch?: Maybe<Scalars['String']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryAirlinesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  globalSearch?: Maybe<Scalars['String']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryAutomatedBaseRateArgs = {
  vehicleId: Scalars['ID'];
  stops: Array<Maybe<StopInformationInputForAutomatedPricing>>;
};


/** The top-level API for "/operator" endpoint */
export type QueryDashboardArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryDriversArgs = {
  farmAffiliateId?: Maybe<Scalars['ID']>;
  includeExternalOperators?: Maybe<Scalars['Boolean']>;
};


/** The top-level API for "/operator" endpoint */
export type QuerySearchResultsArgs = {
  searchTerm?: Maybe<Scalars['String']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryOperatorRoutesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryPriceZonesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderByPriceZoneInput>;
};


/** The top-level API for "/operator" endpoint */
export type QueryRecipientsArgs = {
  searchTerm?: Maybe<Scalars['String']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryChatParticipantsArgs = {
  mobilePhones: Array<Maybe<ChatParticipantInput>>;
};


/** The top-level API for "/operator" endpoint */
export type QueryRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  globalSearch?: Maybe<Scalars['String']>;
  stage?: Maybe<RequestStage>;
  view?: Maybe<View>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  contactId?: Maybe<Scalars['String']>;
  moovsNetworkOperatorId?: Maybe<Scalars['String']>;
  griddnetOperatorId?: Maybe<Scalars['String']>;
  externalOperatorId?: Maybe<Scalars['String']>;
  requestClosedOutStatus?: Maybe<RequestClosedOutStatus>;
};


/** The top-level API for "/operator" endpoint */
export type QueryRouteStatusLogsArgs = {
  operatorRouteId: Scalars['ID'];
};


/** The top-level API for "/operator" endpoint */
export type QueryTwilioNumberBySidArgs = {
  phoneSid?: Maybe<Scalars['String']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryVehicleTypesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryWebsiteTemplateArgs = {
  templateId?: Maybe<Scalars['String']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryPriceZoneRoutesByZoneIdArgs = {
  priceZoneId: Scalars['ID'];
  includeRouteWithinZone?: Maybe<Scalars['Boolean']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadCancellationPoliciesArgs = {
  operatorSlug: Scalars['String'];
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadContactsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadRouteDriversArgs = {
  farmAffiliateId?: Maybe<Scalars['ID']>;
  includeExternalOperators?: Maybe<Scalars['Boolean']>;
  routeId?: Maybe<Scalars['ID']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadFarmAffiliatesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  viewFilter: Array<Maybe<FarmAffiliateVariantEnum>>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadFarmAffiliateVehiclesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  searchFilter?: Maybe<Scalars['String']>;
  vehicleTypeSlug?: Maybe<Scalars['String']>;
  tripId?: Maybe<Scalars['ID']>;
  stops?: Maybe<Array<Maybe<StopInformationInputForAutomatedPricing>>>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadInvoicesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  invoiceSearch?: Maybe<Scalars['String']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadInvoiceableRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  contactId?: Maybe<Scalars['ID']>;
  farmorOperatorId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadQuotesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  view: View;
  filter?: Maybe<Scalars['String']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadNewQuotesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadReservationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  view: ReservationView;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadVehiclesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  stops?: Maybe<Array<Maybe<StopInformationInputForAutomatedPricing>>>;
  farmAffiliateId?: Maybe<Scalars['ID']>;
  includeExternalOperators?: Maybe<Scalars['Boolean']>;
  shouldCalculateBRA?: Maybe<Scalars['Boolean']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadVehicleAvailabilityCalendarArgs = {
  vehicleId: Scalars['ID'];
  stops: Array<Maybe<TripStopInput>>;
  routeId?: Maybe<Scalars['ID']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadTripConflictArgs = {
  tripId: Scalars['ID'];
  vehicleId: Scalars['ID'];
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadContactsAndFarmAffiliatesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadContactsAndFarmAffiliatesAndCompaniesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadPotentialDriverPayoutsArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadAffiliateTripsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  farmAffiliateId?: Maybe<Scalars['ID']>;
  externalOperatorId?: Maybe<Scalars['ID']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadTripsForBookingContactArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  contactId: Scalars['ID'];
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadCompanyTripsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  companyId: Scalars['ID'];
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadFilterableDispatchTripsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  tripStatuses?: Maybe<Array<Maybe<StatusSlug>>>;
  companyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  vehicleIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  driverIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  affiliateIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  paidStatus?: Maybe<PaidNotPaid>;
  openClosedTrip?: Maybe<OpenClosedTrip>;
  driverAssigned?: Maybe<AssignedUnassigned>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadCompaniesBySearchTermArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadDriverPlatformReportArgs = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadDriverDashboardArgs = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadReservationDashboardArgs = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadTripReviewsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadPotentialTrackedFlightArgs = {
  flightNumber: Scalars['Int'];
  firstStopDateTime: Scalars['String'];
  arrivalAirport?: Maybe<Scalars['String']>;
  departureAirport?: Maybe<Scalars['String']>;
  airline: Scalars['String'];
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadInvitePendingUserArgs = {
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadChatParticipantsArgs = {
  chatParticipants: Array<Maybe<LoadChatParticipantInput>>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadShuttleRoutesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  companyId: Scalars['ID'];
  searchTerm?: Maybe<Scalars['String']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadPromoCodeResultsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  promoCodeId: Scalars['ID'];
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadTransactionsForPaymentMethodArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  paymentMethodId: Scalars['ID'];
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadTransactionsForRequestArgs = {
  requestId: Scalars['ID'];
};


/** The top-level API for "/operator" endpoint */
export type QueryStripePayoutsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** The top-level API for "/operator" endpoint */
export type QueryStripeTransactionsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  stripePayoutId: Scalars['String'];
};


/** The top-level API for "/operator" endpoint */
export type QueryStripePaymentReceiptLinkArgs = {
  paymentId: Scalars['ID'];
};


/** The top-level API for "/operator" endpoint */
export type QueryLoadEmailLogsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
};

export type RecentSearch = {
  __typename?: 'RecentSearch';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  searchTerm: Scalars['String'];
  searchResult: SearchResult;
};

/** Configuration for refund */
export type Refund = {
  __typename?: 'Refund';
  periodName?: Maybe<PeriodName>;
  periodValue?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
};

/** Input for one refund period on a cancellation policy. e.g. Week, 1, active. */
export type RefundInput = {
  periodName?: Maybe<PeriodName>;
  periodValue?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
};

export type RegisterSalesAutomationInput = {
  requestId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RegisterSalesAutomationPayload = {
  __typename?: 'RegisterSalesAutomationPayload';
  salesAutomation?: Maybe<SalesAutomation>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveCancellationPolicyInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveCancellationPolicyPayload = {
  __typename?: 'RemoveCancellationPolicyPayload';
  cancellationPolicy?: Maybe<CancellationPolicy>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveCompanyInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveCompanyPayload = {
  __typename?: 'RemoveCompanyPayload';
  company?: Maybe<Company>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveContactInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveContactPayload = {
  __typename?: 'RemoveContactPayload';
  contact: Contact;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveDriverInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveDriverPayload = {
  __typename?: 'RemoveDriverPayload';
  driver: Driver;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveExternalOperatorInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveExternalOperatorPayload = {
  __typename?: 'RemoveExternalOperatorPayload';
  externalOperator?: Maybe<ExternalOperator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveInsuranceAttachmentInput = {
  /** Insurance Attachment ID */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveInsuranceAttachmentPayload = {
  __typename?: 'RemoveInsuranceAttachmentPayload';
  insurancePolicy?: Maybe<InsurancePolicy>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveInsurancePolicyInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveInsurancePolicyPayload = {
  __typename?: 'RemoveInsurancePolicyPayload';
  insurancePolicy?: Maybe<InsurancePolicy>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveInvoiceInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveInvoicePayload = {
  __typename?: 'RemoveInvoicePayload';
  invoice: Invoice;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveLinkedPassengerInput = {
  contactId: Scalars['ID'];
  passengerId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveLinkedPassengerPayload = {
  __typename?: 'RemoveLinkedPassengerPayload';
  contact?: Maybe<Contact>;
  passenger?: Maybe<Contact>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveOperatorOrderTypeInput = {
  orderTypeId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveOperatorOrderTypePayload = {
  __typename?: 'RemoveOperatorOrderTypePayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemovePaymentMethodInput = {
  paymentMethodId: Scalars['ID'];
  payerId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemovePaymentMethodPayload = {
  __typename?: 'RemovePaymentMethodPayload';
  paymentMethod?: Maybe<PaymentMethod>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemovePriceZoneInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemovePriceZonePayload = {
  __typename?: 'RemovePriceZonePayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemovePromoCodeInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemovePromoCodePayload = {
  __typename?: 'RemovePromoCodePayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveSendgridCustomDomainInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveSendgridCustomDomainPayload = {
  __typename?: 'RemoveSendgridCustomDomainPayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveStopInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveStopPayload = {
  __typename?: 'RemoveStopPayload';
  stop?: Maybe<Stop>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveStripeBankAccountInput = {
  bankAccountId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveStripeBankAccountPayload = {
  __typename?: 'RemoveStripeBankAccountPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveSubscriptionPaymentMethodInput = {
  subscriptionPaymentMethodId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveSubscriptionPaymentMethodPayload = {
  __typename?: 'RemoveSubscriptionPaymentMethodPayload';
  paymentMethod: PaymentMethod;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveTermInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveTermPayload = {
  __typename?: 'RemoveTermPayload';
  term?: Maybe<Term>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveTripInput = {
  tripId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveTripPayload = {
  __typename?: 'RemoveTripPayload';
  trip?: Maybe<Trip>;
  outboundTrip?: Maybe<Trip>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveUserAccessPermissionInput = {
  userId: Scalars['ID'];
  accessPermissionId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveUserAccessPermissionPayload = {
  __typename?: 'RemoveUserAccessPermissionPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveUserInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveUserPayload = {
  __typename?: 'RemoveUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveVehicleFeatureInput = {
  vehicleId: Scalars['ID'];
  featureId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveVehicleFeaturePayload = {
  __typename?: 'RemoveVehicleFeaturePayload';
  vehicle?: Maybe<Vehicle>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveVehicleFromRouteInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveVehicleFromRoutePayload = {
  __typename?: 'RemoveVehicleFromRoutePayload';
  route?: Maybe<Route>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveVehicleInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveVehiclePayload = {
  __typename?: 'RemoveVehiclePayload';
  vehicle: Vehicle;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveVehiclePhotoInput = {
  /** Photo ID */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveVehiclePhotoPayload = {
  __typename?: 'RemoveVehiclePhotoPayload';
  vehicle?: Maybe<Vehicle>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ReopenTripInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ReopenTripPayload = {
  __typename?: 'ReopenTripPayload';
  trip: Trip;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Request = Node & {
  __typename?: 'Request';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** If all trips are cancelled, returns the date of latest cancelled trip */
  cancelledAt?: Maybe<Scalars['DateTime']>;
  /** If all trips are closed or cancelled, returns the date of latest farmed route if farmee or closed trip otherwise */
  closedAt?: Maybe<Scalars['DateTime']>;
  activeTripsCount?: Maybe<Scalars['Int']>;
  readyForPayment?: Maybe<Scalars['Boolean']>;
  stage: RequestStage;
  /** @deprecated prefer orderType */
  type?: Maybe<OrderTypeEnum>;
  orderType?: Maybe<OrderTypeEnum>;
  /** Excludes return trips from list of all trips */
  trips: Array<Maybe<Trip>>;
  /** @deprecated use bookingContact. Deprecated on 06/07/2022. */
  teamMembers: Array<Maybe<TeamMember>>;
  bookingContact: Contact;
  orderNumber: Scalars['String'];
  status: RequestStatus;
  emailLogs: Array<Maybe<EmailLog>>;
  invoice?: Maybe<Invoice>;
  /** Request level total amount calculated from it's trips. Before payments have been factored in. */
  totalAmount: Scalars['Float'];
  /** Request level amount due calculated from it's trips */
  amountDue: Scalars['Float'];
  /** Request level payment status determined from it's amount due */
  paymentStatus: PaidNotPaid;
  /** Preferred payment method for this request, appointed by the booking contact */
  preferredPaymentMethod?: Maybe<PaymentMethod>;
  farmedRouteStatus?: Maybe<FarmedRouteStatusEnum>;
  /**
   * All successful sub payments made towards this request.
   *            If operator is farmor this will be customer payments if
   *            operator is farmee it will be sub payments to their farmed route(s)
   */
  combinedSubPayments?: Maybe<Array<Maybe<SubPayment>>>;
  /**
   * All successful refunds made towards this request.
   *            If operator is farmor this will be customer payments if
   *            operator is farmee it will be refunds to their farmed route(s)
   */
  combinedRefunds?: Maybe<Array<Maybe<PaymentRefund>>>;
  /** A Google-generated id used to attribute this request to an ad click */
  gclid?: Maybe<Scalars['String']>;
  /** Currently, white label operator is mainly Swoop operator */
  whiteLabelOperator?: Maybe<Operator>;
  /** If a unconfirmed reservation was declined. */
  isDeclined: Scalars['Boolean'];
  /** If a quote was manually archived. Note: archived on client side also includes "expired" quotes, i.e. trip start date is in the past (see isExpired below). */
  isArchived: Scalars['Boolean'];
  /** If a quote or reservation is in the past. Requires "timezone offset" to be sent from client. Therefore only works on Operator App and Customer App (right now). */
  isExpired?: Maybe<Scalars['Boolean']>;
  /** Sales automation texts, both draft and sent/scheduled texts */
  salesAutomation?: Maybe<SalesAutomation>;
  /** Reasons a quote cannot be confirmed into a reservation. This logic also used for ability to send a quote */
  confirmReservationDisabledVariant?: Maybe<ConfirmReservationDisabledVariantEnum>;
  /** When customer signs during reservation confirmation, a reservation confirmation signature log will be generated */
  reservationConfirmationSignatureLog?: Maybe<ReservationConfirmationSignatureLog>;
  /** Company linked to request */
  company?: Maybe<Company>;
};

/** All trips of a request must be closed out for request to be closed out */
export enum RequestClosedOutStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  All = 'ALL'
}

export type RequestCommentInput = {
  bodyText: Scalars['String'];
};

export type RequestContactInformationInput = {
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  preferences?: Maybe<Scalars['String']>;
  workAddress?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneCountryDialCode?: Maybe<Scalars['String']>;
  phoneCountryName?: Maybe<Scalars['String']>;
  phoneCountryFormat?: Maybe<Scalars['String']>;
};

export type RequestRouteInformationInput = {
  vehicle?: Maybe<RequestVehicleInformationInput>;
  baseRateAmt?: Maybe<Scalars['Float']>;
  hourlyBaseRateHours?: Maybe<Scalars['Float']>;
  driverGratuityAmt?: Maybe<Scalars['Float']>;
  driverGratuityPercent?: Maybe<Scalars['Float']>;
  promoDiscountAmt?: Maybe<Scalars['Float']>;
  promoDiscountPercent?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['Float']>;
  tollsAmt?: Maybe<Scalars['Float']>;
  meetGreetAmt?: Maybe<Scalars['Float']>;
  otherName?: Maybe<Scalars['String']>;
  otherAmt?: Maybe<Scalars['Float']>;
  otherPercent?: Maybe<Scalars['Float']>;
  other2Name?: Maybe<Scalars['String']>;
  other2Amt?: Maybe<Scalars['Float']>;
  other2Percent?: Maybe<Scalars['Float']>;
  other3Name?: Maybe<Scalars['String']>;
  other3Amt?: Maybe<Scalars['Float']>;
  other3Percent?: Maybe<Scalars['Float']>;
  carryOnLuggage?: Maybe<Scalars['Int']>;
  checkedLuggage?: Maybe<Scalars['Int']>;
  oversizeLuggage?: Maybe<Scalars['Int']>;
  promoCodeId?: Maybe<Scalars['String']>;
  promoCodeAmt?: Maybe<Scalars['Float']>;
  promoCodePercent?: Maybe<Scalars['Float']>;
  forwardFacingSeatQuantity?: Maybe<Scalars['Int']>;
  forwardFacingSeatAmt?: Maybe<Scalars['Float']>;
  rearFacingSeatQuantity?: Maybe<Scalars['Int']>;
  rearFacingSeatAmt?: Maybe<Scalars['Float']>;
  boosterSeatQuantity?: Maybe<Scalars['Int']>;
  boosterSeatAmt?: Maybe<Scalars['Float']>;
  driverGratuityIsPct?: Maybe<Scalars['Boolean']>;
  promoDiscountIsPct?: Maybe<Scalars['Boolean']>;
  taxIsPct?: Maybe<Scalars['Boolean']>;
  otherIsPct?: Maybe<Scalars['Boolean']>;
  other2IsPct?: Maybe<Scalars['Boolean']>;
  other3IsPct?: Maybe<Scalars['Boolean']>;
};

export enum RequestStage {
  Quote = 'QUOTE',
  Reservation = 'RESERVATION',
  Unconfirmed = 'UNCONFIRMED'
}

export enum RequestStatus {
  EmailSent = 'EMAIL_SENT',
  Draft = 'DRAFT',
  NewQuote = 'NEW_QUOTE'
}

export type RequestStopInformationInput = {
  location: Scalars['String'];
  dateTime?: Maybe<Scalars['DateTime']>;
  stopIndex: Scalars['Int'];
  groupSize?: Maybe<Scalars['Int']>;
  logisticStreetViewUrl?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  flightNumber?: Maybe<Scalars['String']>;
  airportIcao?: Maybe<Scalars['String']>;
  airlineIcao?: Maybe<Scalars['String']>;
  trackedFlight?: Maybe<Scalars['JSON']>;
  flightData?: Maybe<Scalars['JSON']>;
  locationAlias?: Maybe<Scalars['String']>;
};

export type RequestTripInformationInput = {
  totalGroupSize: Scalars['Int'];
  stops: Array<Maybe<RequestStopInformationInput>>;
  routes: Array<Maybe<RequestRouteInformationInput>>;
  contact: RequestContactInformationInput;
  note?: Maybe<Scalars['String']>;
  temporaryPassenger?: Maybe<Scalars['JSON']>;
  comments?: Maybe<Array<Maybe<RequestCommentInput>>>;
};

export type RequestVehicleInformationInput = {
  id: Scalars['ID'];
};

/** A connection to a list of items. */
export type RequestsConnection = {
  __typename?: 'RequestsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RequestsEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RequestsEdge = {
  __typename?: 'RequestsEdge';
  /** The item at the end of the edge */
  node?: Maybe<Request>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ResendInviteFailure = {
  __typename?: 'ResendInviteFailure';
  message?: Maybe<Scalars['String']>;
};

export type ResendInviteInput = {
  userId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResendInvitePayload = {
  __typename?: 'ResendInvitePayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResendInviteResult = ResendInviteSuccess | ResendInviteFailure;

export type ResendInviteSuccess = {
  __typename?: 'ResendInviteSuccess';
  success?: Maybe<Scalars['Boolean']>;
};

export type ResendInviteV2Input = {
  userId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResendInviteV2Payload = {
  __typename?: 'ResendInviteV2Payload';
  result: ResendInviteResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ReservationConfirmationSignatureLog = {
  __typename?: 'ReservationConfirmationSignatureLog';
  /** The ID of an object */
  id: Scalars['ID'];
  pdfUrl?: Maybe<Scalars['String']>;
  printedName: Scalars['String'];
  ipAddress: Scalars['String'];
  downloadEnabled: Scalars['Boolean'];
};

export type ReservationDashboard = {
  __typename?: 'ReservationDashboard';
  /** Total reservation amount for a date range. */
  totalAmount: Scalars['Float'];
  /** Total reservation payment amount paid for a date range. */
  totalAmountPaid: Scalars['Float'];
  /** Total reservation payment amount due for a date range. */
  totalAmountDue: Scalars['Float'];
  /** List of reservation amount per series interval. */
  timeSeries: Scalars['JSON'];
  /** Interval between time series buckets */
  seriesInterval: TimeInterval;
  /** List of reservation amount per payment method/type. */
  paymentBreakdown: Scalars['JSON'];
  /** List of reservation amount per vehicle over a date range */
  vehicleBreakdown: Scalars['JSON'];
  /** List of reservation amount per order type over a date range */
  orderTypeBreakdown: Scalars['JSON'];
};

/** Reservation view used when filtering */
export enum ReservationView {
  Open = 'OPEN',
  Closed = 'CLOSED',
  All = 'ALL',
  NotPaid = 'NOT_PAID',
  Unconfirmed = 'UNCONFIRMED'
}

export type ReturnTripCommentInput = {
  bodyText: Scalars['String'];
};

export enum ReviewSite {
  Google = 'GOOGLE',
  Facebook = 'FACEBOOK',
  Yelp = 'YELP'
}

export type Role = {
  __typename?: 'Role';
  name: Scalars['String'];
  slug: Scalars['String'];
};

export enum RoundTripVariant {
  Outbound = 'OUTBOUND',
  Return = 'RETURN'
}

export type Route = Node & {
  __typename?: 'Route';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  vehicle?: Maybe<Vehicle>;
  groupSize?: Maybe<Scalars['Int']>;
  operatorConfirmation?: Maybe<Scalars['String']>;
  /** Note for operator */
  operatorNote?: Maybe<Scalars['String']>;
  operatorCloseoutStartDateTime?: Maybe<Scalars['DateTime']>;
  operatorCloseoutEndDateTime?: Maybe<Scalars['DateTime']>;
  operatorCloseoutDurationMinutes?: Maybe<Scalars['Int']>;
  operatorCloseoutNote?: Maybe<Scalars['String']>;
  alert: Alert;
  driverCloseoutStartDateTime?: Maybe<Scalars['DateTime']>;
  driverCloseoutEndDateTime?: Maybe<Scalars['DateTime']>;
  driverCloseoutDurationMinutes?: Maybe<Scalars['Int']>;
  /** Determines whether pricing can be adjusted on a route by checking for existence of invoice. This works for both customer invoices from perspective of farmor AND farmedRoute invoices from perspective of farmee */
  canEditPricing: Scalars['Boolean'];
  /** pricing on a route. Does not show for farmee. */
  pricing?: Maybe<RoutePricing>;
  billings: Array<Maybe<Billing>>;
  payments?: Maybe<Array<Maybe<SubPayment>>>;
  publicId?: Maybe<Scalars['String']>;
  /**
   * either driver on route or farmed route
   * @deprecated use routeDriver instead
   */
  driver?: Maybe<Driver>;
  /** driver type w/ availability for route */
  routeDriver?: Maybe<RouteDriver>;
  /** Note for driver */
  driverNote?: Maybe<Scalars['String']>;
  /** Feedback from driver after completion of ride. */
  driverCloseoutNote?: Maybe<Scalars['String']>;
  driverStatus?: Maybe<DriverStatus>;
  driverPayoutFlatRate?: Maybe<Scalars['Float']>;
  driverPayoutHours?: Maybe<Scalars['Float']>;
  driverPayoutRatePerHour?: Maybe<Scalars['Float']>;
  driverPayoutGratuity?: Maybe<Scalars['Float']>;
  automatedDriverPayoutFlatRate?: Maybe<Scalars['Float']>;
  automatedDriverPayoutHours?: Maybe<Scalars['Float']>;
  automatedDriverPayoutRatePerHour?: Maybe<Scalars['Float']>;
  automatedDriverPayoutGratuity?: Maybe<Scalars['Float']>;
  hasAutomatedDriverPayout?: Maybe<Scalars['Boolean']>;
  dispatchStatus: Scalars['String'];
  isFarmedRoute: Scalars['Boolean'];
  farmRelationship?: Maybe<FarmRelationshipEnum>;
  /** Determines whether pricing can be adjusted on a farmed route by checking for existence of invoice. Only relevent if you this route is farmed and the current user is the Farmor. */
  canEditFarmeePricing?: Maybe<Scalars['Boolean']>;
  farmeePricing?: Maybe<RoutePricing>;
  farmAffiliateVariant?: Maybe<FarmAffiliateVariantEnum>;
  /** The Farm Affiliate tied to the route. If Farmee, shows the farmor. If Farmor, shows the farmee. */
  farmAffiliate?: Maybe<FarmAffiliate>;
  /** status of farmed route as it relates to relationship between two operators */
  farmStatus?: Maybe<FarmedRouteStatusEnum>;
  farmoutNote?: Maybe<Scalars['String']>;
  farmeeClosedAt?: Maybe<Scalars['DateTime']>;
  farmeeCloseoutStartDateTime?: Maybe<Scalars['DateTime']>;
  farmeeCloseoutEndDateTime?: Maybe<Scalars['DateTime']>;
  farmeeCloseoutDurationMinutes?: Maybe<Scalars['Int']>;
  farmeeCloseoutNote?: Maybe<Scalars['String']>;
  /** Human readable label describing requested vehicle. eg 'Party Bus (15 person)' */
  requestedVehicleNote?: Maybe<Scalars['String']>;
  /** A sub-object containing information about an in-progress route */
  routeDispatch: RouteDispatch;
  isFarmedRouteInvoiced?: Maybe<Scalars['Boolean']>;
  externalConfirmationNumber?: Maybe<Scalars['String']>;
  /** The number of stops for the current route */
  length: Scalars['Int'];
  trip: Trip;
  driverAmountDue?: Maybe<Scalars['Float']>;
  /** Used by driver app, when driver ends trip */
  garageIn?: Maybe<Scalars['DateTime']>;
  /** Used by driver app, when driver starts trip */
  garageOut?: Maybe<Scalars['DateTime']>;
  carryOnLuggage?: Maybe<Scalars['Int']>;
  checkedLuggage?: Maybe<Scalars['Int']>;
  oversizeLuggage?: Maybe<Scalars['Int']>;
  /** @deprecated dispatchStatus is more descriptive */
  statusSlug: Scalars['String'];
  /** @deprecated Old swoop related transactions table. Not used */
  transactions: Array<Maybe<Transaction>>;
  /** @deprecated use pricing */
  baseRateAmt?: Maybe<Scalars['Float']>;
};

export type RouteAvailability = {
  __typename?: 'RouteAvailability';
  /** The ID of an object */
  id: Scalars['ID'];
  available: Scalars['Boolean'];
};

export type RouteDispatch = {
  __typename?: 'RouteDispatch';
  /** The ID of an object */
  id: Scalars['ID'];
  previousStopName?: Maybe<Scalars['String']>;
  previousStop?: Maybe<Stop>;
  currentStopName?: Maybe<Scalars['String']>;
  currentStop?: Maybe<Stop>;
  nextStopName?: Maybe<Scalars['String']>;
  nextStop?: Maybe<Stop>;
  currentStatus?: Maybe<RouteDispatchLogStatusEnum>;
  nextStatus?: Maybe<RouteDispatchLogStatusEnum>;
  driverEta?: Maybe<Scalars['DateTime']>;
  currentStopArrivalTime?: Maybe<Scalars['DateTime']>;
};

export enum RouteDispatchLogStatusEnum {
  EnRoute = 'EN_ROUTE',
  Arrived = 'ARRIVED',
  Departed = 'DEPARTED'
}

export type RouteDriver = {
  __typename?: 'RouteDriver';
  /** The ID of an object */
  id: Scalars['ID'];
  driver?: Maybe<Driver>;
  routeAvailability?: Maybe<RouteAvailability>;
  personalAvailability?: Maybe<PersonalAvailability>;
};

export type RouteInput = {
  vehicle?: Maybe<VehicleInput>;
  baseRateAmt?: Maybe<Scalars['Float']>;
  hourlyBaseRateHours?: Maybe<Scalars['Float']>;
  driverGratuityAmt?: Maybe<Scalars['Float']>;
  driverGratuityPercent?: Maybe<Scalars['Float']>;
  promoDiscountAmt?: Maybe<Scalars['Float']>;
  promoDiscountPercent?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['Float']>;
  tollsAmt?: Maybe<Scalars['Float']>;
  otherName?: Maybe<Scalars['String']>;
  otherAmt?: Maybe<Scalars['Float']>;
  otherPercent?: Maybe<Scalars['Float']>;
  other2Name?: Maybe<Scalars['String']>;
  other2Amt?: Maybe<Scalars['Float']>;
  other2Percent?: Maybe<Scalars['Float']>;
  other3Name?: Maybe<Scalars['String']>;
  other3Amt?: Maybe<Scalars['Float']>;
  other3Percent?: Maybe<Scalars['Float']>;
  meetGreetAmt?: Maybe<Scalars['Float']>;
  carryOnLuggage?: Maybe<Scalars['Int']>;
  checkedLuggage?: Maybe<Scalars['Int']>;
  oversizeLuggage?: Maybe<Scalars['Int']>;
  promoCodeId?: Maybe<Scalars['String']>;
  promoCodeAmt?: Maybe<Scalars['Float']>;
  promoCodePercent?: Maybe<Scalars['Float']>;
  forwardFacingSeatQuantity?: Maybe<Scalars['Int']>;
  forwardFacingSeatAmt?: Maybe<Scalars['Float']>;
  rearFacingSeatQuantity?: Maybe<Scalars['Int']>;
  rearFacingSeatAmt?: Maybe<Scalars['Float']>;
  boosterSeatQuantity?: Maybe<Scalars['Int']>;
  boosterSeatAmt?: Maybe<Scalars['Float']>;
  driverGratuityIsPct?: Maybe<Scalars['Boolean']>;
  promoDiscountIsPct?: Maybe<Scalars['Boolean']>;
  taxIsPct?: Maybe<Scalars['Boolean']>;
  otherIsPct?: Maybe<Scalars['Boolean']>;
  other2IsPct?: Maybe<Scalars['Boolean']>;
  other3IsPct?: Maybe<Scalars['Boolean']>;
};

export type RoutePricing = {
  __typename?: 'RoutePricing';
  /** The ID of an object */
  id: Scalars['ID'];
  baseRateAmt?: Maybe<Scalars['Float']>;
  /** Flat or hourly base rate. */
  baseRateVariant: BaseRateVariantEnum;
  /**
   * The number of hours for the hourly base rate.
   *           Should be a multiple of 0.25, because we only allow for quarter-hour intervals.
   */
  hourlyBaseRateHours?: Maybe<Scalars['Float']>;
  /** The calculated dollar rate per hour. If null, then base rate variant is flat. */
  hourlyBaseRate?: Maybe<Scalars['Float']>;
  driverGratuityAmt?: Maybe<Scalars['Float']>;
  driverGratuityPercent?: Maybe<Scalars['Float']>;
  promoDiscountAmt?: Maybe<Scalars['Float']>;
  promoDiscountPercent?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['Float']>;
  tollsAmt?: Maybe<Scalars['Float']>;
  meetGreetAmt?: Maybe<Scalars['Float']>;
  otherName?: Maybe<Scalars['String']>;
  otherAmt?: Maybe<Scalars['Float']>;
  otherPercent?: Maybe<Scalars['Float']>;
  other2Name?: Maybe<Scalars['String']>;
  other2Amt?: Maybe<Scalars['Float']>;
  other2Percent?: Maybe<Scalars['Float']>;
  other3Name?: Maybe<Scalars['String']>;
  other3Amt?: Maybe<Scalars['Float']>;
  other3Percent?: Maybe<Scalars['Float']>;
  promoCodeId?: Maybe<Scalars['ID']>;
  promoCodeName?: Maybe<Scalars['String']>;
  promoCodeAmt?: Maybe<Scalars['Float']>;
  promoCodePercent?: Maybe<Scalars['Float']>;
  forwardFacingSeatQuantity?: Maybe<Scalars['Int']>;
  forwardFacingSeatAmt?: Maybe<Scalars['Float']>;
  rearFacingSeatQuantity?: Maybe<Scalars['Int']>;
  rearFacingSeatAmt?: Maybe<Scalars['Float']>;
  boosterSeatQuantity?: Maybe<Scalars['Int']>;
  boosterSeatAmt?: Maybe<Scalars['Float']>;
  /** Sum of all charges with successful payment on a cancelled trip */
  cancellationFee?: Maybe<Scalars['Float']>;
  /** Sum of all amounts on route. If cancelled, is the cancellation fee. */
  totalAmt?: Maybe<Scalars['Float']>;
  /** Route level payment status determined from it's amount due */
  paymentStatus?: Maybe<PaidNotPaid>;
  /** Amount paid towards total amount */
  amountPaid?: Maybe<Scalars['Float']>;
  driverGratuityIsPct?: Maybe<Scalars['Boolean']>;
  promoDiscountIsPct?: Maybe<Scalars['Boolean']>;
  taxIsPct?: Maybe<Scalars['Boolean']>;
  otherIsPct?: Maybe<Scalars['Boolean']>;
  other2IsPct?: Maybe<Scalars['Boolean']>;
  other3IsPct?: Maybe<Scalars['Boolean']>;
};

export type RouteStatus = {
  __typename?: 'RouteStatus';
  statusSlug: Scalars['String'];
  statusName: Scalars['String'];
};

export type RouteStatusLog = {
  __typename?: 'RouteStatusLog';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  statusSlug: Scalars['String'];
  statusName: Scalars['String'];
  author: Author;
};

export type SalesAutomation = {
  __typename?: 'SalesAutomation';
  /** The ID of an object */
  id: Scalars['ID'];
  /** Reasons that the quote cannot be enrolled in sales automation */
  disabledVariant?: Maybe<SalesAutomationDisabledVariantEnum>;
  /** Utilized within SalesAutomationDialog client side for displaying draft messages. 7 days after 3rd text will potentially be sent or pick-up date - reservation cutoff (whichever is first). */
  autoArchiveDate: Scalars['DateTime'];
  /** Potential texts be sent, prior to user clicking "Enroll" */
  draftTexts?: Maybe<Array<Maybe<SalesAutomationText>>>;
  /** Texts that actually have corresponding records in db. Used in activity log after user clicks "Enroll" */
  texts?: Maybe<Array<Maybe<SalesAutomationText>>>;
  /** The user who enrolled in sales automation (or who is potentially going to enroll). Texts are sent to customer from the perspective of this user. */
  sender: User;
};

export enum SalesAutomationDisabledVariantEnum {
  /** The request has already enrolled in sales automation. Only one sales automation is allowed per request. */
  AlreadyEnrolled = 'ALREADY_ENROLLED',
  /** Request has been archived meaning it was manually archived, but still in the future */
  RequestArchived = 'REQUEST_ARCHIVED',
  /** Request pickup datetime is in the past */
  RequestExpired = 'REQUEST_EXPIRED',
  /** Request is currently within the reservation cutoff period, and therefore it cannot be confirmed by customer via booking tool. */
  ReservationCutoff = 'RESERVATION_CUTOFF',
  /** Operator does not have a moovs chat number, and therefore automated texts cannot send. */
  NoMoovsChatNumber = 'NO_MOOVS_CHAT_NUMBER',
  /** At least trip on the quote does not have a required pricing fields */
  NoPricing = 'NO_PRICING',
  /** At least trip on the quote does not have a vehicle */
  NoVehicle = 'NO_VEHICLE'
}

export type SalesAutomationText = {
  __typename?: 'SalesAutomationText';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The message that is actually sent to user. Includes handlebars template that can be replaced with dynamic values. Null if stage is disabled. */
  content?: Maybe<Scalars['String']>;
  /** Order texts will send in */
  index: Scalars['Int'];
  /** Date the text was sent or is scheduled to send. Null if stage is disabled. */
  sendDate?: Maybe<Scalars['DateTime']>;
  /** Options are DRAFT, SENT, SCHEDULED, ERROR, or DISABLED */
  stage: SalesAutomationTextStageEnum;
  /** Dates of when users clicked the link included in the text */
  impressions?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  /** Person who recieves the automated texts. Always booking contact. But theoeretically could be different person if booking contact is changed after enrolling in sales automation. */
  recipient?: Maybe<Contact>;
};

export enum SalesAutomationTextStageEnum {
  /** Texts that have not yet been scheduled (i.e. client hasn't clicked enroll */
  Draft = 'DRAFT',
  /** Texts that have successfully sent. */
  Sent = 'SENT',
  /** Texts that are scheduled to send. */
  Scheduled = 'SCHEDULED',
  /** Texts that attempted and failed to send. */
  Error = 'ERROR',
  /** Texts that cannot be scheduled to send due to conflict on timing (trip date or reservation cutoff date conflicts with text sendDate) */
  Disabled = 'DISABLED'
}

export type SearchResult = Driver | Request | Contact | Company;

export type SendDriverAssignTextInput = {
  routeId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendDriverAssignTextOrNotificationFailure = {
  __typename?: 'SendDriverAssignTextOrNotificationFailure';
  message?: Maybe<Scalars['String']>;
};

export type SendDriverAssignTextOrNotificationInput = {
  routeId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendDriverAssignTextOrNotificationPayload = {
  __typename?: 'SendDriverAssignTextOrNotificationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendDriverAssignTextOrNotificationResult = SendDriverAssignTextOrNotificationSuccess | SendDriverAssignTextOrNotificationFailure;

export type SendDriverAssignTextOrNotificationSuccess = {
  __typename?: 'SendDriverAssignTextOrNotificationSuccess';
  success?: Maybe<Scalars['Boolean']>;
};

export type SendDriverAssignTextOrNotificationV2Input = {
  routeId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendDriverAssignTextOrNotificationV2Payload = {
  __typename?: 'SendDriverAssignTextOrNotificationV2Payload';
  result: SendDriverAssignTextOrNotificationResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendDriverAssignTextPayload = {
  __typename?: 'SendDriverAssignTextPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum SendEmailMode {
  Quote = 'QUOTE',
  Reservation = 'RESERVATION',
  Invoice = 'INVOICE'
}

export type SendEmailToCustomerInput = {
  requestId: Scalars['ID'];
  mode: SendEmailMode;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendEmailToCustomerPayload = {
  __typename?: 'SendEmailToCustomerPayload';
  request: Request;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendEmailToMultipleCustomersFailure = {
  __typename?: 'SendEmailToMultipleCustomersFailure';
  message?: Maybe<Scalars['String']>;
};

export type SendEmailToMultipleCustomersInput = {
  mode: EmailModeEnum;
  toEmails: Array<Maybe<Scalars['String']>>;
  ccEmails: Array<Maybe<Scalars['String']>>;
  message: Scalars['String'];
  pdfAttached?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['ID']>;
  invoiceId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendEmailToMultipleCustomersPayload = {
  __typename?: 'SendEmailToMultipleCustomersPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendEmailToMultipleCustomersResult = SendEmailToMultipleCustomersSuccess | SendEmailToMultipleCustomersFailure;

export type SendEmailToMultipleCustomersSuccess = {
  __typename?: 'SendEmailToMultipleCustomersSuccess';
  success?: Maybe<Scalars['Boolean']>;
};

export type SendEmailToMultipleCustomersV2Input = {
  mode: EmailModeEnum;
  toEmails: Array<Maybe<Scalars['String']>>;
  ccEmails: Array<Maybe<Scalars['String']>>;
  message: Scalars['String'];
  pdfAttached?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['ID']>;
  invoiceId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendEmailToMultipleCustomersV2Payload = {
  __typename?: 'SendEmailToMultipleCustomersV2Payload';
  result: SendEmailToMultipleCustomersResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendInvoiceEmailToCustomerInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendInvoiceEmailToCustomerPayload = {
  __typename?: 'SendInvoiceEmailToCustomerPayload';
  invoice?: Maybe<Invoice>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendRequestAffiliateEmailInput = {
  tripId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendRequestAffiliateEmailPayload = {
  __typename?: 'SendRequestAffiliateEmailPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetBasicOperatorDudaWebsiteUserPermissionsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetBasicOperatorDudaWebsiteUserPermissionsPayload = {
  __typename?: 'SetBasicOperatorDudaWebsiteUserPermissionsPayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetPremiumOperatorDudaWebsiteUserPermissionsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetPremiumOperatorDudaWebsiteUserPermissionsPayload = {
  __typename?: 'SetPremiumOperatorDudaWebsiteUserPermissionsPayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ShuttleRequestCommentInput = {
  bodyText: Scalars['String'];
};

export type ShuttleRequestRouteInformationInput = {
  vehicle?: Maybe<ShuttleRequestVehicleInformationInput>;
  baseRateAmt?: Maybe<Scalars['Float']>;
  hourlyBaseRateHours?: Maybe<Scalars['Float']>;
  driverGratuityAmt?: Maybe<Scalars['Float']>;
  driverGratuityPercent?: Maybe<Scalars['Float']>;
  promoDiscountAmt?: Maybe<Scalars['Float']>;
  promoDiscountPercent?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['Float']>;
  tollsAmt?: Maybe<Scalars['Float']>;
  otherName?: Maybe<Scalars['String']>;
  otherAmt?: Maybe<Scalars['Float']>;
  otherPercent?: Maybe<Scalars['Float']>;
  other2Name?: Maybe<Scalars['String']>;
  other2Amt?: Maybe<Scalars['Float']>;
  other2Percent?: Maybe<Scalars['Float']>;
  other3Name?: Maybe<Scalars['String']>;
  other3Amt?: Maybe<Scalars['Float']>;
  other3Percent?: Maybe<Scalars['Float']>;
  meetGreetAmt?: Maybe<Scalars['Float']>;
  carryOnLuggage?: Maybe<Scalars['Int']>;
  checkedLuggage?: Maybe<Scalars['Int']>;
  oversizeLuggage?: Maybe<Scalars['Int']>;
  promoCodeId?: Maybe<Scalars['String']>;
  promoCodeAmt?: Maybe<Scalars['Float']>;
  promoCodePercent?: Maybe<Scalars['Float']>;
  forwardFacingSeatQuantity?: Maybe<Scalars['Int']>;
  forwardFacingSeatAmt?: Maybe<Scalars['Float']>;
  rearFacingSeatQuantity?: Maybe<Scalars['Int']>;
  rearFacingSeatAmt?: Maybe<Scalars['Float']>;
  boosterSeatQuantity?: Maybe<Scalars['Int']>;
  boosterSeatAmt?: Maybe<Scalars['Float']>;
  driverGratuityIsPct?: Maybe<Scalars['Boolean']>;
  promoDiscountIsPct?: Maybe<Scalars['Boolean']>;
  taxIsPct?: Maybe<Scalars['Boolean']>;
  otherIsPct?: Maybe<Scalars['Boolean']>;
  other2IsPct?: Maybe<Scalars['Boolean']>;
  other3IsPct?: Maybe<Scalars['Boolean']>;
};

export type ShuttleRequestStopInformationInput = {
  location: Scalars['String'];
  dateTime?: Maybe<Scalars['DateTime']>;
  stopIndex: Scalars['Int'];
  groupSize?: Maybe<Scalars['Int']>;
  logisticStreetViewUrl?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  flightNumber?: Maybe<Scalars['String']>;
  airportIcao?: Maybe<Scalars['String']>;
  airlineIcao?: Maybe<Scalars['String']>;
  trackedFlight?: Maybe<TrackedFlightInput>;
  locationAlias?: Maybe<Scalars['String']>;
};

export type ShuttleRequestVehicleInformationInput = {
  id: Scalars['ID'];
};

export type ShuttleRoute = Node & {
  __typename?: 'ShuttleRoute';
  /** The ID of an object */
  id: Scalars['ID'];
  company: Company;
  name: Scalars['String'];
  shuttleRouteStops?: Maybe<Array<Maybe<Stop>>>;
};

/** sort direction for orderBy inputs */
export enum SortEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum StatusSlug {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Pending = 'PENDING',
  Otw = 'OTW',
  OnLocation = 'ON_LOCATION',
  Pob = 'POB',
  Done = 'DONE'
}

export type Stop = Node & {
  __typename?: 'Stop';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stopIndex: Scalars['Int'];
  location: Scalars['String'];
  dateTime?: Maybe<Scalars['DateTime']>;
  timezoneId?: Maybe<Scalars['String']>;
  groupSize?: Maybe<Scalars['Int']>;
  trip?: Maybe<Trip>;
  /** Photo of places for parking */
  logisticStreetViewUrl?: Maybe<Scalars['String']>;
  /** Common note for everyone */
  note?: Maybe<Scalars['String']>;
  /** Note for operator */
  operatorNote?: Maybe<Scalars['String']>;
  /** Note for driver */
  driverNote?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  flightNumber?: Maybe<Scalars['String']>;
  airport?: Maybe<Airport>;
  airline?: Maybe<Airline>;
  variant?: Maybe<Scalars['String']>;
  /** meet & greet and curbside are availible for airport and non-airport pickups */
  pickUpVariant?: Maybe<PickUpVariant>;
  /** @deprecated now use airport type */
  airportIcao?: Maybe<Scalars['String']>;
  /** @deprecated now use airline type */
  airlineIcao?: Maybe<Scalars['String']>;
  /** if stop is at an airport, this is the flight tracking info */
  trackedFlight?: Maybe<TrackedFlight>;
  /** @deprecated no longer used, use tracked flight only */
  flightData?: Maybe<Scalars['JSON']>;
  /** @deprecated no longer used. hasnt been used for a year but was not removed from client gql queries */
  flightDataUpdatedAt?: Maybe<Scalars['JSON']>;
  coordinates?: Maybe<Scalars['JSON']>;
  stopDispatch?: Maybe<StopDispatch>;
  /** If stop is airport, returns the original intended dateTime set by user */
  originalDateTime?: Maybe<Scalars['DateTime']>;
  /** Return the +/- minutes for a trip that contains a flight */
  flightOffset?: Maybe<Scalars['Int']>;
  /** the dateTime of the pick up for the trip containing this stop. Used for shuttle return trips */
  pickUpDateTime: Scalars['DateTime'];
  /** the timezoneId of the pick up for the trip containing this stop. Used for shuttle return trips */
  pickUpTimezoneId: Scalars['String'];
  /** Alias for location name */
  locationAlias?: Maybe<Scalars['String']>;
};

export type StopDispatch = {
  __typename?: 'StopDispatch';
  /** The ID of an object */
  id: Scalars['ID'];
  enRouteAt?: Maybe<Scalars['DateTime']>;
  arrivedAt?: Maybe<Scalars['DateTime']>;
  departedAt?: Maybe<Scalars['DateTime']>;
};

export type StopInformationInputForAutomatedPricing = {
  location: Scalars['String'];
  dateTime?: Maybe<Scalars['DateTime']>;
  coordinates?: Maybe<Scalars['JSONObject']>;
  airportIcao?: Maybe<Scalars['String']>;
};

export type StripeAccount = {
  __typename?: 'StripeAccount';
  /** The ID of an object */
  id: Scalars['ID'];
  status: StripeAccountStatusEnum;
  statusDescription: Scalars['String'];
  isPayoutsEnabled: Scalars['Boolean'];
  isTreasuryEnabled: Scalars['Boolean'];
  payoutSchedule: PayoutSchedule;
  isAccountSetupComplete: Scalars['Boolean'];
  bankAccounts?: Maybe<Array<Maybe<BankAccount>>>;
};

/** Link to Connected Accounts onboarding */
export type StripeAccountLink = {
  __typename?: 'StripeAccountLink';
  url: Scalars['String'];
};

export enum StripeAccountStatusEnum {
  Complete = 'COMPLETE',
  Enabled = 'ENABLED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Restricted = 'RESTRICTED',
  RestrictedSoon = 'RESTRICTED_SOON'
}

export type StripeFinancialAccount = {
  __typename?: 'StripeFinancialAccount';
  /** The ID of an object */
  id: Scalars['ID'];
  balance: Scalars['Float'];
};

/** A stripe payout for connected account */
export type StripePayout = {
  __typename?: 'StripePayout';
  stripePayoutId: Scalars['String'];
  payoutDate: Scalars['DateTime'];
  amount: Scalars['Float'];
  status: Scalars['String'];
  variant: Scalars['String'];
};

/** A connection to a list of items. */
export type StripePayoutsConnection = {
  __typename?: 'StripePayoutsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StripePayoutsEdge>>>;
  payoutsEnabled: Scalars['Boolean'];
  totalCount: Scalars['Int'];
  payoutIntervalStart?: Maybe<Scalars['Int']>;
  payoutIntervalEnd?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type StripePayoutsEdge = {
  __typename?: 'StripePayoutsEdge';
  /** The item at the end of the edge */
  node?: Maybe<StripePayout>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A transaction related to a stripe payout */
export type StripeTransactions = {
  __typename?: 'StripeTransactions';
  action: Scalars['String'];
  contact?: Maybe<Contact>;
  /** Trips in this list may belong to different requests. (source Invoice) */
  trips?: Maybe<Array<Trip>>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  gross: Scalars['Float'];
  fees: Scalars['Float'];
  netTotal: Scalars['Float'];
  /** The flow in which the payment for this transaction was created. */
  source?: Maybe<PaymentSource>;
  farmor?: Maybe<FarmAffiliate>;
};

/** A connection to a list of items. */
export type StripeTransactionsConnection = {
  __typename?: 'StripeTransactionsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StripeTransactionsEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type StripeTransactionsEdge = {
  __typename?: 'StripeTransactionsEdge';
  /** The item at the end of the edge */
  node?: Maybe<StripeTransactions>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type SubPayment = Node & {
  __typename?: 'SubPayment';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  paymentId: Scalars['ID'];
  amount: Scalars['Float'];
  route: Route;
  payment: Payment;
  refunds?: Maybe<Array<Maybe<PaymentRefund>>>;
};

/** ID and type of source */
export type SubPaymentInput = {
  routeId: Scalars['ID'];
  /** in dollars */
  amount: Scalars['Float'];
};

export type SubRefund = Node & {
  __typename?: 'SubRefund';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  amount: Scalars['Float'];
  route: Route;
};

export type SubscriptionMetrics = {
  __typename?: 'SubscriptionMetrics';
  userCount: Scalars['Int'];
  tripCount: Scalars['Int'];
  vehicleCount: Scalars['Int'];
  driverCount: Scalars['Int'];
  renewalDate?: Maybe<Scalars['DateTime']>;
};

export type TeamMember = Node & {
  __typename?: 'TeamMember';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  contact: Contact;
};

export type TemporaryPassenger = {
  __typename?: 'TemporaryPassenger';
  name?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneCountryName?: Maybe<Scalars['String']>;
  phoneCountryFormat?: Maybe<Scalars['String']>;
  phoneCountryDialCode?: Maybe<Scalars['String']>;
  mobilePhoneInternational?: Maybe<Scalars['String']>;
};

export type Term = Node & {
  __typename?: 'Term';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  description: Scalars['String'];
  guideline: Scalars['String'];
};

export type TermType = Node & {
  __typename?: 'TermType';
  /** The ID of an object */
  id: Scalars['ID'];
  name: Scalars['String'];
  guideline: Scalars['String'];
};

export enum TextRecipientEnum {
  None = 'NONE',
  Both = 'BOTH',
  Passenger = 'PASSENGER',
  BookingContact = 'BOOKING_CONTACT'
}

export type Tier = {
  __typename?: 'Tier';
  /** in hours if hourly, in miles if transfer */
  lowerLimit: Scalars['Int'];
  /** in hours if hourly, in miles if transfer */
  upperLimit: Scalars['Int'];
  /** in hours if hourly, in miles if transfer. -1 indicates last tier */
  incrementalAmt: Scalars['Int'];
  /** saved in db as cents and sending to client in dollars */
  rate: Scalars['Float'];
  type: PricingVariant;
};

export type TieredPricingHourly = {
  __typename?: 'TieredPricingHourly';
  type: TieredPricingVariant;
  tiers: Array<Maybe<Tier>>;
};

export type TieredPricingTransfer = {
  __typename?: 'TieredPricingTransfer';
  type: TieredPricingVariant;
  tiers: Array<Maybe<Tier>>;
};

export enum TieredPricingVariant {
  Incremental = 'INCREMENTAL',
  Fixed = 'FIXED'
}


export enum TimeDuration {
  Hours = 'HOURS',
  Days = 'DAYS'
}

export enum TimeInterval {
  Month = 'MONTH',
  Day = 'DAY'
}

export type ToggleDriverAppAmountDueDisplayInput = {
  enabled: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ToggleDriverAppAmountDueDisplayPayload = {
  __typename?: 'ToggleDriverAppAmountDueDisplayPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TrackedFlight = Node & {
  __typename?: 'TrackedFlight';
  /** The ID of an object */
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  flightStatus: FlightStatus;
  /** Equivalent to "ident" in flightAware. e.g. UAL301 */
  flightNumber: Scalars['String'];
  /**
   * If codeshare, is the actual flightnumber
   *         tied to the airline actually providing the flight.
   *         Equivalent to "actualIdent" in flightAware. e.g. UAL301
   */
  actualFlightNumber?: Maybe<Scalars['String']>;
  /** id created by FlightAware that tracks an actual flight tied to an actual airplane. */
  faFlightId?: Maybe<Scalars['String']>;
  airline: Airline;
  /** If codeshare, is the actual airline providing the flight. */
  actualAirline?: Maybe<Airline>;
  /** Where the flight departs from. */
  origin: TransitStop;
  /** Where the flight arrives at. */
  destination: TransitStop;
};

export type TrackedFlightInput = {
  id?: Maybe<Scalars['ID']>;
  faFlightId?: Maybe<Scalars['String']>;
  flightStatus: FlightStatus;
  /** Client sends flightNumber as actualFlightNumber if there is no actualFlightNumber (i.e. no codeshare). */
  actualFlightNumber?: Maybe<Scalars['String']>;
  /** Where the flight departs from. */
  origin: TransitStopInput;
  /** Where the flight arrives at. */
  destination: TransitStopInput;
};

export type TrackedVehicle = {
  __typename?: 'TrackedVehicle';
  id: Scalars['String'];
  name: Scalars['String'];
  deviceId: Scalars['String'];
};

export type Transaction = Node & {
  __typename?: 'Transaction';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  paidAt?: Maybe<Scalars['DateTime']>;
  type: TransactionTypeEnum;
  totalAmount: Scalars['Float'];
  includedGratuity: Scalars['Float'];
  promoAmount: Scalars['Float'];
  /** @deprecated Use note in future */
  swoopNote?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  fromContact?: Maybe<Contact>;
  fromOperator?: Maybe<Operator>;
  toOperator?: Maybe<Operator>;
};

export enum TransactionTypeEnum {
  Payment = 'PAYMENT',
  Refund = 'REFUND'
}

/** Info related to origin (departure) or destination (arrival) airport of a flight. */
export type TransitStop = {
  __typename?: 'TransitStop';
  id?: Maybe<Scalars['ID']>;
  airport?: Maybe<Airport>;
  scheduledDateTime: Scalars['DateTime'];
  estimatedDateTime?: Maybe<Scalars['DateTime']>;
  actualDateTime?: Maybe<Scalars['DateTime']>;
  /** Terminal when flight is filed. */
  scheduledTerminal?: Maybe<Scalars['String']>;
  /** Most recent terminal (if different than scheduled) */
  actualTerminal?: Maybe<Scalars['String']>;
  /** Gate when flight is filed. */
  scheduledGate?: Maybe<Scalars['String']>;
  /** Most recent gate (if different than scheduled) */
  actualGate?: Maybe<Scalars['String']>;
};

/** Info related to origin (departure) or destination (arrival) airport of a flight. */
export type TransitStopInput = {
  id?: Maybe<Scalars['ID']>;
  airportIcao: Scalars['String'];
  scheduledDateTime: Scalars['DateTime'];
  estimatedDateTime?: Maybe<Scalars['DateTime']>;
  actualDateTime?: Maybe<Scalars['DateTime']>;
  /** Terminal when flight is filed. */
  scheduledTerminal?: Maybe<Scalars['String']>;
  /** Most recent terminal (if different than scheduled) */
  actualTerminal?: Maybe<Scalars['String']>;
  /** Gate when flight is filed. */
  scheduledGate?: Maybe<Scalars['String']>;
  /** Most recent gate (if different than scheduled) */
  actualGate?: Maybe<Scalars['String']>;
};

export type Trip = Node & {
  __typename?: 'Trip';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  cancelledAt?: Maybe<Scalars['DateTime']>;
  requestId: Scalars['ID'];
  tripCategory: TripCategory;
  roundTripVariant?: Maybe<RoundTripVariant>;
  /** Return trips are excluded from list of all trips, they only live on outbound trip */
  returnTrip?: Maybe<Trip>;
  totalGroupSize: Scalars['Int'];
  availableSeats: Scalars['Int'];
  totalDuration?: Maybe<Scalars['Int']>;
  estimatedDuration?: Maybe<Scalars['Int']>;
  googleDirectionsResult?: Maybe<Scalars['JSON']>;
  useTotalDuration?: Maybe<Scalars['Boolean']>;
  timezoneId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  tripNumber?: Maybe<Scalars['String']>;
  stops: Array<Maybe<Stop>>;
  routes: Array<Maybe<Route>>;
  contact?: Maybe<Contact>;
  /** Billings table records tied to the trip. No new records should be added after request_stage_field migration */
  billings?: Maybe<Array<Maybe<Billing>>>;
  subPayments?: Maybe<Array<Maybe<SubPayment>>>;
  /** total amount of all route pricing */
  totalAmount: Scalars['Float'];
  /** Remaining amount left to be paid. */
  amountDue: Scalars['Float'];
  /** payment status for whole trip */
  paymentStatus: PaymentStatus;
  tempPassenger?: Maybe<TemporaryPassenger>;
  /** @deprecated switching to tempPassenger, so that we can have a type instead of just JSON */
  temporaryPassenger?: Maybe<Scalars['JSON']>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  closedAt?: Maybe<Scalars['DateTime']>;
  farmedRouteStatus?: Maybe<FarmedRouteStatusEnum>;
  /** overall farm status of all trips except current one */
  otherTripsFarmedRouteStatus?: Maybe<FarmedRouteStatusEnum>;
  /**
   * Disabled if...
   *         1. stops are before today's date.
   *         OR
   *         2. operator is farmor AND farmee has not closed out the trip.
   *         If null, then close out is NOT disabled.
   */
  closeOutDisabledVariant?: Maybe<CloseOutDisabledEnum>;
  reopenDisabled?: Maybe<Scalars['Boolean']>;
  request: Request;
  isTripInvoiced?: Maybe<Scalars['Boolean']>;
  canAutomateCustomerChange: Scalars['Boolean'];
  shuttleRoute?: Maybe<ShuttleRoute>;
  childSeats: Scalars['JSON'];
  notification?: Maybe<Notification>;
  reminderTextTo: TextRecipientEnum;
  statusUpdateTextTo: TextRecipientEnum;
  cancellationRequestedAt?: Maybe<Scalars['DateTime']>;
  calendarTripInviteBcEnabled: Scalars['Boolean'];
  calendarTripInvitePassengerEnabled: Scalars['Boolean'];
  organizer?: Maybe<User>;
  canRequestTripEdit: Scalars['Boolean'];
  estimatedDistanceMeters?: Maybe<Scalars['Float']>;
};

export enum TripCategory {
  OneWay = 'ONE_WAY',
  Hourly = 'HOURLY',
  RoundTrip = 'ROUND_TRIP',
  ShuttlePickUp = 'SHUTTLE_PICK_UP',
  ShuttleReturn = 'SHUTTLE_RETURN'
}

export type TripChangeMetadata = {
  __typename?: 'TripChangeMetadata';
  orderTypePrevious?: Maybe<OrderTypeEnum>;
  driverNotePrevious?: Maybe<Scalars['String']>;
  vehiclePrevious?: Maybe<Vehicle>;
  forwardFacingSeatQuantityPrevious?: Maybe<Scalars['Int']>;
  rearFacingSeatQuantityPrevious?: Maybe<Scalars['Int']>;
  boosterSeatQuantityPrevious?: Maybe<Scalars['Int']>;
  swoopNotePrevious?: Maybe<Scalars['String']>;
  stopsPrevious?: Maybe<Array<Maybe<Stop>>>;
};

export type TripCommentInput = {
  bodyText: Scalars['String'];
};

export type TripContactInformationInput = {
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  preferences?: Maybe<Scalars['String']>;
};

export enum TripLocationsVariant {
  GarageToGarage = 'GARAGE_TO_GARAGE',
  LocationDropOff = 'LOCATION_DROP_OFF'
}

export type TripReview = {
  __typename?: 'TripReview';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  trip: Trip;
  /** 1 to 5 */
  rating: Scalars['Int'];
  body?: Maybe<Scalars['String']>;
  reviewSite?: Maybe<ReviewSite>;
};

export type TripRouteInformationInput = {
  vehicle?: Maybe<TripVehicleInformationInput>;
  baseRateAmt?: Maybe<Scalars['Float']>;
  hourlyBaseRateHours?: Maybe<Scalars['Float']>;
  driverGratuityAmt?: Maybe<Scalars['Float']>;
  driverGratuityPercent?: Maybe<Scalars['Float']>;
  promoDiscountAmt?: Maybe<Scalars['Float']>;
  promoDiscountPercent?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['Float']>;
  tollsAmt?: Maybe<Scalars['Float']>;
  otherName?: Maybe<Scalars['String']>;
  otherAmt?: Maybe<Scalars['Float']>;
  otherPercent?: Maybe<Scalars['Float']>;
  other2Name?: Maybe<Scalars['String']>;
  other2Amt?: Maybe<Scalars['Float']>;
  other2Percent?: Maybe<Scalars['Float']>;
  other3Name?: Maybe<Scalars['String']>;
  other3Amt?: Maybe<Scalars['Float']>;
  other3Percent?: Maybe<Scalars['Float']>;
  meetGreetAmt?: Maybe<Scalars['Float']>;
  carryOnLuggage?: Maybe<Scalars['Int']>;
  checkedLuggage?: Maybe<Scalars['Int']>;
  oversizeLuggage?: Maybe<Scalars['Int']>;
  promoCodeId?: Maybe<Scalars['String']>;
  promoCodeAmt?: Maybe<Scalars['Float']>;
  promoCodePercent?: Maybe<Scalars['Float']>;
  forwardFacingSeatQuantity?: Maybe<Scalars['Int']>;
  forwardFacingSeatAmt?: Maybe<Scalars['Float']>;
  rearFacingSeatQuantity?: Maybe<Scalars['Int']>;
  rearFacingSeatAmt?: Maybe<Scalars['Float']>;
  boosterSeatQuantity?: Maybe<Scalars['Int']>;
  boosterSeatAmt?: Maybe<Scalars['Float']>;
  driverGratuityIsPct?: Maybe<Scalars['Boolean']>;
  promoDiscountIsPct?: Maybe<Scalars['Boolean']>;
  taxIsPct?: Maybe<Scalars['Boolean']>;
  otherIsPct?: Maybe<Scalars['Boolean']>;
  other2IsPct?: Maybe<Scalars['Boolean']>;
  other3IsPct?: Maybe<Scalars['Boolean']>;
};

export type TripStopInput = {
  location: Scalars['String'];
  dateTime?: Maybe<Scalars['DateTime']>;
  stopIndex: Scalars['Int'];
  airportIcao?: Maybe<Scalars['String']>;
};

export type TripTripInformationInput = {
  totalGroupSize: Scalars['Int'];
  stops: Array<Maybe<CreateTripStopInformationInput>>;
  routes: Array<Maybe<TripRouteInformationInput>>;
  contact: TripContactInformationInput;
  note?: Maybe<Scalars['String']>;
  temporaryPassenger?: Maybe<Scalars['JSON']>;
  comments?: Maybe<Array<Maybe<TripCommentInput>>>;
  tripType?: Maybe<TripCategory>;
  shuttleRouteId?: Maybe<Scalars['ID']>;
};

export type TripVehicleInformationInput = {
  id: Scalars['ID'];
};

export type UndoCloseOutTripInput = {
  tripId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UndoCloseOutTripPayload = {
  __typename?: 'UndoCloseOutTripPayload';
  trip: Trip;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UndoConvertQuoteInput = {
  requestId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UndoConvertQuotePayload = {
  __typename?: 'UndoConvertQuotePayload';
  request: Request;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UndoRemoveTripInput = {
  tripId: Scalars['ID'];
  returnTripId?: Maybe<Scalars['ID']>;
  outboundTripId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UndoRemoveTripPayload = {
  __typename?: 'UndoRemoveTripPayload';
  trip?: Maybe<Trip>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnlinkExternalOperatorDriverInput = {
  driverId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnlinkExternalOperatorDriverPayload = {
  __typename?: 'UnlinkExternalOperatorDriverPayload';
  externalOperator: ExternalOperator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnlinkExternalOperatorVehicleInput = {
  vehicleId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnlinkExternalOperatorVehiclePayload = {
  __typename?: 'UnlinkExternalOperatorVehiclePayload';
  externalOperator: ExternalOperator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnlinkPaymentMethodInput = {
  paymentMethodId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnlinkPaymentMethodPayload = {
  __typename?: 'UnlinkPaymentMethodPayload';
  paymentMethod: PaymentMethod;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateBookingCutoffTimeInput = {
  bookingCutoffTimeValue?: Maybe<Scalars['Float']>;
  bookingCutoffTimeType?: Maybe<TimeDuration>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateBookingCutoffTimePayload = {
  __typename?: 'UpdateBookingCutoffTimePayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCalendarTripInviteInput = {
  tripId: Scalars['ID'];
  calendarTripInviteBcEnabled: Scalars['Boolean'];
  calendarTripInvitePassengerEnabled: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCalendarTripInvitePayload = {
  __typename?: 'UpdateCalendarTripInvitePayload';
  trip: Trip;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCancellationPolicyInput = {
  id: Scalars['ID'];
  refund100?: Maybe<RefundInput>;
  refund50?: Maybe<RefundInput>;
  refund25?: Maybe<RefundInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCancellationPolicyPayload = {
  __typename?: 'UpdateCancellationPolicyPayload';
  cancellationPolicy?: Maybe<CancellationPolicy>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCompanyInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCompanyPayload = {
  __typename?: 'UpdateCompanyPayload';
  company?: Maybe<Company>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateContactInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  companyPosition?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  preferences?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Scalars['String']>;
  workAddress?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneCountryDialCode?: Maybe<Scalars['String']>;
  phoneCountryName?: Maybe<Scalars['String']>;
  phoneCountryFormat?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateContactPayload = {
  __typename?: 'UpdateContactPayload';
  contact?: Maybe<Contact>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDefaultBankAccountInput = {
  bankAccountId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDefaultBankAccountPayload = {
  __typename?: 'UpdateDefaultBankAccountPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDriverInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneCountryDialCode?: Maybe<Scalars['String']>;
  phoneCountryName?: Maybe<Scalars['String']>;
  phoneCountryFormat?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseClass?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  licenseExpirationDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  driverProfilePhoto?: Maybe<Scalars['String']>;
  settings?: Maybe<DriverSettingsInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDriverPayload = {
  __typename?: 'UpdateDriverPayload';
  driver?: Maybe<Driver>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDriverPayoutInput = {
  routeId: Scalars['ID'];
  driverPayoutFlatRate?: Maybe<Scalars['Float']>;
  driverPayoutGratuity?: Maybe<Scalars['Float']>;
  driverPayoutRatePerHour?: Maybe<Scalars['Float']>;
  driverPayoutHours?: Maybe<Scalars['Float']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDriverPayoutPayload = {
  __typename?: 'UpdateDriverPayoutPayload';
  route: Route;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDynamicPricingRuleActiveStatusInput = {
  ruleId: Scalars['ID'];
  active: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDynamicPricingRuleActiveStatusPayload = {
  __typename?: 'UpdateDynamicPricingRuleActiveStatusPayload';
  rule: DynamicPricingRule;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDynamicPricingRuleInput = {
  ruleId: Scalars['ID'];
  name: Scalars['String'];
  amount: Scalars['Float'];
  isPercent: Scalars['Boolean'];
  /** true for increase, false for decrease */
  isIncrease: Scalars['Boolean'];
  vehicleIds?: Maybe<Array<Scalars['ID']>>;
  daysOfWeek?: Maybe<Array<DayOfWeek>>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  repeatAnnually?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDynamicPricingRulePayload = {
  __typename?: 'UpdateDynamicPricingRulePayload';
  rule: DynamicPricingRule;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateExternalOperatorInput = {
  id: Scalars['ID'];
  operatorName?: Maybe<Scalars['String']>;
  operatorEmail?: Maybe<Scalars['String']>;
  operatorPhone?: Maybe<Scalars['String']>;
  operatorAddress?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateExternalOperatorPayload = {
  __typename?: 'UpdateExternalOperatorPayload';
  externalOperator: ExternalOperator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateFarmedRoutePricingInput = {
  routeId: Scalars['ID'];
  baseRateAmt?: Maybe<Scalars['Float']>;
  hourlyBaseRateHours?: Maybe<Scalars['Float']>;
  driverGratuityAmt?: Maybe<Scalars['Float']>;
  driverGratuityPercent?: Maybe<Scalars['Float']>;
  promoDiscountAmt?: Maybe<Scalars['Float']>;
  promoDiscountPercent?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['Float']>;
  tollsAmt?: Maybe<Scalars['Float']>;
  otherName?: Maybe<Scalars['String']>;
  otherAmt?: Maybe<Scalars['Float']>;
  otherPercent?: Maybe<Scalars['Float']>;
  other2Name?: Maybe<Scalars['String']>;
  other2Amt?: Maybe<Scalars['Float']>;
  other2Percent?: Maybe<Scalars['Float']>;
  other3Name?: Maybe<Scalars['String']>;
  other3Amt?: Maybe<Scalars['Float']>;
  other3Percent?: Maybe<Scalars['Float']>;
  promoCodeId?: Maybe<Scalars['String']>;
  promoCodeAmt?: Maybe<Scalars['Float']>;
  promoCodePercent?: Maybe<Scalars['Float']>;
  meetGreetAmt?: Maybe<Scalars['Float']>;
  forwardFacingSeatQuantity?: Maybe<Scalars['Int']>;
  forwardFacingSeatAmt?: Maybe<Scalars['Float']>;
  rearFacingSeatQuantity?: Maybe<Scalars['Int']>;
  rearFacingSeatAmt?: Maybe<Scalars['Float']>;
  boosterSeatQuantity?: Maybe<Scalars['Int']>;
  boosterSeatAmt?: Maybe<Scalars['Float']>;
  driverGratuityIsPct?: Maybe<Scalars['Boolean']>;
  promoDiscountIsPct?: Maybe<Scalars['Boolean']>;
  taxIsPct?: Maybe<Scalars['Boolean']>;
  otherIsPct?: Maybe<Scalars['Boolean']>;
  other2IsPct?: Maybe<Scalars['Boolean']>;
  other3IsPct?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateFarmedRoutePricingPayload = {
  __typename?: 'UpdateFarmedRoutePricingPayload';
  route: Route;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateFarmedRouteStatusInput = {
  routeId: Scalars['ID'];
  farmedRouteStatus: FarmedRouteStatusEnum;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateFarmedRouteStatusPayload = {
  __typename?: 'UpdateFarmedRouteStatusPayload';
  route: Route;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateGriddnetOperatorNoteInput = {
  griddnetOperatorId: Scalars['String'];
  internalNote: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateGriddnetOperatorNotePayload = {
  __typename?: 'UpdateGriddnetOperatorNotePayload';
  griddnetOperator: GriddnetOperator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateInsurancePolicyInput = {
  id: Scalars['ID'];
  insuranceCompany?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  coverageAmount?: Maybe<Scalars['Float']>;
  monthlyPremium?: Maybe<Scalars['Float']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateInsurancePolicyPayload = {
  __typename?: 'UpdateInsurancePolicyPayload';
  insurancePolicy: InsurancePolicy;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateNameSlugInput = {
  nameSlug: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateNameSlugPayload = {
  __typename?: 'UpdateNameSlugPayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOnboardingOperatorInput = {
  companyName: Scalars['String'];
  address: Scalars['String'];
  currencyCode: Scalars['String'];
  distanceUnit: Scalars['String'];
  websiteUrl?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOnboardingOperatorPayload = {
  __typename?: 'UpdateOnboardingOperatorPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOnboardingUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobilePhone: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  phoneCountryDialCode: Scalars['String'];
  phoneCountryName: Scalars['String'];
  phoneCountryFormat: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOnboardingUserPayload = {
  __typename?: 'UpdateOnboardingUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOperatorGettingStartedInput = {
  skipQuote?: Maybe<Scalars['Boolean']>;
  removeBanner?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOperatorGettingStartedPayload = {
  __typename?: 'UpdateOperatorGettingStartedPayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOperatorGnetIdInput = {
  gnetId?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOperatorGnetIdPayload = {
  __typename?: 'UpdateOperatorGnetIdPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOperatorInput = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  permitNumber?: Maybe<Scalars['String']>;
  generalEmail?: Maybe<Scalars['String']>;
  customDomainFromName?: Maybe<Scalars['String']>;
  bookingEmail?: Maybe<Scalars['String']>;
  voicePhoneNumber?: Maybe<Scalars['String']>;
  textPhoneNumber?: Maybe<Scalars['String']>;
  widgetBannerUrl?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  pricingLayout?: Maybe<PricingLayoutInputType>;
  voicePhoneCountryCode?: Maybe<Scalars['String']>;
  voicePhoneCountryDialCode?: Maybe<Scalars['String']>;
  voicePhoneCountryName?: Maybe<Scalars['String']>;
  voicePhoneCountryFormat?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOperatorPayload = {
  __typename?: 'UpdateOperatorPayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOperatorReviewSiteSettingsInput = {
  reviewSite?: Maybe<ReviewSite>;
  reviewSiteId?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOperatorReviewSiteSettingsPayload = {
  __typename?: 'UpdateOperatorReviewSiteSettingsPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOperatorRouteInput = {
  id: Scalars['ID'];
  /** Works only for changing vehicle */
  vehicleId?: Maybe<Scalars['ID']>;
  /** Works only for changing driver */
  driverId?: Maybe<Scalars['ID']>;
  /** Ride status */
  statusSlug?: Maybe<Scalars['String']>;
  alert?: Maybe<AlertInput>;
  driverNote?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOperatorRoutePayload = {
  __typename?: 'UpdateOperatorRoutePayload';
  operatorRoute?: Maybe<OperatorRoute>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOperatorSettingsInput = {
  remindDriverPendingJobs?: Maybe<Scalars['Boolean']>;
  remindDriverTripStatus?: Maybe<Scalars['Boolean']>;
  sendTripCancellationEmail?: Maybe<Scalars['Boolean']>;
  googleTrackingId?: Maybe<Scalars['String']>;
  /** Expects all 3 driver gratuity preset pct values in an array. */
  driverGratuityPresets?: Maybe<Array<Maybe<Scalars['Int']>>>;
  gratuityWhenBooking?: Maybe<Scalars['Boolean']>;
  customGratuityWhenBooking?: Maybe<Scalars['Boolean']>;
  cashGratuityWhenBooking?: Maybe<Scalars['Boolean']>;
  moovsChatNumberAsPrimary?: Maybe<Scalars['Boolean']>;
  luggageEnabled?: Maybe<Scalars['Boolean']>;
  stopPrice?: Maybe<Scalars['Int']>;
  dynamicPricingRulesCombine?: Maybe<Scalars['Boolean']>;
  driverFlightUpdatedTextsEnabled?: Maybe<Scalars['Boolean']>;
  internalFlightUpdatedTextsEnabled?: Maybe<Scalars['Boolean']>;
  pricingLayout?: Maybe<PricingLayoutType>;
  colorTheme?: Maybe<ColorThemeVariant>;
  requireConsentWhenBooking?: Maybe<Scalars['Boolean']>;
  requireSignatureWhenBooking?: Maybe<Scalars['Boolean']>;
  widgetCta?: Maybe<WidgetCta>;
  autoPaymentPreference?: Maybe<AutoPaymentPreference>;
  autoPaymentDeposit?: Maybe<AutoPaymentDepositType>;
  customerChangeAutomationEnabled?: Maybe<Scalars['Boolean']>;
  customerChangeAutomationTimeValue?: Maybe<Scalars['Int']>;
  customerChangeAutomationTimeType?: Maybe<TimeDuration>;
  reviewSiteTextIncludeLink?: Maybe<Scalars['Boolean']>;
  shuttleLateTextEnabled?: Maybe<Scalars['Boolean']>;
  memberChangedStatusTextEnabled?: Maybe<Scalars['Boolean']>;
  driverDeclineJobTextEnabled?: Maybe<Scalars['Boolean']>;
  driverDeclineJobEmailEnabled?: Maybe<Scalars['Boolean']>;
  skipVehicleSelectionEnabled?: Maybe<Scalars['Boolean']>;
  skipVehicleDefaultVehicleId?: Maybe<Scalars['String']>;
  notifyDriverTripChangeEnabled?: Maybe<Scalars['Boolean']>;
  tripStatusUpdateTextTo?: Maybe<TextRecipientEnum>;
  pobStatusTextToBcEnabled?: Maybe<Scalars['Boolean']>;
  tripReminderTextTo?: Maybe<TextRecipientEnum>;
  customerTripCancellationRequestEnabled?: Maybe<Scalars['Boolean']>;
  calendarTripInviteBcEnabled?: Maybe<Scalars['Boolean']>;
  calendarTripInvitePassengerEnabled?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOperatorSettingsPayload = {
  __typename?: 'UpdateOperatorSettingsPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOperatorTripTypeInput = {
  tripType: Scalars['String'];
  enabled: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOperatorTripTypePayload = {
  __typename?: 'UpdateOperatorTripTypePayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePaymentMethodInput = {
  payerId: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  cardNote?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePaymentMethodPayload = {
  __typename?: 'UpdatePaymentMethodPayload';
  paymentMethod: PaymentMethod;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePayoutScheduleInput = {
  payoutSchedule: PayoutSchedule;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePayoutSchedulePayload = {
  __typename?: 'UpdatePayoutSchedulePayload';
  stripeAccount: StripeAccount;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePriceZoneInput = {
  id: Scalars['ID'];
  zoneName?: Maybe<Scalars['String']>;
  /** zip_code zoneVariant only */
  zipCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** location zoneVariant only */
  radius?: Maybe<Scalars['Float']>;
  /** location zoneVariant only */
  centerCoordinates?: Maybe<CenterCoordinatesInput>;
  /** location zoneVariant only */
  geoJson?: Maybe<Scalars['JSON']>;
  /** location zoneVariant only */
  address?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePriceZonePayload = {
  __typename?: 'UpdatePriceZonePayload';
  priceZone: PriceZone;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePriceZoneRouteBaseRatesInput = {
  id: Scalars['ID'];
  baseRates?: Maybe<Array<Maybe<BaseRateInput>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePriceZoneRouteBaseRatesPayload = {
  __typename?: 'UpdatePriceZoneRouteBaseRatesPayload';
  priceZoneRoute: PriceZoneRoute;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePrimaryPaymentMethodInput = {
  paymentMethodId: Scalars['ID'];
  payerId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePrimaryPaymentMethodPayload = {
  __typename?: 'UpdatePrimaryPaymentMethodPayload';
  paymentMethod?: Maybe<PaymentMethod>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePrimarySubscriptionPaymentMethodInput = {
  subscriptionPaymentMethodId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePrimarySubscriptionPaymentMethodPayload = {
  __typename?: 'UpdatePrimarySubscriptionPaymentMethodPayload';
  paymentMethod: PaymentMethod;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePromoCodeInput = {
  id: Scalars['ID'];
  promoCodeAmt?: Maybe<Scalars['Float']>;
  promoCodePercent?: Maybe<Scalars['Float']>;
  totalLimit?: Maybe<Scalars['Int']>;
  bookingContactLimit?: Maybe<Scalars['Int']>;
  bookingStartDate?: Maybe<Scalars['DateTime']>;
  bookingEndDate?: Maybe<Scalars['DateTime']>;
  tripStartDate?: Maybe<Scalars['DateTime']>;
  tripEndDate?: Maybe<Scalars['DateTime']>;
  active?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePromoCodePayload = {
  __typename?: 'UpdatePromoCodePayload';
  promoCode?: Maybe<PromoCode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRequestCompanyLinkingInput = {
  requestId: Scalars['ID'];
  linkCompany: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRequestCompanyLinkingPayload = {
  __typename?: 'UpdateRequestCompanyLinkingPayload';
  request: Request;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRequestInput = {
  requestId: Scalars['ID'];
  orderType?: Maybe<Scalars['String']>;
  bookingContactId?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRequestPayload = {
  __typename?: 'UpdateRequestPayload';
  request: Request;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRouteDispatchInput = {
  routeId: Scalars['ID'];
  vehicleId?: Maybe<Scalars['ID']>;
  driverId?: Maybe<Scalars['ID']>;
  /** What stage the ride is in. POB, OTW, Completed, etc. */
  statusSlug?: Maybe<Scalars['String']>;
  alert?: Maybe<AlertInput>;
  driverNote?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRouteDispatchPayload = {
  __typename?: 'UpdateRouteDispatchPayload';
  route: Route;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRouteInput = {
  id: Scalars['ID'];
  /** Works only for changing vehicle */
  vehicleId?: Maybe<Scalars['ID']>;
  /** Works only for changing driver */
  driverId?: Maybe<Scalars['ID']>;
  /** Note for driver */
  driverNote?: Maybe<Scalars['String']>;
  operatorConfirmation?: Maybe<Scalars['String']>;
  carryOnLuggage?: Maybe<Scalars['Int']>;
  checkedLuggage?: Maybe<Scalars['Int']>;
  oversizeLuggage?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRoutePayload = {
  __typename?: 'UpdateRoutePayload';
  route?: Maybe<Route>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRoutePricingInput = {
  id: Scalars['ID'];
  baseRateAmt?: Maybe<Scalars['Float']>;
  hourlyBaseRateHours?: Maybe<Scalars['Float']>;
  driverGratuityAmt?: Maybe<Scalars['Float']>;
  driverGratuityPercent?: Maybe<Scalars['Float']>;
  promoDiscountAmt?: Maybe<Scalars['Float']>;
  promoDiscountPercent?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['Float']>;
  tollsAmt?: Maybe<Scalars['Float']>;
  otherName?: Maybe<Scalars['String']>;
  otherAmt?: Maybe<Scalars['Float']>;
  otherPercent?: Maybe<Scalars['Float']>;
  other2Name?: Maybe<Scalars['String']>;
  other2Amt?: Maybe<Scalars['Float']>;
  other2Percent?: Maybe<Scalars['Float']>;
  other3Name?: Maybe<Scalars['String']>;
  other3Amt?: Maybe<Scalars['Float']>;
  other3Percent?: Maybe<Scalars['Float']>;
  promoCodeId?: Maybe<Scalars['String']>;
  promoCodeAmt?: Maybe<Scalars['Float']>;
  promoCodePercent?: Maybe<Scalars['Float']>;
  meetGreetAmt?: Maybe<Scalars['Float']>;
  forwardFacingSeatQuantity?: Maybe<Scalars['Int']>;
  forwardFacingSeatAmt?: Maybe<Scalars['Float']>;
  rearFacingSeatQuantity?: Maybe<Scalars['Int']>;
  rearFacingSeatAmt?: Maybe<Scalars['Float']>;
  boosterSeatQuantity?: Maybe<Scalars['Int']>;
  boosterSeatAmt?: Maybe<Scalars['Float']>;
  baseRateAutomation?: Maybe<BaseRateAutomationInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRoutePricingPayload = {
  __typename?: 'UpdateRoutePricingPayload';
  route?: Maybe<Route>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateStopInput = {
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  dateTime?: Maybe<Scalars['DateTime']>;
  contactId?: Maybe<Scalars['ID']>;
  groupSize?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  operatorNote?: Maybe<Scalars['String']>;
  driverNote?: Maybe<Scalars['String']>;
  logisticStreetViewUrl?: Maybe<Scalars['String']>;
  flightNumber?: Maybe<Scalars['String']>;
  airportIcao?: Maybe<Scalars['String']>;
  airlineIcao?: Maybe<Scalars['String']>;
  trackedFlight?: Maybe<Scalars['JSON']>;
  locationAlias?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateStopOrderInput = {
  tripId: Scalars['ID'];
  sourceIndex: Scalars['Int'];
  destinationIndex: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateStopOrderPayload = {
  __typename?: 'UpdateStopOrderPayload';
  stops: Array<Maybe<Stop>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateStopPayload = {
  __typename?: 'UpdateStopPayload';
  stop?: Maybe<Stop>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTermInput = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTermPayload = {
  __typename?: 'UpdateTermPayload';
  term: Term;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTrackedFlightInput = {
  /** Flight number assigned to the stop. May differ from tracked flight in event of codeshare. */
  flightNumber: Scalars['String'];
  trackedFlightId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTrackedFlightPayload = {
  __typename?: 'UpdateTrackedFlightPayload';
  trackedFlight: TrackedFlight;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTripInput = {
  id: Scalars['ID'];
  totalGroupSize?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  passengerId?: Maybe<Scalars['ID']>;
  temporaryPassenger?: Maybe<Scalars['JSON']>;
  shuttleRouteId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTripPayload = {
  __typename?: 'UpdateTripPayload';
  trip?: Maybe<Trip>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTripTextRecipientsInput = {
  tripId: Scalars['ID'];
  reminderTextTo?: Maybe<TextRecipientEnum>;
  statusUpdateTextTo?: Maybe<TextRecipientEnum>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTripTextRecipientsPayload = {
  __typename?: 'UpdateTripTextRecipientsPayload';
  trip: Trip;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  invitePending?: Maybe<Scalars['Boolean']>;
  roleSlug?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneCountryDialCode?: Maybe<Scalars['String']>;
  phoneCountryName?: Maybe<Scalars['String']>;
  phoneCountryFormat?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserSettingsInput = {
  tripsViewConfig?: Maybe<Scalars['JSON']>;
  defaultScreen?: Maybe<DefaultScreenVariant>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserSettingsPayload = {
  __typename?: 'UpdateUserSettingsPayload';
  user: User;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateVehicleChildSeatInput = {
  vehicleChildSeatId: Scalars['ID'];
  active: Scalars['Boolean'];
  quantity: Scalars['Int'];
  amt: Scalars['Float'];
  imageUrl: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateVehicleChildSeatPayload = {
  __typename?: 'UpdateVehicleChildSeatPayload';
  vehicleChildSeat?: Maybe<VehicleChildSeat>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateVehicleFailure = {
  __typename?: 'UpdateVehicleFailure';
  message?: Maybe<Scalars['String']>;
};

export type UpdateVehicleInput = {
  id: Scalars['ID'];
  cancellationPolicyId?: Maybe<Scalars['ID']>;
  insuranceId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  typeSlug?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Int']>;
  licensePlate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  vinNumber?: Maybe<Scalars['String']>;
  minimumTotalBaseRate?: Maybe<Scalars['Float']>;
  deadheadRatePerMile?: Maybe<Scalars['Float']>;
  tripRatePerMile?: Maybe<Scalars['Float']>;
  totalDeadheadDurationMinutes?: Maybe<Scalars['Int']>;
  weekdayHourlyCost?: Maybe<Scalars['Float']>;
  weekdayMinMinutes?: Maybe<Scalars['Int']>;
  weekendHourlyCost?: Maybe<Scalars['Float']>;
  weekendMinMinutes?: Maybe<Scalars['Int']>;
  weekends?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  enableBaseRateAutomation?: Maybe<Scalars['Boolean']>;
  available?: Maybe<Scalars['Boolean']>;
  enableBaseRateAutomationBookingTool?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<VehicleSettingsInput>;
  featureIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  childSeats?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateVehiclePayload = {
  __typename?: 'UpdateVehiclePayload';
  vehicle?: Maybe<Vehicle>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateVehiclePhotoInput = {
  /** vehicle photo id */
  id: Scalars['ID'];
  url: Scalars['String'];
  photoIndex: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateVehiclePhotoOrderInput = {
  photos: Array<Maybe<VehiclePhotoOrderInput>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateVehiclePhotoOrderPayload = {
  __typename?: 'UpdateVehiclePhotoOrderPayload';
  photos: Array<VehiclePhoto>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateVehiclePhotoPayload = {
  __typename?: 'UpdateVehiclePhotoPayload';
  vehicle?: Maybe<Vehicle>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateVehicleResult = Vehicle | UpdateVehicleFailure;

export type UpdateVehicleSettingsInput = {
  vehicleId: Scalars['ID'];
  weekends?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  conflictBlockQuote?: Maybe<Scalars['Boolean']>;
  conflictBlockReservation?: Maybe<Scalars['Boolean']>;
  pricelessBookingEnabled?: Maybe<Scalars['Boolean']>;
  pricelessBookingTarget?: Maybe<PricelessBookingTarget>;
  addPricelessBookingContactIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  addPricelessBookingCompanyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  removePricelessBookingContactIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  removePricelessBookingCompanyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateVehicleSettingsInputV2 = {
  conflictBlockQuote?: Maybe<Scalars['Boolean']>;
  conflictBlockReservation?: Maybe<Scalars['Boolean']>;
  pricelessBookingEnabled?: Maybe<Scalars['Boolean']>;
  pricelessBookingTarget?: Maybe<PricelessBookingTarget>;
  pricelessBookingCompaniesIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  pricelessBookingContactsIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UpdateVehicleSettingsPayload = {
  __typename?: 'UpdateVehicleSettingsPayload';
  vehicleSettings?: Maybe<VehicleSettings>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateVehicleTierInput = {
  lowerLimit: Scalars['Int'];
  upperLimit: Scalars['Int'];
  rate: Scalars['Float'];
  type: PricingVariant;
};

export type UpdateVehicleTieredPricingInput = {
  type: TieredPricingVariant;
  tiers: Array<Maybe<UpdateVehicleTierInput>>;
};

export type UpdateVehicleV2Input = {
  id: Scalars['ID'];
  cancellationPolicyId?: Maybe<Scalars['ID']>;
  insuranceId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  typeSlug?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Int']>;
  licensePlate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  vinNumber?: Maybe<Scalars['String']>;
  minimumTotalBaseRate?: Maybe<Scalars['Float']>;
  deadheadRatePerMile?: Maybe<Scalars['Float']>;
  tripRatePerMile?: Maybe<Scalars['Float']>;
  totalDeadheadDurationMinutes?: Maybe<Scalars['Int']>;
  weekdayHourlyCost?: Maybe<Scalars['Float']>;
  weekdayMinMinutes?: Maybe<Scalars['Int']>;
  weekendHourlyCost?: Maybe<Scalars['Float']>;
  weekendMinMinutes?: Maybe<Scalars['Int']>;
  weekends?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  enableBaseRateAutomation?: Maybe<Scalars['Boolean']>;
  available?: Maybe<Scalars['Boolean']>;
  enableBaseRateAutomationBookingTool?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<UpdateVehicleSettingsInputV2>;
  featureIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  childSeats?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  useTieredTransfer?: Maybe<Scalars['Boolean']>;
  useTieredHourly?: Maybe<Scalars['Boolean']>;
  useTieredHourlyWeekend?: Maybe<Scalars['Boolean']>;
  tieredPricingTransfer?: Maybe<UpdateVehicleTieredPricingInput>;
  tieredPricingHourly?: Maybe<UpdateVehicleTieredPricingInput>;
  tieredPricingHourlyWeekend?: Maybe<UpdateVehicleTieredPricingInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateVehicleV2Payload = {
  __typename?: 'UpdateVehicleV2Payload';
  result: UpdateVehicleResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpgradeSubscriptionPlanInput = {
  lagoPlanCode: LagoPlanCode;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpgradeSubscriptionPlanPayload = {
  __typename?: 'UpgradeSubscriptionPlanPayload';
  operator: Operator;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type User = Node & {
  __typename?: 'User';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  emailNormalize: Scalars['String'];
  mobilePhone: Scalars['String'];
  mobilePhoneInternational: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  phoneCountryDialCode: Scalars['String'];
  phoneCountryName: Scalars['String'];
  phoneCountryFormat: Scalars['String'];
  invitePending: Scalars['Boolean'];
  role: Role;
  operatorId: Scalars['ID'];
  operator: Operator;
  permissions: Permissions;
  accessPermissions: Array<Maybe<UserAccessPermission>>;
  settings: UserSetting;
  /** @deprecated Onboarding should be completed based off of operator, not user. Moving to Operator. */
  onboardingCompleted?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use settings.tripsViewConfig, encapsulating all user settings under UserSettingsType */
  tripsViewConfig?: Maybe<Scalars['JSON']>;
};

export type UserAccessPermission = Node & {
  __typename?: 'UserAccessPermission';
  /** The ID of an object */
  id: Scalars['ID'];
  path: Scalars['String'];
  category: Scalars['String'];
  name: Scalars['String'];
  grantedByDefault: Scalars['Boolean'];
};

export type UserAccessPermissionInput = {
  id?: Maybe<Scalars['ID']>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  /** The ID of an object */
  id: Scalars['ID'];
  tripsViewConfig?: Maybe<Scalars['JSON']>;
  defaultScreen?: Maybe<DefaultScreenVariant>;
};

export type ValidateSendgridCustomDomainInput = {
  customDomainId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ValidateSendgridCustomDomainPayload = {
  __typename?: 'ValidateSendgridCustomDomainPayload';
  operator?: Maybe<Operator>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Vehicle = Node & {
  __typename?: 'Vehicle';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  available: Scalars['Boolean'];
  capacity: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  photos: Array<Maybe<VehiclePhoto>>;
  features: Array<Maybe<VehicleFeature>>;
  operator: Operator;
  /** @deprecated use vehicleType */
  type: VehicleType;
  vehicleType: VehicleType;
  licensePlate?: Maybe<Scalars['String']>;
  weekendHourlyCost?: Maybe<Scalars['Float']>;
  weekdayHourlyCost?: Maybe<Scalars['Float']>;
  weekendMinMinutes?: Maybe<Scalars['Float']>;
  weekdayMinMinutes?: Maybe<Scalars['Float']>;
  vinNumber?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  cancellationPolicy?: Maybe<CancellationPolicy>;
  insurancePolicy?: Maybe<InsurancePolicy>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  minimumTotalBaseRate?: Maybe<Scalars['Float']>;
  deadheadRatePerMile?: Maybe<Scalars['Float']>;
  tripRatePerMile?: Maybe<Scalars['Float']>;
  totalDeadheadDurationMinutes?: Maybe<Scalars['Int']>;
  enableBaseRateAutomation?: Maybe<Scalars['Boolean']>;
  enableBaseRateAutomationBookingTool?: Maybe<Scalars['Boolean']>;
  publishedToDudaSite?: Maybe<Scalars['Boolean']>;
  automatedBaseRate?: Maybe<Scalars['Float']>;
  /** Only exists on create new request for customer app. */
  returnAutomatedBaseRate?: Maybe<Scalars['Float']>;
  baseRateAutomation?: Maybe<BaseRateAutomation>;
  returnBaseRateAutomation?: Maybe<BaseRateAutomation>;
  externalOperator?: Maybe<ExternalOperator>;
  settings?: Maybe<VehicleSettings>;
  /** If null this vehicle was not checked for conflicts */
  hasTripConflict?: Maybe<Scalars['Boolean']>;
  useTieredTransfer?: Maybe<Scalars['Boolean']>;
  useTieredHourly?: Maybe<Scalars['Boolean']>;
  useTieredHourlyWeekend?: Maybe<Scalars['Boolean']>;
  tieredPricingTransfer?: Maybe<TieredPricingTransfer>;
  tieredPricingHourly?: Maybe<TieredPricingHourly>;
  tieredPricingHourlyWeekend?: Maybe<TieredPricingHourly>;
};

export type VehicleAvailability = {
  __typename?: 'VehicleAvailability';
  confirmationNumber?: Maybe<Scalars['String']>;
  isQuote?: Maybe<Scalars['Boolean']>;
  /** Whether or not trip overlaps with current trip, or if current trip, if it has any overlaps with prev or next trips */
  isOverlap: Scalars['Boolean'];
  minutesToCurrentTrip?: Maybe<Scalars['Int']>;
  pickUpLocation: Scalars['String'];
  pickUpDateTime: Scalars['DateTime'];
  dropOffLocation: Scalars['String'];
  dropOffDateTime?: Maybe<Scalars['DateTime']>;
  estimatedDuration?: Maybe<Scalars['Int']>;
  requestId?: Maybe<Scalars['ID']>;
  tripId?: Maybe<Scalars['ID']>;
};

export type VehicleAvailabilityCalendar = {
  __typename?: 'VehicleAvailabilityCalendar';
  previousTrip?: Maybe<VehicleAvailability>;
  currentTrip: VehicleAvailability;
  nextTrip?: Maybe<VehicleAvailability>;
};

export type VehicleChildSeat = Node & {
  __typename?: 'VehicleChildSeat';
  /** The ID of an object */
  id: Scalars['ID'];
  type: ChildSeatVariant;
  active: Scalars['Boolean'];
  quantity: Scalars['Int'];
  amt: Scalars['Float'];
  imageUrl: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type VehicleChildSeatInput = {
  active: Scalars['Boolean'];
  quantity: Scalars['Int'];
  amt: Scalars['Float'];
  imageUrl: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type VehicleCommentInput = {
  bodyText: Scalars['String'];
};

export type VehicleFeature = Node & {
  __typename?: 'VehicleFeature';
  /** The ID of an object */
  id: Scalars['ID'];
  name: Scalars['String'];
  category: Scalars['String'];
};

export type VehicleFeatureInput = {
  id?: Maybe<Scalars['ID']>;
};

export type VehicleInput = {
  id: Scalars['ID'];
};

export type VehiclePhoto = Node & {
  __typename?: 'VehiclePhoto';
  /** The ID of an object */
  id: Scalars['ID'];
  url: Scalars['String'];
  photoIndex: Scalars['Int'];
};

export type VehiclePhotoInput = {
  url?: Maybe<Scalars['String']>;
  photoIndex?: Maybe<Scalars['Int']>;
};

export type VehiclePhotoOrderInput = {
  id?: Maybe<Scalars['ID']>;
  photoIndex: Scalars['Int'];
};

export type VehicleSettings = {
  __typename?: 'VehicleSettings';
  /** The ID of an object */
  id: Scalars['ID'];
  /** Array of weekends represented as objects */
  weekends?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  conflictBlockQuote: Scalars['Boolean'];
  conflictBlockReservation: Scalars['Boolean'];
  forwardFacingSeat: VehicleChildSeat;
  rearFacingSeat: VehicleChildSeat;
  boosterSeat: VehicleChildSeat;
  pricelessBookingEnabled: Scalars['Boolean'];
  /** Priceless booking will either be available to all or specific contacts */
  pricelessBookingTarget: PricelessBookingTarget;
  pricelessBookingCompanies?: Maybe<Array<Maybe<Company>>>;
  pricelessBookingContacts?: Maybe<Array<Maybe<Contact>>>;
};

export type VehicleSettingsInput = {
  conflictBlockQuote?: Maybe<Scalars['Boolean']>;
  conflictBlockReservation?: Maybe<Scalars['Boolean']>;
  pricelessBookingEnabled?: Maybe<Scalars['Boolean']>;
  pricelessBookingTarget?: Maybe<PricelessBookingTarget>;
  pricelessBookingCompaniesIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  pricelessBookingContactsIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type VehicleType = {
  __typename?: 'VehicleType';
  typeName: Scalars['String'];
  typeSlug: Scalars['String'];
  iconCategory: Scalars['String'];
};

export enum View {
  EmailSent = 'EMAIL_SENT',
  Draft = 'DRAFT',
  NewQuote = 'NEW_QUOTE',
  All = 'ALL',
  Expired = 'EXPIRED',
  Archived = 'ARCHIVED'
}

export enum WebsitePermissions {
  Basic = 'BASIC',
  Premium = 'PREMIUM'
}

export enum WebsitePlan {
  Free = 'FREE',
  Paid = 'PAID'
}

export enum WidgetCta {
  BookNow = 'BOOK_NOW',
  GetAQuote = 'GET_A_QUOTE'
}

export type ZipCode = {
  __typename?: 'ZipCode';
  zipCode: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  coordinates: Scalars['JSONObject'];
};

export type ZipCodeZone = {
  __typename?: 'ZipCodeZone';
  variant: ZoneVariantEnum;
  zipCodes?: Maybe<Array<Maybe<ZipCode>>>;
};

export type ZoneVariantData = ZipCodeZone | LocationZone;

export enum ZoneVariantEnum {
  ZipCode = 'zip_code',
  Location = 'location'
}

export type CreatePaymentMethodForCompanyInput = {
  /** ID of the company */
  companyId: Scalars['ID'];
  stripePaymentMethod: Scalars['JSON'];
  cardNote?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePaymentMethodForCompanyPayload = {
  __typename?: 'createPaymentMethodForCompanyPayload';
  paymentMethod: PaymentMethod;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePaymentMethodForContactInput = {
  /** ID of the contact */
  contactId: Scalars['ID'];
  stripePaymentMethod: Scalars['JSON'];
  cardNote?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePaymentMethodForContactPayload = {
  __typename?: 'createPaymentMethodForContactPayload';
  paymentMethod: PaymentMethod;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePaymentMethodForFarmAffiliateInput = {
  /** ID of the farmor */
  farmAffiliateId: Scalars['ID'];
  stripePaymentMethod: Scalars['JSON'];
  cardNote?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePaymentMethodForFarmAffiliatePayload = {
  __typename?: 'createPaymentMethodForFarmAffiliatePayload';
  paymentMethod: PaymentMethod;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type LoadAffiliateTripsConnection = {
  __typename?: 'loadAffiliateTripsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadAffiliateTripsEdge>>>;
};

/** An edge in a connection. */
export type LoadAffiliateTripsEdge = {
  __typename?: 'loadAffiliateTripsEdge';
  /** The item at the end of the edge */
  node?: Maybe<Trip>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadCompaniesBySearchTermConnection = {
  __typename?: 'loadCompaniesBySearchTermConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadCompaniesBySearchTermEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadCompaniesBySearchTermEdge = {
  __typename?: 'loadCompaniesBySearchTermEdge';
  /** The item at the end of the edge */
  node?: Maybe<Company>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadCompanyTripsConnection = {
  __typename?: 'loadCompanyTripsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadCompanyTripsEdge>>>;
};

/** An edge in a connection. */
export type LoadCompanyTripsEdge = {
  __typename?: 'loadCompanyTripsEdge';
  /** The item at the end of the edge */
  node?: Maybe<Trip>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadContactsAndFarmAffiliatesAndCompaniesConnection = {
  __typename?: 'loadContactsAndFarmAffiliatesAndCompaniesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadContactsAndFarmAffiliatesAndCompaniesEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadContactsAndFarmAffiliatesAndCompaniesEdge = {
  __typename?: 'loadContactsAndFarmAffiliatesAndCompaniesEdge';
  /** The item at the end of the edge */
  node?: Maybe<ContactsAndFarmAffiliatesAndCompanies>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadContactsAndFarmAffiliatesConnection = {
  __typename?: 'loadContactsAndFarmAffiliatesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadContactsAndFarmAffiliatesEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadContactsAndFarmAffiliatesEdge = {
  __typename?: 'loadContactsAndFarmAffiliatesEdge';
  /** The item at the end of the edge */
  node?: Maybe<ContactsAndFarmAffiliatesAndCompanies>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadContactsConnection = {
  __typename?: 'loadContactsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadContactsEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadContactsEdge = {
  __typename?: 'loadContactsEdge';
  /** The item at the end of the edge */
  node?: Maybe<Contact>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadEmailLogsConnection = {
  __typename?: 'loadEmailLogsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadEmailLogsEdge>>>;
};

/** An edge in a connection. */
export type LoadEmailLogsEdge = {
  __typename?: 'loadEmailLogsEdge';
  /** The item at the end of the edge */
  node?: Maybe<EmailLog>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadFarmAffiliateVehiclesConnection = {
  __typename?: 'loadFarmAffiliateVehiclesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadFarmAffiliateVehiclesEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadFarmAffiliateVehiclesEdge = {
  __typename?: 'loadFarmAffiliateVehiclesEdge';
  /** The item at the end of the edge */
  node?: Maybe<FarmAffiliateVehicle>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadFarmAffiliatesConnection = {
  __typename?: 'loadFarmAffiliatesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadFarmAffiliatesEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadFarmAffiliatesEdge = {
  __typename?: 'loadFarmAffiliatesEdge';
  /** The item at the end of the edge */
  node?: Maybe<FarmAffiliate>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadFilterableDispatchTripsConnection = {
  __typename?: 'loadFilterableDispatchTripsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadFilterableDispatchTripsEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadFilterableDispatchTripsEdge = {
  __typename?: 'loadFilterableDispatchTripsEdge';
  /** The item at the end of the edge */
  node?: Maybe<Trip>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadInvoiceableRequestsConnection = {
  __typename?: 'loadInvoiceableRequestsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadInvoiceableRequestsEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadInvoiceableRequestsEdge = {
  __typename?: 'loadInvoiceableRequestsEdge';
  /** The item at the end of the edge */
  node?: Maybe<Request>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadInvoicesConnection = {
  __typename?: 'loadInvoicesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadInvoicesEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadInvoicesEdge = {
  __typename?: 'loadInvoicesEdge';
  /** The item at the end of the edge */
  node?: Maybe<Invoice>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadNewQuotesConnection = {
  __typename?: 'loadNewQuotesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadNewQuotesEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadNewQuotesEdge = {
  __typename?: 'loadNewQuotesEdge';
  /** The item at the end of the edge */
  node?: Maybe<Request>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadPromoCodeResultsConnection = {
  __typename?: 'loadPromoCodeResultsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadPromoCodeResultsEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadPromoCodeResultsEdge = {
  __typename?: 'loadPromoCodeResultsEdge';
  /** The item at the end of the edge */
  node?: Maybe<Trip>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadQuotesConnection = {
  __typename?: 'loadQuotesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadQuotesEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadQuotesEdge = {
  __typename?: 'loadQuotesEdge';
  /** The item at the end of the edge */
  node?: Maybe<Request>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadReservationsConnection = {
  __typename?: 'loadReservationsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadReservationsEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadReservationsEdge = {
  __typename?: 'loadReservationsEdge';
  /** The item at the end of the edge */
  node?: Maybe<Request>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadShuttleRoutesConnection = {
  __typename?: 'loadShuttleRoutesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadShuttleRoutesEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadShuttleRoutesEdge = {
  __typename?: 'loadShuttleRoutesEdge';
  /** The item at the end of the edge */
  node?: Maybe<ShuttleRoute>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadTransactionsForPaymentMethodConnection = {
  __typename?: 'loadTransactionsForPaymentMethodConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadTransactionsForPaymentMethodEdge>>>;
};

/** An edge in a connection. */
export type LoadTransactionsForPaymentMethodEdge = {
  __typename?: 'loadTransactionsForPaymentMethodEdge';
  /** The item at the end of the edge */
  node?: Maybe<PaymentMethodTransaction>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadTripReviewsConnection = {
  __typename?: 'loadTripReviewsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadTripReviewsEdge>>>;
  totalReviewCount: Scalars['Int'];
  avgRating: Scalars['Float'];
};

/** An edge in a connection. */
export type LoadTripReviewsEdge = {
  __typename?: 'loadTripReviewsEdge';
  /** The item at the end of the edge */
  node?: Maybe<TripReview>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadTripsForBookingContactConnection = {
  __typename?: 'loadTripsForBookingContactConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadTripsForBookingContactEdge>>>;
};

/** An edge in a connection. */
export type LoadTripsForBookingContactEdge = {
  __typename?: 'loadTripsForBookingContactEdge';
  /** The item at the end of the edge */
  node?: Maybe<Trip>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};
