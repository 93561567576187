import React from "react";

import { ApolloQueryResult } from "@apollo/client";

import { Box } from "@mui/material";

import PassengerInfoUpdateBlock from "./PassengerInfoUpdateBlock";
import PricingInfoUpdateBlock from "./PricingInfoUpdateBlock";
import AddVehicleUpdateBlock from "./AddVehicleUpdateBlock";
import TripInfoUpdateBlock from "./TripInfoUpdateBlock";
import AddVehicleCreateBlock from "../../vehicles/AddVehicleCreateBlock";
import TripDetailsUpdateBlock from "./TripDetailsUpdateBlock";
import {
  BaseRateAutomation,
  FarmRelationshipEnum,
  Trip,
  TripCategory,
  Vehicle,
} from "../../../types";
import { useScreenSize } from "globals/hooks";
import { LuggageFields } from "../luggage/types";
import ShuttleTripInfoUpdateBlock from "./ShuttleTripInfoUpdateBlock";

type OverviewTabViewProps = {
  trip: Trip;
  tripRefetch?: () => void;
  setSaveIndicatorState: (
    saveState: "loading" | "default" | "saved" | "error"
  ) => void;
  suggestedAddressInfo?: {
    firstName: string;
    lastName: string;
    address: string;
    mode: string;
  }[];
  mode: "reservations" | "quotes";
  addVehicles: (selectedVehicles: Vehicle[]) => void;
  requestRefetch: (variables?: {
    id: string;
  }) => Promise<ApolloQueryResult<any>>;
  showAutomatedBaseRateIcon?: boolean;
  automatedBaseRateLoading?: boolean;
  automatedBaseRate?: BaseRateAutomation;
  hidden: boolean;
  adjustLuggageAmount: (luggageAmount: LuggageFields) => void;
};
function OverviewTabView(props: OverviewTabViewProps) {
  const {
    trip,
    tripRefetch,
    setSaveIndicatorState,
    suggestedAddressInfo,
    mode,
    addVehicles,
    requestRefetch,
    showAutomatedBaseRateIcon,
    automatedBaseRateLoading,
    automatedBaseRate,
    hidden,
    adjustLuggageAmount,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // derived state
  const vehicle = trip?.routes[0]?.vehicle;
  const isShuttleReservation =
    trip?.tripCategory === TripCategory.ShuttlePickUp ||
    trip?.tripCategory === TripCategory.ShuttleReturn;
  const isFarmedRoute = trip?.routes[0]?.isFarmedRoute;
  const isFarmor =
    trip?.routes[0]?.farmRelationship === FarmRelationshipEnum.Farmor;

  return (
    <Box display={hidden ? "none" : undefined}>
      {isShuttleReservation ? (
        <ShuttleTripInfoUpdateBlock
          trip={trip}
          setSaveIndicatorState={setSaveIndicatorState}
          suggestedAddressInfo={suggestedAddressInfo}
        />
      ) : (
        <>
          <PassengerInfoUpdateBlock
            tripId={trip.id}
            contact={trip.contact}
            bookingContact={trip.request.bookingContact}
            temporaryPassenger={trip.temporaryPassenger}
            refetch={tripRefetch}
            setSaveIndicatorState={setSaveIndicatorState}
          />
          <TripInfoUpdateBlock
            trip={trip}
            setSaveIndicatorState={setSaveIndicatorState}
            suggestedAddressInfo={suggestedAddressInfo}
          />
        </>
      )}
      <TripDetailsUpdateBlock
        trip={trip}
        setSaveIndicatorState={setSaveIndicatorState}
        adjustLuggageAmount={adjustLuggageAmount}
      />

      {!!vehicle ? (
        <AddVehicleUpdateBlock
          mode={mode}
          trip={trip}
          refetch={tripRefetch}
          setSaveIndicatorState={setSaveIndicatorState}
          {...(isFarmedRoute &&
            isFarmor && {
              farmAffiliateId: trip?.routes[0]?.farmAffiliate?.id,
            })}
        />
      ) : (
        <AddVehicleCreateBlock
          addVehicles={addVehicles}
          stops={trip.stops}
          disableBRA={isShuttleReservation}
        />
      )}
      {mode === "quotes" && trip.routes.length > 0 && (
        <PricingInfoUpdateBlock
          routePricing={trip.routes[0].pricing}
          routeId={trip.routes[0].id}
          totalDuration={trip.totalDuration}
          setSaveIndicatorState={setSaveIndicatorState}
          refetchQuery={requestRefetch}
          BRAProps={{
            automatedBaseRate,
            automatedBaseRateLoading,
            showAutomatedBaseRateIcon:
              isMobileView && showAutomatedBaseRateIcon,
            vehicleId: vehicle?.id,
            hasEnabledVehicleBRA: vehicle?.enableBaseRateAutomation,
            stops: trip.stops,
          }}
        />
      )}
    </Box>
  );
}

export default OverviewTabView;
