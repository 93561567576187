import React, { createContext, useContext, useMemo } from "react";
import { Control, UseFormReturn } from "react-hook-form";
import isNil from "lodash/isNil";

import { TieredPricingHourly, TieredPricingTransfer } from "types";
import { UpdateVehicleFormData } from "../UpdateVehicleDrawer/hooks/useUpdateVehicleForm";
import { CreateVehiclePricingFormData } from "../CreateVehicleDrawer/hooks/useCreateVehiclePricingForm";
import { VehiclePricingBlockProps } from ".";

type VehiclePricing = {
  weekendHourlyCost: number | null;
  weekdayHourlyCost: number | null;
  weekendMinMinutes: number | null;
  weekdayMinMinutes: number | null;
  minimumTotalBaseRate: number | null;
  deadheadRatePerMile: number | null;
  tripRatePerMile: number | null;
  totalDeadheadDurationMinutes: number | null;
  enableBaseRateAutomation: boolean;
  settings?: {
    weekends?: any;
  };
  useTieredTransfer: boolean;
  tieredPricingTransfer: TieredPricingTransfer | null;
  useTieredHourly: boolean;
  tieredPricingHourly: TieredPricingHourly | null;
  useTieredHourlyWeekend: boolean;
  tieredPricingHourlyWeekend: TieredPricingHourly | null;
};

type VehiclePricingContextValue = {
  isReadOnly: boolean;
  isDisabled: boolean;
  isBRAEnabled: boolean;
  control: Control<UpdateVehicleFormData | CreateVehiclePricingFormData> | null;
  formContext: UseFormReturn<
    UpdateVehicleFormData | CreateVehiclePricingFormData
  > | null;
  vehiclePricing: VehiclePricing | null;
  tieredPricingErrors: {
    tieredPricingTransfer: boolean;
    tieredPricingHourly: boolean;
    tieredPricingHourlyWeekend: boolean;
  };
};

export const VehiclePricingContext =
  createContext<VehiclePricingContextValue | null>(null);

export function useVehiclePricing() {
  const context = useContext(VehiclePricingContext);
  if (!context) {
    throw new Error(
      "useVehiclePricing must be used within a VehiclePricingProvider"
    );
  }
  return context;
}

export function VehiclePricingProvider({
  children,
  ...props
}: VehiclePricingBlockProps & { children: React.ReactNode }) {
  const {
    control,
    watch,
    formState: { errors },
  } = props.formContext || {
    control: null,
    formState: { errors: undefined },
  };

  // derived state
  const vehiclePricing = useMemo(() => {
    if (!props.isReadOnly) return null;

    return {
      weekendHourlyCost: isNil(props.vehicle?.weekendHourlyCost)
        ? null
        : props.vehicle?.weekendHourlyCost,
      weekdayHourlyCost: isNil(props.vehicle?.weekdayHourlyCost)
        ? null
        : props.vehicle?.weekdayHourlyCost,
      weekendMinMinutes: isNil(props.vehicle?.weekendMinMinutes)
        ? null
        : props.vehicle?.weekendMinMinutes,
      weekdayMinMinutes: isNil(props.vehicle?.weekdayMinMinutes)
        ? null
        : props.vehicle?.weekdayMinMinutes,
      minimumTotalBaseRate: isNil(props.vehicle?.minimumTotalBaseRate)
        ? null
        : props.vehicle?.minimumTotalBaseRate,
      deadheadRatePerMile: isNil(props.vehicle?.deadheadRatePerMile)
        ? null
        : props.vehicle?.deadheadRatePerMile,
      tripRatePerMile: isNil(props.vehicle?.tripRatePerMile)
        ? null
        : props.vehicle?.tripRatePerMile,
      totalDeadheadDurationMinutes: isNil(
        props.vehicle?.totalDeadheadDurationMinutes
      )
        ? null
        : props.vehicle?.totalDeadheadDurationMinutes,
      enableBaseRateAutomation:
        props.vehicle?.enableBaseRateAutomation ?? false,
      settings: props.vehicle?.settings,
      // tiered pricing
      useTieredTransfer: props.vehicle?.useTieredTransfer ?? false,
      tieredPricingTransfer: props.vehicle?.tieredPricingTransfer ?? null,
      useTieredHourly: props.vehicle?.useTieredHourly ?? false,
      tieredPricingHourly: props.vehicle?.tieredPricingHourly ?? null,
      useTieredHourlyWeekend: props.vehicle?.useTieredHourlyWeekend ?? false,
      tieredPricingHourlyWeekend:
        props.vehicle?.tieredPricingHourlyWeekend ?? null,
    };
  }, [props.vehicle, props.isReadOnly]);

  const tieredPricingErrors = useMemo(() => {
    if (!control)
      return {
        tieredPricingTransfer: false,
        tieredPricingHourly: false,
        tieredPricingHourlyWeekend: false,
      };

    return {
      tieredPricingTransfer: !!errors?.vehicle?.tieredPricingTransfer,
      tieredPricingHourly: !!errors?.vehicle?.tieredPricingHourly,
      tieredPricingHourlyWeekend: !!errors?.vehicle?.tieredPricingHourlyWeekend,
    };
  }, [errors?.vehicle, control]);

  // if control is null, form context is not available
  // fallback to true to keep disabled state
  const isBRAEnabled = control
    ? watch("vehicle.enableBaseRateAutomation")
    : true;

  const value = useMemo(
    () => ({
      isReadOnly: !!props.isReadOnly,
      isDisabled: !!props.isReadOnly,
      isBRAEnabled,
      control,
      formContext: props.formContext,
      vehiclePricing,
      tieredPricingErrors,
    }),
    [
      props.isReadOnly,
      props.formContext,
      isBRAEnabled,
      control,
      vehiclePricing,
      tieredPricingErrors,
    ]
  );

  return (
    <VehiclePricingContext.Provider value={value}>
      {children}
    </VehiclePricingContext.Provider>
  );
}
