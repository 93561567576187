import React from "react";
import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { grayMedium } from "design-system/colors";

function VehicleColumn(props: GridRenderCellParams<string>) {
  const { row } = props;

  const vehicleName = row?.routes[0]?.vehicle?.name || "";

  if (!vehicleName) {
    return (
      <Typography variant="body2" color={grayMedium}>
        Add Vehicle
      </Typography>
    );
  }

  return (
    <Box>
      <Typography variant="body2">{vehicleName}</Typography>
    </Box>
  );
}

export default VehicleColumn;
