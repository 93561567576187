import React, { lazy } from "react";
import { Switch } from "react-router-dom";

import PrivateRoute from "routes/PrivateRoute";
import GeneralSettingsPage from "../pages/settings/general/GeneralSettingsPage";
import CancellationSettingsPage from "../pages/settings/CancellationSettingsPage";
import InsuranceSettingsPage from "../pages/settings/InsuranceSettingsPage";
import TermsSettingsPage from "../pages/settings/TermsSettingsPage";
import DriversSettingsPage from "../pages/settings/DriverSettingsPage/DriversSettingsPage";
import DynamicPricingPage from "pages/settings/DynamicPricingPage";
import VehiclesPage from "../pages/vehicles/VehiclesPage";
import MoovsMarketPage from "../pages/moovs-market";
import QuotesPage from "pages/requests/QuotesPage";
import ReservationsListPage from "pages/requests/ReservationsListPage";
import ReservationsPage from "pages/reservations/ReservationsPage";
import DriverTrackingPage from "../pages/driverTracking/DriverTrackingPage";
import ContactsPage from "../pages/contacts/ContactsPage";
import DriverPayoutPage from "../pages/driverPayout/DriverPayoutPage";
import StripeFinancesPage from "../pages/stripeFinances/StripeFinancesPage";
import InvoicesPage from "../pages/invoices/InvoicesPage";
import CompaniesPage from "../pages/companies/CompaniesPage";
import FarmAffiliatesPage from "../pages/farmAffiliates/FarmAffiliatesPage";
import DispatchPage from "pages/dispatch/DispatchPage";
import WebsiteAnalyticsPage from "pages/website/WebsiteAnalyticsPage";
import WebsiteMainPage from "pages/website/WebsiteMainPage";
import WebsiteSettingsPage from "pages/website/WebsiteSettingsPage";
import DriverAppNoAccess from "../design-system/illustrations/driverAppNoAccess.svg";
import MoovsMarketAppNoAccess from "components/globals/MoovsMarketAppNoAccess";
import MembersSettingsPage from "pages/settings/MemberSettingsPage";
import SubscriptionsPage from "pages/settings/SubscriptionsPage";
import BillingPage from "pages/settings/BillingPage";
import FinancialPage from "pages/settings/FinancialPage";
import { useOperator, useCurrentUser } from "globals/hooks";
import CustomerPortalPage from "pages/settings/CustomerPortalPage";
import MoovsCardPage from "pages/moovsCard";
import GettingStartedPage from "pages/gettingStarted";
import ChatPage from "pages/chat";
import CrmPage from "pages/crm/CrmPage";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";

// lazy loaded pages (for code-splitting)
const PriceZonePage = lazy(() => import("../pages/settings/PriceZonePage"));
const DashboardPage = lazy(() => import("../pages/dashboard/DashboardPage"));

function MainRouter() {
  // hooks
  const operator = useOperator();
  const { enablePocketflowsCrm } = useLaunchDarklyFlags();
  const currentUser = useCurrentUser();
  const isOwner = currentUser?.role?.slug === "owner";

  return (
    <Switch>
      {/* quotes and reservations */}
      <PrivateRoute path={["/quotes/:requestId/", "/quotes"]}>
        <QuotesPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={[
          "/reservations",
          "/reservations/create",
          "/reservations/shuttle/create",
        ]}
      >
        <ReservationsListPage />
      </PrivateRoute>
      <PrivateRoute
        path={[
          "/reservations/shuttle/:requestId/",
          "/reservations/:requestId/",
        ]}
      >
        <ReservationsPage />
      </PrivateRoute>

      {/* dispatch page */}
      <PrivateRoute path="/dispatch">
        <DispatchPage />
      </PrivateRoute>

      {/* driver tracking page */}
      <PrivateRoute path="/driver-tracking">
        {operator?.moovsMarketApps.driverApp.active ? (
          <DriverTrackingPage />
        ) : (
          <MoovsMarketAppNoAccess
            title="No Access to Driver Tracking Page"
            description="Please install Driver Platform app in Moovs Market to view"
            icon={DriverAppNoAccess}
          />
        )}
      </PrivateRoute>

      {/* vehicle page */}
      <PrivateRoute path="/vehicles">
        <VehiclesPage />
      </PrivateRoute>

      {/* invoices page */}
      <PrivateRoute path="/invoices">
        <InvoicesPage />
      </PrivateRoute>

      {/* dashboard page */}
      <PrivateRoute path="/dashboard">
        <DashboardPage />
      </PrivateRoute>

      {/* contacts/companies/farmaffiliates pages */}
      {/* TODO: consider different routing that better reflects actual app navigation */}
      <PrivateRoute path="/contacts">
        <ContactsPage />
      </PrivateRoute>
      <PrivateRoute path="/companies">
        <CompaniesPage />
      </PrivateRoute>
      <PrivateRoute path="/affiliates">
        <FarmAffiliatesPage />
      </PrivateRoute>

      {/* driver payables page */}
      <PrivateRoute path="/payables">
        <DriverPayoutPage />
      </PrivateRoute>

      {/* Stripe Finances page */}
      {isOwner && (
        <PrivateRoute path="/finances" permissionsOverride>
          <StripeFinancesPage />
        </PrivateRoute>
      )}

      {/* Moovs Card page */}
      <PrivateRoute path="/card" permissionsOverride>
        <MoovsCardPage />
      </PrivateRoute>

      {/* Chat page */}
      {/* Note: This is only used if a member's only home permission is chat */}
      <PrivateRoute path="/chat">
        <ChatPage />
      </PrivateRoute>

      {/* CRM page - only show if feature flag is enabled */}
      {enablePocketflowsCrm && (
        <PrivateRoute path="/crm" permissionsOverride>
          <CrmPage />
        </PrivateRoute>
      )}

      {/* getting started page */}
      <PrivateRoute path="/getting-started" permissionsOverride>
        <GettingStartedPage />
      </PrivateRoute>

      {/* settings routes */}
      {/* TODO: Consider creating settings router and separate settings layout */}
      <PrivateRoute path="/settings/general">
        <GeneralSettingsPage />
      </PrivateRoute>
      <PrivateRoute path="/settings/customer-portal">
        <CustomerPortalPage />
      </PrivateRoute>
      <PrivateRoute path="/settings/port-phone-number" permissionsOverride>
        <GeneralSettingsPage />
      </PrivateRoute>
      <PrivateRoute path="/settings/cancellation">
        <CancellationSettingsPage />
      </PrivateRoute>
      <PrivateRoute path="/settings/insurance">
        <InsuranceSettingsPage />
      </PrivateRoute>
      <PrivateRoute path="/settings/terms-and-conditions">
        <TermsSettingsPage />
      </PrivateRoute>
      <PrivateRoute path="/settings/members">
        <MembersSettingsPage />
      </PrivateRoute>
      <PrivateRoute path="/settings/drivers">
        <DriversSettingsPage />
      </PrivateRoute>
      <PrivateRoute path="/settings/zone-pricing">
        <PriceZonePage />
      </PrivateRoute>
      <PrivateRoute path="/settings/dynamic-pricing">
        <DynamicPricingPage />
      </PrivateRoute>

      {/* website pages */}
      {/* TODO: Consider creating website settings router and separate layout */}
      <PrivateRoute path="/settings/website/main">
        <WebsiteMainPage />
      </PrivateRoute>
      <PrivateRoute path="/settings/website/analytics">
        <WebsiteAnalyticsPage />
      </PrivateRoute>
      <PrivateRoute path="/settings/website/settings">
        <WebsiteSettingsPage />
      </PrivateRoute>

      {/* moovs market page */}
      <PrivateRoute path="/settings/moovs-market">
        <MoovsMarketPage />
      </PrivateRoute>

      <PrivateRoute path="/settings/billing/plans">
        <SubscriptionsPage />
      </PrivateRoute>
      <PrivateRoute path="/settings/billing">
        <BillingPage />
      </PrivateRoute>
      <PrivateRoute path="/settings/financial" permissionsOverride>
        <FinancialPage />
      </PrivateRoute>
    </Switch>
  );
}

export default MainRouter;
