import React, { useCallback, useState } from "react";
import { StripeAddressElementChangeEvent } from "@stripe/stripe-js";
import { useElements, AddressElement } from "@stripe/react-stripe-js";

import { Box, Typography } from "@mui/material";

import { useOperator } from "./useOperator";

type BillingDetailsValue = {
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  address?: {
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    state?: string | null;
    postal_code?: string | null;
    country: string;
  };
  phone?: string | null;
};

type BillingDetailsElementProps = {
  defaultValues?: BillingDetailsValue;
  onChange?: (event: StripeAddressElementChangeEvent) => void;
};

function useStripeBillingDetailsElement() {
  const operator = useOperator();
  const elements = useElements();

  const [addressValue, setAddressValue] = useState<BillingDetailsValue>(null);

  // derived state
  const { billingDetailsNotRequired } = operator?.settings || {};

  // event handler
  const handleBillingDetailsElementChange = useCallback(
    (event: StripeAddressElementChangeEvent) => {
      setAddressValue(event.value);
    },
    []
  );

  const getBillingDetails = async () => {
    // if not required, return state to bypass validation
    // if address line 1 is not empty, all other fields required
    if (billingDetailsNotRequired && !addressValue?.address?.line1) {
      return addressValue;
    }

    const addressElement = elements.getElement(AddressElement);
    if (!addressElement) {
      return;
    }

    // validates form
    const address = await addressElement.getValue();
    if (!address.complete) {
      return;
    }

    return address.value;
  };

  const BillingDetailsElement = useCallback(
    (props: BillingDetailsElementProps) => (
      <>
        <Typography variant="overline">
          {`Billing Information${
            billingDetailsNotRequired ? " (Optional)" : ""
          }`}
        </Typography>

        <Box mt={1}>
          <AddressElement
            options={{
              mode: "billing",
              fields: { phone: "always" },
              defaultValues: props.defaultValues,
              validation: {
                phone: { required: "never" },
              },
              autocomplete: {
                mode: "google_maps_api",
                apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
              },
            }}
            onChange={(event) => {
              handleBillingDetailsElementChange(event);

              if (props.onChange) props.onChange(event);
            }}
          />
        </Box>
      </>
    ),
    [handleBillingDetailsElementChange, billingDetailsNotRequired]
  );

  return { BillingDetailsElement, getBillingDetails };
}

export default useStripeBillingDetailsElement;
