import React, { useEffect } from "react";
import kebabCase from "lodash/kebabCase";

import { useHistory } from "react-router-dom";

import { useCurrentUser, useOperator } from "globals/hooks";
import { useUserSettings } from "globals/hooks/useUserSettings";
import { getRedirectPathBasedOnPermissions } from "globals/utils/getRedirectPathBasedOnPermissions";

function AuthenticatedRouteHOC({ children }) {
  // hooks
  const currentUser = useCurrentUser();
  const { settings } = useUserSettings() || {};
  const operator = useOperator();
  const history = useHistory();

  // redirect to onboarding if onboarding isn't complete
  useEffect(() => {
    if (
      !currentUser?.operator?.onboardingCompleted &&
      currentUser?.role?.slug === "owner" &&
      history.location.pathname !== "/onboarding"
    ) {
      history.replace("/onboarding");
    }
  }, [history, currentUser, operator]);

  // Redirect to default screen, if landing on "/".
  // If user does not have access to default screen, redirect to another home screen
  useEffect(() => {
    if (history.location.pathname === "/" && settings?.defaultScreen) {
      const redirectPath = getRedirectPathBasedOnPermissions({
        userAccessPermissions: currentUser?.accessPermissions || [],
        preferredPath: `/${kebabCase(settings.defaultScreen)}`,
      });
      history.replace(redirectPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, settings?.defaultScreen]);

  return <>{children}</>;
}

export default AuthenticatedRouteHOC;
