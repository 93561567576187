/**
 * @file DriverEditAutoComplete.tsx
 * AutoComplete dropdown that populates with list of drivers.
 * - For use with DataGrid Driver edit column
 *
 **/

import React, { ChangeEvent, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import sortBy from "lodash/sortBy";

import {
  Box,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/material";

import { LOAD_ROUTE_DRIVERS_QUERY } from "globals/graphql";
import { useSnackbar } from "globals/hooks";
import { formatPhoneNumber } from "globals/utils/phoneNumberFormatter/phoneNumberFormatter";
import { RouteDriver } from "types";
import CancelTripIcon from "design-system/icons/actions/CancelTripIcon";
import { errorRed } from "design-system/colors";
import { CalendarUnavailableIcon } from "design-system/icons";
import { fromGlobalId, toGlobalId } from "globals/utils/helpers";
import AvailabilityConflictDialog from "components/AvailabilityConflictDialog";

type DriverEditAutoCompleteProps = {
  value?: RouteDriver;
  onDriverAutoCompleteChange?: (
    event: ChangeEvent<HTMLInputElement>,
    newValue?
  ) => void;
  farmAffiliateId?: string;
  routeId?: string;
};

function DriverEditAutoComplete(props: DriverEditAutoCompleteProps, inputRef) {
  const { value, onDriverAutoCompleteChange, farmAffiliateId, routeId } = props;

  // hooks
  const snackbar = useSnackbar();

  // state
  const [open, setOpen] = useState(false);
  const [availabilityConflictDialogOpen, setAvailabilityConflictDialogOpen] =
    useState(false);
  const [conflictedDriver, setConflictedDriver] = useState<RouteDriver>(null);
  const [event, setEvent] = useState<ChangeEvent<HTMLInputElement>>(null);

  // queries
  const { data: driversData, loading } = useQuery(LOAD_ROUTE_DRIVERS_QUERY, {
    variables: {
      farmAffiliateId,
      routeId: toGlobalId(fromGlobalId(routeId).id, "Route"),
    },
    fetchPolicy: "cache-and-network",
    skip: !open,
    onError: snackbar.error,
  });

  // todo: Fix repeated logic across files
  // - src/components/autocompletes/DriverAutoComplete.tsx
  // - src/pages/tripView/components/TripDataGrid/components/editCellComponents/DriverEditAutocomplete.tsx
  const sortedDrivers = useMemo(() => {
    if (!driversData) return null;

    const routeDrivers = sortBy(driversData.loadRouteDrivers, [
      "driver.firstName",
      "driver.lastName",
    ]);

    const driversAndAvailability = routeDrivers.map((routeDriver) => {
      return {
        routeDriver,
        availability: !routeDriver.routeAvailability.available
          ? "routeConflict"
          : !routeDriver.personalAvailability?.available
          ? "personalConflict"
          : "available",
      };
    });

    return [
      ...driversAndAvailability.filter(
        (driver) => driver.availability === "available"
      ),
      ...driversAndAvailability.filter(
        (driver) => driver.availability === "personalConflict"
      ),
      ...driversAndAvailability.filter(
        (driver) => driver.availability === "routeConflict"
      ),
    ].map((driver) => driver);
  }, [driversData]);

  // event handlers
  const handleAutoCompleteChange = (
    _e: ChangeEvent<HTMLInputElement>,
    driverData
  ) => {
    const routeDriver = driverData && driverData.routeDriver;
    if (
      routeDriver &&
      (!routeDriver.routeAvailability?.available ||
        !routeDriver.personalAvailability?.available)
    ) {
      setEvent(_e);
      setConflictedDriver(routeDriver);
      setAvailabilityConflictDialogOpen(true);
    } else {
      onDriverAutoCompleteChange(_e, routeDriver || null);
    }
  };

  return (
    <>
      <Autocomplete
        id="dispatch-driver-autocomplete"
        sx={{
          "& .MuiAutocomplete-inputRoot": {
            padding: 0,
            fontSize: "14px",
            textOverflow: "ellipsis",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
          },
        }}
        ref={inputRef}
        value={value?.driver}
        loading={loading}
        fullWidth
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={handleAutoCompleteChange}
        options={sortedDrivers || []}
        filterOptions={(drivers, { inputValue }) =>
          drivers.filter(({ routeDriver }) => {
            const input = inputValue.toLowerCase() || "";

            return (
              routeDriver?.driver.firstName.toLowerCase().startsWith(input) ||
              routeDriver?.driver.lastName.toLowerCase().startsWith(input) ||
              routeDriver?.driver.mobilePhone.includes(input) ||
              formatPhoneNumber(
                routeDriver?.driver?.mobilePhone
              )?.formatted?.includes(input)
            );
          })
        }
        getOptionLabel={(optionData) =>
          optionData?.routeDriver
            ? `${optionData.routeDriver.driver.firstName} ${optionData.routeDriver.driver.lastName}`
            : ""
        }
        renderInput={(params) => (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
            width="100%"
          >
            <TextField
              sx={{ pl: 0.5 }}
              ref={params.InputProps.ref}
              fullWidth
              {...params}
              placeholder="Enter Driver Name"
            />
          </Box>
        )}
        renderOption={(props, optionData) => {
          const routeDriver = optionData.routeDriver;
          const showRouteConflict = !routeDriver.routeAvailability.available;
          const showPersonalConflict =
            !showRouteConflict && !routeDriver.personalAvailability.available;

          return (
            <li {...props}>
              <Box display="flex" alignItems="center" width="100%">
                <Box>
                  <Typography variant="body2" fontWeight={500}>
                    {`${routeDriver?.driver.firstName} ${routeDriver?.driver.lastName}`}
                  </Typography>
                  <Typography fontSize={12}>
                    {routeDriver.driver.mobilePhone}
                  </Typography>
                </Box>
                <Box marginLeft="auto" display="flex" alignItems="center">
                  {showRouteConflict && (
                    <Tooltip placement="top" title="Trip Conflict">
                      <IconButton>
                        <CancelTripIcon color={errorRed} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {showPersonalConflict && (
                    <Tooltip placement="top" title="Availability Conflict">
                      <IconButton>
                        <CalendarUnavailableIcon color={errorRed} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </li>
          );
        }}
        PaperComponent={({ children }) => (
          <Paper variant="outlined" sx={{ mt: 1.5 }}>
            {children}
          </Paper>
        )}
      />

      <AvailabilityConflictDialog
        open={availabilityConflictDialogOpen}
        onClose={() => setAvailabilityConflictDialogOpen(false)}
        onAssign={() => onDriverAutoCompleteChange(event, conflictedDriver)}
        routeDriver={conflictedDriver}
      />
    </>
  );
}

export default React.forwardRef(DriverEditAutoComplete);
