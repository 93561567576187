import React, { useState } from 'react';
import { Box } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import MoovsToggleButtonGroup from 'components/MoovsToggleButtonGroup';

const paidStatusOptions = [
  { value: null, label: "All" },
  { value: "Paid", label: "Paid" },
  { value: "Not Paid", label: "Not Paid" }
];

export default function PaidStatusFilterInput(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;
  const [selection, setSelection] = useState<string | null>(null);

  const handleChange = (_: React.MouseEvent<HTMLElement>, newValue: string | null) => {
    setSelection(newValue);
    applyValue({ ...item, value: newValue });
  };

  return (
    <Box>
      <MoovsToggleButtonGroup
        options={paidStatusOptions.map(opt => ({
          value: opt.value,
          label: opt.label
        }))}
        value={selection}
        onChange={handleChange}
      />
    </Box>
  );
} 