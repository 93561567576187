import React from "react";

import {
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { NumberFormatDollar } from "design-system/components/inputs/NumberFormat";
import { granite } from "design-system/colors";
import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";
import { DistanceUnitEnum, PricingVariant } from "types";
import { getTieredTransferPricingVariantToggleButtons } from "../constant";
import { useVehiclePricing } from "../context";
import { ControllerWrapper } from "./ControllerWrapper";
import { PricingTiersBlock } from "./PricingTiersBlock";
import { useOperator } from "globals/hooks";

export function TransferPricingSection() {
  const { isReadOnly, isDisabled, control, vehiclePricing, formContext } =
    useVehiclePricing();
  const operator = useOperator();
  const distanceUnit = operator?.settings?.distanceUnit;
  const isMiles = distanceUnit === DistanceUnitEnum.Miles;
  const { watch } = formContext || {};

  // derived state
  const isUsingTieredTransferPricing = isReadOnly
    ? vehiclePricing?.useTieredTransfer
    : watch("vehicle.useTieredTransfer");

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
        <Typography variant="subtitle2" sx={{ color: granite }}>
          Transfer
        </Typography>

        <ControllerWrapper
          control={control}
          name="vehicle.useTieredTransfer"
          transformOnChange={(e) => e.target.checked}
          deps={[
            "vehicle.minimumTotalBaseRate",
            "vehicle.deadheadRatePerMile",
            "vehicle.tripRatePerMile",
          ]}
        >
          <FormControlLabel
            control={
              <Switch
                color="primary"
                data-testid="transfer-tiered-pricing-toggle"
              />
            }
            checked={isUsingTieredTransferPricing}
            disabled={isDisabled}
            label={<Typography variant="body2">Tiered Pricing</Typography>}
            sx={{ ml: 1 }}
          />
        </ControllerWrapper>
      </Box>

      {!isUsingTieredTransferPricing && (
        <ControllerWrapper
          control={control}
          name="vehicle.minimumTotalBaseRate"
          transformOnChange={(e) =>
            e.target.value === "" ? null : Number(e.target.value)
          }
          deps={[
            "vehicle.deadheadRatePerMile",
            "vehicle.tripRatePerMile",
            "vehicle.weekdayHourlyCost",
            "vehicle.weekdayMinMinutes",
          ]}
        >
          <TextField
            id="minimum-total-base-rate"
            data-testid="minimum-total-base-rate-input"
            fullWidth
            sx={{ mb: 1 }}
            disabled={isDisabled}
            label="Minimum Total Base Rate"
            variant="outlined"
            InputProps={{
              inputComponent: NumberFormatDollar as any,
            }}
            {...(isReadOnly && {
              value: vehiclePricing?.minimumTotalBaseRate,
            })}
          />
        </ControllerWrapper>
      )}

      <ControllerWrapper
        control={control}
        name="vehicle.deadheadRatePerMile"
        transformOnChange={(e) =>
          e.target.value === "" ? null : Number(e.target.value)
        }
        deps={[
          "vehicle.minimumTotalBaseRate",
          "vehicle.tripRatePerMile",
          "vehicle.weekdayHourlyCost",
          "vehicle.weekdayMinMinutes",
        ]}
      >
        <TextField
          id="deadhead-rate-per-mile"
          data-testid="deadhead-rate-input"
          fullWidth
          sx={{ mb: 1 }}
          disabled={isDisabled}
          label={`Deadhead Rate per ${isMiles ? "Mile" : "Kilometre"}`}
          variant="outlined"
          InputProps={{
            inputComponent: NumberFormatDollar as any,
          }}
          {...(isReadOnly && {
            value: vehiclePricing?.deadheadRatePerMile,
          })}
        />
      </ControllerWrapper>

      {!isUsingTieredTransferPricing && (
        <Box display="flex" alignItems="center" mb={1}>
          <ControllerWrapper
            control={control}
            name="vehicle.tripRatePerMile"
            transformOnChange={(e) =>
              e.target.value === "" ? null : Number(e.target.value)
            }
            deps={[
              "vehicle.minimumTotalBaseRate",
              "vehicle.deadheadRatePerMile",
              "vehicle.weekdayHourlyCost",
              "vehicle.weekdayMinMinutes",
            ]}
          >
            <TextField
              id="trip-rate-per-mile"
              data-testid="transfer-rate-input"
              sx={{ width: "200px" }}
              disabled={isDisabled}
              label="Transfer Rate"
              variant="outlined"
              InputProps={{
                inputComponent: NumberFormatDollar as any,
              }}
              {...(isReadOnly && {
                value: vehiclePricing?.tripRatePerMile,
              })}
            />
          </ControllerWrapper>
          <MoovsToggleButtonGroup
            testId="transfer-rate-type-toggle"
            options={getTieredTransferPricingVariantToggleButtons(isMiles)}
            value={PricingVariant.PerMile}
            onChange={() => {}}
            disabled={true}
            sx={{ ml: 2 }}
          />
        </Box>
      )}

      {isUsingTieredTransferPricing && (
        <PricingTiersBlock
          variant="transfer"
          mode="update"
          tieredPricingTypeFieldName="vehicle.tieredPricingTransfer.type"
          tieredPricingTiersFieldName="vehicle.tieredPricingTransfer.tiers"
        />
      )}
    </>
  );
}
