import { gql } from "@apollo/client";

export const LOAD_EXTERNAL_OPERATOR_QUERY = gql`
  query ExternalOperator($id: ID!) {
    node(id: $id) {
      id
      ... on ExternalOperator {
        id
        operatorName
        operatorEmail
        operatorPhone
        operatorAddress
        contactName
        internalNote
        serviceArea
        vehicleTypes {
          typeName
          typeSlug
        }
        drivers {
          id
          firstName
          lastName
          email
          mobilePhone
        }
        vehicles {
          id
          name
          capacity
          available
          description
          licensePlate
          weekendHourlyCost
          weekdayHourlyCost
          weekendMinMinutes
          weekdayMinMinutes
          minimumTotalBaseRate
          deadheadRatePerMile
          tripRatePerMile
          totalDeadheadDurationMinutes
          useTieredTransfer
          useTieredHourly
          useTieredHourlyWeekend
          tieredPricingTransfer {
            type
            tiers {
              lowerLimit
              upperLimit
              incrementalAmt
              rate
              type
            }
          }
          tieredPricingHourly {
            type
            tiers {
              lowerLimit
              upperLimit
              incrementalAmt
              rate
              type
            }
          }
          tieredPricingHourlyWeekend {
            type
            tiers {
              lowerLimit
              upperLimit
              incrementalAmt
              rate
              type
            }
          }
          farmAffiliateName
          farmAffiliateVariant
          settings {
            id
            weekends
          }
          features {
            id
            name
            category
          }
          photos {
            id
            url
            photoIndex
          }
          vehicleType {
            typeName
            typeSlug
            iconCategory
          }
        }
      }
    }
  }
`;

export const CREATE_EXTERNAL_OPERATOR_MUTATION = gql`
  mutation CreateExternalOperator($input: CreateExternalOperatorInput!) {
    createExternalOperator(input: $input) {
      externalOperator {
        id
        operatorName
        operatorEmail
        operatorPhone
        operatorAddress
        contactName
        internalNote
        serviceArea
        vehicleTypes {
          typeName
          typeSlug
        }
        drivers {
          id
          firstName
          lastName
        }
        vehicles {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_EXTERNAL_OPERATOR_MUTATION = gql`
  mutation UpdateExternalOperator($input: UpdateExternalOperatorInput!) {
    updateExternalOperator(input: $input) {
      externalOperator {
        id
        operatorName
        operatorEmail
        operatorPhone
        operatorAddress
        contactName
        internalNote
        serviceArea
        vehicleTypes {
          typeName
          typeSlug
        }
        drivers {
          id
          firstName
          lastName
        }
        vehicles {
          id
          name
        }
      }
    }
  }
`;

export const LINK_EXTERNAL_OPERATOR_DRIVER_MUTATION = gql`
  mutation LinkExternalOperatorDriver(
    $input: LinkExternalOperatorDriverInput!
  ) {
    linkExternalOperatorDriver(input: $input) {
      externalOperator {
        id
        operatorName
        operatorEmail
        operatorPhone
        operatorAddress
        contactName
        internalNote
        serviceArea
        vehicleTypes {
          typeName
          typeSlug
        }
        drivers {
          id
          firstName
          lastName
        }
        vehicles {
          id
          name
        }
      }
    }
  }
`;

export const UNLINK_EXTERNAL_OPERATOR_DRIVER_MUTATION = gql`
  mutation UnlinkExternalOperatorDriver(
    $input: UnlinkExternalOperatorDriverInput!
  ) {
    unlinkExternalOperatorDriver(input: $input) {
      externalOperator {
        id
        operatorName
        operatorEmail
        operatorPhone
        operatorAddress
        contactName
        internalNote
        serviceArea
        vehicleTypes {
          typeName
          typeSlug
        }
        drivers {
          id
          firstName
          lastName
        }
        vehicles {
          id
          name
        }
      }
    }
  }
`;

export const LINK_EXTERNAL_OPERATOR_VEHICLE_MUTATION = gql`
  mutation LinkExternalOperatorVehicle(
    $input: LinkExternalOperatorVehicleInput!
  ) {
    linkExternalOperatorVehicle(input: $input) {
      externalOperator {
        id
        operatorName
        operatorEmail
        operatorPhone
        operatorAddress
        contactName
        internalNote
        serviceArea
        vehicleTypes {
          typeName
          typeSlug
        }
        drivers {
          id
          firstName
          lastName
        }
        vehicles {
          id
          name
        }
      }
    }
  }
`;

export const UNLINK_EXTERNAL_OPERATOR_VEHICLE_MUTATION = gql`
  mutation UnlinkExternalOperatorVehicle(
    $input: UnlinkExternalOperatorVehicleInput!
  ) {
    unlinkExternalOperatorVehicle(input: $input) {
      externalOperator {
        id
        operatorName
        operatorEmail
        operatorPhone
        operatorAddress
        contactName
        internalNote
        serviceArea
        vehicleTypes {
          typeName
          typeSlug
        }
        drivers {
          id
          firstName
          lastName
        }
        vehicles {
          id
          name
        }
      }
    }
  }
`;
