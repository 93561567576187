import { Box, TextField } from "@mui/material";
import { GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import React, { Dispatch, SetStateAction, useEffect } from "react";

import { deriveIsFarmee } from "globals/utils/farm";
import first from "lodash/first";
import FarmeePricingDialog from "pages/reservations/components/ActionButtonPanel/components/ViewMoreActionButton/components/FarmeePricingDialog";
import TripPricingDialog from "pages/reservations/components/ActionButtonPanel/components/ViewMoreActionButton/components/TripPricingDialog";
import { useUpdatePricingClick } from "pages/reservations/components/ActionButtonPanel/components/ViewMoreActionButton/hooks";
import { Route } from "types";

type TotalAmountEditColumnProps = {
  params: GridRenderEditCellParams;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function TotalAmountEditColumn(props: TotalAmountEditColumnProps) {
  const {
    params: { row, api, id, field },
  } = props;

  const trip = row;
  const request = row.request;

  // hooks
  const updatePricing = useUpdatePricingClick({
    request,
    trip,
    analyticsName: "reservation_pricingOpened",
  });

  // derived state
  const route = first(trip.routes) as Route;
  const isFarmee = deriveIsFarmee(route);
  const currentTripIndex = request?.trips?.findIndex(
    ({ id }) => trip.id === id
  );
  const totalAmount = isFarmee
    ? route.farmeePricing?.totalAmt
    : row.totalAmount;

  useEffect(() => {
    updatePricing.onClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <TextField
        sx={{ px: 0.5 }}
        inputProps={{
          placeholder: `$${totalAmount}`,
          readOnly: true,
          style: {
            fontSize: "0.875rem",
            padding: "10px",
          },
        }}
      />
      {isFarmee ? (
        <FarmeePricingDialog
          open={updatePricing.open}
          onClose={() => {
            updatePricing.onClose();
            api.setCellMode(id, field, "view");
          }}
          currentTripIndex={currentTripIndex}
          pricings={updatePricing.pricings}
          routeId={route.id}
          disableSelectTrip={true}
        />
      ) : (
        <TripPricingDialog
          open={updatePricing.open}
          onClose={() => {
            updatePricing.onClose();
            api.setCellMode(id, field, "view");
          }}
          currentTripIndex={currentTripIndex}
          pricings={updatePricing.pricings}
          request={request}
          legacyBillings={trip.billings}
          disableSelectTrip={true}
        />
      )}
    </Box>
  );
}

export default TotalAmountEditColumn;
