import { useEffect, useState } from "react";
import isNil from "lodash/isNil";

import { useTestWidgetConnection } from "pages/settings/CustomerPortalPage/components/Tabs/InstallationAndShareTab/sections/InstallationSection/hooks/useTestWidgetConnection";
import { Operator } from "../../types";

type UseCachedWidgetInstalledParams = {
  operator: Operator;
};

/*
 Returns a cached boolean representing whether the customer portal widget
 has been installed on the operator's website
*/
function useCachedWidgetInstalled(params: UseCachedWidgetInstalledParams) {
  const { operator } = params;

  const CACHE_KEY = `moovs_customer_portal_widget_installed`;
  const CACHE_KEY_EXPIRY_TIME = `${CACHE_KEY}_expiry_time`;
  const CACHE_DURATION = 60 * 60 * 1000; // 60 minutes in milliseconds

  // hooks
  const { testConnection } = useTestWidgetConnection();

  // state
  // Try to initialize the value using the cache if within the cache expiry period
  const [widgetInstalled, setWidgetInstalled] = useState<boolean | null>(() => {
    const cachedData = localStorage?.getItem(CACHE_KEY);
    const cachedExpiryTime = localStorage?.getItem(CACHE_KEY_EXPIRY_TIME);

    if (cachedData && cachedExpiryTime) {
      const expiryTime = parseInt(cachedExpiryTime, 10);
      if (Date.now() < expiryTime) {
        return JSON.parse(cachedData);
      }
    }
    return null;
  });

  useEffect(() => {
    const fetchWidgetStatus = async () => {
      let isInstalled = null;

      if (operator?.websiteUrl) {
        isInstalled = await testConnection(operator.websiteUrl, true);
      }

      // Default to false in case the operator is missing websiteUrl or there was an error in testConnection
      isInstalled = !isNil(isInstalled) ? isInstalled : false;

      setWidgetInstalled(isInstalled);

      // Cache the value
      localStorage.setItem(CACHE_KEY, JSON.stringify(isInstalled));
      localStorage.setItem(
        CACHE_KEY_EXPIRY_TIME,
        (Date.now() + CACHE_DURATION).toString()
      );
    };

    if (widgetInstalled === null && operator) {
      fetchWidgetStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operator, testConnection]);

  return widgetInstalled;
}

export { useCachedWidgetInstalled };
