import { PricingVariant } from "types";

export type PricingVariantToggleButtonOption = {
  value: PricingVariant;
  label: string;
};

export const getTieredTransferPricingVariantToggleButtons = (
  isMiles: boolean
): PricingVariantToggleButtonOption[] => [
  {
    value: PricingVariant.PerMile,
    label: isMiles ? "Per mile" : "Per kilometre",
  },
  {
    value: PricingVariant.Flat,
    label: "Flat",
  },
];

export const tieredHourlyPricingVariantToggleButtons: PricingVariantToggleButtonOption[] =
  [
    {
      value: PricingVariant.PerHour,
      label: "Per hour",
    },
    {
      value: PricingVariant.Flat,
      label: "Flat",
    },
  ];

export const getPricingVariant = (isMiles: boolean) => {
  return isMiles ? PricingVariant.PerMile : "Per";
};
