import React, { ChangeEvent } from "react";
import {
  Control,
  Controller,
  FieldArrayPath,
  FieldPath,
} from "react-hook-form";
import { UpdateVehicleFormData } from "../../UpdateVehicleDrawer/hooks/useUpdateVehicleForm";
import { CreateVehiclePricingFormData } from "components/vehicles/CreateVehicleDrawer/hooks/useCreateVehiclePricingForm";

type ControllerWrapperProps<
  T = UpdateVehicleFormData | CreateVehiclePricingFormData
> = {
  control: Control<T> | null;
  name: FieldPath<T> | FieldArrayPath<T>;
  children: React.ReactNode;
  transformValue?: (value: any) => any;
  transformOnChange?: (...args: any[]) => any;
  deps?: FieldPath<T>[];
  afterChange?: (value: any) => void;
};

export function ControllerWrapper({
  control,
  name,
  children,
  transformValue,
  transformOnChange,
  deps,
  afterChange,
}: ControllerWrapperProps) {
  if (control) {
    return (
      <Controller
        control={control}
        name={name}
        {...(deps && {
          rules: {
            deps,
          },
        })}
        render={({
          field: { name, value, onChange },
          fieldState: { error },
        }) => {
          const handleOnChange = async (...args: any[]) => {
            let value;

            if (transformOnChange) {
              value = transformOnChange(...args);
              if (value instanceof Promise) {
                value = await value;
              }
            } else {
              const event = args[0] as ChangeEvent<HTMLInputElement>;
              value = event.target.value;
            }

            onChange(value);

            if (afterChange) {
              afterChange(value);
            }
          };

          return React.cloneElement(children as React.ReactElement, {
            name,
            value: transformValue ? transformValue(value) : value,
            onChange: handleOnChange,
            error: !!error,
            // required prop used to show error message for LabeledInlineSelect
            required: !!error,
            errorText: error?.message,
            helperText: error?.message,
          });
        }}
      />
    );
  }

  // if no control, just return the children with the name
  return React.cloneElement(children as React.ReactElement, { name });
}
