import React, { ChangeEvent, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import startCase from "lodash/startCase";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import pickBy from "lodash/pickBy";

import {
  TextField,
  Typography,
  Grid,
  Select,
  Box,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import {
  useAnalytics,
  useCurrentUser,
  useOperator,
  useScreenSize,
  useSnackbar,
} from "globals/hooks";
import { black, grayDark } from "design-system/colors";
import MoovsDialog from "components/MoovsDialog";
import { CheckIcon, ChevronDownIcon } from "design-system/icons";
import { useMutation } from "@apollo/client";
import {
  CREATE_STRIPE_EXTERNAL_ACCOUNT_MUTATION,
  LOAD_OPERATOR_QUERY,
  LOAD_STRIPE_ACCOUNT_QUERY,
} from "globals/graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

const accountHolderTypeOptions = ["Individual", "Company"];

const initialTokenInput = {
  accountHolderName: "",
  accountHolderType: first(accountHolderTypeOptions),
  routingNumber: "",
  accountNumber: "",
  transitNumber: "",
  institutionNumber: "",
};

const initialErrors = {
  accountHolderName: "",
  routingNumber: "",
  accountNumber: "",
  transitNumber: "",
  institutionNumber: "",
};

export type ContactCreateCreditCardDialogProps = {
  open: boolean;
  onClose: () => void;
};

function AddBankAccountDialog(props: ContactCreateCreditCardDialogProps) {
  const { open, onClose } = props;

  // hooks
  const snackbar = useSnackbar();
  const { isMobileView } = useScreenSize();
  const stripe = useStripe();
  const { track } = useAnalytics();
  const { operatorId } = useCurrentUser() || {};
  const operator = useOperator();

  // state
  const [bankAccountTokenInput, setBankAccountTokenInput] =
    useState(initialTokenInput);
  const [bankAccountInputErrors, setBankAccountInputErrors] =
    useState(initialErrors);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  // derived state
  const isCanadianAccount = operator.settings.operatingCountryCode === "CA";

  // mutations
  const [createStripeExternalAccount] = useMutation(
    CREATE_STRIPE_EXTERNAL_ACCOUNT_MUTATION,
    {
      onCompleted(data) {
        snackbar.success("Successfully added bank account!");

        track("moovsPayments_bankAccountAdded");

        handleClose();
      },
      onError(error) {
        setSubmitDisabled(false);

        const errorMessage =
          getErrorMessage(error) || "Error adding bank account";

        snackbar.error(errorMessage);
      },

      refetchQueries: [
        {
          query: LOAD_STRIPE_ACCOUNT_QUERY,
        },
        {
          query: LOAD_OPERATOR_QUERY,
          variables: {
            id: operatorId,
          },
        },
      ],

      awaitRefetchQueries: true,
    }
  );

  // event handlers
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value: number | string | boolean = e.target.value;
    const errors = { ...bankAccountInputErrors };

    if (e.target.name === "accountHolderName") {
      errors.accountHolderName = initialErrors.accountHolderName;
    }

    // routing number validation
    if (e.target.name === "routingNumber") {
      errors.routingNumber = initialErrors.routingNumber;
    }

    // account number validation
    if (e.target.name === "accountNumber") {
      errors.accountNumber = initialErrors.accountNumber;
    }

    setBankAccountTokenInput((bankAccountTokenInput) => ({
      ...bankAccountTokenInput,
      [e.target.name]: value,
    }));

    setBankAccountInputErrors(errors);
  };

  const validate = () => {
    const errors = { ...bankAccountInputErrors };

    if (bankAccountTokenInput.accountHolderName.length < 1) {
      errors.accountHolderName = "Name required";
    }

    if (isCanadianAccount) {
      const { transitNumberErrorMessage, institutionNumberErrorMessage } =
        validateCanadianRoutingNumber(
          bankAccountTokenInput.transitNumber,
          bankAccountTokenInput.institutionNumber
        );

      errors.transitNumber = transitNumberErrorMessage;
      errors.institutionNumber = institutionNumberErrorMessage;
    } else {
      const { errorMessage } = validateRoutingNumber(
        bankAccountTokenInput.routingNumber,
        operator.settings.operatingCountryCode
      );
      errors.routingNumber = errorMessage;
    }

    const { errorMessage: accountNumberErrorMessage } = validateAccountNumber(
      bankAccountTokenInput.accountNumber,
      operator.settings.operatingCountryCode
    );
    errors.accountNumber = accountNumberErrorMessage;

    setBankAccountInputErrors(errors);

    return isEmpty(pickBy(errors));
  };

  const handleClose = () => {
    onClose();
    setBankAccountTokenInput(initialTokenInput);
    setBankAccountInputErrors(initialErrors);
    setSubmitDisabled(false);
  };

  const createBankAccountToken = async () => {
    const {
      accountHolderName,
      accountHolderType,
      accountNumber,
      routingNumber,
      transitNumber,
      institutionNumber,
    } = bankAccountTokenInput;

    const { token, error } = await stripe.createToken("bank_account", {
      country: operator.settings.operatingCountryCode,
      currency: operator.settings.currencyCode,
      account_number: accountNumber,
      routing_number: isCanadianAccount
        ? transitNumber + "-" + institutionNumber
        : routingNumber,
      account_holder_name: accountHolderName,
      account_holder_type: accountHolderType.toLowerCase(),
    });

    return { token, error };
  };

  const handleSaveBankAccount = async () => {
    if (!validate()) return;

    setSubmitDisabled(true);

    // do not create token if stripe has yet to load
    if (!stripe) {
      setSubmitDisabled(false);
      return;
    }

    // create bank account token
    const { token, error: stripeBankAccountTokenError } =
      await createBankAccountToken();

    if (stripeBankAccountTokenError) {
      setSubmitDisabled(false);
      snackbar.error(stripeBankAccountTokenError.message);
      return;
    }

    createStripeExternalAccount({
      variables: {
        input: {
          externalAccountToken: token.id,
        },
      },
    });
  };

  if (!operator) {
    return <></>;
  }

  return (
    <MoovsDialog
      size="xs"
      open={open}
      onClose={handleClose}
      dialogTitle="Add Bank Account"
      onAccept={handleSaveBankAccount}
      hideTopBorder={!isMobileView}
      acceptButtonText="Save"
      acceptDisabled={submitDisabled}
    >
      <Grid container spacing={2}>
        <Grid item xs={6} minHeight={120}>
          <Typography variant="overline">Account Holder Name</Typography>
          <TextField
            autoFocus
            variant="outlined"
            fullWidth
            name="accountHolderName"
            onChange={handleChange}
            error={!!bankAccountInputErrors.accountHolderName}
            helperText={bankAccountInputErrors.accountHolderName}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="overline">Account Holder Type</Typography>
          <Select
            value={bankAccountTokenInput.accountHolderType}
            renderValue={() =>
              startCase(bankAccountTokenInput.accountHolderType)
            }
            fullWidth
            variant="outlined"
            name="accountHolderType"
            onChange={handleChange}
            IconComponent={() => (
              <Box
                position="relative"
                top="5px"
                right="20px"
                sx={[
                  {
                    pointerEvents: "none",
                  },
                ]}
              >
                <ChevronDownIcon color={grayDark} size="small" />
              </Box>
            )}
          >
            {accountHolderTypeOptions.map((type, i) => {
              return (
                <MenuItem value={type} key={i}>
                  <ListItemText primary={type} />
                  {type === bankAccountTokenInput.accountHolderType && (
                    <ListItemIcon>
                      <CheckIcon color={black} size="small" />
                    </ListItemIcon>
                  )}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        {isCanadianAccount ? (
          <>
            <Grid item xs={12} minHeight={120}>
              <Typography variant="overline">Transit Number</Typography>
              <TextField
                type="number"
                variant="outlined"
                fullWidth
                multiline
                name="transitNumber"
                onChange={handleChange}
                error={!!bankAccountInputErrors.transitNumber}
                helperText={bankAccountInputErrors.transitNumber}
              />
            </Grid>

            <Grid item xs={12} minHeight={120}>
              <Typography variant="overline">Institution Number</Typography>
              <TextField
                type="number"
                variant="outlined"
                fullWidth
                multiline
                name="institutionNumber"
                onChange={handleChange}
                error={!!bankAccountInputErrors.institutionNumber}
                helperText={bankAccountInputErrors.institutionNumber}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} minHeight={120}>
            <Typography variant="overline">Routing Number</Typography>
            <TextField
              type="number"
              variant="outlined"
              fullWidth
              multiline
              name="routingNumber"
              onChange={handleChange}
              error={!!bankAccountInputErrors.routingNumber}
              helperText={bankAccountInputErrors.routingNumber}
            />
          </Grid>
        )}

        <Grid item xs={12} minHeight={120}>
          <Typography variant="overline">Account Number</Typography>
          <TextField
            type="number"
            variant="outlined"
            fullWidth
            multiline
            name="accountNumber"
            onChange={handleChange}
            error={!!bankAccountInputErrors.accountNumber}
            helperText={bankAccountInputErrors.accountNumber}
          />
        </Grid>
      </Grid>
    </MoovsDialog>
  );
}

const validateRoutingNumber = (
  routingNumber: string,
  countryCode: string
): { isValid: boolean; errorMessage: string } => {
  // US routing numbers are only contain numbers and 9 digits. Ex. 110000000
  const usFormat = /^(\d{9})$/;

  switch (countryCode) {
    case "US":
      if (!usFormat.test(routingNumber)) {
        return {
          isValid: false,
          errorMessage:
            "Routing number must be 9 digits and contain only numbers. Ex. 123456789",
        };
      }
      return { isValid: true, errorMessage: "" };

    default:
      return { isValid: false, errorMessage: "Invalid country code." };
  }
};

const validateCanadianRoutingNumber = (
  transitNumber: string,
  institutionNumber: string
): {
  isValid: boolean;
  transitNumberErrorMessage: string;
  institutionNumberErrorMessage: string;
} => {
  // CA transit numbers are only contain numbers and 5 digits. Ex. 12345
  const transitNumberFormat = /^(\d{5})$/;
  // CA institution numbers are only contain numbers and 3 digits. Ex. 123
  const institutionNumberFormat = /^(\d{3})$/;

  let transitNumberErrorMessage = "";
  let institutionNumberErrorMessage = "";
  let isValid = true;

  if (!transitNumberFormat.test(transitNumber)) {
    transitNumberErrorMessage = "Transit number must be 5 digits. Ex. 12345";
    isValid = false;
  }

  if (!institutionNumberFormat.test(institutionNumber)) {
    institutionNumberErrorMessage =
      "Institution number must be 3 digits. Ex. 123";
    isValid = false;
  }

  return { isValid, transitNumberErrorMessage, institutionNumberErrorMessage };
};

const validateAccountNumber = (
  accountNumber: string,
  countryCode: string
): { isValid: boolean; errorMessage: string } => {
  // US account numbers are only contain numbers and 8-17 digits. Ex. 110000000
  const usFormat = /^(\d{8,17})$/;
  // CA account numbers are only contain numbers and 7-12 digits. Ex. 11000000
  const canadianFormat = /^(\d{7,12})$/;

  switch (countryCode) {
    case "US":
      if (!usFormat.test(accountNumber)) {
        return {
          isValid: false,
          errorMessage: "Bank account number must be between 8-17 digits.",
        };
      }
      return { isValid: true, errorMessage: "" };
    case "CA":
      if (!canadianFormat.test(accountNumber)) {
        return {
          isValid: false,
          errorMessage: "Bank account number must be between 7-12 digits.",
        };
      }
      return { isValid: true, errorMessage: "" };
  }
};

export default AddBankAccountDialog;
