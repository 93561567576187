import React from 'react';
import { useQuery } from '@apollo/client';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import MultiSelectFilterInput from './MultiSelectFilterInput';
import { LOAD_COMPANIES_NAME_AND_ID_QUERY } from 'globals/graphql';

export default function CompanyFilterInput(props: GridFilterInputValueProps) {
  const { data } = useQuery(LOAD_COMPANIES_NAME_AND_ID_QUERY);
  
  const companyOptions = data?.companies.map((company) => ({
    name: company.name,
    id: company.id,
  })) || [];

  return <MultiSelectFilterInput {...props} options={companyOptions} />;
} 