/**
 * @file graphql/stripe.ts
 * queries/mutations for Stripe.
 *
 */

import { gql } from "@apollo/client";

export const CREATE_ONBOARDING_STRIPE_ACCOUNT_MUTATION = gql`
  mutation CreateOnboardingStripeAccount(
    $input: CreateOnboardingStripeAccountInput!
  ) {
    createOnboardingStripeAccount(input: $input) {
      operator {
        id
      }
      accountLink {
        url
      }
    }
  }
`;

export const CREATE_STRIPE_ACCOUNT_LINK_MUTATION = gql`
  mutation CreateStripeAccountLink($input: CreateStripeAccountLinkInput!) {
    createStripeAccountLink(input: $input) {
      accountLink {
        url
      }
    }
  }
`;

export const CREATE_STRIPE_EXTERNAL_ACCOUNT_MUTATION = gql`
  mutation CreateStripeExternalAccount(
    $input: CreateStripeExternalAccountInput!
  ) {
    createStripeExternalAccount(input: $input) {
      __typename
    }
  }
`;

export const REMOVE_STRIPE_BANK_ACCOUNT_MUTATION = gql`
  mutation RemoveStripeBankAccount($input: RemoveStripeBankAccountInput!) {
    removeStripeBankAccount(input: $input) {
      __typename
    }
  }
`;

export const UPDATE_DEFAULT_BANK_ACCOUNT_MUTATION = gql`
  mutation UpdateDefaultBankAccount($input: UpdateDefaultBankAccountInput!) {
    updateDefaultBankAccount(input: $input) {
      __typename
    }
  }
`;

export const LOAD_STRIPE_PAYOUTS_QUERY = gql`
  query stripePayouts($limit: Int, $cursor: String) {
    stripePayouts(first: $limit, after: $cursor) {
      payoutsEnabled
      totalCount
      payoutIntervalStart
      payoutIntervalEnd
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          stripePayoutId
          amount
          payoutDate
          status
          variant
        }
      }
    }
  }
`;

export const LOAD_STRIPE_TRANSACTIONS_QUERY = gql`
  query stripeTransactions($stripePayoutId: String!) {
    stripeTransactions(stripePayoutId: $stripePayoutId) {
      edges {
        node {
          action
          transactionDate
          gross
          fees
          netTotal
          source
          contact {
            id
            firstName
            lastName
          }
          trips {
            id
            requestId
            tripNumber
          }
          farmor {
            ... on MoovsNetworkOperator {
              id
              operatorName
            }
          }
        }
      }
    }
  }
`;

export const LOAD_STRIPE_ACCOUNT_QUERY = gql`
  query {
    stripeAccount {
      id
      status
      statusDescription
      isAccountSetupComplete
      payoutSchedule
      bankAccounts {
        id
        bankAccountNumberLast4
        routingNumber
        bankName
        currency
        isDefault
        isInstantPayoutEligible
      }
    }
  }
`;

export const LOAD_STRIPE_PAYMENT_RECEIPT_LINK = gql`
  query stripePaymentReceiptLink($paymentId: ID!) {
    stripePaymentReceiptLink(paymentId: $paymentId)
  }
`;

export const CREATE_INSTANT_PAYOUT_MUTATION = gql`
  mutation CreateInstantPayout($input: CreateInstantPayoutInput!) {
    createInstantPayout(input: $input) {
      payout {
        stripePayoutId
      }
    }
  }
`;
