/**
 * @file CreatePaymentDialog.tsx
 * Create dialog for a Payment transaction.
 *
 * components:
 *   CreatePaymentDialog
 *
 * author: sienag
 */
import React, { useState, useEffect, ChangeEvent, useMemo } from "react";

import { useMutation, useQuery } from "@apollo/client";

import { Box, TextField, Typography } from "@mui/material";

import { Contact, CreateTripPaymentInput } from "../../types";
import { useSnackbar } from "../../globals/hooks/useSnackbar";
import {
  CREATE_TRIP_PAYMENT_MUTATION,
  LOAD_REQUEST_QUERY,
  LOAD_CONTACT_QUERY,
} from "../../globals/graphql";
import MoovsDialog from "../MoovsDialog";
import PaymentSummarySection from "./PaymentSummarySection";
import { NumberFormatDollar } from "../../design-system/components/inputs/NumberFormat";
import { useAnalytics } from "../../globals/hooks";
import { PaymentMethodEnum } from "../../types";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import MethodSelectDropdown from "components/requests/ChargePayerDialog/components/MethodSelectDropdown/MethodSelectDropdown";

// constants
const initialPaymentErrors = {
  amount: "",
  method: "",
};

type CreatePaymentDialogProps = {
  open: boolean;
  onClose: () => void;
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
  totalAmount: number;
  requestId: string;
  routeId: string;
  amountDue: number;
  passenger?: Contact;
};

function CreatePaymentDialog(props: CreatePaymentDialogProps) {
  const {
    open,
    onClose,
    setSaveIndicatorState,
    requestId,
    amountDue,
    routeId,
    passenger,
  } = props;
  const snackbar = useSnackbar();

  const [noteError, setNoteError] = useState(null);
  const [paymentErrors, setPaymentErrors] = useState(initialPaymentErrors);

  // hooks
  const { track } = useAnalytics();

  // state
  const [payment, setPayment] = useState<Partial<CreateTripPaymentInput>>({
    amount: null,
    method: null,
    note: null,
    paymentMethodId: null,
  });

  const [submitDisabled, setSubmitDisabled] = useState(false);

  // queries
  const { data, refetch: refetchRequest } = useQuery(LOAD_REQUEST_QUERY, {
    variables: {
      id: requestId,
    },
    skip: !requestId,
  });

  const request = data && data.node;
  const contactId = request?.bookingContact?.id;

  const { data: contactData } = useQuery(LOAD_CONTACT_QUERY, {
    variables: {
      id: contactId,
    },
    skip: !contactId,
    fetchPolicy: "network-only",
  });

  const paymentMethods = useMemo(
    () => contactData?.node?.paymentMethods || [],
    [contactData]
  );

  const companyPaymentMethods = useMemo(
    () => request?.company?.paymentMethods || null,
    [request]
  );

  useEffect(() => {
    if (open) {
      setPaymentErrors(initialPaymentErrors);

      const paymentMethodsLinkedToPassenger = paymentMethods.filter(
        (paymentMethod) => paymentMethod?.linkedPassenger?.id === passenger?.id
      );
      const paymentMethodsNotLinkedToPassenger = paymentMethods.filter(
        (paymentMethod) => !paymentMethod?.linkedPassenger
      );

      // a contact's primary payment method
      const primaryPaymentMethod = paymentMethods.find(
        (paymentMethod) => paymentMethod.isPrimary
      );

      // preferred payment method for a request
      const preferredPaymentMethod = request?.preferredPaymentMethod;

      // preferred payment method for a request takes precedence
      let defaultPaymentMethod;
      if (paymentMethodsLinkedToPassenger.length === 1) {
        defaultPaymentMethod = paymentMethodsLinkedToPassenger[0];
      } else if (paymentMethodsLinkedToPassenger.length > 1) {
        defaultPaymentMethod = null;
      } else if (paymentMethodsNotLinkedToPassenger.length === 1) {
        defaultPaymentMethod = paymentMethodsNotLinkedToPassenger[0];
      } else if (preferredPaymentMethod) {
        defaultPaymentMethod = preferredPaymentMethod;
      } else if (primaryPaymentMethod && !preferredPaymentMethod) {
        defaultPaymentMethod = primaryPaymentMethod;
      }

      setPayment({
        amount: null,
        note: null,
        ...(defaultPaymentMethod
          ? {
              method: PaymentMethodEnum.Card,
              paymentMethodId: defaultPaymentMethod.id,
            }
          : { method: null }),
      });
    }
  }, [open, paymentMethods, request?.preferredPaymentMethod, passenger?.id]);

  const [createTripPayment] = useMutation(CREATE_TRIP_PAYMENT_MUTATION, {
    onCompleted() {
      track("trip_chargeCreated");
      setSaveIndicatorState("saved");
      refetchRequest();
      handleClose();
    },
    onError(error) {
      setSubmitDisabled(false);
      setSaveIndicatorState("error");
      snackbar.error(getErrorMessage(error));
    },
  });

  const handleClickSave = () => {
    let errors = {
      method: "",
      amount: "",
    };
    if (!payment.method) {
      errors.method = "Select a payment method";
    }
    if (!payment.amount) {
      errors.amount = "Insert an amount";
    }
    if (errors.method || errors.amount) {
      setPaymentErrors(errors);
      return;
    }

    setSubmitDisabled(true);
    setSaveIndicatorState("loading");

    createTripPayment({
      variables: {
        input: {
          ...payment,
          routeId,
        },
      },
    });
  };

  const handleInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value =
      event.target.name === "amount" && event.target.value !== ""
        ? parseFloat(event.target.value)
        : event.target.value;

    if (event.target.name === "note") {
      value.toString().length >= 1000
        ? setNoteError("Reached character limit")
        : setNoteError(null);
    }

    setPaymentErrors({
      ...paymentErrors,
      [event.target.name]: "",
    });

    setPayment({
      ...payment,
      [event.target.name]: value,
    });
  };

  const handleMethodInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let method: PaymentMethodEnum;
    let paymentMethodId: string;

    if (Object.values<string>(PaymentMethodEnum).includes(event.target.value)) {
      method = event.target.value as PaymentMethodEnum;
    } else {
      paymentMethodId = event.target.value;
      method = PaymentMethodEnum.Card;
    }

    setPaymentErrors({
      ...paymentErrors,
      method: "",
    });

    setPayment({
      ...payment,
      method,
      paymentMethodId: paymentMethodId || null,
    });
  };

  const handleClose = () => {
    refetchRequest();
    onClose();
    setSubmitDisabled(false);
  };

  const paymentMethodValue = payment.paymentMethodId || payment.method || "";

  return (
    <MoovsDialog
      onAccept={handleClickSave}
      acceptButtonText="Charge Customer"
      fixedFooter
      onClose={handleClose}
      open={open}
      size="sm"
      dialogTitle="Charge Customer"
      acceptDisabled={submitDisabled}
    >
      <PaymentSummarySection
        beforeAmountDue={amountDue}
        totalAmount={payment.amount}
      />

      <Box mt={1} display="flex" flexDirection="column">
        <Box mb={1}>
          <Typography variant="overline">Amount</Typography>
          <TextField
            required
            fullWidth
            variant="outlined"
            placeholder="Enter Amount"
            name="amount"
            error={!!paymentErrors.amount}
            helperText={paymentErrors.amount}
            value={payment.amount || ""}
            onChange={handleInput}
            InputProps={{
              inputComponent: NumberFormatDollar as any,
            }}
          />
        </Box>

        <Box mb={1}>
          <Typography variant="overline">Payment Method</Typography>
          <MethodSelectDropdown
            methodError={paymentErrors.method}
            onMethodInputChange={handleMethodInput}
            paymentMethods={paymentMethods}
            companyPaymentMethods={companyPaymentMethods}
            paymentMethodValue={paymentMethodValue}
          />
        </Box>
      </Box>

      <Box mt={1} mb={3}>
        <Typography variant="overline">Note</Typography>
        <TextField
          inputProps={{ maxLength: 1000 }}
          error={!!noteError}
          helperText={noteError}
          required
          fullWidth
          multiline
          variant="outlined"
          name="note"
          placeholder="Add note"
          value={payment.note || ""}
          onChange={handleInput}
        />
      </Box>
    </MoovsDialog>
  );
}

export default CreatePaymentDialog;
