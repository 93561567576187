import React, { useMemo, useState } from "react";
import omit from "lodash/omit";
import isNil from "lodash/isNil";
import { useMutation } from "@apollo/client";

import MoovsPricingDialog from "components/pricing/MoovsPricingDialog";
import { PricingDialogPricingData } from "components/pricing/utils";
import {
  BaseRateAutomation,
  Billing,
  Request,
  UpdateRoutePricingInput,
} from "types";
import { useSnackbar } from "globals/hooks";
import { UPDATE_ROUTE_PRICING_MUTATION } from "globals/graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { getChildSeatOptionsFromVehicle } from "components/requests/create/CreateRequest/helpers/getChildSeatOptionsFromVehicle";

type TripPricingDialogProps = {
  open: boolean;
  onClose: () => void;
  pricings: PricingDialogPricingData[];
  currentTripIndex: number;
  request: Request;
  legacyBillings: Billing[];
  disableSelectTrip?: boolean;
};

function TripPricingDialog(props: TripPricingDialogProps) {
  const {
    open,
    onClose,
    pricings,
    currentTripIndex,
    request,
    legacyBillings,
    disableSelectTrip,
  } = props;

  // hooks
  const snackbar = useSnackbar();

  // state
  const [isBaseRateAutomationClicked, setIsBaseRateAutomationClicked] =
    useState(false);
  const [existingAutomatedBaseRate, setExisitngAutomatedBaseRate] =
    useState<BaseRateAutomation>(null);

  // memo
  const childSeatOptions = useMemo(
    () =>
      getChildSeatOptionsFromVehicle(
        request?.trips?.[currentTripIndex]?.routes?.[0]?.vehicle
      ),
    [request?.trips, currentTripIndex]
  );

  // mutations
  const [updateRoutePricingAndCloseDialog, { loading: routePricingUpdating }] =
    useMutation(
      UPDATE_ROUTE_PRICING_MUTATION,

      {
        refetchQueries: ["Request", "LoadFilterableDispatchTrips"],
        onCompleted(data) {
          snackbar.success("Successfully updated pricing!");
          onClose();
        },
        onError(error) {
          snackbar.error("Error updating pricing");
        },
      }
    );

  const [
    updateRoutePricingWithoutClosingDialog,
    { loading: routePricingUpdatingWCD },
  ] = useMutation(UPDATE_ROUTE_PRICING_MUTATION, {
    refetchQueries: ["Request", "LoadFilterableDispatchTrips"],
    onCompleted(data) {
      snackbar.success("Successfully updated pricing!");
    },
    onError(error) {
      const errorMessage = getErrorMessage(error) || "Error updating pricing.";
      snackbar.error(errorMessage);
    },
  });

  // event handlers
  const handleUpdatePricingOnRoute = (
    routePricing: Omit<
      UpdateRoutePricingInput,
      | "id"
      | "driverGratuityIsPct"
      | "promoDiscountIsPct"
      | "taxIsPct"
      | "otherIsPct"
      | "other2IsPct"
      | "other3IsPct"
    >,
    tripIndex: number,
    closeDialogOnComplete: boolean
  ) => {
    const {
      driverGratuityAmt,
      driverGratuityPercent,
      promoDiscountAmt,
      promoDiscountPercent,
      taxAmt,
      taxPercent,
      otherAmt,
      otherPercent,
      other2Amt,
      other2Percent,
      other3Amt,
      other3Percent,
      promoCodeAmt,
      promoCodePercent,
    } = routePricing;

    const updateRoutePricingInput: UpdateRoutePricingInput = {
      id: request.trips[tripIndex].routes[0].id,
      ...routePricing,
      driverGratuityAmt: driverGratuityPercent ? null : driverGratuityAmt,
      driverGratuityPercent:
        driverGratuityPercent !== null ? driverGratuityPercent / 100 : null,
      taxAmt: taxPercent ? null : taxAmt,
      taxPercent: taxPercent !== null ? taxPercent / 100 : null,
      promoDiscountAmt: promoDiscountPercent ? null : promoDiscountAmt,
      promoDiscountPercent:
        promoDiscountPercent !== null ? promoDiscountPercent / 100 : null,
      otherAmt: otherPercent ? null : otherAmt,
      otherPercent: otherPercent !== null ? otherPercent / 100 : null,
      other2Amt: other2Percent ? null : other2Amt,
      other2Percent: other2Percent !== null ? other2Percent / 100 : null,
      other3Amt: other3Percent ? null : other3Amt,
      other3Percent: other3Percent !== null ? other3Percent / 100 : null,
      promoCodeAmt: !isNil(promoCodePercent) ? null : promoCodeAmt,
      promoCodePercent:
        promoCodePercent !== null ? promoCodePercent / 100 : null,
      ...(isBaseRateAutomationClicked && {
        baseRateAutomation: {
          total: existingAutomatedBaseRate.total,
          lineItems: existingAutomatedBaseRate.lineItems.map((lineItem) =>
            omit(lineItem, "__typename")
          ),
        },
      }),
    };

    const mutationInput = {
      variables: {
        input: updateRoutePricingInput,
      },
    };

    if (closeDialogOnComplete) {
      updateRoutePricingAndCloseDialog(mutationInput);
    } else {
      updateRoutePricingWithoutClosingDialog(mutationInput);
    }

    setIsBaseRateAutomationClicked(false);
  };

  return (
    open && (
      <MoovsPricingDialog
        open={open}
        onClose={onClose}
        currentTripIndex={currentTripIndex}
        pricings={pricings}
        onAccept={handleUpdatePricingOnRoute}
        isUpdating={routePricingUpdating || routePricingUpdatingWCD}
        legacyBillings={legacyBillings}
        // add sendPricingAsDollarAmt prop, b/c updateRoute mutation wants actual dollar amount even if isPct flag on
        sendPricingAsDollarAmt
        setIsBaseRateAutomationClicked={setIsBaseRateAutomationClicked}
        setExisitngAutomatedBaseRate={setExisitngAutomatedBaseRate}
        childSeatOptions={childSeatOptions}
        disableSelectTrip={disableSelectTrip}
      />
    )
  );
}

export default TripPricingDialog;
