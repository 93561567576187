import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useMutation } from "@apollo/client";
import { TextField, InputAdornment, Select, MenuItem } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import {
  DataGridPro,
  GridCellEditCommitParams,
  GridCellParams,
  LicenseInfo,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";

import {
  FarmRelationshipEnum,
  LoadFilterableDispatchTripsConnection,
} from "types";
import { useSnackbar, useAnalytics } from "globals/hooks";
import { UPDATE_ROUTE_MUTATION } from "globals/graphql";
import { useTripsViewConfig } from "./hooks/useTripsViewConfig";
import { moovsBlue, white, grayMedium } from "design-system/colors";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { CustomColumnsPanel, CustomLoadingOverlay } from "./components";
import { EditableField } from "./utils/dataGridDefaultConfigV2";
import DriverFilterInput from "./components/DriverFilterInput";
import StatusFilterInput from "./components/StatusFilterInput";
import VehicleFilterInput from "./components/VehicleFilterInput";
import AffiliateFilterInput from "./components/AffiliateFilterInput";
import CompanyFilterInput from "./components/CompanyFilterInput";
import CloseStatusFilterInput from "./components/CloseStatusFilterInput";
import PaidStatusFilterInput from "./components/PaidStatusFilterInput";

// set license
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

type TripDataGridV2Props = {
  tripData: LoadFilterableDispatchTripsConnection;
  loading: boolean;
  onFetchMore: () => void;
  refetchTripsData: () => void;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"loading" | "default" | "error" | "saved">
  >;
};

enum SearchType {
  Text = "text",
  MultiSelect = "multiSelect",
  SingleSelect = "singleSelect",
}

const searchColumnConfig = {
  statusSlug: {
    type: SearchType.MultiSelect,
    component: StatusFilterInput,
    label: "Trip Status",
  },
  driverRow: {
    type: SearchType.MultiSelect,
    component: DriverFilterInput,
    label: "Driver",
  },
  vehicle: {
    type: SearchType.MultiSelect,
    component: VehicleFilterInput,
    label: "Vehicle",
  },
  affiliate: {
    type: SearchType.MultiSelect,
    component: AffiliateFilterInput,
    label: "Affiliate",
  },
  company: {
    type: SearchType.MultiSelect,
    component: CompanyFilterInput,
    label: "Company",
  },
  closeStatus: {
    type: SearchType.SingleSelect,
    component: CloseStatusFilterInput,
    label: "Close Status",
  },
  paidStatus: {
    type: SearchType.SingleSelect,
    component: PaidStatusFilterInput,
    label: "Paid Status",
  },
};

function SearchBar(props) {
  const { searchColumn, onSearch } = props;
  const apiRef = useGridApiRef();

  const config = searchColumnConfig[searchColumn] || { type: SearchType.Text };
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setSearchText("");
    onSearch(null);
  }, [searchColumn, onSearch]);

  if (
    config.type === SearchType.MultiSelect ||
    config.type === SearchType.SingleSelect
  ) {
    const FilterComponent = config.component;
    return (
      <Box
        sx={config.type === SearchType.MultiSelect ? { width: 300 } : undefined}
      >
        <FilterComponent
          apiRef={apiRef}
          item={{
            columnField: searchColumn,
            value: [],
            operatorValue:
              config.type === SearchType.MultiSelect ? "contains" : "equals",
            id: "quickSearch",
          }}
          applyValue={(params) => onSearch(params.value)}
        />
      </Box>
    );
  } else {
    return (
      <TextField
        variant="outlined"
        size="medium"
        placeholder={"Search..."}
        value={searchText}
        onChange={(e) => {
          const newValue = e.target.value;
          setSearchText(newValue);
          onSearch(newValue);
        }}
        sx={{ width: 300 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

function TripDataGridV2(props: TripDataGridV2Props) {
  const { loading, onFetchMore, refetchTripsData, setSaveIndicatorState } =
    props;

  // hooks
  const apiRef = useGridApiRef();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const {
    columns,
    onColumnVisibilityChange,
    onColumnOrderChange,
    onColumnWidthChange,
  } = useTripsViewConfig({ apiRef, setSaveIndicatorState, refetchTripsData });

  // derived state
  const tripData = useMemo(() => {
    return props.tripData.edges.map(({ node }) => node);
  }, [props.tripData]);

  // event handlers
  const handleOnRowsScrollEnd = () => {
    if (tripData.length < props.tripData.totalCount) {
      onFetchMore();
    }
  };

  // mutations
  const [updateRoute] = useMutation(UPDATE_ROUTE_MUTATION, {
    onCompleted() {
      track("reservationTripView_driverNoteUpdated");
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      const errorMessage =
        getErrorMessage(error) || "Error updating driver note.";
      snackbar.error(errorMessage);
    },
  });

  const handleRowEditCommit = useCallback(
    async (params: GridCellEditCommitParams) => {
      // @ts-ignore fix later
      const { row, value, field } = params;

      // commit changes server-side
      if (field === "driverNote") {
        setSaveIndicatorState("loading");

        updateRoute({
          variables: {
            input: {
              id: row.routes[0].id,
              driverNote: value,
            },
          },
        });
      }
    },
    [updateRoute, setSaveIndicatorState]
  );

  const [searchColumn, setSearchColumn] = useState("driverRow");

  const handleSearch = useCallback(
    (searchValue: any) => {
      const searchType =
        searchColumnConfig[searchColumn]?.type || SearchType.Text;

      let filterItem;
      if (searchType === SearchType.MultiSelect) {
        filterItem = {
          id: "quickSearch",
          value: searchValue,
          columnField: searchColumn,
          operatorValue: "contains",
        };
      } else if (searchType === SearchType.SingleSelect) {
        filterItem =
          searchValue === null
            ? null
            : {
                id: "quickSearch",
                value: searchValue,
                columnField: searchColumn,
                operatorValue: "equals",
              };
      } else {
        filterItem = searchValue
          ? {
              id: "quickSearch",
              value: searchValue,
              columnField: searchColumn,
              operatorValue: "contains",
            }
          : null;
      }

      apiRef.current.setFilterModel({
        items: filterItem ? [filterItem] : [],
      });
    },
    [apiRef, searchColumn]
  );

  const handleCellClick = (params: GridCellParams) => {
    const { field, row } = params;

    const isFarmee =
      row.routes[0].farmRelationship === FarmRelationshipEnum.Farmee;

    if (isFarmee) {
      // farmee can only edit these fields
      const isEditableFarmeeField = [
        EditableField.Vehicle,
        EditableField.StatusSlug,
        EditableField.DriverRow,
        EditableField.DriverNote,
        EditableField.Alerts,
        EditableField.TotalAmount,
      ].includes(field as EditableField);

      if (!isEditableFarmeeField) {
        return;
      }
    }

    const isEditableField = [
      EditableField.Vehicle,
      EditableField.PickupTime,
      EditableField.DropoffTime,
      EditableField.StatusSlug,
      EditableField.DriverRow,
      EditableField.DriverNote,
      EditableField.Alerts,
      EditableField.TotalAmount,
    ].includes(field as EditableField);

    if (!isEditableField) {
      return;
    }

    // Exit edit mode for all cells
    const allRows = apiRef.current.getAllRowIds();
    allRows.forEach((rowId) => {
      apiRef.current.getAllColumns().forEach((column) => {
        apiRef.current.setCellMode(rowId, column.field, "view");
      });
    });

    // Enter edit mode for the clicked cell
    apiRef.current.setCellMode(params.id, field, "edit");
  };

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        display: "flex",
        flexDirection: "column",
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          borderTop: `1px solid ${grayMedium}`,
        },
        "& .MuiDataGrid-virtualScroller": {
          // to account for the fixed position footer so that the last row is also visible
          paddingBottom: "52px",
        },
        "& .MuiDataGrid-cell--editable: hover": {
          cursor: "pointer",
        },
        "& .MuiDataGrid-cell--editing": {
          border: `2px solid ${moovsBlue}`,
          borderRadius: "4px",
        },
        "& .MuiDataGrid-footerContainer": {
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "100%",
          paddingRight: 6, // to account for fab icon
          backgroundColor: white,
        },
        backgroundColor: white,
        "& .MuiTextField-root": {
          backgroundColor: white,
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              borderColor: moovsBlue,
            },
          },
        },
        "& .search-container": {
          display: "flex",
          gap: 2,
          justifyContent: "flex-end",
          width: "100%",
          mt: 1,
          mb: 1,
          pr: 2,
          px: 2,
        },
        "& .MuiDataGrid-cell": {
          borderRight: `1px solid ${grayMedium}`, // Light grey border
        },
        "& .MuiDataGrid-columnHeader": {
          borderRight: `1px solid ${grayMedium}`,
          borderBottom: `1px solid ${grayMedium}`,
        },
      }}
    >
      <Box className="search-container">
        <SearchBar searchColumn={searchColumn} onSearch={handleSearch} />
        <Select
          size="small"
          value={searchColumn}
          onChange={(e) => setSearchColumn(e.target.value)}
          sx={{ minWidth: 200 }}
        >
          {columns
            .filter((col) => col.filterable)
            .map((col) => {
              const config = searchColumnConfig[col.field];
              return (
                <MenuItem key={col.field} value={col.field}>
                  {config?.label || col.headerName}
                </MenuItem>
              );
            })}
        </Select>
      </Box>

      <DataGridPro
        sx={{
          marginBottom: "50px",
        }}
        apiRef={apiRef}
        disableColumnPinning
        disableSelectionOnClick
        disableChildrenSorting
        hideFooterPagination
        disableMultipleColumnsSorting
        aria-label="Dispatch Table"
        paginationMode="server"
        sortingMode="client"
        loading={loading}
        onCellClick={handleCellClick}
        onCellEditCommit={handleRowEditCommit}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        onColumnVisibilityChange={onColumnVisibilityChange}
        onColumnOrderChange={onColumnOrderChange}
        columns={columns}
        onColumnWidthChange={onColumnWidthChange}
        rows={tripData}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          ColumnsPanel: CustomColumnsPanel,
          NoRowsOverlay: () => <div>No results found</div>,
        }}
        componentsProps={{
          panel: {
            sx: {
              "& .MuiDataGrid-panelContent": { overflow: "unset" },
            },
          },
        }}
        rowCount={props.tripData.totalCount}
        filterMode="client"
      />
    </Box>
  );
}

export default TripDataGridV2;
