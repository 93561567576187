import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useMutation } from "@apollo/client";

import {
  DataGridPro,
  GridCellEditCommitParams,
  LicenseInfo,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";

import { LoadFilterableDispatchTripsConnection } from "types";
import { useSnackbar, useAnalytics } from "globals/hooks";
import { UPDATE_ROUTE_MUTATION } from "globals/graphql";
import { useTripsViewConfig } from "./hooks/useTripsViewConfig";
import { moovsBlue, white } from "design-system/colors";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { CustomColumnsPanel, CustomLoadingOverlay } from "./components";

// set license
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

type TripDataGridProps = {
  tripData: LoadFilterableDispatchTripsConnection;
  loading: boolean;
  onFetchMore: () => void;
  refetchTripsData: () => void;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"loading" | "default" | "error" | "saved">
  >;
};

function TripDataGrid(props: TripDataGridProps) {
  const { loading, onFetchMore, refetchTripsData, setSaveIndicatorState } =
    props;

  // hooks
  const apiRef = useGridApiRef();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const {
    columns,
    onColumnVisibilityChange,
    onColumnOrderChange,
    onColumnWidthChange,
  } = useTripsViewConfig({ apiRef, setSaveIndicatorState, refetchTripsData });

  // derived state
  const tripData = useMemo(() => {
    return props.tripData.edges.map(({ node }) => node);
  }, [props.tripData]);

  // event handlers
  const handleOnRowsScrollEnd = () => {
    if (tripData.length < props.tripData.totalCount) {
      onFetchMore();
    }
  };

  // mutations
  const [updateRoute] = useMutation(UPDATE_ROUTE_MUTATION, {
    onCompleted() {
      track("reservationTripView_driverNoteUpdated");
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      const errorMessage =
        getErrorMessage(error) || "Error updating driver note.";
      snackbar.error(errorMessage);
    },
  });

  const handleRowEditCommit = useCallback(
    async (params: GridCellEditCommitParams) => {
      // @ts-ignore fix later
      const { row, value, field } = params;

      // commit changes server-side
      if (field === "driverNote") {
        setSaveIndicatorState("loading");

        if (row.routes?.[0]?.id) {
          updateRoute({
            variables: {
              input: {
                id: row.routes[0].id,
                driverNote: value,
              },
            },
          });
        } else {
          setSaveIndicatorState("error");
          snackbar.error("Could not update driver note - route not found");
        }
      }
    },
    [updateRoute, setSaveIndicatorState, snackbar]
  );

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          // to account for the fixed position footer so that the last row is also visible
          paddingBottom: "52px",
        },
        "& .MuiDataGrid-cell--editable: hover": {
          cursor: "pointer",
        },
        "& .MuiDataGrid-cell--editing": {
          border: `2px solid ${moovsBlue}`,
          borderRadius: "4px",
        },
        "& .MuiDataGrid-footerContainer": {
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "100%",
          paddingRight: 6, // to account for fab icon
          backgroundColor: white,
        },
        backgroundColor: white,
      }}
    >
      <DataGridPro
        apiRef={apiRef}
        disableColumnFilter
        disableColumnPinning
        disableSelectionOnClick
        disableChildrenSorting
        hideFooterPagination
        disableMultipleColumnsSorting
        aria-label="Dispatch Table"
        paginationMode="server"
        sortingMode="server"
        loading={loading}
        onCellEditCommit={handleRowEditCommit}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        onColumnVisibilityChange={onColumnVisibilityChange}
        onColumnOrderChange={onColumnOrderChange}
        columns={columns}
        onColumnWidthChange={onColumnWidthChange}
        rows={tripData}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          ColumnsPanel: CustomColumnsPanel,
        }}
        rowCount={props.tripData.totalCount}
      />
    </Box>
  );
}

export default TripDataGrid;
