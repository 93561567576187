import React from "react";
import capitalize from "lodash/capitalize";
import startCase from "lodash/startCase";
import { Box, Link, Typography } from "@mui/material";

import {
  grayLight,
  orangeDark,
  tintOrange,
  white,
  moovsBlueDark,
  moovsBlueHover,
} from "design-system/colors";
import RoundChip from "design-system/components/chips/RoundChip";
import { useOperator } from "globals/hooks";
import { format } from "globals/reactStringFormat/index";
import { SalesAutomationText, User } from "types";

type SalesAutomationTextContentProps = {
  requestId: string;
  orderNumber: string;
  text: SalesAutomationText;
  sender: User;
};

function SalesAutomationTextContent(props: SalesAutomationTextContentProps) {
  const { requestId, orderNumber, text, sender } = props;

  // hooks
  const operator = useOperator();
  if (!text) return null;

  // components
  const recipient = (
    <>
      <RoundChip
        label={capitalize(text.recipient.firstName)}
        inputProps={{
          style: {
            backgroundColor: grayLight,
            height: "30px",
            fontWeight: "normal",
          },
        }}
      />
    </>
  );

  const senderName = (
    <>
      <RoundChip
        label={capitalize(sender.firstName)}
        inputProps={{
          style: {
            backgroundColor: tintOrange,
            height: "30px",
            fontWeight: "normal",
            color: orangeDark,
          },
        }}
      />
    </>
  );

  const link = (
    <Box>
      <Link
        target="_blank"
        sx={{ textDecoration: "none" }}
        href={`${process.env.REACT_APP_CUSTOMERAPP_URL}/${operator.nameSlug}/order/${requestId}`}
      >{`customer.moovs.app/${operator.nameSlug}/order/${orderNumber}`}</Link>
      <br />
      <br />
    </Box>
  );

  const company = (
    <>
      <RoundChip
        label={startCase(operator.name)}
        inputProps={{
          style: {
            backgroundColor: moovsBlueHover,
            height: "30px",
            fontWeight: "normal",
            color: moovsBlueDark,
          },
        }}
      />
    </>
  );

  const emailContent = format(text.content, {
    recipient,
    link,
    company,
    sender: senderName,
  });

  return (
    <Box
      bgcolor={white}
      height="100%"
      p={2}
      sx={{
        whiteSpace: "pre-line",
        border: `1px solid ${grayLight}`,
        borderRadius: "4px",
      }}
    >
      <Typography variant="overline">MESSAGE</Typography>
      <Box mt={3}>{emailContent}</Box>
    </Box>
  );
}

export default SalesAutomationTextContent;
