import React, { useState, useEffect, ChangeEvent, useMemo } from "react";
import { useMutation } from "@apollo/client";

import { Box, Typography, TextField } from "@mui/material";

import { PaymentMethodEnum, Invoice, CreateInvoicePaymentInput } from "types";
import PaymentSummarySection from "components/invoices/PaymentSummarySection";
import MoovsDialog from "components/MoovsDialog";
import { CREATE_INVOICE_PAYMENT_MUTATION } from "globals/graphql";
import { useSnackbar, useAnalytics, useOperator } from "globals/hooks";
import { currency } from "globals/utils/helpers";
import { PaymentMethodDropdown } from "./components";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

// constants
const initialPaymentErrors = {
  method: "",
  depositPct: "",
};

type CreateInvoicePaymentDialogProps = {
  open: boolean;
  onClose: () => void;
  invoice: Invoice;
  invoiceRefetch: any;
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function CreateInvoicePaymentDialog(props: CreateInvoicePaymentDialogProps) {
  const { open, onClose, invoice, setSaveIndicatorState, invoiceRefetch } =
    props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const operator = useOperator();

  // state
  const [noteError, setNoteError] = useState(null);
  const [invoicePaymentErrors, setInvoicePaymentErrors] =
    useState(initialPaymentErrors);
  const [invoicePayment, setInvoicePayment] = useState<
    Partial<CreateInvoicePaymentInput>
  >({
    note: null,
    paymentMethodId: null,
    method: null,
  });
  const [submitDisabled, setSubmitDisabled] = useState(false);

  // derived state
  // Contact or Affiliates Invoice
  const contactOrAffiliatePaymentMethods = useMemo(
    () =>
      (invoice?.contact
        ? invoice.contact.paymentMethods
        : invoice?.farmAffiliate
        ? invoice.farmAffiliate.paymentMethods
        : []) || [],
    [invoice]
  );

  // Company Invoice
  const companyPaymentMethods = useMemo(
    () => invoice?.company && invoice.company.paymentMethods,
    [invoice.company]
  );

  // Booking Contacts of a Company Invoice
  const companyBookingContactPaymentMethods = useMemo(() => {
    const companyBookingContacts = new Set();
    const methods = [];

    if (!invoice?.company) return methods;

    invoice.requests.forEach((request) => {
      const {
        bookingContact: { firstName, lastName, paymentMethods, id },
      } = request;

      const fullName = `${firstName} ${lastName}`;

      if (!companyBookingContacts.has(id)) {
        companyBookingContacts.add(id);
        methods.push({ fullName, paymentMethods });
      }
    });

    return methods;
  }, [invoice.requests, invoice?.company]);

  const trips = invoice?.requests.map((request) => request.trips).flat(1);

  const constituentPayments = trips
    ?.map((trip) => {
      return {
        routeId: trip.routes[0].id,
        amount: trip.amountDue,
      };
    })
    .filter(({ amount }) => amount > 0);

  const compositeAmount = constituentPayments?.reduce(
    (compositeAmount, constituentPayment) =>
      compositeAmount + constituentPayment.amount,
    0
  );

  const hasMoovsPayments = !!operator?.stripeAccountId;

  const paymentMethodValue =
    invoicePayment.paymentMethodId || invoicePayment.method || "";

  const beforeAmountDue = invoice?.requests.reduce(
    (beforeAmountDue, request) => beforeAmountDue + request.amountDue,
    0
  );

  // effects
  // payment method setter
  useEffect(() => {
    if (open) {
      setInvoicePaymentErrors(initialPaymentErrors);

      // Contact or Affiliate Invoice
      const primaryOrOnlyPaymentMethod =
        contactOrAffiliatePaymentMethods.length === 1
          ? contactOrAffiliatePaymentMethods[0]
          : contactOrAffiliatePaymentMethods.find(
              (paymentMethod) => paymentMethod.isPrimary
            );

      setInvoicePayment({
        note: null,
        ...(primaryOrOnlyPaymentMethod
          ? {
              method: PaymentMethodEnum.Card,
              paymentMethodId: primaryOrOnlyPaymentMethod.id,
            }
          : { method: null }),
      });
    }
  }, [open, contactOrAffiliatePaymentMethods]);

  // mutations
  const [createInvoicePayment] = useMutation(CREATE_INVOICE_PAYMENT_MUTATION, {
    onCompleted() {
      track("reservation_chargeCreated");
      setSaveIndicatorState("saved");
      invoiceRefetch();
      handleClose();
    },
    onError(error) {
      setSubmitDisabled(false);
      setSaveIndicatorState("error");
      snackbar.error(getErrorMessage(error));
    },
  });

  // event handlers
  const handleClickSave = () => {
    let errors = {
      method: "",
      depositPct: "",
    };
    if (!invoicePayment.method) {
      errors.method = "Select a payment method";
    }
    if (errors.method || errors.depositPct) {
      setInvoicePaymentErrors(errors);
      return;
    }

    setSubmitDisabled(true);

    createInvoicePayment({
      variables: {
        input: {
          ...invoicePayment,
          invoiceId: invoice.id,
        },
      },
    });
  };

  const handleInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;

    if (event.target.name === "note") {
      value.toString().length >= 1000
        ? setNoteError("Reached character limit")
        : setNoteError(null);
    }

    setInvoicePaymentErrors({
      ...invoicePaymentErrors,
      [event.target.name]: "",
    });

    setInvoicePayment({
      ...invoicePayment,
      [event.target.name]: value,
    });
  };

  const handleMethodInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let method: PaymentMethodEnum;
    let paymentMethodId: string;

    if (Object.values<string>(PaymentMethodEnum).includes(event.target.value)) {
      method = event.target.value as PaymentMethodEnum;
    } else {
      paymentMethodId = event.target.value;
      method = PaymentMethodEnum.Card;
    }

    setInvoicePaymentErrors({
      ...invoicePaymentErrors,
      method: "",
    });

    setInvoicePayment({
      ...invoicePayment,
      method: method,
      paymentMethodId: paymentMethodId || null,
    });
  };

  const handleClose = () => {
    setInvoicePaymentErrors(initialPaymentErrors);
    invoiceRefetch();
    onClose();
    setSubmitDisabled(false);
  };

  return (
    <MoovsDialog
      onAccept={handleClickSave}
      acceptButtonText={`Charge ${currency(compositeAmount)}`}
      fixedFooter
      onClose={handleClose}
      open={open}
      size="xs"
      dialogTitle="Charge Customer"
      acceptDisabled={submitDisabled}
    >
      <PaymentSummarySection
        beforeAmountDue={beforeAmountDue}
        totalAmount={compositeAmount}
      />

      <Box mt={1} display="flex" flexDirection="column">
        <Box mb={1}>
          <Typography variant="overline">Amount</Typography>
          <TextField
            required
            fullWidth
            variant="outlined"
            placeholder="Enter Amount"
            name="amount"
            disabled
            value={currency(compositeAmount) || ""}
          />
        </Box>

        <Box mb={1} flex="1">
          <PaymentMethodDropdown
            invoicePaymentErrors={invoicePaymentErrors}
            paymentMethodValue={paymentMethodValue}
            handleMethodInput={handleMethodInput}
            hasMoovsPayments={hasMoovsPayments}
            contactOrAffiliatePaymentMethods={contactOrAffiliatePaymentMethods}
            companyPaymentMethods={companyPaymentMethods}
            companyBookingContactPaymentMethods={
              companyBookingContactPaymentMethods
            }
          />
        </Box>
      </Box>

      <Box mt={1} mb={3}>
        <Typography variant="overline">Note</Typography>
        <TextField
          inputProps={{ maxLength: 1000 }}
          error={!!noteError}
          helperText={noteError}
          required
          fullWidth
          multiline
          variant="outlined"
          name="note"
          placeholder="Add note"
          value={invoicePayment.note || ""}
          onChange={handleInput}
        />
      </Box>
    </MoovsDialog>
  );
}

export default CreateInvoicePaymentDialog;
