import React from "react";

import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  alabaster,
  black,
  granite,
  grayDark,
  grayLight,
  grayMedium,
  moovsBlue,
  moovsBlueHover,
  moovsBlueSelected,
} from "../design-system/colors";
import { useScreenSize } from "globals/hooks";

type MoovsToggleButtonGroupProps = {
  options: // one of icon or label is required
  (
    | {
        ariaLabel?: string;
        value: string;
        icon: any;
        dataTestId?: string;
        label?: string;
      }
    | {
        ariaLabel?: string;
        value: string;
        icon?: any;
        dataTestId?: string;
        label: string;
      }
  )[];
  value: any;
  onChange: (_: any, newType) => void;
  sx?: Record<string, unknown>;
  hideIconsOnMobile?: boolean;
  size?: "small" | "large";
  fullWidth?: boolean;
  keepInline?: boolean;
  overrideNoWrap?: boolean;
  disabled?: boolean;
  tooltipText?: string;
  // using testId instead of dataTestId to avoid conflicts with options.dataTestId
  testId?: string;
};

function MoovsToggleButtonGroup(props: MoovsToggleButtonGroupProps) {
  const {
    options,
    value,
    onChange,
    sx,
    hideIconsOnMobile,
    size = "small",
    fullWidth,
    keepInline,
    overrideNoWrap,
    disabled,
    tooltipText,
    testId = "",
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // derived state
  const shouldHideIcons = isMobileView && hideIconsOnMobile;
  const fullWidthOnMobile = isMobileView && fullWidth;

  // event handler
  const handleToggle = (e: React.MouseEvent<HTMLElement>, variant: unknown) => {
    if (onChange) {
      onChange(e, variant);
    }
  };

  const content = (
    <ToggleButtonGroup
      exclusive
      value={value}
      onChange={handleToggle}
      fullWidth={fullWidthOnMobile}
      disabled={disabled}
      data-testid={testId}
    >
      {options.map((toggleButton, i) => {
        const { icon, label, ariaLabel = "", dataTestId = "" } = toggleButton;

        const Icon = icon;

        const isSelected = toggleButton.value === value;

        return (
          <ToggleButton
            sx={[
              sx,
              {
                padding: "12px",
                "&.MuiToggleButton-root": {
                  border: `${grayMedium} 1px solid !important`,
                  color: disabled ? grayDark : black,
                  ":hover": {
                    backgroundColor: alabaster,
                  },
                  ":focus": {
                    backgroundColor: grayLight,
                  },
                  "&.Mui-selected": {
                    backgroundColor: moovsBlueSelected,
                    color: black,
                    zIndex: 1,
                    ":hover": {
                      backgroundColor: `${moovsBlueHover} !important`,
                    },
                    ":focus": {
                      backgroundColor: `${moovsBlueHover} !important`,
                    },
                    "& .MuiTypography-root": {
                      color: moovsBlue,
                      opacity: disabled ? 0.5 : 1,
                    },
                  },
                },
              },
            ]}
            key={i}
            disableRipple
            value={toggleButton.value}
            aria-label={ariaLabel}
            data-testid={dataTestId}
            disabled={isSelected}
          >
            <Box
              display="flex"
              flexDirection={isMobileView && !keepInline ? "column" : "row"}
              justifyContent="center"
              alignItems="center"
            >
              {/* icon */}
              {icon && !shouldHideIcons && (
                <Icon
                  color={toggleButton.value === value ? moovsBlue : granite}
                  size={size}
                />
              )}
              {/* spacing, only needed if both icon and label */}
              {icon && !shouldHideIcons && label && <Box width="8px" />}
              {/* label */}
              {label && (
                <Typography
                  {...(!overrideNoWrap && { noWrap: true })}
                  variant="button"
                  lineHeight="1.28"
                  color={
                    disabled
                      ? grayDark
                      : toggleButton.value === value
                      ? moovsBlue
                      : granite
                  }
                  mt={fullWidthOnMobile && !keepInline && 1}
                >
                  {label}
                </Typography>
              )}
            </Box>
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );

  if (!disabled || !tooltipText) return content;

  return (
    <Tooltip title={tooltipText} placement="top">
      {content}
    </Tooltip>
  );
}

export default MoovsToggleButtonGroup;
