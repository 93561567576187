import React, { useState, useEffect, useCallback } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useHistory, useLocation, useParams } from "react-router-dom";
import first from "lodash/first";

import { Box, CircularProgress, Typography } from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import UpdateDrawer from "../../globals/UpdateDrawer";
import {
  LOAD_TRIP_QUERY,
  LOAD_REQUEST_QUERY,
  UPDATE_ROUTE_MUTATION,
  REMOVE_TRIP_MUTATION,
  UNDO_REMOVE_TRIP_MUTATION,
  UNDO_CLOSE_OUT_TRIP_MUTATION,
  LOAD_AUTOMATED_BASE_RATE,
} from "../../../globals/graphql";
import RoundChip from "../../../design-system/components/chips/RoundChip";
import {
  errorRed,
  purple,
  granite,
  grayDark,
  orange,
  tintPurple,
  tintPink,
  tintOrange,
} from "../../../design-system/colors";
import TopOverviewBar from "../../globals/TopOverviewBar";
import GQLQueryStatusIndicator from "../../GQLQueryStatusIndicator";
import {
  QuotesIcon,
  UsersIcon,
  VehicleIcon,
  TripIcon,
  EstimationIcon,
  ClosedIcon,
  ReopenIcon,
  DurationIcon,
  DollarIcon,
  DueIcon,
} from "../../../design-system/icons";
import {
  confirmationNumberFromRequest,
  convertMinutesToHoursMinutes,
  currency,
  getColorForFarmStatus,
} from "../../../globals/utils/helpers";
import {
  Vehicle,
  Request,
  Trip,
  FarmAffiliateVariantEnum,
} from "../../../types";
import RemoveDialog from "../../RemoveDialog";
import MoovsTabs from "../../globals/MoovsTabs";
import OverviewTabView from "../../requests/update/OverviewTabView";
import MoneyTabView from "../../requests/update/MoneyTabView";
import CommentUpdateBlock from "../../CommentUpdateBlock";
import { useSnackbar, useAnalytics } from "../../../globals/hooks";
import MoovsConfetti from "../../../design-system/components/MoovsConfetti";
import { shapeStopsToAutomatedBaseRateQueryStopsArg } from "globals/graphql/automatedPricing";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import {
  useDeleteTripClick,
  useDuplicateTripClick,
  useAddReturnTripClick,
} from "pages/reservations/components/ActionButtonPanel/components/ViewMoreActionButton/hooks";
import { LuggageFields } from "../luggage/types";
import DuplicateTripDialog from "pages/reservations/components/ActionButtonPanel/components/ViewMoreActionButton/components/DuplicateTripDialog";
import { useSuggestedAddressInfo } from "./hooks";
import { getRequestStage } from "globals/utils/getRequestStage";

type UpdateRequestDrawerProps = {
  mode: "reservations" | "quotes";
};

function UpdateRequestDrawer(props: UpdateRequestDrawerProps) {
  const { mode } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const history = useHistory();
  const location = useLocation();
  const { requestId, tripId } = useParams<{
    requestId: string;
    tripId: string;
  }>();

  // state
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "loading" | "saved" | "error"
  >("default");
  const [showAutomatedBaseRateIcon, setShowAutomatedBaseRateIcon] =
    useState(false);
  const [tabMode, setTabMode] = useState("overview");
  const [confetti, setConfetti] = useState(false);
  const [backNavigationPath, setBackNavigationPath] = useState(null);

  const shouldSkipQuery = !tripId;

  // queries
  const {
    data: tripData,
    error: tripError,
    refetch: tripRefetch,
    loading: tripLoading,
  } = useQuery(LOAD_TRIP_QUERY, {
    variables: {
      id: tripId,
    },
    fetchPolicy: "network-only",
    skip: shouldSkipQuery,
    ...(!shouldSkipQuery && { pollInterval: 60 * 1000 }), // every 60 seconds
  });

  const {
    data: requestData,
    loading: requestLoading,
    refetch: requestRefetch,
  } = useQuery(LOAD_REQUEST_QUERY, {
    variables: {
      id: tripData?.node?.requestId,
    },
    skip: !tripData,
  });

  const trip: Trip = tripData?.node;
  const request: Request = requestData?.node;
  const confirmationNumber = confirmationNumberFromRequest(request, trip);
  const routeId = trip?.routes[0].id;
  const affiliateIsMoovsAi =
    first(trip?.routes)?.farmAffiliateVariant ===
    FarmAffiliateVariantEnum.MoovsAiOperator;

  const [
    getAutomatedBaseRate,
    { data: baseRate, loading: automatedBaseRateLoading },
  ] = useLazyQuery(LOAD_AUTOMATED_BASE_RATE, {
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || "Failed to get automated base rate.";

      snackbar.error(errorMessage);
    },
  });

  // mutations
  const [updateRoute] = useMutation(UPDATE_ROUTE_MUTATION, {
    refetchQueries: ["Requests"],
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || "Error adding vehicle to route.";

      snackbar.error(errorMessage);
    },
  });

  const [removeTrip] = useMutation(REMOVE_TRIP_MUTATION, {
    refetchQueries: ["Requests", "Request", "LoadTripConflict"],
    onCompleted(data) {
      // tracks reservation_deleted and quote_deleted
      track(`${mode}_deleted`);
      snackbar.success(`Successfully deleted ${confirmationNumber}!`, {
        onUndo: () => handleClickUndo(data.removeTrip),
      });
    },
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || `Error deleting ${confirmationNumber}`;

      snackbar.error(errorMessage);
    },
  });

  const [undoRemoveTrip] = useMutation(UNDO_REMOVE_TRIP_MUTATION, {
    refetchQueries: ["Requests"],
    onCompleted() {
      snackbar.success(`Successfully retrieved ${confirmationNumber}!`);
    },
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || "Error retrieving deleted reservation.";

      snackbar.error(errorMessage);
    },
  });

  const [undoCloseOutTrip] = useMutation(UNDO_CLOSE_OUT_TRIP_MUTATION, {
    refetchQueries: ["Requests"],
    onCompleted() {
      snackbar.success(`Successfully reopened trip!`);
      setConfetti(false);
    },
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || "Error reopening your trip.";
      snackbar.error(errorMessage);
    },
  });

  const handleClickRemove = () => {
    removeTrip({
      variables: {
        input: {
          tripId: trip.id,
        },
      },
    });
    deleteTripMenuOption.onClose();
    history.push(`/${mode}/${requestId}`);
  };

  const handleClickUndo = (removedTrip) => {
    undoRemoveTrip({
      variables: {
        input: {
          tripId: trip.id,
          // only in case of deleting a trip on roundtrip.
          ...(trip.returnTrip?.id && { returnTripId: trip.returnTrip.id }),
          ...(removedTrip?.outboundTrip?.id && {
            outboundTripId: removedTrip.outboundTrip.id,
          }),
        },
      },
    });
    history.push(`/${mode}/${requestId}/update/${trip.id}`);
  };

  const handleClose = () => {
    // for now this 1st condition is met only when coming from dispatch
    if (backNavigationPath?.backNavigationPath) {
      history.push(`${backNavigationPath.backNavigationPath}`);
    } else if (mode === "quotes") {
      history.push(`/quotes/${requestId}/${location.search}`);
    } else if (mode === "reservations") {
      history.push(`/reservations/${requestId}/${location.search}`);
    }
  };

  const addVehicles = (vehicles: Partial<Vehicle>[]) => {
    updateRoute({
      variables: {
        input: {
          id: routeId,
          vehicleId: vehicles[0].id,
        },
      },
    });
  };

  const handleTabChange = (newValue: string) => {
    setTabMode(newValue);
  };

  const handleReopenTripClick = () => {
    undoCloseOutTrip({ variables: { input: { tripId } } });
  };

  const adjustLuggageAmount = useCallback(
    (luggageAmount: LuggageFields) => {
      setSaveIndicatorState("loading");
      updateRoute({
        variables: {
          input: {
            id: routeId,
            ...luggageAmount,
          },
        },
      });
    },
    [routeId, updateRoute]
  );

  const handleGoToVehicle = () => {
    track("goTo_pageSelected");
    history.push(`/vehicles/update/${trip.routes[0].vehicle.id}`);
  };

  const handleGoToBookingContact = () => {
    track("goTo_pageSelected");
    history.push(`/contacts/update/${request.bookingContact.id}`);
  };

  // hooks (continued...)
  const duplicateTripMenuOption = useDuplicateTripClick({
    trip,
    analyticsName:
      request && `${getRequestStage(request.stage)}_tripDuplicated`,
  });

  const deleteTripMenuOption = useDeleteTripClick({
    trip,
    analyticsName: request && `${getRequestStage(request.stage)}_tripDeleted`,
  });

  const addReturnTripMenuOption = useAddReturnTripClick({
    request,
    trip,
  });

  const { suggestedAddressInfo } = useSuggestedAddressInfo({ request });

  const automatedBaseRate = baseRate?.automatedBaseRate;

  useEffect(() => {
    setShowAutomatedBaseRateIcon(!!automatedBaseRate);
  }, [automatedBaseRate]);

  const stops = trip?.stops;
  const vehicleId = trip?.routes[0]?.vehicle?.id;
  const isVehicleBaseRateAutomated =
    trip?.routes[0]?.vehicle?.enableBaseRateAutomation;

  // if requirements are met, get automated pricing
  useEffect(() => {
    if (stops?.length) {
      const firstStop = stops[0];
      const lastStop = stops[stops.length - 1];

      if (
        isVehicleBaseRateAutomated &&
        (firstStop.location || firstStop.airport) &&
        firstStop.dateTime &&
        (lastStop.location || lastStop.airport)
      ) {
        getAutomatedBaseRate({
          variables: {
            vehicleId: vehicleId,
            stops: shapeStopsToAutomatedBaseRateQueryStopsArg(stops),
          },
        });
      }
    }
  }, [vehicleId, isVehicleBaseRateAutomated, getAutomatedBaseRate, stops]);

  // sometimes backNavigationPath is undefined due to some race condition
  // save the path in a state when the string exists
  useEffect(() => {
    if (history.location?.state && !backNavigationPath) {
      const path = history.location?.state as {
        backNavigationPath: string;
      };

      setBackNavigationPath(path);
    }
  }, [history, location, backNavigationPath]);

  const isCancelledTrip = !!trip?.cancelledAt;
  const isClosedTrip = !!trip?.closedAt;

  const ellipsisMenuOptions =
    mode === "quotes" && trip && request
      ? [
          duplicateTripMenuOption,
          addReturnTripMenuOption,
          "divider",
          "GO TO",
          {
            onClick: () => {},
            text: "Quote",
            icon: <QuotesIcon color={grayDark} size="small" />,
            disableOption: true,
          },
          {
            onClick: handleGoToBookingContact,
            text: "Booking Contact",
            icon: <UsersIcon color={grayDark} size="small" />,
            disableOption: !request.bookingContact,
          },
          {
            onClick: handleGoToVehicle,
            text: "Vehicle",
            icon: <VehicleIcon color={grayDark} size="small" />,
          },
          "divider",
          deleteTripMenuOption,
        ]
      : mode === "reservations" && trip && request
      ? [
          {
            onClick: handleReopenTripClick,
            text: "Reopen Trip",
            icon: <ReopenIcon color={grayDark} />,
            disableOption: !isClosedTrip,
          },
          duplicateTripMenuOption,
          addReturnTripMenuOption,
          "divider",
          "GO TO",
          {
            onClick: () => {},
            text: "Trip",
            icon: <TripIcon color={grayDark} size="small" />,
            disableOption: true,
          },
          {
            onClick: handleGoToBookingContact,
            text: "Booking Contact",
            icon: <UsersIcon color={grayDark} size="small" />,
            disableOption: !request.bookingContact,
          },
          {
            onClick: handleGoToVehicle,
            text: "Vehicle",
            icon: <VehicleIcon color={grayDark} size="small" />,
          },
          "divider",
          deleteTripMenuOption,
        ]
      : ["Loading"];

  return (
    <>
      <UpdateDrawer
        onClose={handleClose}
        updatedAt={tripData && trip?.updatedAt}
        saveIndicatorState={saveIndicatorState}
        ellipsisMenuOptions={ellipsisMenuOptions}
        drawerBorderColor={getColorForFarmStatus.circleAndBorderColor(
          request?.farmedRouteStatus
        )}
        {...(isCancelledTrip && { drawerBorderColor: errorRed })}
        {...(isClosedTrip &&
          trip &&
          request && {
            headerContent: (
              <Box display="flex" mr={3}>
                <Box mr={1}>
                  <ClosedIcon color={grayDark} />
                </Box>
                <Typography variant="caption" style={{ color: granite }}>
                  Closed Trip
                </Typography>
              </Box>
            ),
            isEntityClosedOut: true,
          })}
      >
        <MoovsConfetti active={confetti} />
        {(requestLoading || tripLoading) && !requestData && (
          <Box
            display="flex"
            flex="1"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={40} thickness={2} />
          </Box>
        )}

        {tripError && !tripLoading && (
          <Box
            display="flex"
            flex="1"
            alignItems="center"
            justifyContent="center"
          >
            <GQLQueryStatusIndicator
              name={mode === "quotes" ? "quote" : "reservation"}
              error={tripError}
              refetch={tripRefetch}
            />
          </Box>
        )}

        {trip && request && (
          <>
            <TopOverviewBar
              items={[
                {
                  title: "conf. no.",
                  value: confirmationNumber,
                  icon: (
                    <AssignmentTurnedInIcon
                      viewBox="-4 -4 30 30"
                      style={{ color: grayDark }}
                    />
                  ),
                },
                {
                  title: trip.useTotalDuration
                    ? "total duration"
                    : "est. duration",
                  value: trip.useTotalDuration ? (
                    convertMinutesToHoursMinutes(trip.totalDuration)
                  ) : (
                    <Box display="flex" alignItems="center">
                      <Box mr={0.5}>
                        {convertMinutesToHoursMinutes(trip.estimatedDuration)}
                      </Box>
                      <EstimationIcon size="small" />
                    </Box>
                  ),
                  icon: (
                    <DurationIcon
                      color={grayDark}
                      viewBox="-9 -9 31 31"
                      height="20"
                      width="20"
                    />
                  ),
                },
                mode === "quotes" && {
                  title: "status",
                  value: (
                    <RoundChip
                      variant={
                        request.isExpired
                          ? "EXPIRED"
                          : request.isArchived
                          ? "ARCHIVED"
                          : request?.status
                      }
                    />
                  ),
                },
                ...(mode === "reservations"
                  ? [
                      {
                        title: "amount due",
                        value: currency(trip?.amountDue),
                        icon: (
                          <DueIcon
                            color={grayDark}
                            height="22"
                            width="22"
                            viewBox="-5 -5 30 30"
                          />
                        ),
                        tooltip: { title: "Total amount of all transactions." },
                      },
                      {
                        title: "total amount",
                        value: currency(trip?.totalAmount),
                        icon: (
                          <DollarIcon color={grayDark} center="-4 -4 30 30" />
                        ),
                        tooltip: {
                          title:
                            "The amount you are owed after payments are settled.",
                        },
                      },
                      {
                        title: "status",
                        value: (
                          <RoundChip
                            label={
                              {
                                PAID: "PAID",
                                NOT_PAID: "NOT PAID",
                                PARTIAL: "PARTIAL",
                              }[trip?.paymentStatus] || "N/A"
                            }
                            color={
                              {
                                PAID: purple,
                                NOT_PAID: errorRed,
                                PARTIAL: orange,
                              }[trip?.paymentStatus] || grayDark
                            }
                            backgroundColor={
                              {
                                PAID: tintPurple,
                                NOT_PAID: tintPink,
                                PARTIAL: tintOrange,
                              }[trip?.paymentStatus] || grayDark
                            }
                          />
                        ),
                      },
                    ]
                  : []),
              ]}
            />
            {mode === "reservations" && !affiliateIsMoovsAi && (
              <MoovsTabs
                onChange={handleTabChange}
                value={tabMode}
                tabs={[
                  { label: "OVERVIEW", value: "overview" },
                  { label: "MONEY", value: "money" },
                ]}
              />
            )}
            <OverviewTabView
              hidden={mode === "reservations" && tabMode !== "overview"}
              trip={trip}
              tripRefetch={tripRefetch}
              setSaveIndicatorState={setSaveIndicatorState}
              suggestedAddressInfo={suggestedAddressInfo}
              mode={mode}
              addVehicles={addVehicles}
              requestRefetch={requestRefetch}
              showAutomatedBaseRateIcon={showAutomatedBaseRateIcon}
              automatedBaseRateLoading={automatedBaseRateLoading}
              automatedBaseRate={automatedBaseRate}
              adjustLuggageAmount={adjustLuggageAmount}
            />
            {mode === "reservations" && (
              <MoneyTabView
                hidden={tabMode !== "money"}
                setSaveIndicatorState={setSaveIndicatorState}
                requestRefetch={requestRefetch}
              />
            )}

            {!affiliateIsMoovsAi && (
              <Box my={5} pt={4}>
                <CommentUpdateBlock
                  mode="trip"
                  comments={trip.comments}
                  tripId={trip.id}
                  refetchQuery={requestRefetch}
                  setSaveIndicatorState={setSaveIndicatorState}
                />
              </Box>
            )}
          </>
        )}
      </UpdateDrawer>
      <RemoveDialog
        open={deleteTripMenuOption.open}
        onRemove={handleClickRemove}
        onClose={deleteTripMenuOption.onClose}
        title="Are you sure you want to delete this trip?"
        body="This trip will be removed from your reservation, invoice, and dispatch pages. You will not be able to see these deleted trips on saved contacts."
        removeButtonText="Delete"
      />
      <DuplicateTripDialog
        open={duplicateTripMenuOption.open}
        onClose={duplicateTripMenuOption.onClose}
        request={request}
        tripId={tripId}
        isParentDrawer
      />
    </>
  );
}

export default UpdateRequestDrawer;
