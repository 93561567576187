import { MutableRefObject, useEffect, useMemo, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";

import { alabaster } from "design-system/colors";

// constants
const INITIAL_CENTER_COORDNIATES = { lat: 38.8977, lng: -77.0365 };
const INITIAL_ZOOM = 8;

type UseInitializeMapProps = {
  googleMapRef: MutableRefObject<undefined>;
};

function useInitializeMap(props: UseInitializeMapProps) {
  const { googleMapRef } = props;

  // inside component to prevent google undefined error
  const mapOptions: google.maps.MapOptions = useMemo(
    () => ({
      backgroundColor: alabaster,
      clickableIcons: false,
      disableDefaultUI: true,
      zoomControl: true,
      zoomControlOptions: google?.maps?.ControlPosition?.TOP_LEFT
        ? {
            position: google.maps.ControlPosition.TOP_LEFT,
          }
        : undefined,
      center: INITIAL_CENTER_COORDNIATES,
      zoom: INITIAL_ZOOM,
    }),
    []
  );

  // state
  const [map, setMap] = useState<google.maps.Map>(null);
  const [geocoder, setGeocoder] = useState(null);
  const [bounds, setBounds] = useState(null);

  // initialize map
  useEffect(() => {
    const init = async () => {
      if (!google) {
        const loader = new Loader({
          apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
          version: "weekly",
          libraries: ["places"],
        });

        await loader.load();
      }

      // init geocoder
      const geocoder = new google.maps.Geocoder();
      setGeocoder(geocoder);

      // init bounds
      const bounds = new google.maps.LatLngBounds();
      setBounds(bounds);

      // create map with initial options
      const map = new google.maps.Map(googleMapRef.current, mapOptions);

      setMap(map);
    };

    if (!map) {
      init();
    }
  }, [googleMapRef, map, mapOptions]);

  return {
    map,
    geocoder,
    bounds,
  };
}

export { useInitializeMap };
