import React, { forwardRef } from "react";
import isNull from "lodash/isNull";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Box, CircularProgress, Divider, Typography } from "@mui/material";

import {
  SearchResult,
  RecentSearch,
  CreateRecentSearchInput,
  RequestStage,
} from "types";
import { granite, white, grayDark } from "design-system/colors";
import { CREATE_RECENT_SEARCH_MUTATION } from "globals/graphql";
import { useKeyPress } from "globals/hooks/useKeyPress";
import { useAnalytics } from "globals/hooks";
import { GlobalSearchResultListItem } from "./components";
import { SearchResultTypename } from "../../types";

// constants
const SEARCH_RESULTS_HEIGHT = "120px";

const { Quote } = RequestStage;
const { Company, Contact, Request } = SearchResultTypename;

type GlobalSearchResultListProps = {
  searchResults: SearchResult[];
  recentSearches: RecentSearch[];
  variant: "mobile" | "desktop";
  onGlobalSearchClose: () => void;
  activeSearchResultIndex: number;
  loading: boolean;
};

function GlobalSearchResultList(
  props: GlobalSearchResultListProps,
  inputRef: any
) {
  const {
    searchResults,
    recentSearches,
    variant,
    onGlobalSearchClose,
    activeSearchResultIndex,
    loading,
  } = props;

  // hooks
  const history = useHistory();
  const { track } = useAnalytics();

  // mutations
  const [createRecentSearch] = useMutation(CREATE_RECENT_SEARCH_MUTATION);

  // event handlers
  const handleSearchResultClick = (searchResult: SearchResult) => {
    const { __typename, id } = searchResult || {};

    const input: CreateRecentSearchInput = {
      searchTerm: getDisplayName(searchResult),
    };

    track("Global Search Result Click", {
      result_type: __typename,
    });

    if (__typename === Request) {
      input.requestId = id;
    }
    if (__typename === Contact) {
      input.contactId = id;
    }
    if (__typename === Company) {
      input.companyId = id;
    }

    createRecentSearch({
      variables: {
        input,
      },
    });

    onGlobalSearchClose();

    // delays as history push interupts closing global search otherwise
    setTimeout(() => history.push(getRedirectLink(searchResult)), 0);
  };

  useKeyPress("Enter", {
    ref: inputRef,
    action: () => {
      const activeSearchResult = [
        ...(searchResults || []),
        ...(recentSearches?.map(({ searchResult }) => searchResult) || []),
      ][activeSearchResultIndex];

      handleSearchResultClick(activeSearchResult);
    },
  });

  // helpers
  const getRedirectLink = (searchObject) => {
    const { __typename, id, stage } = searchObject;
    let redirectLink: string;

    if (__typename === Request) {
      if (stage === Quote) {
        redirectLink = `/quotes/${id}`;
      } else {
        redirectLink = `/reservations/${id}`;
      }
    }
    if (__typename === Contact) {
      redirectLink = `/contacts/update/${id}`;
    }
    if (__typename === Company) {
      redirectLink = `/companies/update/${id}`;
    }

    return redirectLink;
  };

  const getDisplayName = (newValue: SearchResult) => {
    const { __typename } = newValue;

    if (__typename === Request) {
      return `${newValue.orderNumber}`;
    }
    if (__typename === Contact) {
      return `${newValue.firstName} ${newValue.lastName}`;
    }
    if (__typename === Company) {
      return newValue.name;
    }
    return "";
  };

  return (
    <Box>
      {loading && (
        <>
          <Box
            display="flex"
            flex="1"
            width="100%"
            alignItems="center"
            justifyContent="center"
            height={SEARCH_RESULTS_HEIGHT}
          >
            <CircularProgress size={24} />
          </Box>
          <Box mx={3}>
            <Divider />
          </Box>
        </>
      )}

      {!loading && !isNull(searchResults) && (
        <>
          <Box display="flex" flex="1" flexDirection="column" p={1}>
            {!!searchResults?.length ? (
              searchResults.map((searchResult: SearchResult, index) => (
                <GlobalSearchResultListItem
                  onClick={handleSearchResultClick}
                  key={searchResult.id}
                  searchResult={searchResult}
                  isSelected={index === activeSearchResultIndex}
                />
              ))
            ) : (
              <Typography sx={{ ml: 2 }} variant="body2">
                No Results
              </Typography>
            )}
          </Box>
          <Box mx={3}>
            <Divider />
          </Box>
        </>
      )}

      <Box pb={1}>
        <Box px={3} pt={variant === "mobile" ? 3 : 1.5}>
          <Typography
            sx={{ backgroundColor: white, color: grayDark }}
            variant={variant === "mobile" ? "h6" : "overline"}
          >
            RECENTLY VIEWED
          </Typography>
        </Box>
        <Box mx={1} mt={1.5}>
          {recentSearches.length > 0 ? (
            recentSearches.map((recentSearch: RecentSearch, index: number) => {
              return (
                <GlobalSearchResultListItem
                  onClick={handleSearchResultClick}
                  key={recentSearch.id}
                  isSelected={
                    index + (searchResults?.length || 0) ===
                    activeSearchResultIndex
                  }
                  {...recentSearch}
                />
              );
            })
          ) : (
            <Box mx={1}>
              <Typography variant="body2" style={{ color: granite }}>
                No recent searches
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default forwardRef(GlobalSearchResultList);
